import { Link } from "react-router-dom";
import { Img } from "../../components/Img";

const PanelshopsPageCard = ({ img, link }) => {
  return (
    <Link
      to={link}
      target="_blank"
      className=" bg-white_A700 flex flex-1 flex-col items-center justify-start outline outline-[1px]
       outline-black_900_0a p-2 rounded-radius13  shadow-bs w-[100%] dark:bg-[#171719]"
    >
      <Img
        src={`${process.env.REACT_APP_API_URL}/storage/uploads/img/category/${img}`}
        className="w-full object-contain h-[80px] md:h-[60px] sm:h-[40px]  rounded-radius11"
        alt="logo Seventeen"
      />
    </Link>
  );
};

export default PanelshopsPageCard;

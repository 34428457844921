import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../api/api";
import { PrimmaryContext } from "../MainContext/MainContext";
import { Line } from "../Line";
import LanguageSwitcherUser from "../Languages/LanguageSwitcherUser";
import { useTranslation } from "react-i18next";
import { FaSun } from "react-icons/fa";
import { FaRegMoon } from "react-icons/fa";

//icons
import {
  BsPerson,
  BsGear,
  BsBoxArrowRight,
} from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { Switch } from "../CustomInputs/CustomSwitchComponent";

const UserPanelSmallMenu = ({ needHeadRoute = false, closeFunction }) => {
  //translations
  const { t } = useTranslation();
  //page navigation
  const navigate = useNavigate();
  //user and token setters
  const { setToken, setUser, handleThemeSwitch } = useContext(PrimmaryContext);
  //logout error
  const [logoutErr, setLogoutErr] = useState(null);
  //dark mode
  const [isDark, setIsDark] = useState('0');

  const handleDarkMode = (value, _) => {
    setIsDark(value)
    handleThemeSwitch()
  }

  //logout api
  const handleLogoutApi = async () => {
    try {
      const res = await axiosClient.get(`/Auth/logout`);
    } catch (error) {
      setLogoutErr(error);
    }
  };

  //logout button functionality
  const handleLogout = () => {
    handleLogoutApi();
    localStorage.removeItem("EXPR_TOKEN");
    setToken({});
    setUser("");
    navigate("/");
  };

  useEffect(() => {
    setIsDark(localStorage.getItem("exprDark") === "dark" ? '1' : '0')
  }, [])

  return (
    <div
      className="w-[280px] font-notosansgeorgian rounded-[17px] bg-white py-[8px] absolute top-[105%] right-0 z-30 shadow-bs
        outline outline-[1px] outline-black_900_0a md:left-0 sm:left-0 dark:bg-[#171719]"
    >
      <div className="px-[8px]">
        <Link
          to={
            needHeadRoute
              ? "/panelroutes/panelsettingsprofile"
              : "../panelsettingsprofile"
          }
          onClick={() => { if (closeFunction) closeFunction() }}
        >
          <div className="flex items-center gap-3 px-[16px] py-[12px]">
            <BsPerson className="font-bold text-[#636366] dark:text-[#aeaeb2]" />
            <p className="text-[#636366] text-[15px] font-bold dark:text-[#aeaeb2]">{t("profile")}</p>
          </div>
        </Link>
        <Link
          to={needHeadRoute ? "/panelroutes/panelsettings" : "../panelsettings"}
          onClick={() => { if (closeFunction) closeFunction() }}
        >
          <div className="flex items-center gap-3 px-[16px] py-[12px]">
            <BsGear className="font-bold text-[#636366] dark:text-[#aeaeb2]" />
            <p className="text-[#636366] text-[15px] font-bold dark:text-[#aeaeb2]">{t("parametrs")}</p>
          </div>
        </Link>
        <Link
          to={
            needHeadRoute
              ? "/panelroutes/paneltransactions"
              : "../paneltransactions"
          }
          onClick={() => { if (closeFunction) closeFunction() }}
        >
          <div className="flex items-center gap-3 px-[16px] py-[12px]">
            <FaMoneyBillWave className="font-bold text-[#636366] dark:text-[#aeaeb2]" />
            <p className="text-[#636366] text-[15px] font-bold dark:text-[#aeaeb2]">{t("transactions")}</p>
          </div>
        </Link>
      </div>
      <Line className="bg-black_900_14 h-[1px] w-[100%]" />
      <div className="px-[8px]">
        <div className="cursor-pointer px-[16px] py-[12px] flex items-center justify-between">
          <div className="flex items-center gap-3">
            {isDark === "1" ?
              <FaRegMoon className="font-bold text-[#636366] dark:text-[#aeaeb2]" /> :
              <FaSun className="font-bold text-[#636366] dark:text-[#aeaeb2]" />
            }
            <p className="text-[#636366] text-[15px] font-bold dark:text-[#aeaeb2]">{t("dark_mode")}</p>
          </div>
          <Switch
            inputSetter={handleDarkMode}
            inputName={""}
            className="h-[100%]"
            value={isDark}
          />
        </div>
        <div className="md:hidden sm:hidden">
          <LanguageSwitcherUser />
        </div>
      </div>
      <Line className="bg-black_900_14 h-[1px] w-[100%] md:hidden sm:hidden" />
      <div className="px-[8px]">
        <div
          className="flex items-center gap-3 px-[16px] py-[12px] cursor-pointer"
          onClick={handleLogout}
        >
          <BsBoxArrowRight className=" text-[#FF3A30] font-bold " />
          <p className="text-[#636366] text-[15px] font-bold dark:text-[#aeaeb2]">{t("log_out")}</p>
        </div>
      </div>
    </div>
  );
};

export default UserPanelSmallMenu;

import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Text } from "../Text";
import { Button } from "../Button";
import { Img } from "../Img";
import BalanceModal from "../BalanceModal/BalanceModal";
import UserPanelMenuItem from "./UserPanelMenuItem";
import { PrimmaryContext } from "../MainContext/MainContext";
import { useTranslation } from "react-i18next";

//icons
import {
  HiPlus,
  HiOutlineLightBulb,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import { BiWallet, BiSend } from "react-icons/bi";
import { CgChevronRight } from "react-icons/cg";
import { FaBoxes, FaStore } from "react-icons/fa";
import { TbPlane } from "react-icons/tb";
import { BsEnvelope, BsTruck } from "react-icons/bs";

//images
import expressLogo from "../../img/expresLogo.png";

const UserPanelMenu = () => {
  //translations 
  const { t } = useTranslation();
  //useLocation for current page tracking
  const location = useLocation();
  //navigate functio for navgating between pages
  const navigate = useNavigate();
  //current page tracker variable
  const [currentPage, setCurrentPage] = useState("panelpackageswarehouse");
  //show balance
  const [showBalance, setShowBalance] = useState(false);
  const { moneyData, shouldDeclare } = useContext(PrimmaryContext);

  //current page tracker handler
  const handleCurrentPageChange = (pageName) => {
    setCurrentPage(pageName);
  };

  //balance toggl handler
  const handleShowBalance = () => {
    setShowBalance((prev) => !prev);
  };

  //location effect
  useEffect(() => {
    const currentLocation = location.pathname.split("/");
    setCurrentPage(currentLocation[currentLocation.length - 1]);
  }, []);

  const menuData = [
    {
      name: t("parcels"),
      pathName: "panelpackageswarehouse",
      Icon: FaBoxes,
      hasCounter: true,
      count: shouldDeclare,
    },
    {
      name: t("flights"),
      pathName: "panelflights",
      Icon: TbPlane,
    },
    {
      name: t("addresses"),
      pathName: "paneladdresses",
      Icon: HiOutlineLocationMarker,
    },
    {
      name: t("shops"),
      pathName: "panelshops",
      Icon: FaStore,
    },
    {
      name: t("instructions"),
      pathName: "panelfaq",
      Icon: HiOutlineLightBulb,
    },
    {
      name: t("contact"),
      pathName: "panelcontact",
      Icon: BiSend,
    },
    {
      name: t("codes"),
      pathName: "messeges",
      Icon: BsEnvelope,
    },
    {
      name: t("courier_clearance"),
      pathName: "courier-clearance",
      Icon: BsTruck,
    },
  ];


  return (
    <div>
      <aside
        className={
          "flex flex-col md:px-[0px] sm:px-[0px] w-[420px] md:hidden sm:hidden"
        }
      >
        <div className="flex flex-col items-center justify-start w-[100%] md:h-full sm:h-full">
          <div
            className="flex flex-col items-start justify-center  pl-[32px] py-[32px] sm:pl-[0px] 
                sm:py-[0px] md:pl-[0px] md:py-[0px] sm:w-full md:w-full w-[auto] md:h-full sm:h-full"
          >
            <div
              className="bg-white_A700 flex flex-col items-start justify-start outline outline-[1px] sm:h-screen
                    outline-black_900_0a rounded-radius17 md:self-stretch sm:self-stretch shadow-bs w-[388px] sm:w-full md:w-full
                    md:h-full dark:bg-[#171719]"
            >
              <div className="flex w-full items-center justify-between py-4 px-6 cursor-pointer">
                <Img
                  src={expressLogo}
                  onClick={() => navigate("/")}
                  className="h-[40px] rounded-tl-[17px] rounded-tr-[17px]"
                  alt="head"
                />
              </div>
              <div className="bg-gray_600_14 border-solid border-white_A700 border-x-bw2 flex flex-col items-start justify-start sm:px-[20px] px-[24px] py-[16px] w-[100%]">
                <div className="flex flex-row gap-[16px] items-center justify-start w-[100%]">
                  <Button className="flex h-[40px] items-center justify-center w-[40px] dark:bg-[#171719]">
                    <BiWallet className="text-teal_A700 dark:text-[#c7c7c7]" />
                  </Button>
                  <div className="flex flex-1 flex-col items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                    <div className="flex flex-row gap-[8px] items-start justify-start w-[220px]">
                      <Text
                        className="font-fw text-black_900_b7 text-left w-[auto] dark:text-[#c7c7c7]"
                        variant="body7"
                      >
                        {t("ballance")}:
                      </Text>
                      <Text
                        className="font-semibold text-left text-teal_A700 w-[auto]"
                        variant="body5"
                      >
                        {moneyData.balance && parseFloat(moneyData.balance).toFixed(2)} ₾
                      </Text>
                    </div>
                    <div className="flex flex-row gap-[8px] items-center justify-start w-[auto]">
                      <Text
                        className="font-fw text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
                        variant="body9"
                      >
                        {t("payable")}:
                      </Text>
                      <Text
                        className="font-semibold text-left text-red_A400 w-[auto]"
                        variant="body7"
                      >
                        {moneyData.debt && parseFloat(moneyData.debt).toFixed(2)} ₾
                      </Text>
                      <CgChevronRight className="font-fw text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]" />
                    </div>
                  </div>
                  <Button
                    onClick={handleShowBalance}
                    className="flex h-[36px] items-center justify-center w-[36px]"
                    shape="icbCircleBorder20"
                    size="mdIcn"
                    variant="icbOutlineBlack9000a_2"
                  >
                    <HiPlus className="text-white" />
                  </Button>
                </div>
              </div>
              <div className="flex flex-col gap-[8px] h-[100%] items-start justify-start sm:px-[20px] px-[24px] py-[40px] w-[100%]">
                {menuData.map((item, index) => {
                  return (
                    <UserPanelMenuItem
                      key={index}
                      name={item.name}
                      pathName={item.pathName}
                      Icon={item.Icon}
                      hasCounter={item.hasCounter}
                      currentPage={currentPage}
                      handleCurrentPageChange={handleCurrentPageChange}
                      count={item.count}
                      img={item.pathName === "messeges" ? true : false}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </aside>
      <BalanceModal
        showBalance={showBalance}
        handleShowBalance={handleShowBalance}
      />
    </div>
  );
};

export default UserPanelMenu;


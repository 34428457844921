import { useEffect, useState } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import FaqCard from "./FaqCard";
import {
  MultyLangualTextInput,
  SelectInputValueId,
  TextInput,
} from "../../inputComponents/inputcomp";
import TextEditor from "../../inputComponents/TextEditor";

const FaqLayout = () => {
  //add new user variable
  const [addNewToggler, setAddNewToggler] = useState(false);

  //get faq data from api
  const [faqData, setFaqData] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //new faq data
  const [newFaq, setNewFaq] = useState({
    mother: 0,
    title: "",
    titleen: "",
    titleru: "",
    text: "",
    texten: "",
    textru: "",
  });

  //handle new faq
  const handleNewFaqData = (value, inputName) => {
    setNewFaq((prev) => ({ ...prev, [inputName]: value }));
  };

  //add new users handlers
  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  //add new faq
  const handleAddNewFaqToDb = async () => {
    try {
      const res = await axiosAdmin.post(`/Faq/add_post`, newFaq);
      setDifResponses(res);
      if (res) {
        successToast("კითხვა წარმატებით დაემატა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //delete faq
  const handleDeleteFaq = async (id) => {
    try {
      const res = await axiosAdmin.get(`/Faq/delete/${id}`);
      setDifResponses(res);
      if (res) {
        successToast("კითხვა წარმატებით წაიშალა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //faq data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Faq/index`).then(({ data }) => {
        setFaqData(data);
      });
    } catch (error) {}
  }, [difResponses]);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
      <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5">
        <div className="w-full flex items-center justify-end ">
          <button
            onClick={() => {
              handleAddNewToggle(true);
            }}
            className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400"
          >
            ახალი ხდკ-ს დამატება
          </button>
        </div>
        {addNewToggler && (
          <div className="bg-slate-100 dark:bg-slate-900">
            <div className="sm:px-0">
              <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                <div className="flex items-center justify-between flex-wrap gap-5">
                  <div className="flex items-center gap-5 sm:gap-3 ">
                    <div>
                      <h2
                        className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]"
                      >
                        ახალი ხდკ-ს დამატება
                      </h2>
                    </div>
                  </div>
                  <div className="flex gap-2 sm:w-full">
                    <button
                      onClick={handleAddNewFaqToDb}
                      className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
                    >
                      შენახვა
                    </button>
                    <button
                      onClick={() => {
                        handleAddNewToggle(false);
                      }}
                      className="rounded-lg p-3 text-white text-sm bg-red-400 w-10"
                    >
                      X
                    </button>
                  </div>
                </div>
                <SelectInputValueId
                  name={"კატეგორიები"}
                  options={[
                    {
                      name: "გადახდები",
                      value: 1,
                    },
                    {
                      name: "ზოგადი",
                      value: 2,
                    },
                    {
                      name: "ინსტუქციები (ვიდეოები)",
                      value: 3,
                    },
                  ]}
                  inputName={"mother"}
                  inputSetter={handleNewFaqData}
                />
                <MultyLangualTextInput
                  name={"ფილიალის სახელი"}
                  geInputName={"title"}
                  enInputName={"titleen"}
                  ruInputName={"titleru"}
                  inputSetter={handleNewFaqData}
                />
                <TextEditor
                  name={"პასუხი"}
                  geInputName={"text"}
                  enInputName={"texten"}
                  ruInputName={"textru"}
                  getData={handleNewFaqData}
                />
                <TextInput
                  name={"youtube url"}
                  inputName={"video"}
                  inputSetter={handleNewFaqData}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-full min-h-screen">
        <div className="p-10 pt-0 sm:p-5 sm:pt-0 grid grid-cols-2 gap-5 sm:grid-cols-1">
          {faqData.map((item) => {
            return (
              <FaqCard
                quistion={item.title && item.title}
                answer={item.text && item.text}
                id={item.id}
                key={item.id}
                video={item.video && item.video}
                handleDeleteFaq={handleDeleteFaq}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FaqLayout;

import React from "react";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import PanelHead from "../../components/PanelHead/PanelHead";
import { useTranslation } from "react-i18next";

import { BsBell, BsBuildingDown } from "react-icons/bs";
import { BiMap, BiLockAlt } from "react-icons/bi";

import { Link } from "react-router-dom";

const PanelsettingsPage = () => {
  //translations 
  const { t } = useTranslation();

  return (
    <div
      className="min-h-screen flex flex-1 flex-col font-firago gap-[36px] items-start
         justify-start max-w-[1500px] md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] sm:gap-8
         sm:pb-[80px] md:pb-[80px]"
    >
      <PanelHead name={t("parametrs")} />
      <Text
        className="font-semibold text-black_900_e0 dark:text-[#ededed] text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
          hidden sm:block md:block"
        variant="body1"
      >
        {t("parametrs")}
      </Text>
      <div className="relative w-[100%]">
        <div className="flex flex-col inset-[0] items-center justify-start m-[auto] w-[100%]">
          <div
            className="md:gap-[20px] sm:gap-[20px] gap-[32px] grid sm:grid-cols-1 md:grid-cols-2 
              lg:grid-cols-2  grid-cols-3 justify-center min-h-[auto] w-[100%]"
          >
            <Link to="../panelsettingssecurity">
              <div className="common-pointer bg-white_A700 dark:bg-[#171719] flex flex-1 flex-col gap-[24px] h-[100%] items-start justify-start outline outline-[1px]
                 outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 shadow-bs w-[100%]">
                <Button
                  className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                  variant="icbFillIndigo40014"
                  shape="circular"
                >
                  <BiLockAlt className="h-[24px] w-[24px] text-indigo_400" />
                </Button>
                <div className="flex flex-col gap-[4px] items-start justify-start w-[100%]">
                  <Text
                    className="font-bold font-firago text-black_900_e0 dark:text-[#ededed] text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    variant="body3"
                  >
                    {t("security")}
                  </Text>
                  <Text
                    className="font-normal font-notosansgeorgian not-italic text-black_900_99 dark:text-[#aeaeb2] text-left w-[auto]"
                    variant="body7"
                  >
                    {t("view_or_change_your_password")}
                  </Text>
                </div>
              </div>
            </Link>
            <Link to="../panelsettingsaddresses">
              <div className="common-pointer bg-white_A700 dark:bg-[#171719] flex flex-1 flex-col gap-[24px] items-start justify-start outline outline-[1px] outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]">
                <Button
                  className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                  variant="icbFillIndigo40014"
                  shape="circular"
                >
                  <BiMap className="h-[24px] w-[24px] text-indigo_400" />
                </Button>
                <div className="flex flex-col gap-[4px] items-start justify-start w-[100%]">
                  <Text
                    className="font-bold font-firago text-black_900_e0 dark:text-[#ededed] text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    variant="body3"
                  >
                    {t("my_addresses")}
                  </Text>
                  <Text
                    className="font-normal font-notosansgeorgian leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 dark:text-[#aeaeb2] text-left"
                    variant="body7"
                  >
                    {t("manage_your_address_for_better_courier_service")}
                  </Text>
                </div>
              </div>
            </Link>
            <Link to="../panelsettingsfilials">
              <div className="common-pointer bg-white_A700 dark:bg-[#171719] flex flex-1 flex-col gap-[24px] items-start justify-start outline outline-[1px] outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]">
                <Button
                  className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                  variant="icbFillIndigo40014"
                  shape="circular"
                >
                  <BsBuildingDown className="h-[24px] w-[24px] text-indigo_400" />
                </Button>
                <div className="flex flex-col gap-[4px] items-start justify-start w-[100%]">
                  <Text
                    className="font-bold font-firago text-black_900_e0 dark:text-[#ededed] text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    variant="body3"
                  >
                    {t("filials")}
                  </Text>
                  <Text
                    className="font-normal font-notosansgeorgian leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 dark:text-[#aeaeb2] text-left"
                    variant="body7"
                  >
                    {t("manage_your_address_for_better_courier_service")}
                  </Text>
                </div>
              </div>
            </Link>
            <Link to="../panelsettingsnotifi">
              <div className="common-pointer bg-white_A700 dark:bg-[#171719] flex flex-1 flex-col gap-[24px] h-[100%] items-start justify-start outline outline-[1px] outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 shadow-bs w-[100%]">
                <Button
                  className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                  variant="icbFillIndigo40014"
                  shape="circular"
                >
                  <BsBell className="h-[24px] w-[24px] text-indigo_400" />
                </Button>
                <div className="flex flex-col gap-[4px] items-start justify-start w-[100%]">
                  <Text
                    className="font-bold font-firago text-black_900_e0 dark:text-[#ededed] text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    variant="body3"
                  >
                    {t("notification")}
                  </Text>
                  <Text
                    className="font-normal font-notosansgeorgian leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 dark:text-[#aeaeb2] text-left"
                    variant="body7"
                  >
                    {t("manage_the_information_you_want_to_receive_via_SMS_and_Email")}
                  </Text>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelsettingsPage;

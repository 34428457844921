import { Link } from "react-router-dom";
import { useState } from "react";
import ContinueComponent from "../../../../components/Alerts/ContinueComponent";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";

//icons
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";
import { useContext } from "react";

const FlightsItem = ({ id, number, date, handleDeleteFlights, sendDate }) => {
  //user
  const { user } = useContext(AdminPrimaryContext);
  //show contnue
  const [showContinue, setShowContinue] = useState(false);

  return (
    <div className="flex py-2 bg-white dark:bg-slate-700 p-2 rounded-lg justify-between">
      <div className="w-[24%] flex flex-col gap-1">
        <p className="text-gray-400 text-[12px]">რეისის ნომერი</p>
        <p className="text-gray-500 text-[12px]">{number}</p>
      </div>
      <div className="w-[24%] flex flex-col gap-1">
        <p className="text-gray-400 text-[12px]">სავარაუდო ჩამოსვლის დრო</p>
        <p className="text-gray-500 text-[12px]">{date}</p>
      </div>
      <div className="w-[24%] flex flex-col gap-1">
        <p className="text-gray-400 text-[12px]">რეისის გამოგზავნის დრო</p>
        <p className="text-gray-500 text-[12px]">{sendDate}</p>
      </div>
      <div className="w-[24%] flex justify-end gap-1">
        {(user.role === 1 || user.role === 100) && (
          <>
            <Link
              to={`../flights/${id}`}
              className="w-8 h-8 rounded-lg bg-violet-400
                                flex gap-2 items-center justify-center p-1 py-3 cursor-pointer"
            >
              <PencilSquare className="text-white font-bold" />
            </Link>
            <div
              onClick={() => {
                setShowContinue(true);
              }}
              className="w-8 h-8 rounded-lg bg-red-400
                                flex gap-2 items-center justify-center p-1 py-3 cursor-pointer"
            >
              <BsTrash className="text-white font-bold" />
            </div>
          </>
        )}
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeleteFlights}
        id={id}
      />
    </div>
  );
};

export default FlightsItem;

import { Link } from "react-router-dom";
import { useContext } from "react";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import { useState } from "react";
import ContinueComponent from "../../../../components/Alerts/ContinueComponent";

//icons
import { PencilSquare } from "react-bootstrap-icons";
import { BsGraphUp, BsTrash } from "react-icons/bs";

const UserCard = ({
    id,
    name,
    nickname,
    mail,
    phone,
    handleDeleteAdmin
}) => {
    //user
    const { user } = useContext(AdminPrimaryContext);
    //show continues
    const [showContinue, setShowContinue] = useState(false);

    return (
        <div className="bg-white rounded-xl p-5 w-full relative flex flex-col items-center justify-center gap-3 dark:bg-slate-700">
            {user.role === 100 &&
                <div
                    onClick={() => { setShowContinue(true) }}
                    className="w-8 h-8 rounded-lg bg-red-400 flex items-center justify-center
            absolute top-3 right-3 cursor-pointer">
                    <BsTrash className="text-white" />
                </div>
            }
            <div className="w-full flex flex-col gap-2">
                <h2 className="text-2xl font-bold text-[#777a83] dark:text-navy-100 dark:text-gray-200">{name}</h2>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">მომხმარებელი:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{nickname}</p>
                </div>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">ელ-ფოსტა:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{mail}</p>
                </div>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">ტელეფონი:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{phone}</p>
                </div>
            </div>
            <div className="w-full flex gap-2 md:flex-col sm:flex-col">
                {user.role === 100 &&
                    <Link to={`../users/${id}`} className="w-full rounded-lg bg-violet-400
                flex gap-2 items-center justify-center p-1 py-3">
                        <PencilSquare className="text-white font-bold" />
                        <p className="text-white text-sm font-bold">შეცვლა</p>
                    </Link>
                }
                <Link to={`../users/st/${id}`} className="w-full rounded-lg bg-green-400
                 flex gap-2 items-center justify-center p-1 py-3">
                    <BsGraphUp className="text-white font-bold" />
                    <p className="text-white text-sm font-bold">სტატისტიკა</p>
                </Link>
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDeleteAdmin}
                setShowContinue={setShowContinue}
                id={id}
            />
        </div>
    )
}


export default UserCard;

import { useEffect, useState } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput } from "../../inputComponents/inputcomp";

const Contact = () => {
    //contact data
    const [contactData, setContactData] = useState({
        c_mail: "info@expressgroup.ge",
        fb: "https://www.facebook.com/ExpressGroupGeorgia/",
        ins: "https://www.instagram.com/expressgroup.ge",
        mail: "info@expressgroup.ge",
        phone: "032-2197192",
        yp: "https://www.facebook.com/ExpressGroupGeorgia/",
    });
    //get contact data error from api
    const [contactDataErr, setContactDataErr] = useState(null);
    //get contact data error from api
    const [filialEditErr, setContactEditErr] = useState(null);

    //handle new filial
    const handleNewContactData = (value, inputName) => {
        setContactData(prev => ({ ...prev, [inputName]: value }))
    }

    //add new contact
    const handleEditContact = async () => {
        try {
            const res = await axiosAdmin.post(`Pages/contact_update`, contactData);
            if (res) {
                successToast("მონაცემები წარმატებით განახლდა!");
            }
        } catch (error) {
            setContactEditErr(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //contact data from api
    useEffect(() => {
        try {
            axiosAdmin.get(`/Pages/contact`).then(({ data }) => {
                setContactData(data);
            });
        } catch (error) {
            setContactDataErr(error);
        }
    }, []);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900 
        min-h-screen">
            <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5" >
                <div className="bg-slate-100 dark:bg-slate-900 mx-5 sm:mx-0">
                    <div className="sm:px-0">
                        <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                            <div className="flex items-center justify-between flex-wrap gap-5">
                                <div className="flex items-center gap-5 sm:gap-3 ">
                                    <div>
                                        <h2 className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]">კონტაქტების განახლება</h2>
                                    </div>
                                </div>
                                <div className="flex gap-2 sm:w-full">
                                    <button
                                        onClick={handleEditContact}
                                        className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                                        შენახვა
                                    </button>
                                </div>
                            </div>
                            <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                                <div className="w-6/12 sm:w-full md:w-full">
                                    <TextInput
                                        name={"ტელ.ნომერი"}
                                        inputName={"phone"}
                                        inputSetter={handleNewContactData}
                                        value={contactData.phone}
                                    />
                                    <TextInput
                                        name={"სისტემური ელ.ფოსტა"}
                                        inputName={"c_mail"}
                                        inputSetter={handleNewContactData}
                                        value={contactData.c_mail}
                                    />
                                    <TextInput
                                        name={"instagram"}
                                        inputName={"ins"}
                                        inputSetter={handleNewContactData}
                                        value={contactData.ins}
                                    />
                                </div>
                                <div className="w-6/12 sm:w-full md:w-full">
                                    <TextInput
                                        name={"ელ.ფოსტა"}
                                        inputName={"mail"}
                                        inputSetter={handleNewContactData}
                                        value={contactData.mail}
                                    />
                                    <TextInput
                                        name={"fb"}
                                        inputName={"fb"}
                                        inputSetter={handleNewContactData}
                                        value={contactData.fb}
                                    />
                                    <TextInput
                                        name={"youtube"}
                                        inputName={"yp"}
                                        inputSetter={handleNewContactData}
                                        value={contactData.yp}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;

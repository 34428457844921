import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MainContext } from "./components/MainContext/MainContext";
import { BrowserRouter } from "react-router-dom";

import "./styles/custom.css";
import "./styles/output.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MainContext>
        <App />
      </MainContext>
    </BrowserRouter>
  </React.StrictMode>
);

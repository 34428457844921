import { useContext, useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { successToast, errorToast } from '../../toastAlerts/ToastAlerts'

import { BsX } from 'react-icons/bs'
import { FaRegCopy } from 'react-icons/fa'

const SeeParcelPlaces = ({ parcels = [] }) => {
    const [toggle, setToggle] = useState(false)
    const [parcelInfo, setParcelInfo] = useState([])
    const [userInfo, setUser] = useState({
        user: "",
        roomId: "",
    })
    const [amount, setAmount] = useState(0)
    const divRef = useRef(null)

    const isFlightInInfo = (dataArray, searchValue) => {
        var result = false
        dataArray.forEach((object, objectIndex) => {
            object.group.forEach((text, textIndex) => {
                if (text.includes(searchValue)) {
                    result = { objectIndex: objectIndex, textIndex: textIndex }
                }
            })
        })
        return result
    }

    const handleCopyText = () => {
        if (divRef.current) {
            const textToCopy = divRef.current.innerText;

            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    successToast('Copied!');
                })
                .catch((err) => {
                    errorToast('Unable to copy text: ');
                });
        }
    };

    useEffect(() => {
        const tmp = parcels.filter((item) => item.show_user === 1)
        setAmount(tmp.length)
    }, [parcels])

    useEffect(() => {
        setParcelInfo([])
        setParcelInfo((prev) => {
            if (parcels.length === 0) {
                return prev;
            }
            const updatedParcelInfo = [...prev];
            parcels.forEach((item) => {
                const flightExists = isFlightInInfo(updatedParcelInfo, item.x + " - " + item.y)

                if (flightExists) {
                    const oldText = updatedParcelInfo[flightExists.objectIndex].group[flightExists.textIndex].split('|')
                    const newText = oldText[0] + " , " + item.tracking_number + " : " + item.total_weight + " KG" + " | " + oldText[1]
                    updatedParcelInfo[flightExists.objectIndex].group[flightExists.textIndex] = newText
                    updatedParcelInfo[flightExists.objectIndex].groupLength = updatedParcelInfo[flightExists.objectIndex].groupLength + 1
                }
                else {
                    if (item.show_user === 1) {
                        const newRecord =
                            item.tracking_number +
                            " : " +
                            item.total_weight +
                            " KG" + " | " +
                            item.x +
                            " - " +
                            item.y

                        const existingGroupIndex = updatedParcelInfo.findIndex(
                            (existingItem) => existingItem.groupName === item.flight_number
                        );

                        if (existingGroupIndex !== -1) {
                            updatedParcelInfo[existingGroupIndex] = {
                                groupName: item.flight_number,
                                group: [...updatedParcelInfo[existingGroupIndex].group, newRecord],
                                groupLength: updatedParcelInfo[existingGroupIndex].groupLength + 1
                            };
                        } else {
                            updatedParcelInfo.push({
                                groupName: item.flight_number,
                                group: [newRecord],
                                groupLength: 1
                            });
                        }

                    }
                }

            });
            var user = parcels[0].user;
            setUser({
                user:
                    user.user_type === 1
                        ? user.fname + " " + user.lname
                        : user.ceo_fname + " " + user.ceo_lname,
                roomId: "EG" + user.id,
            });
            return updatedParcelInfo;
        });

    }, [parcels]);

    return (
        <div>
            <div
                onClick={() => { setToggle(true) }}
                className="rounded-lg bg-green-400 w-full cursor-pointer
                 flex items-center justify-center gap-1 p-1 py-2 ">
                <p className='text-white text-center'>პირადი ნომრის მიხედვით</p>
            </div>
            {toggle &&
                <div className='w-full h-full flex items-center justify-center fixed top-0 left-0 bg-black bg-opacity-80 z-50'>
                    <div className='h-[50vh] rounded-2xl p-10 bg-white relative min-w-[30vw]'>
                        <div className='flex items-center gap-2  absolute top-5 right-5'>
                            <FaRegCopy
                                className='text-green-500 text-2xl cursor-pointer'
                                onClick={handleCopyText}
                            />
                            <BsX
                                className='text-red-500 text-4xl cursor-pointer'
                                onClick={() => { setToggle(false) }}
                            />
                        </div>

                        <div className='h-full overflow-y-scroll scrollbar' ref={divRef}>
                            <p className='text-sm text-slate-600'>{userInfo.user}</p>
                            <p className='text-sm text-slate-600'>{userInfo.roomId} - {amount} ამანათი</p>
                            <p className='text-sm text-slate-600'>--------------------------------</p>
                            {parcelInfo.length !== 0 && parcelInfo.map((item, index) => {
                                return <div key={index}>
                                    {item.group.map((item2, index2) => {
                                        const info = item2.split(",")
                                        return (<div key={index2}>
                                            {info.map((info, infoIndex) => {
                                                return <p key={infoIndex} className='text-sm text-slate-600' >
                                                    {info}
                                                </p>
                                            })}
                                            <br />
                                        </div>)

                                    })}
                                    <p className='text-sm text-slate-600' >
                                        {item.groupName} - {item.groupLength} ამანათი
                                    </p>
                                    <p className='text-sm text-slate-600'>--------------------------------</p>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default SeeParcelPlaces
import { useEffect, useState } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import HomeCard from "./HomeCard";

//icons
import { BsFillPersonFill } from "react-icons/bs";
import { FaBoxes } from "react-icons/fa";

const AdminHome = () => {
  //get data from api
  const [data, setData] = useState({
    added: 0,
    inway: 0,
    out: 0,
    received1: 0,
    received2: 0,
    total_users: 0
  });
  //get data error from api
  const [dataErr, setDataErr] = useState(null);
  //data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Home/index`).then(({ data }) => {
        setData(data);
      });
    } catch (error) {
      setDataErr(error);
    }
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 min-h-screen p-10 sm:p-5">
      <div className="sm:px-0 flex gap-5">
        <div
          className={`w-full relative max-lg:fixed max-lg:w-screen
                max-lg:bottom-0 max-lg:left-0 transition-all delay-50
                linear duration-300 `}
        >
          <div
            className="w-full rounded-xl"
          >
            <div className="flex items-center justify-center flex-wrap gap-3">
              <HomeCard
                name={"რეგ.მომხმარებლები"}
                value={data.total_users}
                Icon={BsFillPersonFill}
                color={"#6ccac9"}
              />
              <HomeCard
                name={"დამატებული"}
                value={data.added}
                Icon={FaBoxes}
                color={"#76c156"}
              />
              <HomeCard
                name={"მიღებული უცხოეთში"}
                value={data.received1}
                Icon={FaBoxes}
                color={"#f8d347"}
              />
              <HomeCard
                name={"გზაშია"}
                value={data.inway}
                Icon={FaBoxes}
                color={"#57c8f2"}
              />
              <HomeCard
                name={"ჩამოსული"}
                value={data.received2}
                Icon={FaBoxes}
                color={"#57c8f2"}
              />
              <HomeCard
                name={"გატანილი"}
                value={data.out}
                Icon={FaBoxes}
                color={"#57c8f2"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;

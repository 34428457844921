import { useState } from "react";
import { Button } from "../Button";
import { BsGlobe } from "react-icons/bs";
import { useEffect } from "react";
import { useContext } from "react";
import { PrimmaryContext } from "../MainContext/MainContext";

const LanguageSwitcher = () => {
    const { setLanguage, language } = useContext(PrimmaryContext);
    const [show, setShow] = useState(false);
    const [value, setValue] = useState(language);
    const languages = ["ge", "en", "ru"];

    const handleShow = () => {
        setShow(prev => !prev);
    }

    useEffect(() => {
        setLanguage(value);
    }, [value])

    return (
        <div className="relative">
            <Button
                onClick={handleShow}
                className="flex h-[42px] w-[42px] items-center justify-center"
                variant="icbFillIndigo40014"
            >
                <BsGlobe className="text-[#5856D6] font-[22px]" />
            </Button>
            {show &&
                <div className="rounded-lg absolute top-[105%] left-0 bg-indigo_400_14 w-full p-2">
                    {languages.map((value, id) => {
                        return (
                            <p key={id}
                                onClick={() => { handleShow(); setValue(value) }}
                                className="text-white hover:text-[#5856D6] text-[16px] cursor-pointer font-bold text-center uppercase">
                                {value}
                            </p>
                        )
                    })}
                </div>
            }
        </div >
    )
}

export default LanguageSwitcher;
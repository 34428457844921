import axiosAdmin from "../../../../../api/apiAdmin";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";

const InnerCuriorCard = ({
    data,
    setUpdater,
    curierId
}) => {

    const handleCurrior = (userId, address) => {
        axiosAdmin.post(`/Pack/transport_tings`, {
            user_id: userId,
            transport_address: address,
            flight_number: curierId
        })
            .then((res) => {
                successToast("წარმატებით გაფორმდა!");
                setUpdater(res);
            })
            .catch(() => {
                errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
            })
    }

    return (
        <div className="bg-white p-2 rounded-lg
        dark:bg-slate-700 grid grid-cols-2 gap-4">
            <div className="w-full">
                <p className="text-[#989ba5] text-[10px]">მომხმარებელი</p>
                <p className="text-[#858890] text-[12px]">
                    {data.user_type === 1 ?
                        `${data.fname + " " + data.lname} || ${data.fname_eng + " " + data.lname_eng}
                        - id: ${data.id} - პ.ნ: ${data.id_num}` :
                        `${data.ceo_fname + " " + data.ceo_lname} || ${data.ceo_fname_eng + " " + data.ceo_lname_eng}
                        - id: ${data.id} - ს.კ: ${data.company_id}`
                    }

                </p>
            </div>
            <div className="w-full">
                <p className="text-[#989ba5] text-[10px]">კონტაქტი</p>
                <p className="text-[#858890] text-[12px]">{data.phone}</p>
                <p className="text-[#858890] text-[12px]">{data.email}</p>
            </div>
            {data.user_packs.map((item) => {
                return (
                    <>
                        <div className="w-full">
                            <p className="text-[#989ba5] text-[10px]">მისამართი / მითითებული ნომერი / რაოდენობა</p>
                            <p className="text-[#858890] text-[12px]">
                                {item.transport_address}
                            </p>
                            <p className="text-[#858890] text-[12px]">
                                {item.transport_phone}
                            </p>
                            <p className="text-[#858890] text-[12px]">
                                {item.count} ამანათი
                            </p>
                            <p className="text-[#858890] text-[12px]">
                                ოფისი {item.branch}
                            </p>
                        </div>
                        <div className="w-full">
                            {(item.transport_ting[0] !== "1") ?
                                <button className="bg-red-300 p-4 rounded-lg text-white w-full"
                                    onClick={() => { handleCurrior(data.id, item.transport_address) }}>
                                    გასაფორმებელი
                                </button> :
                                <button className="bg-green-300 p-4 rounded-lg text-white w-full">
                                    გაფორმდა
                                </button>
                            }
                        </div>
                    </>
                )
            })}
        </div>
    )
}

export default InnerCuriorCard;
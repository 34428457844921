import { useState, useEffect } from "react";
import axiosClient from "../../api/api";
import { Text } from "../../components/Text";
import { Img } from "../../components/Img";
import { List } from "../../components/List";
import PanelHead from "../../components/PanelHead/PanelHead";
import PanelFlightsCard from "./PanelFlightsCard";
import { useTranslation } from "react-i18next";
import abOffices from "../../components/MainContext/data";

import chinaImg from "../../img/countryFlags/china.png";
import turkeyImg from "../../img/countryFlags/turkey.png";
import usaImg from "../../img/countryFlags/usa.png";
import greeceImg from "../../img/countryFlags/greece.png";

const PanelflightsPage = () => {
  const { t } = useTranslation();
  const [fightsData, setFlightsData] = useState([]);
  const [selectedAbOffice, setSelectedAbOffice] = useState("");

  //get current logged in user
  useEffect(() => {
    axiosClient
      .get(`/Flights/index?country=${selectedAbOffice}`)
      .then(({ data }) => {
        setFlightsData(data);
      })
      .catch((err) => {});
  }, [selectedAbOffice]);

  return (
    <div
      className="min-h-screen flex flex-1 flex-col font-firago gap-[36px] items-start justify-start max-w-[1500px]
       md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] sm:gap-8 sm:pb-[80px] md:pb-[80px]"
    >
      <Text
        className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
          hidden sm:block md:block dark:text-[#c7c7c7]"
        variant="body1"
      >
        {t("flights")}
      </Text>
      <PanelHead name={t("flights")} />
      <div
        className="bg-[#dedfe4] rounded-radius11 outline outline-[1px] outline-black_900_0a shadow-bs
                cursor-pointer p-[2px] flex items-center flex-wrap sm:justify-between dark:bg-[#171719]"
      >
        {abOffices.map((office) => {
          return (
            <div
              className={`flex items-center justify-center sm:w-[49%] gap-2  px-[16px] py-[8px] rounded-radius8
              ${
                selectedAbOffice === office.id &&
                "bg-white dark:bg-[#48484a] outline outline-[1px] outline-black_900_0a shadow-bs"
              }`}
              onClick={() => {
                setSelectedAbOffice(office.id);
              }}
              key={office.id}
            >
              <Img
                src={office.img}
                className="h-[32px] w-[32px] object-contain"
                alt="#"
              />
              <p
                className={`text-sm font-semibold text-[#636366] dark:text-[#c7c7c7] sm:text-xs text-center sm:w-full`}
              >
                {t(office.name)}
              </p>
            </div>
          );
        })}
      </div>

      <div className="font-notosansgeorgian relative w-[100%]">
        <div className="flex flex-col inset-[0] items-center justify-start m-[auto] w-[100%]">
          <List
            className="flex-col gap-[24px] grid  items-start w-[100%] sm:gap-2"
            orientation="vertical"
          >
            {fightsData.map((item) => {
              return <PanelFlightsCard key={item.id} data={item} />;
            })}
          </List>
        </div>
      </div>
    </div>
  );
};

export default PanelflightsPage;

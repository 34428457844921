import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";

const GoBackButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-white rounded-xl p-3 w-10 h-10 cursor-pointer dark:bg-[#171719]"
      onClick={() => {
        navigate(-1);
      }}
    >
      <BsArrowLeft className="w-full h-full text-[#8E8E93] font-normal dark:text-[#c7c7c7]" />
    </div>
  );
};

export default GoBackButton;

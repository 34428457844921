import React, { useState } from 'react';
import { useEffect } from 'react';

function ContinueComponent({ showFlag, actionFunction, id, showContinuemsg, setShowContinue, needAnyway, addedShelvess }) {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [addedShelves, setAddedShelves] = useState([])
    const [chosenShelf, setChosenShelf] = useState(["", ""])

    // Perform the desired action when the user confirms
    const handleContinue = (flag) => {
        if (needAnyway) {
            if (flag === "anyway") {
                actionFunction(true, ["", ""]);
            } else {
                actionFunction(true, chosenShelf, "ok");
            }
            setChosenShelf(["", ""])
        } else {
            if (id) {
                actionFunction(id);
            } else {
                actionFunction();
            }
        }
        setShowConfirmation(false);
    };

    // Handle cancellation or closing of the dialog
    const handleCancel = () => {
        setShowContinue(false);
        setShowConfirmation(false);
    };

    //show continue
    useEffect(() => {
        setShowConfirmation(showFlag);
    }, [showFlag])

    useEffect(() => {
        if (addedShelvess) {
            setAddedShelves(addedShelvess)
        }
    }, [addedShelvess])

    return (
        <>
            {showConfirmation && (
                <div className="fixed top-2 left-[50%] translate-x-[-50%] p-4 border border-gray-300 shadow-sm
                rounded-lg z-[90] bg-white">
                    <p className='text-gray-400 text-sm mt-2'>
                        {showContinuemsg ? showContinuemsg : "Are you sure you want to continue?"}
                    </p>
                    {addedShelves.length !== 0 &&
                        <div className='flex gap-2 flex-wrap'>
                            {addedShelves.map((item, index) => {
                                return <p
                                    className={`${chosenShelf[0] === item[0] && chosenShelf[1] === item[1] ? "text-green-500" : "text-blue-500 underline"}   cursor-pointer`}
                                    key={index}
                                    onClick={() => { setChosenShelf(item) }}>
                                    {`(${item[0]}, ${item[1]}) `}
                                </p>
                            })}
                        </div>
                    }
                    <div className='flex items-center justify-between'>
                        <button
                            onClick={handleContinue}
                            className='py-2 px-4 rounded-lg mt-2 bg-green-400 text-white
                            font-bold'
                        >
                            Ok
                        </button>
                        {needAnyway &&
                            (<button
                                onClick={() => { handleContinue("anyway") }}
                                className='py-2 px-4 rounded-lg mt-2 bg-blue-400 text-white font-bold'
                            >
                                Cont Anyway
                            </button>)
                        }
                        <button
                            onClick={handleCancel}
                            className='py-2 px-4 rounded-lg mt-2 bg-red-400 text-white
                            font-bold'
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ContinueComponent;
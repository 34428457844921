import { Link } from "react-router-dom";

const CuriorCard = ({
    code,
    amount
}) => {
    return (
        <Link to={`../curier/${code}`} className="w-[19%] bg-white rounded-lg p-3
        flex items-center justify-center dark:bg-slate-700 gap-2 relative
        border border-slate-300 dark:border-slate-500">
            <p className="text-slate-400">{code}</p>
            <p className="text-red-400 font-bold text-sm absolute top-1 right-1">
                {amount}
            </p>
        </Link>
    )
}


export default CuriorCard;
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Img } from "../../components/Img"
import CalculateVolume from "../../components/CalculateVolume/CalculateVolume"
import LanguageSwitcher from "../../components/Languages/LanguageSwitcher"
import FlightsModal from "../FlightsModal/FlightsModal"
import PanelfaqPageCard from "../Panelfaq/PanelfaqPageCard"

import axiosClient from "../../api/api"
import useScrollPosition from "../../components/CustomHooks/useScrollPosition"

import { TbPlane } from "react-icons/tb"

import expressLogo from "../../img/expresLogo.png";
import phoneLogo from "../../img/phoneLogo.png";

const Instructions = () => {
    //translations
    const { t } = useTranslation();
    //navigation
    const navigate = useNavigate();
    //scroll position
    const scrollPosition = useScrollPosition();
    //flights toggle variable
    const [flightsOpen, setflightsOpen] = useState(false);

    //flights toggle handler
    const handleflightsOpen = () => {
        setflightsOpen((prev) => !prev);
    };

    //faq categorys
    const categoryList = [
        {
            name: t("video_instructions"),
            value: 3,
        },
        {
            name: t("payment"),
            value: 1,
        },
        {
            name: t("general"),
            value: 2,
        },

    ];
    //faqs based on cateory
    const [faqCategory, setFaqCategory] = useState(3);
    //faqs data variable
    const [faqs, setFaqs] = useState([]);
    //error
    const [error, setError] = useState(null);

    //get current logged in user
    useEffect(() => {
        axiosClient
            .get(`/Faq/sub/${faqCategory}`)
            .then(({ data }) => {
                setFaqs(data);
            })
            .catch((err) => {
                setError(err);
            });
    }, [faqCategory]);

    return (
        <>
            <div
                className="bg-gray_100 flex items-center justify-center w-full font-notosansgeorgian
    relative z-20"
            >
                <FlightsModal
                    flightsOpen={flightsOpen}
                    handleflightsOpen={handleflightsOpen}
                />
                <header
                    className={`fixed top-0 left-0 z-30 px-[100px] py-4 w-full flex justify-between items-center 
            transition-all duration-300 delay-150 ease-linear md:px-10 sm:px-5 sm:bg-white 
            ${scrollPosition <= 5 ? "" : "bg-white bg-opacity-90"}`}
                >
                    <Img
                        src={expressLogo}
                        className="h-10 sm:hidden cursor-pointer"
                        onClick={() => navigate("/")}
                        alt="logo"
                    />
                    <Img
                        src={phoneLogo}
                        className="w-10 hidden sm:block cursor-pointer"
                        onClick={() => navigate("/")}
                        alt="logo"
                    />

                    <div className="flex flex-row gap-3 sm:gap-2 items-center justify-start">
                        < CalculateVolume
                            weight={true}
                        />
                        <div className="sm:w-full">
                            <button
                                onClick={handleflightsOpen}
                                className="flex items-center gap-3 justify-center w-[auto] rounded-radius11 p-[11px] bg-teal_A700_14 text-teal_A700">
                                <TbPlane className="text-[20px] text-center" />
                                <div className="bg-transparent cursor-pointer font-semibold text-[14px] text-left text-teal_A700 md:hidden sm:hidden">
                                    {t("flights")}
                                </div>
                            </button>
                        </div>
                        <div className="sm:w-full w-[17%]">
                            <LanguageSwitcher />
                        </div>
                    </div>
                </header>

                <div className="mt-20 min-h-screen w-screen max-w-[1920px] flex justify-center">
                    <div className="w-full flex flex-col font-notosansgeorgian gap-[40px] items-start justify-start sm:gap-4
                    py-20 px-[100px] md:px-[40px] sm:p-[20px] ">
                        <div className="bg-gray_600_29 flex flex-row items-center justify-start p-[2px]
                                rounded-radius10 sm:w-full w-[auto] sm:gap-[0px]">
                            {categoryList.map((item, index) => {
                                return (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            setFaqCategory(item.value);
                                        }}
                                        className={`cursor-pointer rounded-radius8 p-[11px] h-full
                ${faqCategory === item.value &&
                                            "bg-white_A700 outline outline-[1px] outline-black_900_0a shadow-bs text-black_900_e0"
                                            }`}
                                    >
                                        <p
                                            className={`text-center sm:w-full sm:text-sm text-[15px] ${faqCategory === item.value
                                                ? "text-black_900_e0  font-semibold"
                                                : "font-normal not-italic text-black_900_b7"
                                                }`}
                                        >
                                            {item.name}
                                        </p>
                                    </button>
                                );
                            })}
                        </div>
                        <div className="flex flex-col font-firago items-start justify-start w-full">
                            <div className="flex flex-col gap-[24px] items-start justify-start w-full sm:gap-4">
                                {faqs.map((item) => {
                                    return (
                                        <PanelfaqPageCard
                                            key={item.id}
                                            text={item.text}
                                            texten={item.texten}
                                            textru={item.textru}
                                            title={item.title}
                                            titleen={item.titleen}
                                            titleru={item.titleru}
                                            video={item.video}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>


                </div>
            </div >
        </>
    )
}

export default Instructions
import { useState } from "react";
import { Text } from "../../components/Text";
import { Line } from "../../components/Line";
import axiosClient from "../../api/api";
import AddAdressModal from "../../components/AddAddressModal/AddAddressModa";

import { BiPencil } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";

const PanelsettingsaddressesPageCard = ({
  handleDeleteAdresse,
  data,
  setFetchUpdater,
  handleUpdateRecord,
}) => {
  //update modal
  const [adressModalOpenUpdate, setAdressesModalOpenUpdate] = useState(false);
  //error
  const [error, setError] = useState(null);
  //new address
  const [address, setAddress] = useState({
    name: "",
    address: "",
  });

  //handle adress modal 
  const handleAddreessModalOpen = () => {
    setAdressesModalOpenUpdate(prev => !prev);
  }


  //handle new adress
  const handleNewAdress = (value, inputName) => {
    setAddress((prev) => ({ ...prev, [inputName]: value }));
  };

  //change data
  const handleUpdateData = async (id) => {
    try {
      const res = await axiosClient.post(`/Adres/update/${id}`, {
        special: 1,
      });
      if (res) {
        setFetchUpdater(res);
      }
    } catch (err) {
      setError(err);
    }
  };

  //handle whole Update
  const handleWholeUpdate = (id, address) => {
    try {
      handleUpdateRecord(id, address)
      handleAddreessModalOpen()
    } catch (err) { }
  }

  return (
    <div
      className="bg-white_A700 flex flex-1 sm:flex-col flex-row gap-[16px] items-center 
        justify-start outline outline-[1px] outline-black_900_0a p-[24px] sm:px-0 sm:py-4
        rounded-radius13 shadow-bs w-[100%] dark:bg-[#171719]"
    >
      <div
        className="flex flex-1 flex-col gap-[8px] items-start
        justify-start md:self-stretch sm:self-stretch w-[100%] sm:px-4 "
      >
        <Text
          className="font-semibold text-black_900_e0 text-left w-[auto] dark:text-[#ededed]"
          variant="body4"
        >
          {data.name}
        </Text>
        <div className="flex flex-col items-start justify-start w-[100%] ">
          <Text
            className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
            variant="body7"
          >
            {data.address}- {data.city} - {data.phone}
          </Text>
        </div>
      </div>
      <Line className="self-center h-[1px] bg-black_900_14 w-[100%] hidden sm:block" />
      <div className="flex items-center gap-[16px] sm:justify-between sm:w-full sm:px-4">
        <div className="flex items-center gap-[16px]">
          <div
            onClick={() => {
              handleUpdateData(data.id);
            }}
            className={`flex h-[40px] items-center justify-center w-[40px] 
          rounded-lg cursor-pointer  ${data.special ? "bg-orange_500_14" : "bg-gray_600_14"
              }`}
          >
            <AiFillStar
              className={`text-xl font-semibold ${data.special ? "text-[#FF9500]" : "text-black_900_b7 dark:text-[#c7c7c7]"
                }`}
            />
          </div>
          <div
            onClick={() => {
              setAdressesModalOpenUpdate(true);
            }}
            className="flex h-[40px] items-center justify-center w-[40px] bg-gray_600_14
          rounded-lg cursor-pointer"
          >
            <BiPencil className="text-xl text-black_900_b7 font-semibold dark:text-[#c7c7c7]" />
          </div>
        </div>
        <div
          onClick={() => {
            handleDeleteAdresse(data.id);
          }}
          className="flex h-[40px] items-center justify-center w-[40px] bg-red_500_14
          rounded-lg cursor-pointer"
        >
          <BsTrash className="text-xl text-red_A400 font-semibold" />
        </div>
      </div>
      <AddAdressModal
        adressModalOpen={adressModalOpenUpdate}
        handleAddreessModalOpen={handleAddreessModalOpen}
        handleNewAdress={handleNewAdress}
        handleAddNewAdresses={handleWholeUpdate}
        status={"update"}
        data={data}
        updatedAdress={address}
      />
    </div>
  );
};

export default PanelsettingsaddressesPageCard;

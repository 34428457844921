import { useEffect, useState, useContext } from "react";
import { errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../api/api";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import { Text } from "../../components/Text";
import CustomCheckbox from "../../components/CustomInputs/CustomCheckbox";
import RegistrationPanelPath from "../../components/RegistrationPanelPath/RegistrationPanelPath";
import CustomTextInut from "../../components/CustomInputs/CustomTextInut";
import { useTranslation } from "react-i18next";

//icons
import { BsLock, BsEye, BsFlag } from "react-icons/bs";
import { BiChevronLeft, BiX } from "react-icons/bi";

const RegistrationmodalstepFourPage = ({ step, handleStep }) => {
  //translations 
  const { t } = useTranslation();
  //navigation 
  const navigate = useNavigate();
  //input fields for entity person
  const [inputFields, setInputFields] = useState([
    {
      placeholder: "password",
      prefixIcon: BsLock,
      suffixIcon: BsEye,
      input: "text",
      inputName: "password",
      mFill: false,
    },
    {
      placeholder: "conf_password",
      prefixIcon: BsLock,
      suffixIcon: BsEye,
      input: "text",
      inputName: "passwordConf",
      mFill: false,
    },
  ]);
  //agree terms variable
  const [agreeTerms, setAgreeTerms] = useState(null);
  //agree terms variable
  const [isCheckedAgreeTerms, setIsCheckedAgreeTerms] = useState(false);
  //register credentials setter function
  const {
    handleRegCredentials,
    registerCredetials,
    setToken, setUser
  } = useContext(PrimmaryContext);
  //password error quote
  const [passwordValidationQuote, setPasswordValidationQuote] = useState(false);
  //current step controll
  const [currenStep, setCurrentStep] = useState("");
  //agree terms value change handler
  const handleAgreeTerms = (value) => {
    setAgreeTerms(value);
  };

  //register api
  const handleRegisterApi = async () => {
    try {
      const res = await axiosClient.post(
        `Auth/register_post`,
        registerCredetials
      );
      if (res) {
        setToken(res.data.token);
        setUser(res.data.user);
        handleStep("");
        navigate("/panelroutes/panelpackageswarehouse");
      }
    } catch (error) {
      errorToast(t("error"))
    }
  };
  //register function
  const handleRegister = () => {
    const newData = inputFields.map((obj) => {
      if (registerCredetials[obj.inputName] === "") {
        return { ...obj, mFill: true };
      }
      return obj;
    });
    setInputFields(newData);

    if (!agreeTerms) {
      setIsCheckedAgreeTerms(true);
    }

    if (
      agreeTerms &&
      registerCredetials.password !== "" &&
      registerCredetials.passwordConf !== ""
    ) {
      if (registerCredetials.password !== registerCredetials.passwordConf) {
        setPasswordValidationQuote(true);
      } else {
        handleRegisterApi();
        setPasswordValidationQuote(false);
      }
    }
  };

  //current step tracker effect
  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  return (
    <>
      <div
        className={`bg-gray_100 flex flex-col font-firago items-start justify-start 
      mx-[auto] outline outline-[1px] outline-black_900_1e fixed top-0 left-0
      transition-all duration-300 delay-150 ease-linear z-50 popup_container
      ${currenStep === "stepFour" ? "translate-x-[0]" : "translate-x-[100%]"}`}
      >
        <header className="bg-white_A700 border-b-[1px] border-black_900_14 border-solid flex flex-row gap-[16px] items-center justify-center sm:px-[20px] px-[32px] py-[16px] w-[100%]">
          <Text
            className="flex-1 font-bold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
            variant="body3"
          >
            {t("registration")}
          </Text>
          <div
            onClick={() => {
              handleStep("");
            }}
            className="bg-gray_600_14 flex flex-col h-[36px] items-center justify-center rounded-radius50 w-[36px]
            cursor-pointer"
          >
            <BiX className="text-[#FF3A30] text-[22px]" />
          </div>
        </header>
        <div
          className="flex flex-col font-notosansgeorgian gap-[40px] h-[100%] items-start justify-start p-[32px] 
        sm:px-[20px] w-[100%] sm:gap-5"
        >
          <RegistrationPanelPath
            stepOnePassed={true}
            stepTwoPassed={true}
            stepThreePassed={true}
            stepFourActive={true}
          />
          <div
            className="flex flex-col gap-[24px] items-start justify-start max-w-[1122px] mx-[auto] 
          md:px-[20px] w-[100%] h-full"
          >
            {passwordValidationQuote &&
              <p className={`text-[12px]  text-red-500`}>
                {t("password_dont_match")}
              </p>
            }
            <div className="w-full grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2">
              {inputFields.map((item, index) => {
                return (
                  <div className="w-full" key={index}>
                    <CustomTextInut
                      PrefixIcon={item.prefixIcon}
                      SuffixIcon={item.suffixIcon}
                      placeholder={item.placeholder}
                      inputName={item.inputName}
                      inputSetter={handleRegCredentials}
                      type="password"
                      mFill={item.mFill}
                      noDark={true}
                    />
                  </div>
                );
              })}
            </div>
            <CustomCheckbox
              label={t("i_agree_the_terms_and_conditions_of_the_site")}
              classNames={"w-[20px] h-[20px]"}
              inputName={"klajsd123@3oi12"}
              inputSetter={handleAgreeTerms}
              mFill={isCheckedAgreeTerms}
            />
          </div>
        </div>
        <footer
          className="bg-white_A700 border-black_900_14 border-solid border-t-[1px] flex flex-row 
        font-notosansgeorgian gap-[16px] items-center justify-end sm:px-[20px] px-[32px] py-[16px] w-[100%]
        sm:justify-between"
        >
          <button
            onClick={() => {
              handleStep("stepThree");
            }}
            className="rounded-lg px-[16px] py-[10px] flex items-center gap-2 bg-gray_600_14"
          >
            <BiChevronLeft className="text-[22px] text-center text-[#636366]" />
            <div
              className="common-pointer bg-transparent cursor-pointer font-fw text-[17px] text-black_900_99 text-left
            sm:hidden"
            >
              {t("back")}
            </div>
          </button>
          <button
            onClick={handleRegister}
            className="rounded-lg px-[16px] py-[10px] flex items-center gap-2 
            bg-[#00C58C] outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
          >
            <div className="common-pointer bg-transparent cursor-pointer font-semibold text-[17px] text-left text-white_A700">
              {t("register")}
            </div>
            <BsFlag className="text-[22px] text-center" />
          </button>
        </footer>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default RegistrationmodalstepFourPage;

import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import WarehousePackageItem from "./WarehousePackageItem";
import { CheckBox } from "../../../inputComponents/inputcomp";
import axiosAdmin from "../../../../../api/apiAdmin";
import FilialsCardWar from "./FilialsCardWar";
import { useTranslation } from "react-i18next";
import PaginatedItems from "../../../Pagination/Pagination";
import { SelectInput } from "../../../inputComponents/inputcomp";
import { BsX } from "react-icons/bs";
import ContinueComponent from "../../../../../components/Alerts/ContinueComponent";

const WarehousePackage = () => {
  //translations
  const { t } = useTranslation();
  //data from context
  const {
    user,
    globalSearchValue,
    handleSearchValue,
    handleSearchTransport,
    flights,
    setGlobalAoId,
  } = useContext(AdminPrimaryContext);
  //current filial
  const [currentPlaceId, setCurrentPlaceId] = useState(() => {
    if (user.role === 3) {
      return 5;
    } else if (user.role === 2) {
      return 1;
    } else if (user.role === 7) {
      return 16;
    } else if (user.role === 9) {
      return 20;
    } else if (user.role === 10) {
      return 21;
    } else {
      return 1;
    }
  });
  //package data
  const [packageData, setPackageData] = useState([]);
  //package dataErr
  const [packageDataErr, setPackageDataErr] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //package check all
  const [checkAll, setCheckAll] = useState(false);
  //selected Packages
  const [selectedPackages, setSelectedPackages] = useState({ pack_id: [] });
  //current page
  const [currentPage, setCurrentPage] = useState(0);
  //total records
  const [total, setTotal] = useState(0);
  //flight num
  const [flightNum, setFlightNum] = useState(null);
  //send with flights
  const [showContinue, setShowContinue] = useState(false);
  //ab offices with weight and parcel quantity
  const [foreignAddresses, setForeignAddresses] = useState([]);

  //handle flight number change
  const handleFlightNum = (value, key) => {
    setFlightNum(value);
  };

  //handle checking
  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);
  };

  //handle selected Packages
  const handleSelectedPackages = (value) => {
    if (!selectedPackages.pack_id.includes(value)) {
      setSelectedPackages((prev) => ({ pack_id: [...prev.pack_id, value] }));
    }
  };

  //handle delete selected Packages
  const handleDeleteSelectedPackages = (value) => {
    setSelectedPackages((prev) => ({
      pack_id: prev.pack_id.filter((obj) => {
        return obj !== value;
      }),
    }));
  };

  //current filial handler
  const handleCurrentPlaceId = (value) => {
    setGlobalAoId(value);
    setCurrentPlaceId(value);
    setCheckAll(false);
    setSelectedPackages({
      pack_id: [],
    });
  };

  //handle delete package
  const handleDeletePackage = async (id) => {
    try {
      const res = await axiosAdmin.get(`/Pack/delete/${id}`);
      if (res) {
        setDifResponses(res);
        successToast(t("success"));
      }
    } catch (err) {
      setPackageDataErr(err);
      errorToast(t("error"));
    }
  };

  //const handle send package
  const handleSendSelectedPackages = async () => {
    try {
      const res = await axiosAdmin.post(
        `/Pack/send_details_postt`,
        selectedPackages
      );
      if (res) {
        successToast("ამანათები წარმატებით გაიგზავნა!");
        setCheckAll(false);
        setSelectedPackages({
          pack_id: [],
        });
        setCurrentPage(0);
        setDifResponses(res);
      }
    } catch (err) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //parcel function
  const handleParcelThings = () => {
    axiosAdmin
      .get(
        `/Pack/get/2/${currentPlaceId}/30/${currentPage + 1}?key=${
          globalSearchValue.key
        }&transport=${globalSearchValue.transport}`
      )
      .then((res) => {
        setPackageData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //on barcode scanner using
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleParcelThings();
    }
  };

  //move parcels with specific flight number to the next section / stage
  const handleMoveWithFlightNum = () => {
    axiosAdmin
      .post(`/gaafrine`, { flight_num: flightNum })
      .then((res) => {
        setDifResponses(res);
        successToast("ამანათები გამოიგზავნა წარმატებით!");
      })
      .catch(() => {
        errorToast(
          "რეისის ნომერი არასწორია ან დაფიქსირდა გაუთვალისწინებელი შეცდომა ცადეთ ხელახლა!"
        );
      });
  };

  //show parcels from every ab_office
  const handleShowAllPackage = () => {
    setCurrentPlaceId(0);
  };

  //package data fetching
  useEffect(() => {
    handleParcelThings();
  }, [currentPlaceId, difResponses, globalSearchValue, currentPage]);

  //package data fetching
  useEffect(() => {
    if (selectedPackages.pack_id.length > 0) {
      setCheckAll(false);
      setSelectedPackages({
        pack_id: [],
      });
    }
  }, [currentPage]);

  //on page load
  useEffect(() => {
    axiosAdmin
      .get(`/Pack/officeInfo/2`)
      .then((res) => {
        setForeignAddresses(res.data);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div
        className={`mb-5 relative ${
          user.role !== 3 &&
          user.role !== 2 &&
          user.role !== 7 &&
          user.role !== 9
            ? "p-2 overflow-x-auto scrollbar"
            : "grid grid-cols-2 sm:grid-cols-1"
        }`}
      >
        <div className="min-w-[1000px] w-full flex gap-4">
          {foreignAddresses.map((item) => {
            if (
              user.role !== 3 &&
              user.role !== 2 &&
              user.role !== 7 &&
              user.role !== 9 &&
              user.role !== 10
            ) {
              return (
                <FilialsCardWar
                  item={item}
                  key={item.id}
                  currentPlaceId={currentPlaceId}
                  handleCurrentPlaceId={handleCurrentPlaceId}
                  setDifResponses={setDifResponses}
                />
              );
            }
            if (user.role === 2 && item.id === 1) {
              return (
                <FilialsCardWar
                  item={item}
                  key={item.id}
                  currentPlaceId={currentPlaceId}
                  handleCurrentPlaceId={handleCurrentPlaceId}
                  setDifResponses={setDifResponses}
                />
              );
            }
            if (user.role === 3 && (item.id === 5 || item.id === 19)) {
              return (
                <FilialsCardWar
                  item={item}
                  key={item.id}
                  currentPlaceId={currentPlaceId}
                  handleCurrentPlaceId={handleCurrentPlaceId}
                  setDifResponses={setDifResponses}
                />
              );
            }
            if (user.role === 7 && item.id === 16) {
              return (
                <FilialsCardWar
                  item={item}
                  key={item.id}
                  currentPlaceId={currentPlaceId}
                  handleCurrentPlaceId={handleCurrentPlaceId}
                  setDifResponses={setDifResponses}
                />
              );
            }
            if (user.role === 9 && item.id === 20) {
              return (
                <FilialsCardWar
                  item={item}
                  key={item.id}
                  currentPlaceId={currentPlaceId}
                  handleCurrentPlaceId={handleCurrentPlaceId}
                  setDifResponses={setDifResponses}
                />
              );
            }
            if (user.role === 10 && item.id === 21) {
              return (
                <FilialsCardWar
                  item={item}
                  key={item.id}
                  currentPlaceId={currentPlaceId}
                  handleCurrentPlaceId={handleCurrentPlaceId}
                  setDifResponses={setDifResponses}
                />
              );
            }
          })}
        </div>
        <div
          className="absolute top-0 right-0 bg-red-500 w-8 h-8 rounded-full p-1 cursor-pointer"
          onClick={handleShowAllPackage}
        >
          <BsX className="w-full h-full text-white" />
        </div>
      </div>
      <h2 className="text-slate-400 text-2xl">
        {t("parcels_arrived_at_the_warehouse")}
      </h2>
      <div className="w-full">
        <div className="bg-yellow mt-2 flex gap-1 sm:flex-col">
          <div
            className="bg-white  pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-9/12 sm:w-full"
          >
            <input
              type="search"
              placeholder={t("search")}
              value={globalSearchValue.key}
              onChange={(e) => {
                handleSearchValue(e, "key");
              }}
              className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
              onKeyDown={handleKeyPress}
            />
          </div>
          {(user.role === 100 || user.role === 1) && (
            <Link
              to={`${process.env.REACT_APP_API_URL}/api/admin/Pack/export/2/${currentPlaceId}/30/0`}
              className="w-3/12 rounded-lg bg-green-400 sm:w-full
             flex items-center justify-center p-1 py-3 text-white"
              target={"_blank"}
            >
              EXPORT CSV
            </Link>
          )}
        </div>
        <div className="flex gap-2 items-center sm:order-2 mt-3">
          <CheckBox
            name={t("transportation")}
            inputName={"transport"}
            handleCarData={handleSearchTransport}
          />
          {(user.role === 100 || user.role === 1) && (
            <div className="flex items-center gap-3">
              <input
                checked={checkAll}
                onChange={(e) => {
                  handleCheckAll(e);
                }}
                type="checkbox"
              />
              <p className="text-sm text-gray-500">ყველას მონიშვნა</p>
            </div>
          )}
        </div>
        <div className="my-2 flex-wrap w-full grid grid-cols-3 gap-3 md:grid-cols-2 sm:grid-cols-1">
          {(user.role === 100 || user.role === 1) && (
            <div
              onClick={handleSendSelectedPackages}
              className="rounded-lg bg-green-400 w-full cursor-pointer
                 flex items-center justify-center gap-1 p-1 py-2 text-white"
            >
              <p>მონიშნულის გამოგზავნა</p>
            </div>
          )}
          {(user.role === 100 || user.role === 1) && (
            <>
              <SelectInput handleCarData={handleFlightNum} options={flights} />
              <div
                onClick={() => {
                  setShowContinue(true);
                }}
                className="rounded-lg bg-purple-400 w-full cursor-pointer
                 flex items-center justify-center gap-1 p-1 py-2 text-white"
              >
                <p>რეისით გამოგზავნა</p>
              </div>
            </>
          )}
        </div>
        <div className="w-full flex gap-3 flex-wrap mt-5">
          <PaginatedItems pageNumberSetter={setCurrentPage} dataLength={total}>
            <div className="w-full flex flex-col gap-2">
              {packageData.map((item) => {
                return (
                  <WarehousePackageItem
                    key={item.id}
                    data={item}
                    handleDeletePackage={handleDeletePackage}
                    setCheckedAll={checkAll}
                    setDifResponses={setDifResponses}
                    handleSelectedPackages={handleSelectedPackages}
                    handleDeleteSelectedPackages={handleDeleteSelectedPackages}
                  />
                );
              })}
            </div>
          </PaginatedItems>
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleMoveWithFlightNum}
        setShowContinue={setShowContinue}
      />
    </div>
  );
};

export default WarehousePackage;

import { Link } from "react-router-dom";

const ClearenceCard = ({
    code,
    amount
}) => {
    return (
        <Link to={`../clearence/${code}`} className="w-[19%] md:w-[32%] sm:w-[49%] bg-white rounded-lg p-3 flex 
        items-center justify-center dark:bg-slate-700 border border-slate-300 dark:border-slate-500 relative">
            <p className="text-slate-400">{code}</p>
            <p className="text-red-400 font-bold text-sm absolute top-1 right-1">
                {amount}
            </p>
        </Link>
    )
}


export default ClearenceCard;
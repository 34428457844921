import { useTranslation } from "react-i18next";
import { SelectInput, TextInput } from "../../inputComponents/inputcomp";
import { useContext, useState } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import { ChevronDown } from "react-bootstrap-icons";
import { errorToast, successToast } from "../../toastAlerts/ToastAlerts";

const UpdateParcel = ({ dataUpdater, tracking }) => {
  const { t } = useTranslation();
  //toggle this "update" section
  const [toggleThis, setToggleThis] = useState(false);

  //search toggler
  const [searchToggler, setSearchToggler] = useState(false);
  //searched user
  const [searchedUser, setSearchedUser] = useState(null);
  //user
  const { user, flights } = useContext(AdminPrimaryContext);
  //weight and bag
  const [payloadBarcode, setPayloadBarcode] = useState({
    weight: "",
    user_id: "",
    dementionX: "",
    dementionY: "",
    dementionZ: "",
  });
  //selected user
  const [selectedUser, setSelectedUser] = useState("-Search user-");
  //america volume weight
  const [americaDimensions, setAmericaDimensions] = useState({});
  let timer;

  //receive package functionality
  const handleFindUser = (e) => {
    const searchTerm = e.target.value;
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (searchTerm) {
        axiosAdmin
          .get(`/Users/sh/${searchTerm}`)
          .then(({ data }) => {
            setSearchedUser(data);
          })
          .catch((err) => {});
      }
    }, 1000);
  };

  //const choosing user
  const handleChooseUser = (value, userId) => {
    setSelectedUser(value);
    handlePayloadBarcode(userId, "user_id");
  };

  //handle Payload
  const handlePayloadBarcode = (value, inputName) => {
    setPayloadBarcode((prev) => ({ ...prev, [inputName]: value }));
    if (inputName !== "weight") {
      setAmericaDimensions((prev) => ({ ...prev, [inputName]: value }));
    }
  };

  const handleUpdate = () => {
    axiosAdmin
      .post(`/pack_update/${tracking}`, payloadBarcode)
      .then((res) => {
        dataUpdater(res);
        successToast("success");
      })
      .catch((res) => {
        errorToast("error");
      });
  };

  return (
    <>
      {[1, 100].includes(user.role) && (
        <div>
          <div
            className="mt-2 flex w-full items-center justify-between bg-slate-200 text-gray-500 rounded-sm px-2 py-2 cursor-pointer"
            onClick={() => {
              setToggleThis((prev) => !prev);
            }}
          >
            <p> ამანათის განახლება </p>

            <ChevronDown
              className={`text-xl transition-all ${toggleThis && "rotate-180"}`}
            />
          </div>
          {toggleThis && (
            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col gap-2 relative">
                <div
                  className="flex flex-col mt-2"
                  onClick={() => {
                    setSearchToggler((prev) => !prev);
                  }}
                >
                  <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">
                    {t("room_id")}
                  </p>
                  <div
                    className="h-10 w-full pl-1.5 dark:bg-slate-800 border border-gray-300 shadow rounded-lg
                                                flex items-center "
                  >
                    <p className="text-gray-500">{selectedUser}</p>
                  </div>
                </div>
                {searchToggler && (
                  <div
                    className="border border-gray-300 shadow-sm rounded-lg p-2
                                            flex flex-col gap-2 absolute top-[105%] left-0 w-full bg-white z-30"
                  >
                    <input
                      type="text"
                      onChange={(e) => {
                        handleFindUser(e);
                      }}
                      className="w-full h-8 pl-1.5 focus:outline-none text-gray-500
                                                dark:bg-slate-800 border border-gray-300 shadow rounded-lg"
                    />
                    {searchedUser &&
                      searchedUser.map((item) => {
                        return (
                          <div key={item.id}>
                            {item.user_type === 1 && (
                              <p
                                onClick={() => {
                                  handleChooseUser(
                                    `EG${item.id} - ${item.fname_eng} ${
                                      item.lname_eng
                                    } (${
                                      [9, 7, 2, 3].includes(user.role)
                                        ? ""
                                        : item.id_num
                                    }) (${item.office.addressen})`,
                                    item.id
                                  );
                                  setSearchToggler(false);
                                }}
                                className="text-gray-500 text-sm"
                              >
                                {`EG${item.id} - ${item.fname_eng} ${
                                  item.lname_eng
                                } (${
                                  [9, 7, 2, 3].includes(user.role)
                                    ? ""
                                    : item.id_num
                                }) (${item.office.addressen})`}
                              </p>
                            )}
                            {item.user_type === 2 && (
                              <p
                                onClick={() => {
                                  handleChooseUser(
                                    `EG${item.id} - ${item.ceo_fname_eng} ${
                                      item.ceo_lname_eng
                                    } (${
                                      [9, 7, 2, 3].includes(user.role)
                                        ? ""
                                        : item.ceo_id_num
                                    }) (${item.office.addressen})`,
                                    item.id
                                  );
                                  setSearchToggler(false);
                                }}
                                className="text-gray-500 text-sm"
                              >
                                {`EG${item.id} - ${item.ceo_fname_eng} ${
                                  item.ceo_lname_eng
                                } (${
                                  [9, 7, 2, 3].includes(user.role)
                                    ? ""
                                    : item.ceo_id_num
                                }) (${item.office.addressen})`}
                              </p>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
              <TextInput
                name={t("weight")}
                inputName={"weight"}
                inputSetter={handlePayloadBarcode}
                type="number"
                value={payloadBarcode.weight}
              />
              <TextInput
                name={"width"}
                inputName={"dementionX"}
                inputSetter={handlePayloadBarcode}
              />
              <TextInput
                name={"height"}
                inputName={"dementionY"}
                inputSetter={handlePayloadBarcode}
              />
              <TextInput
                name={"length"}
                inputName={"dementionZ"}
                inputSetter={handlePayloadBarcode}
              />

              <SelectInput
                name={"flight"}
                handleCarData={handlePayloadBarcode}
                options={flights}
                inputName={"flight_number"}
              />

              <button
                onClick={handleUpdate}
                className="bg-green-400 text-white px-3 py-2 rounded-lg w-full mt-2 text-center"
              >
                განახლება
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UpdateParcel;

import { useState, useEffect } from "react";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";
import FilialCard from "./FilialCard";
import { MultyLangualTextInput} from "../../inputComponents/inputcomp";

const FilialLayout = () => {
    //add new user variable
    const [addNewToggler, setAddNewToggler] = useState(false);
    //get filial data from api
    const [filialData, setFilialData] = useState([]);
    //affect data change depended on delete and add responses
    const [difResponses, setDifResponses] = useState(null);
    //new filial data
    const [newFilial, setNewFilial] = useState({
        address: "",
        addressen: "",
        addressru: "",
        title: "",
        titleen: "",
        titleru: "",
        sort: 1
    });

    //handle new filial
    const handleNewFilialData = (value, inputName) => {
        setNewFilial(prev => ({ ...prev, [inputName]: value }))
    }

    //add new filials handler
    const handleAddNewToggle = (value) => {
        setAddNewToggler(value);
    }

    //add new filial 
    const handleAddNewFilialToDb = async () => {
        try {
            const res = await axiosAdmin.post(`Branches/add_post`, newFilial);
            setDifResponses(res);
            if (res) {
                successToast("ფილიალი წარმატებით დაემატა!");
            }
        } catch (error) {
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //delete filial 
    const handleDeleteFilial = async (id) => {
        try {
            const res = await axiosAdmin.get(`/Branches/delete/${id}`);
            setDifResponses(res);
            if (res) {
                successToast("ფილიალი წარმატებით წაიშალა!");
            }
        } catch (error) {
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //filial data from api
    useEffect(() => {
        try {
            axiosAdmin.get(`/Branches/index`).then(({ data }) => {
                setFilialData(data);
            });
        } catch (error) { }
    }, [difResponses]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
            <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5" >
                <div className="w-full flex items-center justify-end ">
                    <button
                        onClick={() => { handleAddNewToggle(true) }}
                        className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400">
                        ახალი ფილიალის დამატება
                    </button>
                </div>
                {addNewToggler &&
                    <div className="bg-slate-100 dark:bg-slate-900">
                        <div className="sm:px-0">
                            <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                                <div className="flex items-center justify-between flex-wrap gap-5">
                                    <div className="flex items-center gap-5 sm:gap-3 ">
                                        <div>
                                            <h2 className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]">ახალი ფილიალის დამატება</h2>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 sm:w-full">
                                        <button
                                            onClick={handleAddNewFilialToDb}
                                            className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                                            შენახვა
                                        </button>
                                        <button
                                            onClick={() => { handleAddNewToggle(false) }}
                                            className="rounded-lg p-3 text-white text-sm bg-red-400 w-10">
                                            X
                                        </button>
                                    </div>
                                </div>
                                <MultyLangualTextInput
                                    name={"ფილიალის სახელი"}
                                    geInputName={"title"}
                                    enInputName={"titleen"}
                                    ruInputName={"titleru"}
                                    inputSetter={handleNewFilialData}
                                />
                                <MultyLangualTextInput
                                    name={"მისამართი"}
                                    geInputName={"address"}
                                    enInputName={"addressen"}
                                    ruInputName={"addressru"}
                                    inputSetter={handleNewFilialData}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="w-full min-h-screen">
                <div className="p-10 pt-0 sm:p-5 sm:pt-0 grid grid-cols-2 gap-5 sm:grid-cols-1">
                    {filialData.map((obj) => {
                        return (
                            < FilialCard
                                key={obj.id}
                                id={obj.id}
                                name={`${obj.title} ${obj.address}`}
                                handleDeleteFilial={handleDeleteFilial}
                            />
                        )
                    })}
                </div>
            </div >
        </div>
    )
}

export default FilialLayout;

import { useState } from "react";
import PanelHead from "../../components/PanelHead/PanelHead";
import { Text } from "../../components/Text";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import axiosClient from "../../api/api";

const Messeges = () => {
    //translations
    const { t } = useTranslation();
    //data
    const [data, setData] = useState([])

    //category list
    const categoryList = [
        {
            name: "Trendyol",
            value: 0,
        },
        {
            name: t("else"),
            value: 1,
        },
    ];
    //faqs based on cateory
    const [faqCategory, setFaqCategory] = useState(0);

    //get data from api
    useEffect(() => {
        if (faqCategory === 0) {
            axiosClient.get("/msg/Trendyol")
                .then((res) => {
                    setData(res.data)
                })
                .catch(() => { })
        } else if (faqCategory === 1) {
            axiosClient.get("/msg")
                .then((res) => {
                    setData(res.data)
                })
                .catch(() => { })
        }

    }, [faqCategory])

    return (
        <div
            className="min-h-screen flex flex-1 gap-[36px] flex-col font-firago items-start justify-start
      max-w-[1500px] md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] sm:pb-[80px] md:pb-[80px]"
        >
            <PanelHead name={t("codes")} />
            <Text
                className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
          hidden sm:block md:block dark:text-[#c7c7c7]"
                variant="body1"
            >
                {t("codes")}
            </Text>
            <div
                className="flex flex-col font-notosansgeorgian gap-[40px] items-start justify-start w-[100%]
        sm:gap-4"
            >
                <div
                    className="bg-gray_600_29 flex flex-row items-center justify-start p-[2px]
             rounded-radius10 sm:w-[100%] w-[auto] sm:gap-[0px] dark:bg-[#171719]"
                >
                    {categoryList.map((item, index) => {
                        return (
                            <button
                                key={index}
                                onClick={() => {
                                    setFaqCategory(item.value);
                                }}
                                className={`cursor-pointer w-[176px] rounded-radius8 p-[11px] 
                ${faqCategory === item.value &&
                                    "bg-white_A700 outline outline-[1px] outline-black_900_0a shadow-bs text-black_900_e0 dark:bg-[#48484a]"
                                    }`}
                            >
                                <p
                                    className={`text-center sm:w-full sm:text-sm text-[15px] ${faqCategory === item.value
                                        ? "text-black_900_e0 dark:text-[#c7c7c7]  font-semibold"
                                        : "font-normal not-italic text-black_900_b7 dark:text-[#ededed]"
                                        }`}
                                >
                                    {item.name}
                                </p>
                            </button>
                        );
                    })}
                </div>
                <div className="flex flex-col font-firago items-start justify-start w-[100%]">
                    <div className="flex flex-col gap-[24px] items-start justify-start w-[100%] sm:gap-4">

                        {data.map((item) => {
                            return (
                                <div
                                    className={`bg-white_A700 flex flex-col gap-[20px] items-center justify-start max-w-[1408px]
                                        mx-[auto] outline outline-[1px] outline-indigo_400_14 p-[24px] sm:px-[20px] rounded-radius13
                                        w-[100%] sm:p-2 shadow-[10px_10px_0px_0px_rgba(198,198,210,0.8)] relative dark:bg-[#171719]`}
                                >
                                    <div className="flex flex-row gap-[24px] items-center justify-between w-[100%]">
                                        <div className="flex relative w-full">
                                            <p className="font-semibold text-black_900_e0 text-[20px] relative z-20 dark:text-[#c7c7c7]">
                                                {item.sender}
                                            </p>
                                            <div
                                                className={`bg-gray-300 h-[8px] mb-[2px] mt-[auto] w-full dark:bg-indigo_A200_19
                                                absolute bottom-[-10px] left-0 z-10 ease-linear`}
                                            ></div>
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col font-notosansgeorgian items-center justify-start w-full`}
                                    >
                                        <p className="font-normal leading-[24.00px] w-full not-italic text-black_900_b7 dark:text-[#aeaeb2]
                                        text-left sm:text-xs text-[17px]">
                                            {item.txt}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Messeges
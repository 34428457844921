import { useRef, useState, useEffect } from "react";
import { Button } from "../../components/Button";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useContext } from "react";
import { PrimmaryContext } from "../../components/MainContext/MainContext";

const PanelfaqPageCard = ({
  text,
  texten,
  textru,
  title,
  titleen,
  titleru,
  video,
}) => {
  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useContext(PrimmaryContext);

  const handleIOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = "0";
    }
  }, [isOpen]);

  return (
    <div className="relative w-full">
      <div
        className={`bg-white_A700 flex flex-col dark:bg-[#171719]
      items-center justify-start outline outline-[1px] 
      outline-indigo_400_14 rounded-radius13 w-full
      ${isOpen && "shadow-[10px_10px_0px_0px_rgba(198,198,210,0.8)]"}`}
      >
        <div
          className="flex flex-row gap-[24px] items-center justify-between w-[100%]
        p-[24px] sm:px-[20px] sm:p-2 cursor-pointer"
          onClick={handleIOpen}
        >
          <div className="flex relative w-full">
            <p className="font-semibold text-black_900_e0 text-[20px] sm:text-sm relative z-20 dark:text-[#c7c7c7]">
              {language === "ge"
                ? title
                : language === "en"
                ? titleen
                : language === "ru" && titleru}
            </p>
            <div
              className={`bg-gray-300 h-[8px] mb-[2px] mt-[auto] w-[94%] transition-all duration-300 delay-50
              absolute bottom-0 left-0 z-10 dark:bg-indigo_A200_19
            ease-linear ${isOpen ? "opacity-1" : "opacity-0"}`}
            ></div>
          </div>
          <Button
            className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
            variant={isOpen ? "icbFillRed50014" : "FillGray60014"}
            shape={"circular"}
          >
            {isOpen ? (
              <FaChevronUp className="font-semibold text-red_A400" />
            ) : (
              <FaChevronDown className="font-semibold text-black_900_b7 dark:text-[#c7c7c7]" />
            )}
          </Button>
        </div>
        <div
          ref={contentRef}
          className={`flex flex-col font-notosansgeorgian items-start justify-start w-[100%]
            transition-all overflow-hidden max-h-0 ${
              isOpen && "p-[24px] sm:p-[20px] pt-0 md:pt-0 sm:pt-0"
            }`}
        >
          {video && isOpen ? (
            <div className="w-[500px] md:w-[400px] sm:w-full">
              <iframe
                width={"100%"}
                height={"360"}
                src={video}
                allowFullScreen
                title={titleen}
              ></iframe>
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  language === "ge"
                    ? text
                    : language === "en"
                    ? texten
                    : language === "ru" && textru,
              }}
              className="font-normal leading-[24.00px] md:max-w-[100%] sm:max-w-[100%] mb-5
              not-italic text-black_900_b7 text-left sm:text-xs text-[17px] dark:text-[#aeaeb2]"
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PanelfaqPageCard;

import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import { Text } from "../../components/Text";
import { Line } from "../../components/Line";
import { Button } from "../../components/Button";
import PanelHead from "../../components/PanelHead/PanelHead";
import PanelshopsPageCard from "./PanelshopsPageCard";
import { useTranslation } from "react-i18next";
import abOffices from "../../components/MainContext/data";
import { GiCarWheel } from "react-icons/gi";

//icons
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import { FaChild, FaQuestion } from "react-icons/fa";
import { Img } from "../../components/Img";

const PanelshopsPage = () => {
  //translations
  const { t } = useTranslation();
  //shops data from api
  const [shopsData, setShopsData] = useState(null);
  //selected abroad office
  const [selectedAbOffice, setSelectedAbOffice] = useState(1);

  //get shops on page load
  useEffect(() => {
    axiosClient
      .get(`/Category/index?country=${selectedAbOffice}`)
      .then(({ data }) => {
        setShopsData(data);
      });
  }, [selectedAbOffice]);

  return (
    <div
      className="min-h-screen flex flex-1 flex-col font-firago gap-[36px] items-start justify-start max-w-[1500px]
       md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] sm:pb-[80px] md:pb-[80px]"
    >
      <PanelHead name={t("shops")} />
      <div
        className="bg-[#dedfe4] rounded-radius11 outline outline-[1px] outline-black_900_0a shadow-bs
                cursor-pointer p-[2px] flex items-center flex-wrap sm:justify-between dark:bg-[#171719]"
      >
        {abOffices
          ?.filter((item) => item.id !== 19)
          ?.map((office) => {
            return (
              <div
                className={`flex items-center justify-center sm:w-[49%] gap-2  px-[16px] py-[8px] rounded-radius8
              ${
                selectedAbOffice === office.id &&
                "bg-white dark:bg-[#48484a] outline outline-[1px] outline-black_900_0a shadow-bs"
              }`}
                onClick={() => {
                  setSelectedAbOffice(office.id);
                }}
                key={office.id}
              >
                <Img
                  src={office.img}
                  className="h-[32px] w-[32px] object-contain"
                  alt="#"
                />
                <p
                  className={`text-sm font-semibold text-[#636366] dark:text-[#c7c7c7] sm:text-xs text-center sm:w-full`}
                >
                  {t(office.name)}
                </p>
              </div>
            );
          })}
      </div>
      <Text
        className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 
        sm:tracking-ls1 w-[auto] dark:text-[#c7c7c7] hidden sm:block md:block"
        variant="body1"
      >
        {t("shops")}
      </Text>
      <div className="relative w-[100%]">
        <div className="flex flex-col gap-[48px] inset-[0] items-center justify-start m-[auto] max-w-[1408px] w-[100%] sm:gap-4">
          {selectedAbOffice !== 5 ? (
            shopsData && (
              <>
                <div className="flex flex-col gap-[40px] items-start justify-start w-[100%] sm:gap-4">
                  <ShopText Icon={BsGenderFemale} title={"for_woman"} />
                  <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
                    <div
                      className="md:gap-[20px] sm:gap-2 gap-[32px] grid
                grid-cols-4 justify-center min-h-[auto] w-[100%]"
                    >
                      {shopsData
                        .filter((item) => item.cat === 1) //cat 1 stands for "for woman"
                        .map((item) => {
                          return (
                            <PanelshopsPageCard
                              img={item.img}
                              link={item.link}
                              key={item.id}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>

                <Line className="self-center h-[1px] bg-black_900_14 w-[100%]" />
                <div className="flex flex-col gap-[40px] items-start justify-start w-[100%] sm:gap-4">
                  <ShopText Icon={BsGenderMale} title={"for_man"} />
                  <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
                    <div
                      className="md:gap-[20px] sm:gap-2 gap-[32px] grid
                grid-cols-4 justify-center min-h-[auto] w-[100%]"
                    >
                      {shopsData
                        .filter((item) => item.cat === 2) //cat 2 stands for "for man"
                        .map((item) => {
                          return (
                            <PanelshopsPageCard
                              img={item.img}
                              link={item.link}
                              key={item.id}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>

                <Line className="self-center h-[1px] bg-black_900_14 w-[100%]" />
                <div className="flex flex-col gap-[40px] items-start justify-start w-[100%] sm:gap-4">
                  <ShopText Icon={FaChild} title={"for_childrens"} />
                  <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
                    <div
                      className="md:gap-[20px] sm:gap-2 gap-[32px] grid
                grid-cols-4 justify-center min-h-[auto] w-[100%]"
                    >
                      {shopsData
                        .filter((item) => item.cat === 3) //cat 3 stands for "for children"
                        .map((item) => {
                          return (
                            <PanelshopsPageCard
                              img={item.img}
                              link={item.link}
                              key={item.id}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>

                <Line className="self-center h-[1px] bg-black_900_14 w-[100%]" />
                <div className="flex flex-col gap-[40px] items-start justify-start w-[100%] sm:gap-4">
                  <ShopText Icon={FaChild} title={"self_care_products"} />
                  <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
                    <div
                      className="md:gap-[20px] sm:gap-2 gap-[32px] grid
                grid-cols-4 justify-center min-h-[auto] w-[100%]"
                    >
                      {shopsData
                        .filter((item) => item.cat === 5) //cat 5 stands for "self care products"
                        .map((item) => {
                          return (
                            <PanelshopsPageCard
                              img={item.img}
                              link={item.link}
                              key={item.id}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>

                <Line className="self-center h-[1px] bg-black_900_14 w-[100%]" />
                <div className="flex flex-col gap-[40px] items-start justify-start w-[100%] sm:gap-4">
                  <ShopText Icon={FaChild} title={"residence"} />
                  <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
                    <div
                      className="md:gap-[20px] sm:gap-2 gap-[32px] grid
                grid-cols-4 justify-center min-h-[auto] w-[100%]"
                    >
                      {shopsData
                        .filter((item) => item.cat === 6) //cat 6 stands for "residence shops"
                        .map((item) => {
                          return (
                            <PanelshopsPageCard
                              img={item.img}
                              link={item.link}
                              key={item.id}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>

                {selectedAbOffice !== 20 && (
                  <>
                    <Line className="self-center h-[1px] bg-black_900_14 w-[100%]" />
                    <div className="flex flex-col gap-[40px] items-start justify-start w-[100%] sm:gap-4">
                      <ShopText Icon={GiCarWheel} title={"autoParts"} />
                      <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
                        <div
                          className="md:gap-[20px] sm:gap-2 gap-[32px] grid
                grid-cols-4 justify-center min-h-[auto] w-[100%]"
                        >
                          {shopsData
                            .filter((item) => item.cat === 7) //cat 7 stands for "auto parts"
                            .map((item) => {
                              return (
                                <PanelshopsPageCard
                                  img={item.img}
                                  link={item.link}
                                  key={item.id}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <Line className="self-center h-[1px] bg-black_900_14 w-[100%]" />
                <div className="flex flex-col gap-[40px] items-start justify-start w-[100%] sm:gap-4">
                  <ShopText Icon={FaChild} title={"brend_shops"} />
                  <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
                    <div
                      className="md:gap-[20px] sm:gap-2 gap-[32px] grid
                grid-cols-4 justify-center min-h-[auto] w-[100%]"
                    >
                      {shopsData
                        .filter((item) => item.cat === 9) //cat 9 stands for "brand shops"
                        .map((item) => {
                          return (
                            <PanelshopsPageCard
                              img={item.img}
                              link={item.link}
                              key={item.id}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>

                <Line className="self-center h-[1px] bg-black_900_14 w-[100%]" />
                <div className="flex flex-col gap-[40px] items-start justify-start w-[100%] sm:gap-4">
                  <ShopText Icon={FaQuestion} title={"other"} />
                  <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
                    <div
                      className="md:gap-[20px] sm:gap-2 gap-[32px] grid
                grid-cols-4 justify-center min-h-[auto] w-[100%]"
                    >
                      {shopsData
                        .filter((item) => item.cat === 10) //cat 10 stands for "other websites"
                        .map((item) => {
                          return (
                            <PanelshopsPageCard
                              img={item.img}
                              link={item.link}
                              key={item.id}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </>
            )
          ) : (
            <>
              <div className="flex flex-col gap-[40px] items-start justify-start w-[100%] sm:gap-4">
                <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
                  <div
                    className="md:gap-[20px] sm:gap-2 gap-[32px] grid
                grid-cols-4 justify-center min-h-[auto] w-[100%]"
                  >
                    {shopsData
                      .filter((item) => item.cat === 11) //cat 11 stands for "china websites"
                      .map((item) => {
                        return (
                          <PanelshopsPageCard
                            img={item.img}
                            link={item.link}
                            key={item.id}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PanelshopsPage;

const ShopText = ({ Icon, title }) => {
  //translations
  const { t } = useTranslation();
  return (
    <div className="flex flex-row gap-[16px] items-center justify-start w-[100%]">
      <Button
        className="flex h-[40px] items-center justify-center w-[40px] dark:bg-[#171719]"
        size="mdIcn"
      >
        <Icon className="text-indigo_400 font-semibold" />
      </Button>
      <Text
        className="flex-1 font-semibold text-black_900_e0 dark:text-[#c7c7c7]
    text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
        variant="body3"
      >
        {t(title)}
      </Text>
    </div>
  );
};

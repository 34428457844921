import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../../api/apiAdmin";
import GoBackButton from "../../../../../components/GoBackButton/GoBackButton";
import InnerClearenceCard from "./InnerClearenceCard";

const InnerClearence = () => {
    // id
    const { clearenceId } = useParams();
    // data
    const [data, setData] = useState([]);
    // dataErr
    const [dataErr, setDataErr] = useState([]);
    //rese data
    const [resetResponse, setResetResponse] = useState(null);

    //handle customs clearance
    const handleClearance = async (payload) => {
        try {
            const res = await axiosAdmin.post(`/Pack/isDeclared`, payload);
            setResetResponse(res);
        } catch (err) {
            setDataErr(err)
        }
    }

    //change clearance status to 0 (none clearanced)
    const handleUnclearance = (ids) => {
        axiosAdmin.post(`/Pack/unBaj`, {
            ids: ids
        })
            .then((res) => {
                setResetResponse(res)
            })
            .catch((err) => {
                setDataErr(err)
            })
    }

    // data fetching
    useEffect(() => {
        axiosAdmin.get(`/Pack/declaredd/${clearenceId}`)
            .then(({ data }) => {
                setData(data);
            })
            .catch((err) => {
                setDataErr(err);
            });
    }, [resetResponse])

    return (

        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <div className="w-full flex gap-10 items-center">
                <GoBackButton />
                <h2 className="text-slate-400 text-2xl">განსაბაჟებელი</h2>
            </div>
            <div className="w-full">
                <div>
                    {data.length === 0 && <h2 className="text-slate-400 text-md">მონაცემები არ არის...</h2>}
                </div>
                <div className="w-full flex flex-col gap-3 mt-5">
                    {data.map((data) => {
                        return (
                            <InnerClearenceCard
                                key={data.id}
                                data={data}
                                handleClearance={handleClearance}
                                handleUnclearance={handleUnclearance}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default InnerClearence;
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Text } from "../Text";
import { Button } from "../Button";
import sideImage from "../../img/trendyol.png"

const UserPanelMenuItem = ({
  name,
  pathName,
  Icon,
  hasCounter = false,
  currentPage,
  handleCurrentPageChange,
  bgWhite = true,
  handleOpenMenu = null,
  count = 0,
  img = false
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (currentPage === pathName) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [currentPage]);

  return (
    <Link
      to={`${pathName}`}
      className="w-full"
      onClick={handleOpenMenu && handleOpenMenu}
    >
      <div
        onClick={() => {
          handleCurrentPageChange(pathName);
        }}
        className={`flex flex-col items-center justify-start p-[16px] rounded-radius13 w-full
                    ${isActive ? "bg-indigo_400_14 " : bgWhite && "bg-white dark:bg-transparent"}`}
      >
        <div className="flex flex-row gap-[16px] items-center justify-start md:self-stretch sm:self-stretch w-[100%]">
          <div className="flex flex-1 flex-row gap-[16px] items-center justify-start md:self-stretch sm:self-stretch w-[100%]">
            <Icon
              className={`h-[24px] w-[24px] ${isActive ? "text-indigo_400" : "text-[#636366] dark:text-[#aeaeb2]"
                }`}
            />
            <div className="relative">
              <Text
                className={`flex-1 font-semibold text-left w-[auto] text-[14px]
                            ${isActive ? "text-indigo_400" : "text-[#636366] dark:text-[#aeaeb2]"}`}
                variant="body5"
              >
                {name}
              </Text>
              {img &&
                <div className="h-[22px] absolute top-[-18px] right-[-50px]">
                  <img src={sideImage} alt="#" className="h-full object-contain" />
                </div>
              }
            </div>
          </div>
          {(hasCounter && count > 0) && (
            <div className="flex flex-col items-center justify-end w-[auto]">
              <Button
                className="cursor-pointer font-fw min-w-[31px] text-[13px] text-center text-white_A700 w-[auto]"
                shape="RoundedBorder11"
                size="sm"
                variant="OutlineBlack9000a_2"
              >
                {hasCounter && count}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default UserPanelMenuItem;

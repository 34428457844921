import { useState, createContext, useEffect } from "react";
import axiosAdmin from "../../../api/apiAdmin";

export const AdminPrimaryContext = createContext(null);

export const AdminContext = ({ children }) => {
  //current user data
  const [user, setUser] = useState({});
  //token for remembering user in browser
  const [token, _setToken] = useState(localStorage.getItem("EXPR_TOKEN_ADM"));
  //check if user is authorized
  const [isAuthorized, setIsAuthorized] = useState(false);
  //address
  const [foreignAdresses, setForeignAdresses] = useState([]);
  //global parcel search value
  const [globalSearchValue, setGlobalSearchValue] = useState({
    key: "",
    transport: "",
  });
  //flights
  const [flights, setFlights] = useState([]);
  //global ao_id for changing data (for example flights)
  const [globalAoId, setGlobalAoId] = useState(1);

  //handle change search value
  const handleSearchValue = (e, inputName) => {
    setGlobalSearchValue((prev) => ({ ...prev, [inputName]: e.target.value }));
  };

  //handle change search value
  const handleSearchTransport = (value, inputName) => {
    setGlobalSearchValue((prev) => ({ ...prev, [inputName]: value }));
  };

  //handler function for token
  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("EXPR_TOKEN_ADM", token);
    } else {
      localStorage.removeItem("EXPR_TOKEN_ADM");
    }
  };

  //get user data if user is authorized
  useEffect(() => {
    axiosAdmin.get("/admin").then(({ data }) => {
      setUser(data);
      setIsAuthorized(true);
    });
  }, []);

  //get flights data
  useEffect(() => {
    axiosAdmin
      .get(`/Flights/index?country=${globalAoId}`)
      .then(({ data }) => {
        var tmp = data.map((flight) => {
          return flight.number;
        });
        setFlights(tmp);
      })
      .catch((err) => {});
  }, [globalAoId]);

  //get foreign addresses globally
  useEffect(() => {
    axiosAdmin.get("/Pack/receive").then(({ data }) => {
      setForeignAdresses(data);
    });
  }, [isAuthorized]);

  return (
    <div>
      <AdminPrimaryContext.Provider
        value={{
          user,
          setUser,
          token,
          setToken,
          isAuthorized,
          foreignAdresses,
          setIsAuthorized,
          globalSearchValue,
          handleSearchValue,
          handleSearchTransport,
          flights,
          setGlobalAoId
        }}
      >
        {children}
      </AdminPrimaryContext.Provider>
    </div>
  );
};

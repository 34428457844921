import { useState } from "react";
import axiosClient from "../../api/api";
import CustomTextInut from "../CustomInputs/CustomTextInut";
import {
  successToast,
  errorToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { useTranslation } from "react-i18next";

import logo from "../../img/phoneLogo.png";
import { BsX, BsEnvelope, BsPhone } from "react-icons/bs";

const ForgetPassword = ({ handleFpToggle }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState({
    email: "",
  });
  const [phone, setPhone] = useState({
    phone: "",
  });
  const [toggleMethod, setToggleMethod] = useState("email");

  const handlePassword = () => {
    axiosClient
      .post(`/Auth/forgot_post`, toggleMethod === "email" ? email : phone)
      .then((res) => {
        successToast(t("password_sent"));
      })
      .catch((err) => {
        let errMessage = "";
        if (err.response.status === 400) errMessage = "user_do_not_exists";
        if (err.response.status === 403) errMessage = "already_updated";
        errorToast(t(errMessage));
      });
  };

  const handleChangePassword = () => {
    if (toggleMethod === "email") {
      if (!handleValidateEmail(email.email)) return;
      handlePassword();
    } else if (toggleMethod === "phone") {
      if (!handleValidatePhone(phone.phone)) return;
      handlePassword();
    }
  };

  const handleInputEmail = (value, inputName) => {
    setEmail((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleInputPhone = (value, inputName) => {
    setPhone((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleValidateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleValidatePhone = (phone) => {
    const phoneRegex = /^\d{9}$/;
    return phoneRegex.test(phone);
  };

  return (
    <>
      <div
        className="w-screen h-screen bg-black bg-opacity-80 fixed top-0 left-0
    z-50 flex items-center justify-center"
      >
        <div
          className="w-[500px] bg-white rounded-xl p-5 flex
        justify-center flex-col gap-10 relative sm:w-full sm:mx-3"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <img src={logo} className="w-10" />
              <h2
                className="font-firago font-bold text-black_900_e0  sm:text-[18px] 
        md:text-[24px] text-[28px]"
              >
                {t("recover_password")}
              </h2>
            </div>
            <div
              onClick={handleFpToggle}
              className="w-[36px] h-[36px] rounded-full flex items-center justify-center bg-white
                    cursor-pointer shadow-[rgba(0,0,0,0.05)_0px_6px_24px_0px,rgba(0,0,0,0.08)_0px_0px_0px_1px]"
            >
              <BsX className="h-full w-full text-[24px] text-[#FF453A]" />
            </div>
          </div>

          <div className="flex items-center gap-2">
            <p
              onClick={() => {
                setToggleMethod("email");
              }}
              className={`text-xs cursor-pointer ${
                toggleMethod === "email" ? "text-indigo-400" : ""
              }`}
            >
              {t("email")}
            </p>
            <p
              onClick={() => {
                setToggleMethod("phone");
              }}
              className={`text-xs cursor-pointer ${
                toggleMethod === "phone" ? "text-indigo-400" : ""
              }`}
            >
              {t("phone")}
            </p>
          </div>
          <div className="w-full flex flex-col gap-5">
            {toggleMethod === "email" ? (
              <CustomTextInut
                PrefixIcon={BsEnvelope}
                placeholder={"email"}
                inputName={"email"}
                inputSetter={handleInputEmail}
                type="email"
                validateFuntion={handleValidateEmail}
                validateQuote={"invalid_email"}
              />
            ) : (
              <CustomTextInut
                PrefixIcon={BsPhone}
                placeholder={"phone"}
                inputName={"phone"}
                inputSetter={handleInputPhone}
                type="phone"
                validateFuntion={handleValidatePhone}
                validateQuote={"symbol"}
              />
            )}

            <button
              onClick={handleChangePassword}
              className="common-pointer cursor-pointer font-semibold text-[17px] text-center  w-[100%]
          rounded-radius11 py-4 bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
            >
              {t("send")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;

import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import PassedPackagesItem from "./PassedPackagesItem";
import axiosAdmin from "../../../../../api/apiAdmin";
import PaginatedItems from "../../../Pagination/Pagination";
import { BsX } from "react-icons/bs";

//icons
import { FaRegHandPointRight } from "react-icons//fa";
import {
  DateInput,
  SelectInputValueId,
} from "../../../inputComponents/inputcomp";

const PassedPackages = () => {
  //current filial
  const [currentPlaceId, setCurrentPlaceId] = useState(1);
  const { user, globalSearchValue, handleSearchValue } =
    useContext(AdminPrimaryContext);
  //package data
  const [packageData, setPackageData] = useState([]);
  //package dataErr
  const [packageDataErr, setPackageDataErr] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //current page
  const [currentPage, setCurrentPage] = useState(0);
  //total records
  const [total, setTotal] = useState(0);
  //ab offices with weight and parcel quantity
  const [foreignAddresses, setForeignAddresses] = useState([]);
  //selected branch
  const [selectedBranch, setSelectedBranch] = useState("");
  //get filial data from api
  const [filialData, setFilialData] = useState([]);
  //date for csv export
  const [csvDate, setCsvDate] = useState("");

  //handle branch selecting
  const handleSelectBranch = (value, _) => {
    setSelectedBranch(value);
  };

  //current filial handler
  const handleCurrentPlaceId = (value) => {
    setCurrentPlaceId(value);
  };

  //handle delete package
  const handleDeletePackage = async (id) => {
    try {
      const res = await axiosAdmin.get(`/Pack/delete/${id}`);
      if (res) {
        setDifResponses(res);
        successToast("ამანათი წარმატებით წაიშალა!");
      }
    } catch (err) {
      setPackageDataErr(err);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  const handleParcelThings = () => {
    axiosAdmin
      .get(
        `/Pack/get/5/${currentPlaceId}/30/${currentPage + 1}?key=${
          globalSearchValue.key
        }&branchId=${selectedBranch !== "" ? selectedBranch : ""}`
      )
      .then((res) => {
        setPackageData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //move parcel to previous status
  const handleMoveBack = (parcelId) => {
    axiosAdmin
      .get(`/Pack/back/${parcelId}`)
      .then((res) => {
        setDifResponses(res);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //show parcels from every ab_office
  const handleShowAllPackage = () => {
    setCurrentPlaceId(0);
  };

  //package data fetching
  useEffect(() => {
    handleParcelThings();
  }, [
    currentPlaceId,
    difResponses,
    globalSearchValue,
    currentPage,
    selectedBranch,
  ]);

  //on barcode scanner using
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleParcelThings();
    }
  };

  //on page load
  useEffect(() => {
    axiosAdmin
      .get(`/Pack/officeInfo/5`)
      .then((res) => {
        setForeignAddresses(res.data);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  }, []);

  //filial data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Branches/index`).then(({ data }) => {
        setFilialData(
          data.map((item) => {
            return {
              value: item.id,
              name: item.title,
            };
          })
        );
      });
    } catch (error) {
      setPackageDataErr(error);
    }
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className=" mb-5 p-2 overflow-x-auto scrollbar relative">
        <div className="min-w-[1000px] w-full flex gap-4">
          {foreignAddresses.map((item) => {
            return (
              <div
                key={item.id}
                className={`w-full flex items-center p-3 rounded-lg gap-5 cursor-pointer
                    shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px] 
                    ${
                      currentPlaceId === item.id
                        ? "border border-green-500 bg-green-100"
                        : "bg-white dark:bg-slate-700 "
                    }`}
              >
                <div className="w-full flex flex-col gap-2 justify-between h-full">
                  <p className="text-[14px] text-[#8e95a8]">{item.title}</p>
                  <p className="text-[14px] text-[#8e95a8]">
                    {`${item.country} ${item.city}`}
                  </p>
                  <p className="text-[14px] text-[#8e95a8]">{item.address}</p>
                  <p className="text-[14px] text-[#8e95a8]">{item.phone}</p>
                  <p className="text-[14px] text-[#8e95a8]">
                    {`${item.pack_count}`} parcel /{" "}
                    {`${item.total_weight.toFixed(2)}`} kg
                  </p>
                  <div className="flex gap-1">
                    <div
                      onClick={() => {
                        handleCurrentPlaceId(item.id);
                      }}
                      className="w-full rounded-lg bg-green-400
                 flex items-center justify-center gap-1 p-1 py-3 text-white"
                    >
                      <FaRegHandPointRight />
                      <p>აირჩიე</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="absolute top-0 right-0 bg-red-500 w-8 h-8 rounded-full p-1 cursor-pointer"
          onClick={handleShowAllPackage}
        >
          <BsX className="w-full h-full text-white" />
        </div>
      </div>
      <h2 className="text-slate-400 text-2xl">მისაღები ამანათები</h2>
      <div className="w-full">
        <div className="w-full mt-2 flex gap-1 sm:flex-col">
          <div
            className="bg-white  pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-full"
          >
            <input
              type="search"
              placeholder="ძებნა, (მომხმარებლის id)"
              value={globalSearchValue.key}
              onKeyDown={handleKeyPress}
              onChange={(e) => {
                handleSearchValue(e, "key");
              }}
              className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
            />
          </div>
        </div>
        <div className="mt-5 flex gap-5">
          <DateInput
            inputSetter={(value, _) => {
              setCsvDate((prev) => ({
                ...prev,
                date_from: value,
              }));
            }}
          />
          <DateInput
            inputSetter={(value, _) => {
              setCsvDate((prev) => ({
                ...prev,
                date_to: value,
              }));
            }}
          />
          {[1, 100].includes(user.role) && (
            <>
              <Link
                to={`${
                  process.env.REACT_APP_API_URL
                }/api/admin/Pack/export/5/${currentPlaceId}/30/0?branchId=${
                  selectedBranch !== "" ? selectedBranch : ""
                }&date_from=${
                  csvDate.date_from ? csvDate.date_from : ""
                }&date_to=${csvDate.date_to ? csvDate.date_to : ""}
              &key=${
                globalSearchValue.key ? parseInt(globalSearchValue.key) : ""
              }
              `}
                className="w-3/12 rounded-lg bg-green-400 sm:w-full
                 flex items-center justify-center p-1 py-3 text-white shrink-0"
              >
                EXPORT CSV
              </Link>

              <Link
                to={`${
                  process.env.REACT_APP_API_URL
                }/api/admin/Pack/export/5/${currentPlaceId}/30/0?branchId=${
                  selectedBranch !== "" ? selectedBranch : ""
                }&date_from=${
                  csvDate.date_from ? csvDate.date_from : ""
                }&date_to=${csvDate.date_to ? csvDate.date_to : ""}
                  &key=${globalSearchValue.key ? parseInt(globalSearchValue.key) : ""}
                  &update=1 `}
                className="w-3/12 rounded-lg bg-blue-400 sm:w-full
   flex items-center justify-center p-1 py-3 text-white shrink-0"
              >
                განახლებით EXPORT
              </Link>
            </>
          )}
        </div>
        <div className="flex mt-5">
          <div className="flex items-center min-w-[300px]">
            <SelectInputValueId
              inputSetter={handleSelectBranch}
              options={filialData}
              defaultText={"ფილიალი"}
            />
            <BsX
              onClick={() => {
                setSelectedBranch("");
              }}
              className="text-red-500 shrink-0 text-4xl cursor-pointer"
            />
          </div>
        </div>
        <div className="w-full flex gap-3 flex-wrap mt-5">
          <PaginatedItems pageNumberSetter={setCurrentPage} dataLength={total}>
            <div className="w-full flex flex-col gap-y-2">
              {packageData.map((item) => {
                return (
                  <PassedPackagesItem
                    key={item.id}
                    data={item}
                    handleDeletePackage={handleDeletePackage}
                    handleMoveBack={handleMoveBack}
                  />
                );
              })}
            </div>
          </PaginatedItems>
        </div>
      </div>
    </div>
  );
};

export default PassedPackages;

import { useState } from "react";
import { BsGlobe } from "react-icons/bs";
import { useEffect } from "react";
import { useContext } from "react";
import { PrimmaryContext } from "../MainContext/MainContext";
import { useTranslation } from "react-i18next";

const LanguageSwitcherUser = () => {
    const { t } = useTranslation();
    const { setLanguage, language } = useContext(PrimmaryContext);
    const [show, setShow] = useState(false);
    const [value, setValue] = useState(language);
    const languages = ["ge", "en", "ru"];

    const handleShow = () => {
        setShow(prev => !prev);
    }

    useEffect(() => {
        if (value !== language) {
            setLanguage(value);
        }
    }, [value])

    return (
        <div className="relative">
            <div
                onClick={handleShow}
                className="flex items-center gap-3 px-[16px] py-[12px]">
                <BsGlobe className="font-bold text-[#636366] dark:text-[#aeaeb2]" />
                <p className="text-[#636366] text-[15px] font-bold cursor-pointer dark:text-[#aeaeb2]">{t("language")}</p>
            </div>
            {show &&
                <div className="rounded-lg bg-indigo_400_14 w-full p-2 bg-white dark:bg-[#171719]">
                    {languages.map((value, id) => {
                        return (
                            <p key={id}
                                onClick={() => { handleShow(); setValue(value) }}
                                className="text-[#5856D6] text-[16px] font-bold hover:bg-slate-200 px-4 py-2 rounded-lg cursor-pointer
                                uppercase">
                                {value}
                            </p>
                        )
                    })}
                </div>
            }
        </div >
    )
}

export default LanguageSwitcherUser;
import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import { PrimmaryContext } from "../../../../components/MainContext/MainContext";
import UsCard from "./usCard";
import PaginatedItems from "../../Pagination/Pagination";

import { AdminPrimaryContext } from "../../adminContext/AdminContext";

const UsLayout = () => {
  //get users data from api
  const [usersData, setUsersData] = useState([]);
  //search value
  const [searchValue, setSearchValue] = useState("");
  //context functions
  const { setUser, setToken } = useContext(PrimmaryContext);
  //user
  const { user } = useContext(AdminPrimaryContext);
  //current page
  const [currentPage, setCurrentPage] = useState(() => {
    const num = localStorage.getItem("EXPRPGNUM");
    if (num) {
      return parseInt(num);
    } else {
      return 0;
    }
  });
  //total records
  const [total, setTotal] = useState(0);
  //special users
  const [vip, setVip] = useState("");
  //timer
  let timer;

  //handle search value change
  const handleSearchValue = (e) => {
    const searchTerm = e.target.value;
    clearTimeout(timer);

    timer = setTimeout(() => {
      setSearchValue(searchTerm);
    }, 1000);
  };

  //handle page change
  const handleChangePage = (pageNum) => {
    setCurrentPage(pageNum);
    localStorage.setItem("EXPRPGNUM", pageNum);
  };

  //data fetch  function
  const fetchData = () => {
    axiosAdmin
      .get(`/Pack/barcode/30/${currentPage + 1}?key=${searchValue}&spec=${vip}`)
      .then((res) => {
        setUsersData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {});
  };

  // access user
  const handleAccessUser = (id) => {
    axiosAdmin
      .get(`/Users/access/${id}`)
      .then((res) => {
        setUser(res.data.user);
        setToken(res.data.token);
        window.open("https://expressgroup.ge", "_blank");
      })
      .catch((res) => {});
  };

  // delete user
  const handleDeleteUser = (id) => {
    axiosAdmin
      .get(`/Users/delete/${id}`)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {});
  };

  //users data from api
  useEffect(() => {
    if (searchValue.length !== 0 && currentPage !== 0) {
      setCurrentPage(0);
    }
  }, [searchValue, vip]);

  //users data from api
  useEffect(() => {
    fetchData();
  }, [currentPage, vip, searchValue]);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="w-full">
        <div className="w-full grid grid-cols-3 gap-3 md:grid-cols-2 sm:grid-cols-1">
          <div className="flex justify-between gap-1 w-full">
            <div
              className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-full bg-white"
            >
              <input
                type="search"
                placeholder="სახელი, გვარი, პირადი ნომერი, ოთახის ნომერი"
                className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
                onChange={(e) => {
                  handleSearchValue(e);
                }}
              />
            </div>
          </div>
          {user.role === 100 && (
            <Link
              to={`${process.env.REACT_APP_API_URL}/api/admin/Users/export`}
              className="rounded-lg bg-green-400 flex items-center justify-center p-1 py-3 text-white w-full"
            >
              EXPORT CSV
            </Link>
          )}
          <button
            onClick={() => {
              setVip((prev) => (prev === "" ? "1" : ""));
            }}
            className={`rounded-lg w-full flex items-center justify-center p-1 py-3 text-slate-400
                            ${vip === "" ? "bg-white" : "bg-slate-200"}`}
          >
            სპეც ტარიფები
          </button>
        </div>
        <PaginatedItems
          pageNumberSetter={handleChangePage}
          dataLength={total}
          forcedPage={currentPage}
        >
          <div className="my-5 flex flex-col gap-5">
            {usersData.map((obj) => {
              return (
                <UsCard
                  key={obj.id}
                  data={obj}
                  handleAccessUser={handleAccessUser}
                  handleDeleteUser={handleDeleteUser}
                />
              );
            })}
          </div>
        </PaginatedItems>
      </div>
    </div>
  );
};

export default UsLayout;

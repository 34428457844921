//exports from  modules
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//components
import { Text } from "../../components/Text";
import { List } from "../../components/List";
import { Button } from "../../components/Button";
import { Img } from "../../components/Img";
import MenuOnePage from "../MenuOne/MenuOnePage";
import FlightsModal from "../FlightsModal/FlightsModal";
import RegistrationmodalstepOnePage from "../Registration/RegistrationmodalstepOne";
import RegistrationmodalstepTwoPage from "../Registration/RegistrationmodalstepTwo";
import RegistrationmodalstepThreePage from "../Registration/RegistrationmodalstepThree";
import RegistrationmodalstepFourPage from "../Registration/RegistrationmodalstepFour";
import ForgetPassword from "../../components/ForgetPassword/ForgetPassword";
import MainAuth from "./auth";
import CustomAlert from "../../components/Alerts/CustomAlert";
import Messenger from "../../components/Messenger/Messnger";
import CalculateVolume from "../../components/CalculateVolume/CalculateVolume";
import LanguageSwitcher from "../../components/Languages/LanguageSwitcher";

//functions
import useScrollPosition from "../../components/CustomHooks/useScrollPosition";
import { PrimmaryContext } from "../../components/MainContext/MainContext";

//resources
import termsPdf from "./terms.pdf";
import video from "./video.mp4";
import expressLogo from "../../img/expresLogo.png";
import earth from "../../img/globe.png";
import dots from "../../img/dots.png";
import border from "../../img/border.png";
import el from "../../img/el.png";
import dotsGreen from "../../img/dotsGreen.png";
import dolar from "../../img/dolar.png";
import dolarShadow from "../../img/dolarShadow.png";
import contImg from "../../img/contimg.png";
import manSitting from "../../img/manSiting.png";
import phoneLogo from "../../img/phoneLogo.png";
import treckingSystem from "../../img/treckingSystem.png";
import packageManage from "../../img/packageManage.png";
import pricess from "../../img/prices.png";
import freeReg from "../../img/freeReg.png";
import chinaImg from "../../img/countryFlags/china.png";
import turkeyImg from "../../img/countryFlags/turkey.png";
import usaImg from "../../img/countryFlags/usa.png";
import greeceImg from "../../img/countryFlags/greece.png";
import ukImg from "../../img/countryFlags/uk.png";

//icons
import {
  BsHeadset,
  BsCreditCard,
  BsTruck,
  BsEnvelope,
  BsTelephone,
  BsInstagram,
  BsX,
  BsListUl,
} from "react-icons/bs";
import { BiMap, BiBell, BiMapAlt, BiPaperPlane } from "react-icons/bi";
import { FaUsers, FaFacebookSquare, FaYoutube, FaTiktok } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { TbPlane } from "react-icons/tb";

const MainPage = () => {
  //translations
  const { t } = useTranslation();
  //navigation
  const navigate = useNavigate();
  //scroll position
  const scrollPosition = useScrollPosition();
  //menu toggle variable
  const [menuOpen, setMenuOpen] = useState(false);
  //flights toggle variable
  const [flightsOpen, setflightsOpen] = useState(false);
  //controlling steps on registration
  const [step, setStep] = useState("");
  //hide scroll on step change
  const { handleScollOff } = useContext(PrimmaryContext);
  //forget passowrd toggler
  const [fpToggler, setFpToggler] = useState(false);
  //registration type
  const [regPersonType, setRegPersonType] = useState("");
  //section refs for sliding on sections
  const allRefs = {
    about: useRef(null),
    priority: useRef(null),
    price: useRef(null),
    services: useRef(null),
    contacts: useRef(null),
  };
  //data from api
  const { socialMediaData, filialsData, pagesData, language, prices } =
    useContext(PrimmaryContext);
  //log err popup
  const [logErrPopupFlag, setLgErrPopupFlag] = useState(false);
  //reset popup success toggler
  const [sucPopupFlag, setSucPopupFlag] = useState(false);
  //error message
  const [alertMsg, setAlertMsg] = useState(false);
  //addition text

  //menu toggle handler
  const handleMenuOpen = () => {
    setMenuOpen((prev) => !prev);
  };

  //flights toggle handler
  const handleflightsOpen = () => {
    setflightsOpen((prev) => !prev);
  };

  //step change handler
  const handleStep = (value) => {
    setStep(value);
  };

  //forget password handler
  const handleFpToggle = () => {
    setFpToggler((prev) => !prev);
  };

  //handle registration person type change
  const handleRegPersonType = (value) => {
    setRegPersonType(value);
  };

  //based on popup, srollbar of document should dissapear
  useEffect(() => {
    if (step === "") {
      handleScollOff(false);
    } else {
      handleScollOff(true);
    }
  }, [step]);

  //also scrollbar hidable
  useEffect(() => {
    if (fpToggler) {
      handleScollOff(true);
    } else {
      handleScollOff(false);
    }
  }, [fpToggler]);

  return (
    <>
      {logErrPopupFlag && (
        <CustomAlert
          text={alertMsg}
          setLgErrPopupFlag={setLgErrPopupFlag}
          alertType={"error"}
          Icon={BsX}
          value={logErrPopupFlag}
        />
      )}
      {sucPopupFlag && (
        <CustomAlert
          text={"ბმული გამოიგზავნა ელ.ფოსტაზე"}
          setLgErrPopupFlag={setSucPopupFlag}
          alertType={"success"}
          Icon={BsX}
          value={sucPopupFlag}
        />
      )}
      <MenuOnePage
        menuOpen={menuOpen}
        handleMenuOpen={handleMenuOpen}
        allRefs={allRefs}
      />
      <div
        className="bg-gray_100 flex items-center justify-center w-full font-notosansgeorgian
    relative z-20"
      >
        <FlightsModal
          flightsOpen={flightsOpen}
          handleflightsOpen={handleflightsOpen}
        />
        {step === "stepOne" && (
          <RegistrationmodalstepOnePage
            step={step}
            handleStep={handleStep}
            handleRegPersonType={handleRegPersonType}
          />
        )}
        {step === "stepTwo" && (
          <RegistrationmodalstepTwoPage
            step={step}
            handleStep={handleStep}
            regPersonType={regPersonType}
          />
        )}
        {step === "stepThree" && (
          <RegistrationmodalstepThreePage
            step={step}
            handleStep={handleStep}
            regPersonType={regPersonType}
          />
        )}
        {step === "stepFour" && (
          <RegistrationmodalstepFourPage
            step={step}
            handleStep={handleStep}
            regPersonType={regPersonType}
          />
        )}

        {fpToggler && <ForgetPassword handleFpToggle={handleFpToggle} />}

        <header
          className={`fixed top-0 left-0 z-30 px-[100px] py-4 w-full flex justify-between items-center 
            transition-all duration-300 delay-150 ease-linear md:px-10 sm:px-5 sm:bg-white 
            ${scrollPosition <= 5 ? "" : "bg-white bg-opacity-90"}`}
        >
          <Img
            src={expressLogo}
            className="cursor-pointer h-10 sm:hidden"
            onClick={() => navigate("/")}
            alt="logo"
          />
          <Img
            src={phoneLogo}
            className="cursor-pointer w-10 hidden sm:block"
            onClick={() => navigate("/")}
            alt="logo"
          />

          <div className="flex flex-row gap-3 sm:gap-2 items-center justify-start">
            <CalculateVolume weight={true} />
            <div className="sm:w-full">
              <Link to={"instructions"}>
                <button className="flex items-center gap-3 justify-center w-[auto] rounded-radius11 p-[11px] bg-teal_A700_14 text-teal_A700">
                  <BsListUl className="text-[20px] text-center" />
                  <div className="bg-transparent cursor-pointer font-semibold text-[14px] text-left text-teal_A700 md:hidden sm:hidden">
                    {t("instructions")}
                  </div>
                </button>
              </Link>
            </div>
            <div className="sm:w-full">
              <button
                onClick={handleflightsOpen}
                className="flex items-center gap-3 justify-center w-[auto] rounded-radius11 p-[11px] bg-teal_A700_14 text-teal_A700"
              >
                <TbPlane className="text-[20px] text-center" />
                <div className="bg-transparent cursor-pointer font-semibold text-[14px] text-left text-teal_A700 md:hidden sm:hidden">
                  {t("flights")}
                </div>
              </button>
            </div>
            <div className="sm:w-[100%] w-[17%]">
              <LanguageSwitcher />
            </div>
            <div className="sm:w-[100%] w-[17%] rounded-radius50">
              <button
                onClick={handleMenuOpen}
                className="flex h-[48px] w-[48px] items-center justify-center"
              >
                <FiMenu className="text-[#636366] text-[22px]" />
              </button>
            </div>
          </div>
        </header>

        <div className="w-full relative">
          <div className="relative flex justify-center items-center">
            <div className="absolute bg-gray_900 h-[928px] inset-y-[0] my-[auto] right-[0] w-[38%] z-10 md:hidden sm:hidden"></div>
            <div className="max-w-[1920px] w-full relative h-[928px] md:h-auto  sm:h-auto">
              <div
                className="w-full absolute flex flex-col items-center top-[0] z-20 px-[100px] 
                    md:px-[40px]  sm:px-[20px] md:py-[100px] sm:py-[100px] md:relative sm:relative"
              >
                <div className="flex md:flex-col sm:flex-col flex-row gap-[32px] items-center justify-between w-[100%]">
                  <div
                    className="w-6/12 flex sm:flex-1 flex-col gap-[40px] h-[928px] md:h-[auto] sm:h-[auto] 
                    items-start justify-center sm:gap-[24px] md:w-full sm:w-full"
                  >
                    <div className="flex flex-col gap-[16px] items-start justify-start w-[100%]">
                      <div className="flex flex-col font-firago gap-[8px] items-start justify-start w-[100%]">
                        <Text
                          className="text-indigo_400 text-left tracking-ls056 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                          as="h6"
                          variant="h6"
                        >
                          {t("receive_packages")}
                        </Text>
                        <Text
                          className="leading-[64.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[568px] text-black_900_e0 text-left md:tracking-ls1 sm:tracking-ls1 tracking-ls112"
                          as="h2"
                          variant="h2"
                        >
                          {t("from_Turkey_and_from_China")}
                        </Text>
                      </div>
                      <div className="grid grid-cols-1 gap-2 w-full">
                        {prices.map((item, index) => {
                          return (
                            <div className="flex items-center gap-2">
                              <Img
                                src={
                                  item.country === "China"
                                    ? chinaImg
                                    : item.country === "Turkey"
                                    ? turkeyImg
                                    : item.country === "USA"
                                    ? usaImg
                                    : item.country === "Greece"
                                    ? greeceImg
                                    : ukImg
                                }
                                className="h-[18px] object-contain"
                                alt="#"
                              />
                              <p
                                key={index}
                                className="font-notosansgeorgian not-italic text-black_900_b7 text-left w-full font-fw text-[14px] sm:text-[12px]"
                              >
                                {t(item.country?.toLowerCase())}{" "}
                                {item.country === "Turkey" ||
                                index === 3 ||
                                item.country === "Greece"
                                  ? t("land")
                                  : t("air")}{" "}
                                <span className="text-teal_A700 font-semibold">
                                  -{" "}
                                  {item.country === "Greece"
                                    ? "€"
                                    : item.country === "England"
                                    ? "£"
                                    : "$"}{" "}
                                  {item.price}
                                </span>{" "}
                                ({t(item.price_text)})
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <MainAuth
                      handleStep={handleStep}
                      setLgErrPopupFlag={setLgErrPopupFlag}
                      handleFpToggle={handleFpToggle}
                      setAlertMsg={setAlertMsg}
                    />
                  </div>
                  <div className="w-full relative flex items-center justify-center md:hidden sm:hidden">
                    <div
                      className="w-[592px] flex h-[592px] sm:h-[auto] items-center
                         justify-start p-[16px] rounded-radius50 relative z-20 "
                    >
                      <div className="h-[560px] md:h-[auto] sm:h-[auto] relative sm:w-[100%] w-[560px]">
                        <div className="h-[560px] md:h-[auto] sm:h-[auto] m-[auto] sm:w-[100%] w-[560px]">
                          <div
                            className="h-[560px] md:h-[auto] sm:h-[auto] m-[auto] sm:w-[100%] w-[560px]
                          flex items-center justify-center border border-indigo_400 border-dashed rounded-full"
                          >
                            <Img
                              src={earth}
                              className="h-[440px] m-[auto] rounded-radius50 w-[440px]"
                              alt="bg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative flex justify-center items-center"
            ref={allRefs.about}
          >
            <div
              style={{
                clipPath: "polygon(0 0, 100% 0, 100% 93%, 0% 100%)",
              }}
              className="bg-indigo_400_1e h-[100%] w-[100%] absolute top-0 left-0"
            ></div>
            <div className="max-w-[1920px] w-full relative py-[100px]">
              <div
                className="mx-[auto] sm:px-[20px] px-[100px] 
              md:px-[40px]  md:w-[100%] sm:w-[100%] relative"
              >
                <div className="flex flex-1 flex-col gap-[16px] h-[100%] items-center justify-start w-[100%]">
                  <div
                    className="flex md:flex-col sm:flex-col flex-row font-firago gap-[32px] items-start 
                  justify-start w-[100%] sm:gap-0 md:gap-0"
                  >
                    <div className="relative translate-y-[-120px]">
                      <Img
                        src={border}
                        className="absolute top-10 left-5 z-10 w-full h-full"
                        alt="border"
                      />
                      <div className="overflow-hidden rounded-xl">
                        <video
                          className="w-[500px] h-full relative z-20 md:w-full sm:w-full"
                          controls
                        >
                          <source src={video} type="video/mp4" />
                        </video>
                      </div>
                      <Img
                        src={dots}
                        className="absolute top-[-25px] left-[-50px] sm:top-[-20px] sm:left-[-30px]"
                        alt="border"
                      />
                    </div>
                    <div
                      className="flex flex-1 flex-col gap-[40px] items-start justify-start md:self-stretch 
                                sm:self-stretch w-[100%] sm:gap-[16px]"
                    >
                      <Text
                        className="font-bold text-black_900_e0 text-left tracking-ls072 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                        as="h3"
                        variant="h3"
                      >
                        {language === "ge"
                          ? pagesData && pagesData[1].title
                          : language === "en"
                          ? pagesData && pagesData[1].titleen
                          : language === "ru" &&
                            pagesData &&
                            pagesData[1].titleru}
                      </Text>
                      <div className="flex flex-col font-notosansgeorgian gap-[16px] items-start justify-start w-[100%]">
                        <Text
                          className="font-normal leading-[24.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[808px] not-italic text-black_900_99 text-left"
                          variant="body5"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[1].text
                            : language === "en"
                            ? pagesData && pagesData[1].texten
                            : language === "ru" &&
                              pagesData &&
                              pagesData[1].textru}
                        </Text>
                      </div>
                    </div>
                  </div>
                  <Text
                    className="font-bold text-black_900_e0 text-left tracking-ls072 md:tracking-ls1 sm:tracking-ls1
                    w-full font-firago"
                    as="h3"
                    variant="h3"
                  >
                    {language === "ge"
                      ? pagesData && pagesData[12].title
                      : language === "en"
                      ? pagesData && pagesData[12].titleen
                      : language === "ru" && pagesData && pagesData[12].titleru}
                  </Text>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        language === "ge"
                          ? pagesData && pagesData[12].text
                          : language === "en"
                          ? pagesData && pagesData[12].texten
                          : language === "ru" &&
                            pagesData &&
                            pagesData[12].textru,
                    }}
                    className="font-normal leading-[24.00px] not-italic text-black_900_99 text-left font-notosansgeorgian
                    relative z-20"
                  ></div>
                </div>
              </div>
              <div className="absolute bottom-[-20%] right-0 md:bottom-[-10%] sm:bottom-[-2%]">
                <Img
                  src={el}
                  className="sm:flex-1 h-[452px] md:h-[auto] object-cover  w-[226px]
                            sm:h-[226px] sm:w-[113px]"
                  alt="el"
                />
              </div>
            </div>
          </div>
          <div
            className="relative flex justify-center items-center"
            ref={allRefs.priority}
          >
            <div
              className="max-w-[1920px] w-full relative px-[100px] pt-[50px] pb-[100px] md:px-[40px] sm:px-[20px]
                sm:py-[32px]"
            >
              <div className="flex flex-col gap-[34px] items-start justify-start w-[100%] sm:gap-[24px]">
                <div
                  className="flex flex-1 flex-col gap-[8px] items-start justify-start max-w-[1408px] md:mt-[0] 
                        sm:mt-[0] w-[100%] "
                >
                  <Text
                    className="font-fw font-notosansgeorgian text-indigo_400 text-left w-[auto]"
                    variant="body5"
                  >
                    {t("why_expressgroup")}
                  </Text>
                  <Text
                    className="font-bold font-firago text-black_900_e0 text-left tracking-ls072 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    as="h3"
                    variant="h3"
                  >
                    {t("our_advantages")}
                  </Text>
                </div>
                <List
                  className="sm:flex-col flex-row gap-[32px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 
                            justify-start w-[100%] "
                  orientation="horizontal"
                >
                  <div className="bg-white_A700 hover:cursor-pointer flex flex-1 flex-col gap-[24px] items-start justify-start hover:mx-[0] hover:outline outline hover:outline-[1px] outline-[1px] hover:outline-black_900_0a outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch hover:shadow-bs shadow-bs hover:w-[100%] w-[100%]">
                    <div className="h-[100px] md:h-[50px] sm:h-[50px]">
                      <Img
                        src={freeReg}
                        className="h-full object-contain"
                        alt="pic Two"
                      />
                    </div>
                    <div className="flex flex-col gap-[16px] items-start justify-start w-[100%]">
                      <Text
                        className="font-semibold text-indigo_400 text-left w-[auto]"
                        variant="body5"
                      >
                        {language === "ge"
                          ? pagesData && pagesData[2].title
                          : language === "en"
                          ? pagesData && pagesData[2].titleen
                          : language === "ru" &&
                            pagesData &&
                            pagesData[2].titleru}
                      </Text>
                      <Text
                        className="font-normal leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 text-left"
                        variant="body7"
                      >
                        {language === "ge"
                          ? pagesData && pagesData[2].text
                          : language === "en"
                          ? pagesData && pagesData[2].texten
                          : language === "ru" &&
                            pagesData &&
                            pagesData[2].textru}
                      </Text>
                    </div>
                  </div>
                  <div className="bg-white_A700 hover:cursor-pointer flex flex-1 flex-col gap-[24px] items-start justify-start hover:mx-[0] hover:outline outline hover:outline-[1px] outline-[1px] hover:outline-black_900_0a outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch hover:shadow-bs shadow-bs hover:w-[100%] w-[100%]">
                    <div className="h-[100px] md:h-[50px] sm:h-[50px]">
                      <Img
                        src={treckingSystem}
                        className="h-full object-contain"
                        alt="#"
                      />
                    </div>
                    <div className="flex flex-col gap-[16px] items-start justify-start w-[100%]">
                      <Text
                        className="font-semibold text-indigo_400 text-left w-[auto]"
                        variant="body5"
                      >
                        {language === "ge"
                          ? pagesData && pagesData[3].title
                          : language === "en"
                          ? pagesData && pagesData[3].titleen
                          : language === "ru" &&
                            pagesData &&
                            pagesData[3].titleru}
                      </Text>
                      <Text
                        className="font-normal leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 text-left"
                        variant="body7"
                      >
                        {language === "ge"
                          ? pagesData && pagesData[3].text
                          : language === "en"
                          ? pagesData && pagesData[3].texten
                          : language === "ru" &&
                            pagesData &&
                            pagesData[3].textru}
                      </Text>
                    </div>
                  </div>
                  <div className="bg-white_A700 hover:cursor-pointer flex flex-1 flex-col gap-[24px] items-start justify-start hover:mx-[0] hover:outline outline hover:outline-[1px] outline-[1px] hover:outline-black_900_0a outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch hover:shadow-bs shadow-bs hover:w-[100%] w-[100%]">
                    <div className="h-[100px] md:h-[50px] sm:h-[50px]">
                      <Img
                        src={packageManage}
                        className="h-full object-contain"
                        alt="#"
                      />
                    </div>
                    <div className="flex flex-col gap-[16px] items-start justify-start w-[100%]">
                      <Text
                        className="font-semibold text-indigo_400 text-left w-[auto]"
                        variant="body5"
                      >
                        {language === "ge"
                          ? pagesData && pagesData[4].title
                          : language === "en"
                          ? pagesData && pagesData[4].titleen
                          : language === "ru" &&
                            pagesData &&
                            pagesData[4].titleru}
                      </Text>
                      <Text
                        className="font-normal leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 text-left"
                        variant="body7"
                      >
                        {language === "ge"
                          ? pagesData && pagesData[4].text
                          : language === "en"
                          ? pagesData && pagesData[4].texten
                          : language === "ru" &&
                            pagesData &&
                            pagesData[4].textru}
                      </Text>
                    </div>
                  </div>
                </List>
              </div>
            </div>
          </div>
          <div
            className="relative flex justify-center items-center"
            ref={allRefs.price}
          >
            <div
              style={{
                clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 92%)",
              }}
              className="absolute bg-indigo_400_1e top-0 left-0 flex-1 h-full w-[100%]"
            ></div>
            <div
              className="max-w-[1920px] w-full relative font-firago px-[100px] md:px-[40px] sm:px-[20px] 
                    flex flex-1 md:flex-col sm:flex-col flex-row items-center justify-between 
                    gap-20 py-32 sm:py-20 mdsm:gap-5 sm:gap-5 md:items-start 
                    sm:items-start sm:pt-[32px]"
            >
              <div className="relative">
                <Img
                  src={dolar}
                  className="h-[100%] w-[100px] relative z-20"
                  alt="left"
                />
                <Img
                  src={dolarShadow}
                  className="h-[100%] w-[100px] absolute top-[15px] left-[15px] z-10"
                  alt="left"
                />
              </div>
              <div className="h-[180px] md:h-[220px] sm:h-auto relative w-[100%]">
                <div className="font-notosansgeorgian justify-center w-[100%] flex sm:flex-col sm:gap-4">
                  <div
                    className="flex flex-col gap-[16px] inset-x-[0] items-start justify-start 
                                    max-w-[1424px] w-[100%]"
                  >
                    <Text
                      className="font-bold text-black_900_e0 text-left tracking-ls072 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                      as="h3"
                      variant="h3"
                    >
                      {t("prices")}
                    </Text>
                    <div className="sm:w-full">
                      <div className="grid grid-cols-3 gap-5 sm:grid-cols-3">
                        {prices.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="flex flex-row gap-[16px] items-center justify-start w-full
                                sm:flex-col sm:items-start sm:bg-[#747480]/25 sm:rounded-lg sm:p-2"
                            >
                              <Button className="flex h-[48px] items-center justify-center w-[48px]">
                                <Img
                                  src={
                                    item.country === "China"
                                      ? chinaImg
                                      : item.country === "Turkey"
                                      ? turkeyImg
                                      : item.country === "USA"
                                      ? usaImg
                                      : item.country === "Greece"
                                      ? greeceImg
                                      : ukImg
                                  }
                                  className="h-[24px] object-contain"
                                  alt="camera"
                                />
                              </Button>
                              <div className="flex flex-col items-start justify-center w-[auto]">
                                <Text
                                  className="font-normal font-notosansgeorgian not-italic text-black_900_b7 text-left w-[auto]"
                                  variant="body7"
                                >
                                  {t(item.country?.toLowerCase())}{" "}
                                  {item.country === "Turkey" || index === 3
                                    ? t("land")
                                    : t("air")}
                                </Text>
                                <Text
                                  className="font-bold font-firago text-left text-indigo_400 tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                                  variant="body3"
                                >
                                  1 kg ={" "}
                                  {item.country === "Greece"
                                    ? "€"
                                    : item.country === "England"
                                    ? "£"
                                    : "$"}{" "}
                                  {item.price}
                                </Text>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <Text
                      className="font-normal not-italic text-black_900_99 text-left w-[auto]"
                      variant="body5"
                    >
                      {t(
                        "bulk_shipments_are_subject_to_a_different_rate_based_on_actual_weights"
                      )}
                    </Text>
                    <Text
                      className="font-normal not-italic text-black_900_99 text-left w-[auto]"
                      variant="body5"
                    >
                      {t("prices_text")}
                    </Text>
                  </div>
                  <div className="sm:w-full flex justify-end">
                    <Img
                      src={pricess}
                      className="object-contain w-[250px]"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-[-10%] right-[5%]">
              <Img
                src={dotsGreen}
                className="object-cover w-[150px]"
                alt="img"
              />
            </div>
          </div>
          <div
            className="relative flex justify-center items-center"
            ref={allRefs.services}
          >
            <div className="max-w-[1920px] w-full relative px-[100px]  md:px-[40px]  sm:px-[20px] ">
              <div
                className="bottom-[0] flex flex-col font-notosansgeorgian gap-[48px] inset-x-[0] 
                    items-center justify-end max-w-[1920px] mx-[auto] pb-[80px] pt-[136px]
                    w-[100%] sm:py-[32px] sm:gap-[24px]"
              >
                <div className="flex flex-col gap-[8px] items-start justify-start w-[100%]">
                  <Text
                    className="font-fw font-notosansgeorgian text-indigo_400 text-left w-[auto]"
                    variant="body5"
                  >
                    {t("what_ExpressGroup_offers")}
                  </Text>
                  <Text
                    className="font-bold font-firago text-black_900_e0 text-left tracking-ls072 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    as="h3"
                    variant="h3"
                  >
                    {t("our_services")}
                  </Text>
                </div>
                <div className="flex flex-col items-start justify-start w-[100%]">
                  <div className="md:gap-[20px] sm:gap-[20px] gap-[32px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center min-h-[auto] w-[100%]">
                    <div className="bg-white_A700 flex flex-1 flex-col gap-[24px] items-start justify-start outline outline-[1px] outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]">
                      <Button
                        className="flex h-[56px] items-center justify-center rounded-radius50 w-[56px]"
                        size="2xlIcn"
                        variant="icbFillIndigo40014"
                        shape="circular"
                      >
                        <BiMap className="text-[#5856D6] w-full h-full" />
                      </Button>
                      <div className="flex flex-col gap-[16px] items-start justify-start w-[100%]">
                        <Text
                          className="font-semibold text-black_900_e0 text-left w-[auto]"
                          variant="body5"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[6].title
                            : language === "en"
                            ? pagesData && pagesData[6].titleen
                            : language === "ru" &&
                              pagesData &&
                              pagesData[6].titleru}
                        </Text>
                        <Text
                          className="font-normal leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 text-left"
                          variant="body7"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[6].text
                            : language === "en"
                            ? pagesData && pagesData[6].texten
                            : language === "ru" &&
                              pagesData &&
                              pagesData[6].textru}
                        </Text>
                      </div>
                    </div>
                    <div className="bg-white_A700 flex flex-1 flex-col gap-[24px] items-start justify-start outline outline-[1px] outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]">
                      <Button
                        className="flex h-[56px] items-center justify-center rounded-radius50 w-[56px]"
                        size="2xlIcn"
                        variant="icbFillIndigo40014"
                        shape="circular"
                      >
                        <BsHeadset className="text-[#5856D6] w-full h-full" />
                      </Button>
                      <div className="flex flex-col gap-[16px] items-start justify-start w-[100%]">
                        <Text
                          className="font-semibold text-black_900_e0 text-left w-[auto]"
                          variant="body5"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[7].title
                            : language === "en"
                            ? pagesData && pagesData[7].titleen
                            : language === "ru" &&
                              pagesData &&
                              pagesData[7].titleru}
                        </Text>
                        <Text
                          className="font-normal leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 text-left"
                          variant="body7"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[7].text
                            : language === "en"
                            ? pagesData && pagesData[7].texten
                            : language === "ru" &&
                              pagesData &&
                              pagesData[7].textru}
                        </Text>
                      </div>
                    </div>
                    <div className="bg-white_A700 flex flex-1 flex-col gap-[24px] items-start justify-start outline outline-[1px] outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]">
                      <Button
                        className="flex h-[56px] items-center justify-center rounded-radius50 w-[56px]"
                        size="2xlIcn"
                        variant="icbFillIndigo40014"
                        shape="circular"
                      >
                        <BiBell className="text-[#5856D6] w-full h-full" />
                      </Button>
                      <div className="flex flex-col gap-[16px] items-start justify-start w-[100%]">
                        <Text
                          className="font-semibold text-black_900_e0 text-left w-[auto]"
                          variant="body5"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[8].title
                            : language === "en"
                            ? pagesData && pagesData[8].titleen
                            : language === "ru" &&
                              pagesData &&
                              pagesData[8].titleru}
                        </Text>
                        <Text
                          className="font-normal leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 text-left"
                          variant="body7"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[8].text
                            : language === "en"
                            ? pagesData && pagesData[8].texten
                            : language === "ru" &&
                              pagesData &&
                              pagesData[8].textru}
                        </Text>
                      </div>
                    </div>
                    <div className="bg-white_A700 flex flex-1 flex-col gap-[24px] items-start justify-start outline outline-[1px] outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]">
                      <Button
                        className="flex h-[56px] items-center justify-center rounded-radius50 w-[56px]"
                        size="2xlIcn"
                        variant="icbFillIndigo40014"
                        shape="circular"
                      >
                        <BsCreditCard className="text-[#5856D6] w-full h-full" />
                      </Button>
                      <div className="flex flex-col gap-[16px] items-start justify-start w-[100%]">
                        <Text
                          className="font-semibold text-black_900_e0 text-left w-[auto]"
                          variant="body5"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[9].title
                            : language === "en"
                            ? pagesData && pagesData[9].titleen
                            : language === "ru" &&
                              pagesData &&
                              pagesData[9].titleru}
                        </Text>
                        <Text
                          className="font-normal leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 text-left"
                          variant="body7"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[9].text
                            : language === "en"
                            ? pagesData && pagesData[9].texten
                            : language === "ru" &&
                              pagesData &&
                              pagesData[9].textru}
                        </Text>
                      </div>
                    </div>
                    <div className="bg-white_A700 flex flex-1 flex-col gap-[24px] items-start justify-start outline outline-[1px] outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]">
                      <Button
                        className="flex h-[56px] items-center justify-center rounded-radius50 w-[56px]"
                        size="2xlIcn"
                        variant="icbFillIndigo40014"
                        shape="circular"
                      >
                        <BsTruck className="text-[#5856D6] w-full h-full" />
                      </Button>
                      <div className="flex flex-col gap-[16px] items-start justify-start w-[100%]">
                        <Text
                          className="font-semibold text-black_900_e0 text-left w-[auto]"
                          variant="body5"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[10].title
                            : language === "en"
                            ? pagesData && pagesData[10].titleen
                            : language === "ru" &&
                              pagesData &&
                              pagesData[10].titleru}
                        </Text>
                        <Text
                          className="font-normal leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 text-left"
                          variant="body7"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[10].text
                            : language === "en"
                            ? pagesData && pagesData[10].texten
                            : language === "ru" &&
                              pagesData &&
                              pagesData[10].textru}
                        </Text>
                      </div>
                    </div>
                    <div className="bg-white_A700 flex flex-1 flex-col gap-[24px] items-start justify-start outline outline-[1px] outline-black_900_0a p-[24px] sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]">
                      <Button
                        className="flex h-[56px] items-center justify-center rounded-radius50 w-[56px]"
                        size="2xlIcn"
                        variant="icbFillIndigo40014"
                        shape="circular"
                      >
                        <FaUsers className="text-[#5856D6] w-full h-full" />
                      </Button>
                      <div className="flex flex-col gap-[16px] items-start justify-start w-[100%]">
                        <Text
                          className="font-semibold text-black_900_e0 text-left w-[auto]"
                          variant="body5"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[11].title
                            : language === "en"
                            ? pagesData && pagesData[11].titleen
                            : language === "ru" &&
                              pagesData &&
                              pagesData[11].titleru}
                        </Text>
                        <Text
                          className="font-normal leading-[20.00px] md:max-w-[100%] sm:max-w-[100%] max-w-[400px] not-italic text-black_900_99 text-left"
                          variant="body7"
                        >
                          {language === "ge"
                            ? pagesData && pagesData[11].text
                            : language === "en"
                            ? pagesData && pagesData[11].texten
                            : language === "ru" &&
                              pagesData &&
                              pagesData[11].textru}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative flex justify-center items-center"
            ref={allRefs.contacts}
          >
            <div className="max-w-[1920px] w-full relative px-[100px] font-firago md:px-[40px]  sm:px-[20px]">
              <div
                className="flex flex-col  gap-[56px] items-start justify-start
                    py-[80px] w-[100%] sm:gap-[32px] md:gap-10 sm:py-[32px] sm:sm:pt-[0px]"
              >
                <div className="flex flex-col gap-[16px] items-start justify-start mx-[auto] w-[100%]">
                  <div className="bg-indigo_400 h-[2px] w-[3%]"></div>
                  <Text
                    className="font-bold text-black_900_e0 text-left tracking-ls072 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    as="h3"
                    variant="h3"
                  >
                    {t("contact")}
                  </Text>
                </div>
                <div
                  className="flex flex-col gap-[56px] items-start justify-start mx-[auto] w-[100%]
                        sm:gap-[24px]"
                >
                  <div className="flex md:flex-col sm:flex-col flex-row gap-[32px] items-start justify-start w-[100%]">
                    <List
                      className="md:flex-1 sm:flex-1 sm:flex-col flex-row gap-[32px] grid md:grid-cols-1 
                                    sm:grid-cols-1 grid-cols-2 md:w-[100%] sm:w-[100%] w-[66%] sm:gap-[24px]"
                      orientation="horizontal"
                    >
                      <div
                        className="flex flex-col gap-[24px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]
                                    sm:flex-row sm:items-center"
                      >
                        <Button className="flex h-[48px] items-center justify-center w-[48px]">
                          <BsEnvelope className="text-[#5856D6]" />
                        </Button>
                        <div
                          className="flex flex-col gap-[20px] items-start justify-start w-[100%]
                                        sm:gap-0"
                        >
                          <Text
                            className="font-firago font-semibold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1
                                                w-[auto] sm:text-sm"
                            variant="body3"
                          >
                            {t("write_us")}
                          </Text>
                          <Text
                            className="font-normal font-notosansgeorgian not-italic text-black_900_99 text-left w-[auto]
                                                sm:hidden"
                            variant="body7"
                          >
                            {t("send_us_an_email_via_mail")}
                          </Text>

                          {socialMediaData.mailGeneral !== null &&
                            socialMediaData.mailGeneral
                              .split(" ")
                              .map((item, index) => {
                                return (
                                  <a href={`mailto:${item}`} key={index}>
                                    <Text
                                      className="font-notosansgeorgian font-semibold text-indigo_400 text-left w-[auto]"
                                      variant="body5"
                                    >
                                      {item}
                                    </Text>
                                  </a>
                                );
                              })}
                        </div>
                      </div>
                      <div
                        className="flex flex-col gap-[24px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]
                                    sm:flex-row sm:items-center"
                      >
                        <Button className="flex h-[48px] items-center justify-center w-[48px]">
                          <BsTelephone className="text-[#5856D6]" />
                        </Button>
                        <div
                          className="flex flex-col gap-[20px] items-start justify-start w-[100%]
                                        sm:gap-0"
                        >
                          <Text
                            className="font-firago font-semibold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 
                                                w-[auto] sm:text-sm"
                            variant="body3"
                          >
                            {t("call_us")}
                          </Text>
                          <Text
                            className="font-normal font-notosansgeorgian not-italic text-black_900_99 text-left w-[auto]
                                                sm:hidden"
                            variant="body7"
                          >
                            {t("contact_us_only_during_working_hours")}
                          </Text>
                          <a href={`tel:${socialMediaData.phoneGeneral}`}>
                            <Text
                              className="font-notosansgeorgian font-semibold text-indigo_400 text-left w-[auto]"
                              variant="body5"
                            >
                              {socialMediaData.phoneGeneral}
                            </Text>
                          </a>
                        </div>
                      </div>
                    </List>
                    <Img
                      src={contImg}
                      className="h-[344px] w-[447px] sm:h-auto"
                      alt="ill"
                    />
                  </div>
                  <div
                    className="flex flex-col gap-[40px] items-start justify-start w-[100%]
                            sm:gap-[24px]"
                  >
                    <div className="flex flex-col items-start justify-start w-[100%]">
                      <Text
                        className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                        variant="body1"
                      >
                        {t("filials")}
                      </Text>
                    </div>
                    <div
                      className="font-notosansgeorgian gap-[32px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 
                                items-start justify-start w-[100%] sm:gap-[16px]"
                    >
                      {filialsData.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="bg-white_A700 flex flex-1 flex-col gap-[24px] items-start justify-start outline outline-[1px] outline-black_900_0a p-[24px] 
                          sm:px-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
                          >
                            <div className="flex flex-row gap-[16px] items-center justify-start w-[100%]">
                              <Button
                                className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                                variant="icbFillIndigo40014"
                                shape={"circular"}
                              >
                                <BiMap className="text-[#5856D6] w-full h-full" />
                              </Button>
                              <div className="flex flex-1 flex-col items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                                <Text
                                  className="font-semibold text-black_900_e0 text-left w-[auto]"
                                  variant="body5"
                                >
                                  {language === "ge"
                                    ? pagesData && item.title
                                    : language === "en"
                                    ? pagesData && item.titleen
                                    : language === "ru" &&
                                      pagesData &&
                                      item.titleru}
                                </Text>
                              </div>
                            </div>
                            <div className="flex flex-col gap-[20px] items-start justify-start w-[100%]">
                              <div className="flex flex-col gap-[8px] items-start justify-start w-[100%]">
                                <div className="flex flex-row gap-[16px] items-start justify-start w-[100%]">
                                  <Text
                                    className="font-normal not-italic text-black_900_99 text-left w-[auto]"
                                    variant="body7"
                                  >
                                    {t("mon_fri")}:{" "}
                                  </Text>
                                  <Text
                                    className="flex-1 font-normal not-italic text-black_900_99 text-left w-[auto]"
                                    variant="body7"
                                  >
                                    {item.hours1}
                                  </Text>
                                </div>
                                <div className="flex flex-row gap-[16px] items-start justify-start w-[100%]">
                                  <Text
                                    className="font-normal not-italic text-black_900_99 text-left w-[auto]"
                                    variant="body7"
                                  >
                                    {t("saturdey")}:{" "}
                                  </Text>
                                  <Text
                                    className="flex-1 font-normal not-italic text-black_900_99 text-left w-[auto]"
                                    variant="body7"
                                  >
                                    {item.hours2}
                                  </Text>
                                </div>
                              </div>
                              <div
                                className="flex flex-row gap-[16px] items-center justify-start w-[100%]
                                            sm:gap-[8px]"
                              >
                                <Button
                                  className="flex flex-1 items-center justify-center text-center w-[100%]"
                                  leftIcon={
                                    <BiMapAlt className="text-[#5856D6] mr-2" />
                                  }
                                  shape="RoundedBorder11"
                                  size="lg"
                                  variant="FillIndigo40014"
                                >
                                  <a href={item.coords} target="_blank">
                                    <div className="bg-transparent cursor-pointer font-fw text-[15px] text-indigo_400 text-left">
                                      {t("viev_on_the_map")}
                                    </div>
                                  </a>
                                </Button>
                                <a href={`tel:${item.phone}`}>
                                  <Button
                                    className="flex h-[40px] items-center justify-center w-[40px]"
                                    variant="icbOutlineBlack9000a_1"
                                  >
                                    <BsTelephone className="text-white" />
                                  </Button>
                                </a>
                                <a href={`mailto:${item.mail}`}>
                                  <div
                                    className="bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs
                              flex h-[40px] items-center justify-center w-[40px] rounded-[8px]"
                                  >
                                    <BiPaperPlane className="text-white" />
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div
                        className="bg-indigo_400_14 border-2 border-dashed border-indigo_400 flex flex-1 flex-col 
                                    font-firago h-[100%] items-center justify-center p-[24px] sm:px-[20px] rounded-radius13 w-[100%]"
                      >
                        <Text
                          className="font-medium text-indigo_400 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]
                                            sm:h-[200px] sm:flex sm:items-center sm:justify-center"
                          variant="body3"
                        >
                          Coming soon
                        </Text>
                      </div>
                      <Img
                        src={manSitting}
                        className="flex-1 h-[100%] max-h-[228px] w-[auto] sm:hidden"
                        alt="div"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative flex justify-center items-center bg-gray_900">
            <div className="max-w-[1920px] w-full relative">
              <div
                className=" flex flex-col font-notosansgeorgian items-start justify-start sm:px-[20px] px-[256px]
                     md:px-[40px] py-[40px] w-[100%] sm:py-[20px]"
              >
                <div
                  className="flex sm:flex-col flex-row md:gap-[40px] items-center justify-between max-w-[1408px]
                         mx-[auto] w-[100%] sm:gap-[8px] sm:items-start"
                >
                  <Text
                    className="font-normal not-italic text-left text-white_A700_ea w-[auto]"
                    variant="body7"
                  >
                    2023 {t("all_rights_reserved")}
                  </Text>
                  <a href={termsPdf} target="_blank">
                    <Text
                      className="font-normal not-italic text-left text-white_A700_c1 w-[auto]"
                      variant="body7"
                    >
                      {t("terms_of_use_of_the_site")}
                    </Text>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="fixed bottom-10 left-5 flex flex-col gap-5 z-40
                sm:bottom-[20px] sm:left-[10px]"
          >
            <a
              href={socialMediaData.ins}
              target="_blank"
              className="cursor-pointer w-8 h-8 flex items-center justify-center rounded-full 
              bg-white p-2 border border-slate-100"
            >
              <BsInstagram className="text-2xl text-[#f72373]" />
            </a>
            <a
              href={socialMediaData.yt}
              target="_blank"
              className="cursor-pointer w-8 h-8 flex items-center justify-center rounded-full 
              bg-white p-2 border border-slate-100"
            >
              <FaYoutube className="text-2xl text-[#ff0000]" />
            </a>
            <a
              href={socialMediaData.fb}
              target="_blank"
              className="cursor-pointer w-8 h-8 flex items-center justify-center rounded-full 
              bg-white p-2 border border-slate-100"
            >
              <FaFacebookSquare className="text-2xl text-[#0866ff]" />
            </a>
            <a
              href={socialMediaData.tiktok}
              target="_blank"
              className="cursor-pointer w-8 h-8 flex items-center justify-center rounded-full 
              bg-white p-2 border border-slate-100"
            >
              <FaTiktok className="text-2xl" />
            </a>
          </div>
        </div>
        <Messenger />
      </div>
    </>
  );
};

export default MainPage;

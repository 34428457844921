import { useState, useEffect } from "react";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";

//icons
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { BiMap } from "react-icons/bi";

const SelecWithValue = ({
    name,
    options,
    inputName,
    inputSetter = null,
}) => {
    //toggle variable
    const [isOpen, setIsOpen] = useState(false);
    //current selected value
    const [selectValue, setSelectValue] = useState("");
    //show name
    const [showName, setShowName] = useState(name);

    //select handler
    const handleSelect = (title) => {
        setSelectValue(title);
    };

    //toggle handler
    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    //selected value
    useEffect(() => {
        inputSetter(selectValue, inputName);
        setShowName(selectValue);
    }, [selectValue])

    //set name on load
    useEffect(() => {
        setShowName(name);
    }, [name])

    return (
        <div className="w-full relative">
            <div
                onClick={handleOpen}
                className="flex flex-row gap-[20px] items-center justify-between pr-[20px]
              bg-white_A700 shadow-bs p-5 rounded-radius17 outline dark:bg-[#171719]
              outline-[1px] outline-black_900_0a cursor-pointer"
            >
                <div className="flex items-center gap-2">
                    <Button
                        className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                        variant="icbFillIndigo40014"
                        shape="circular"
                    >
                        <BiMap className="h-[17px] w-[17px] text-indigo_400" />
                    </Button>
                    <Text
                        className="font-fw text-black_900_b7 text-left dark:text-[#c7c7c7]"
                        variant="body7"
                    >
                        {showName}
                    </Text>
                </div>
                {isOpen ? (
                    <BiChevronUp className="text-sm text-gray-500 dark:text-[#c7c7c7]" />
                ) : (
                    <BiChevronDown className="text-sm text-gray-500 dark:text-[#c7c7c7]" />
                )}
            </div>
            {isOpen ? (
                <div
                    className="w-full max-h-80  overflow-y-auto scrollbar bg-white rounded-lg 
                        absolute bottom-0 left-0 translate-y-[105%] border border-slate-300 z-40
                     dark:border-slate-600 dark:bg-[#171719]"
                >
                    {options.map((value) => {
                        return (
                            <div
                                onClick={() => {
                                    handleSelect(value.city ? `${value.address} - ${value.city}` : value.address);
                                    handleOpen();
                                }}
                                key={value.id}
                                className="p-3 w-full hover:bg-slate-300 group"
                            >
                                <p
                                    className="text-sm text-gray-500 font-bold 
                        group-hover:text-slate-800 dark:text-[#aeaeb2]"
                                >
                                    {value.address}
                                </p>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default SelecWithValue;

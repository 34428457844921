import { memo, useState, useCallback, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import axiosClient from "../../api/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const libraries = ["places"];

function Map({ getInformation }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA6DmkyAFw9yTF8KMQRJj8YtoazR14tE3I",
    libraries,
  });

  const [center, setCenter] = useState({ lat: 41.716667, lng: 44.783333 });
  const [marker, setMarker] = useState(null);
  const [map, setMap] = useState(null);

  const fetchReverseGeocoding = async (lat, lng) => {
    try {
      const { data } = await axiosClient.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}`
      );
      getInformation(data.city, "city");
      getInformation(data.countryName, "country");
    } catch (err) {}
  };

  useEffect(() => {
    if (marker) {
      fetchReverseGeocoding(marker.lat, marker.lng);
    }
  }, [marker]);

  const onMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setMarker({ lat, lng });
    getInformation(lat, "lat");
    getInformation(lng, "lng");
  };

  const onLoad = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      setMap(map);
    },
    [center]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setCenter({ lat: coords.latitude, lng: coords.longitude });
        },
        (error) => {
          const errors = {
            1: "User denied the request for Geolocation.",
            2: "Location information is unavailable.",
            3: "The request to get user location timed out.",
            0: "An unknown error occurred.",
          };
          console.error(errors[error.code] || errors[0]);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    isLoaded && (
      <div className="relative">
        <div className="absolute top-3 left-3 z-40">
          <PlacesAutocomplete setMarker={setMarker} setCenter={setCenter} />
        </div>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onMapClick}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          {marker && <Marker position={marker} />}
        </GoogleMap>
      </div>
    )
  );
}

function PlacesAutocomplete({ setMarker, setCenter }) {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    setCenter({ lat, lng });
    setMarker({ lat, lng });
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="w-full p-5"
        placeholder="Search place"
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}

export default memo(Map);

import { useEffect, useState, useContext } from "react";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import { Link } from "react-router-dom";
import axiosAdmin from "../../../../../api/apiAdmin";
import { SelectInput } from "../../../inputComponents/inputcomp";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";
import { useTranslation } from "react-i18next";
import ContinueComponent from "../../../../../components/Alerts/ContinueComponent";

//icons
import {
  BsBoxArrowInRight,
  BsTrash,
  BsFillPersonFill,
  BsFillEnvelopeFill,
  BsTelephoneFill,
  BsFillCalendarFill,
  BsFillHandbagFill,
  BsFillBuildingFill,
} from "react-icons/bs";
import { BiPackage, BiPaperPlane } from "react-icons/bi";
import {
  FaBarcode,
  FaMoneyBillAlt,
  FaScroll,
  FaTruckMoving,
} from "react-icons/fa";
import { GiWeight } from "react-icons/gi";
import UpdateParcel from "../UpdateParcel";

const WarehousePackageItem = ({
  data,
  handleDeletePackage,
  setDifResponses,
  setCheckedAll,
  handleSelectedPackages,
  handleDeleteSelectedPackages,
}) => {
  //translations
  const { t } = useTranslation();
  //package check
  const [check, setCheck] = useState(false);
  //flight container
  const [flights, setFlights] = useState([]);
  //package data
  const [packData, setPackData] = useState([]);
  //selected flights
  const [selectedFlight, setSelectedFlight] = useState("");
  //dropdown toggler
  const [toggler, setToggler] = useState(false);
  //check if user is authorized
  const { user } = useContext(AdminPrimaryContext);
  const flightOptions = useContext(AdminPrimaryContext).flights;
  //show continue
  const [showContinue, setShowContinue] = useState(false);
  //is paid
  const [isPaid, setIsPaid] = useState(false);

  //handle selected flight
  const handleSelectedFlight = (value, inputName) => {
    setSelectedFlight(value);
  };

  //handle checking
  const handleCheck = (e, value) => {
    setCheck(e.target.checked);
    if (e.target.checked) {
      handleSelectedPackages(value);
    } else {
      handleDeleteSelectedPackages(value);
    }
  };

  //check all controller
  useEffect(() => {
    setCheck(setCheckedAll);
    if (setCheckedAll) {
      handleSelectedPackages(data.id);
    } else {
      handleDeleteSelectedPackages(data.id);
    }
  }, [setCheckedAll]);

  //const handle send package
  const handleSend = async () => {
    try {
      const res = await axiosAdmin.post(`/Pack/send_details_post/${data.id}`, {
        flight_num: selectedFlight,
      });
      if (res) {
        successToast(t("success"));
        setDifResponses(res);
      }
    } catch (err) {
      errorToast(t("error"));
    }
  };

  //flights and package data
  useEffect(() => {
    if (flights.length === 0) {
      axiosAdmin
        .get(`/Pack/send_details/${data.id}`)
        .then(({ data }) => {
          setFlights(data.flights);
          setPackData(data.items);
        })
        .catch((err) => {});
    }
  }, []);

  //if package is paid
  useEffect(() => {
    if (data.transport_paid !== null) {
      setIsPaid(true);
    }
  }, [data]);

  return (
    <div className="bg-white p-4 rounded-lg dark:bg-slate-700 relative">
      {(user.role === 100 || user.role === 1) && (
        <div className="absolute top-5 right-5">
          <input
            onChange={(e) => {
              handleCheck(e, data.id);
            }}
            checked={check}
            type="checkbox"
            className="w-4 h-4"
          />
        </div>
      )}
      <div
        className="w-full flex flex-wrap justify-between pb-2
            gap-y-2 border-b border-gray-200 mb-2"
      >
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">
              {t("user_information")}
            </p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillPersonFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.user.user_type === 1 && (
                <p className="text-slate-500 dark:text-gray-400 text-[12px] font-bold">
                  {`${data.user.fname} ${data.user.lname}`} <br />
                  {`${data.user.fname_eng} ${data.user.lname_eng}`}
                </p>
              )}
              {data.user.user_type === 2 && (
                <p className="text-slate-500 dark:text-gray-400 text-[12px] font-bold">
                  {`${data.user.ceo_fname} ${data.user.ceo_lname}`} <br />
                  {`${data.user.ceo_fname_eng} ${data.user.ceo_lname_eng}`}
                </p>
              )}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaBarcode className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">{`id: ${data.user.id}`}</p>
            </div>
            {user.role !== 3 &&
              user.role !== 2 &&
              user.role !== 7 &&
              user.role !== 9 &&
              user.role !== 10 && (
                <>
                  <div className="flex items-center gap-1 my-1">
                    <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                      <BsFillEnvelopeFill className="w-full h-full text-slate-500 dark:text-gray-400" />
                    </div>
                    <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                      {data.user.email}
                    </p>
                  </div>
                  <div className="flex items-center gap-1 my-1">
                    <div className="w-8 h-8 border border-slate-500 dark:border-gray-400  rounded-lg p-2">
                      <BsTelephoneFill className="w-full h-full text-slate-500 dark:text-gray-400" />
                    </div>
                    <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                      {data.user.phone}
                    </p>
                  </div>
                </>
              )}
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillBuildingFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.abroad_office !== null && (
                <p
                  className={`text-[14px] cursor-pointer font-bold
                                ${
                                  data.abroad_office.titleen ===
                                  "Vazha Pshavela branch N2"
                                    ? "text-yellow-500"
                                    : "text-purple-500"
                                }
                                `}
                >
                  {t("office")}: {data.abroad_office.titleen}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">
              {t("parcel_information")}
            </p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BiPackage className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {t("parcel_id")}: {data.tracking_number}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaMoneyBillAlt className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {t("price_sum")}:{" "}
                {data.total_price && data.total_price.toFixed(2)}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {t("weight")} real: {data.real_weight}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {t("weight")} volume: {data.volume_weight} {"("} w:
                {JSON.parse(data.dementions)?.x} h:
                {JSON.parse(data.dementions)?.y} l:
                {JSON.parse(data.dementions)?.z}
                {")"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {t("weight")} : {data.total_weight}{" "}
                {data.real_weight === 0 && data.volume_weight === 0
                  ? "გაურკვეველია"
                  : data.real_weight < data.volume_weight
                  ? "volume"
                  : "real"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillCalendarFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {t("date")}: {data.create_date}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">
              {t("parcel_information")}
            </p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.addon_transportation ? "text-green-500" : "text-red-500"
                } `}
              >
                {data.addon_security ? t("secured") : t("unsecured")}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaTruckMoving className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.transport_address ? "text-green-500" : "text-red-500"
                } `}
              >
                {t("transportation")}:{" "}
                {data.transport_address ? data.transport_address : "X"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.invoice !== "" ? (
                <Link
                  to={`${process.env.REACT_APP_API_URL}/storage/uploads/pack_files/${data.invoice}`}
                  target={"_blank"}
                >
                  <p
                    className={`text-[14px] text-violet-500 underline cursor-pointer`}
                  >
                    {t("invoice")}
                  </p>
                </Link>
              ) : (
                <p
                  className={`text-[14px] text-red-500 underline cursor-pointer`}
                >
                  {t("invoice")}
                </p>
              )}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillHandbagFill className="w-full h-full dark:text-gray-400 text-slate-500" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {t("bag")}: {data.bag}
              </p>
            </div>
          </div>
        </div>
        {user.role !== 3 &&
          user.role !== 2 &&
          user.role !== 7 &&
          user.role !== 9 &&
          user.role !== 10 && (
            <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
              <SelectInput
                name={"flight"}
                handleCarData={handleSelectedFlight}
                options={flightOptions}
                value={data.flight_number}
              />
            </div>
          )}
      </div>
      <div className="flex gap-3 w-full flex-col">
        <div className="flex flex-col gap-y-1">
          <p className="text-[#989ba5] text-[12px]">{t("comment")}:</p>
          <p className="text-gray-400 text-[14px]">{data.comment}</p>
        </div>
        <div className="grid grid-cols-3 gap-3 md:grid-cols-2 sm:grid-cols-1">
          {(user.role === 100 || user.role === 1) && (
            <div
              onClick={handleSend}
              className="rounded-lg bg-green-400 flex items-center justify-center gap-2 px-4 h-10
                    w-full cursor-pointer"
            >
              <BiPaperPlane className="text-white" />
              <p className="text-white text-sm">{t("send")}</p>
            </div>
          )}
          <div className="relative">
            <div
              onClick={() => {
                setToggler(true);
              }}
              className="w-full rounded-lg bg-yellow-400 flex items-center justify-center gap-2 px-4 h-10
                    sm:w-full cursor-pointer"
            >
              <BsBoxArrowInRight className="text-white" />
              <p className="text-white text-sm">{t("details")}</p>
            </div>
            {toggler && (
              <div
                className="w-[300px] h-52 absolute top-[105%] left-0 p-2 rounded-lg sm:w-full border border-slate-500 bg-white
                            pt-10 overflow-auto scrollbar  dark:bg-slate-700 z-50"
              >
                <div
                  onClick={() => {
                    setToggler(false);
                  }}
                  className="w-6 h-6 rounded-full bg-red-400 flex items-center justify-center cursor-pointer
                                    absolute top-2 right-2"
                >
                  <p className="text-white font-bold">X</p>
                </div>
                {packData.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="border border-slate-400 rounded-lg p-3 flex justify-between items-center"
                    >
                      <p className="text-sm text-gray-400">{`(${item.code}) ${item.title}`}</p>
                      <p className="text-sm text-gray-400">{`${item.price} ${item.currency}`}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {(user.role === 100 ||
            ([1, 2, 3, 7, 9, 10].includes(user.role) && !isPaid)) && (
            <div
              onClick={() => {
                setShowContinue(true);
              }}
              className="w-full h-10 rounded-lg bg-red-400 flex items-center gap-2 justify-center cursor-pointer"
            >
              <BsTrash className="text-white" />
              <p className="text-white text-sm">{t("delete")}</p>
            </div>
          )}
        </div>
      </div>

      <UpdateParcel
        dataUpdater={setDifResponses}
        tracking={data.tracking_number}
      />
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeletePackage}
        id={data.id}
        setShowContinue={setShowContinue}
      />
    </div>
  );
};
export default WarehousePackageItem;

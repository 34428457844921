
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import citiesData from "./citiesData";

const Cities = ({
  inputName,
  inputSetter = null,
  value,
  clearFields = "",
  setClearFields,
}) => {
  //translations
  const { t } = useTranslation();
  //toggle variable
  const [isOpen, setIsOpen] = useState(false);
  //current selected value
  const [selectValue, setSelectValue] = useState("");

  //select handler
  const handleSelect = (title) => {
    setSelectValue(title);
    if (inputSetter !== null) {
      inputSetter(title, inputName);
    }
  };

  //toggle handler
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (value !== undefined) {
      if (inputSetter !== null) {
        inputSetter(value, inputName);
      }
      citiesData.forEach((city) => {
        if (city.name_en === value) {
          setSelectValue(city.name_en);
        }
      });
    }
  }, [value]);

  //clear field if necessity
  useEffect(() => {
    if (clearFields !== "" && clearFields) {
      setSelectValue("");
      setClearFields(false);
    }
  }, [clearFields]);

  return (
    <div
      className={`w-[100%] bg-white_A700 px-4 py-2 rounded-xl border  border-solid shadow-bs border-bluegray_100
      dark:bg-[#171719]`}
    >
      <div className="flex items-center gap-4">
        <div
          onClick={() => {
            handleOpen();
          }}
          className="w-full p-2 rounded-lg focus:outline-slate-300
          dark:bg-[#171719] dark:text-gray-300 relative 
               dark:border-slate-600 dark:focus:outline-slate-600"
        >
          <div className="h-[22px] flex justify-between items-center">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-gray-500 dark:text-[#c7c7c7]">
                {selectValue === "" ? t("choose_city") : selectValue}
              </p>
            </div>
            {isOpen ? (
              <BiChevronUp className="text-sm text-gray-500 dark:text-[#c7c7c7]" />
            ) : (
              <BiChevronDown className="text-sm text-gray-500 dark:text-[#c7c7c7]" />
            )}
          </div>
          {isOpen && (
            <div
              className="w-full max-h-80  overflow-y-auto scrollbar bg-white rounded-lg
                      absolute bottom-0 left-0 translate-y-[105%] border border-slate-300 z-40
                      dark:bg-[#171719]  dark:border-slate-600"
            >
              {citiesData.map((city, index) => {
                return (
                  <div
                    onClick={() => {
                      handleSelect(city.name_en);
                    }}
                    key={index}
                    className="p-3 w-full hover:bg-slate-300 group cursor-pointer"
                  >
                    <p
                      className="text-sm text-gray-500 font-bold dark:text-gray-300
                      group-hover:text-slate-800"
                    >
                      {city.name_en}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div >
  )
}

export default Cities


import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../../api/apiAdmin";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import ContinueComponent from "../../../../../components/Alerts/ContinueComponent";

//icons
import {
  BsBoxArrowInRight,
  BsTrash,
  BsFillPersonFill,
  BsFillEnvelopeFill,
  BsTelephoneFill,
  BsFillCalendarFill,
  BsFillHandbagFill,
  BsFillEyeFill,
  BsFillBuildingFill,
  BsBookshelf,
  BsArrowReturnLeft,
} from "react-icons/bs";
import { BiPackage, BiSave } from "react-icons/bi";
import {
  FaBarcode,
  FaMoneyBillAlt,
  FaPlaneDeparture,
  FaScroll,
  FaTruckMoving,
} from "react-icons/fa";
import { GiWeight } from "react-icons/gi";
import UpdateParcel from "../UpdateParcel";

const AppeardPackagesItem = ({
  data,
  handleDeletePackage,
  setCheckedAll,
  handleSelectedPackages,
  handleDeleteSelectedPackages,
  setDifResponses,
  setSelectedPackages,
  handleMoveBack,
}) => {
  //package check
  const [check, setCheck] = useState(false);
  //user
  const { user } = useContext(AdminPrimaryContext);
  //show continue
  const [showContinue, setShowContinue] = useState(false);
  //show continue on package status change
  const [showContinue2, setShowContinue2] = useState(false);
  //turn into custom clarence
  const [showContinue3, setShowContinue3] = useState(false);
  //place in warehouse
  const [shelf, setShelf] = useState({
    x: "",
    y: "",
  });
  //is paid
  const [isPaid, setIsPaid] = useState(false);

  //handle checking
  const handleCheck = (e, value) => {
    setCheck(e.target.checked);
    if (e.target.checked) {
      handleSelectedPackages(value);
    } else {
      handleDeleteSelectedPackages(value);
    }
  };

  //handle shelf value
  const handleShelf = (e, inputName) => {
    setShelf((prev) => ({ ...prev, [inputName]: e.target.value }));
  };

  //const handle handleWithdrawal package
  const handleWithdrawal = async () => {
    try {
      const res = await axiosAdmin.get(`/Pack/set_out/${data.id}`);
      if (res) {
        successToast("ამანათი წარმატებით გაიტანე!");
        setSelectedPackages({
          pack_id: [],
        });
        setDifResponses(res);
      }
    } catch (err) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //change self values
  const handleChangeShelf = async () => {
    try {
      const res = await axiosAdmin.get(
        `/Pack/changePlace/${data.id}/${shelf.x}/${shelf.y}`
      );
    } catch (err) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //make parcel custom clarence
  const handleTurnCustomClarence = async () => {
    try {
      const res = await axiosAdmin.get(`/Pack/notDeclared/${data.id}`);
      if (res) {
        successToast("მოქედება განხორციელდა!");
        setDifResponses(res);
      }
    } catch (err) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //place parcel in yellow corridor
  const handleSendInYellowCorridor = (id) => {
    axiosAdmin
      .get(`/Pack/yvitelia/${id}`)
      .then((res) => {
        successToast("მოქედება განხორციელდა!");
        setDifResponses(res);
      })
      .catch(() => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  //check all controller
  useEffect(() => {
    setCheck(setCheckedAll);
    if (setCheckedAll) {
      handleSelectedPackages(data.id);
    } else {
      handleDeleteSelectedPackages(data.id);
      /////////////////////////////////////////////???????
      setCheck(false);
      /////////////////////////////////////////////???????
    }
  }, [setCheckedAll]);

  //set initil x and y for shelf
  useEffect(() => {
    setShelf({
      x: data.x,
      y: data.y,
    });
  }, []);

  //save shelf change automatically
  useEffect(() => {
    if (
      (shelf.x !== data.x || shelf.y !== data.y) &&
      shelf.x &&
      shelf.y &&
      shelf.x !== "" &&
      shelf.y !== ""
    ) {
      handleChangeShelf();
    }
  }, [shelf]);

  //if package is paid
  useEffect(() => {
    if (data.transport_paid !== null) {
      setIsPaid(true);
    }
  }, [data]);

  return (
    <div
      className={` p-4 rounded-lg relative
            ${data.gansabaj === 1 ? "bg-red-100" : "bg-white dark:bg-slate-700"}
        `}
    >
      <div className="absolute top-5 right-5">
        {(user.role === 100 || user.role === 1 || user.role === 5) && (
          <input
            onChange={(e) => {
              handleCheck(e, data.id);
            }}
            checked={check}
            type="checkbox"
            className="w-4 h-4"
          />
        )}
        {(user.role === 100 ||
          user.role === 1 ||
          user.role === 5 ||
          user.role === 4 ||
          user.role === 6 ||
          user.role === 8) &&
          data.show_user !== 1 && (
            <BsFillEyeFill className={"text-red-500 w-4 h-4"} />
          )}
      </div>

      <div
        className="w-full flex flex-wrap justify-between pb-2
            gap-y-2 border-b border-gray-200 mb-2"
      >
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">
              მომხმარებელის ინფორმაცია
            </p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillPersonFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.user !== null && data.user.user_type === 1 && (
                <p className="text-slate-500 dark:text-gray-400 text-[12px] font-bold">
                  {`${data.user.fname} ${data.user.lname}`} <br />
                  {`${data.user.fname_eng} ${data.user.lname_eng}`}
                </p>
              )}
              {data.user !== null && data.user.user_type === 2 && (
                <p className="text-slate-500 dark:text-gray-400 text-[12px] font-bold">
                  {`${data.user.ceo_fname} ${data.user.ceo_lname}`} <br />
                  {`${data.user.ceo_fname_eng} ${data.user.ceo_lname_eng}`}
                </p>
              )}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaBarcode className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">{`id: ${
                data.user !== null && data.user.id
              }`}</p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillEnvelopeFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {data.user !== null && data.user.email}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsTelephoneFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {data.user !== null && data.user.phone}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillBuildingFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.abroad_office !== null && (
                <p
                  className={`text-[14px] cursor-pointer font-bold
                                ${
                                  data.abroad_office.titleen ===
                                  "Vazha Pshavela branch N2"
                                    ? "text-yellow-500"
                                    : "text-purple-500"
                                }
                                `}
                >
                  ოფისი: {data.abroad_office.titleen}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">გზავნილის ინფორმაცია</p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BiPackage className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                გზავნილის ID: {data.tracking_number}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaMoneyBillAlt className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                დეკლ. თანხის ჯამი{" "}
                {data.total_price && data.total_price.toFixed(2)}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                წონა real: {data.real_weight}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                წონა volume: {data.volume_weight} {"("} w:
                {JSON.parse(data.dementions)?.x} h:
                {JSON.parse(data.dementions)?.y} l:
                {JSON.parse(data.dementions)?.z}
                {")"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                წონა : {data.total_weight}{" "}
                {data.real_weight === 0 && data.volume_weight === 0
                  ? "გაურკვეველია"
                  : data.real_weight < data.volume_weight
                  ? "volume"
                  : "real"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillCalendarFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                თარიღი: {data.create_date}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">გზავნილის ინფორმაცია</p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaPlaneDeparture className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                რეისი: {data.flight_number}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.addon_transportation ? "text-green-500" : "text-red-500"
                } `}
              >
                {data.addon_transportation ? "დაზღვეულია" : "არაა დაზღვეული"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaTruckMoving className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.transport_address ? "text-green-500" : "text-red-500"
                } `}
              >
                ტრანსპორტირება:{" "}
                {data.transport_address ? data.transport_address : "X"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.invoice !== "" ? (
                <Link
                  to={`${process.env.REACT_APP_API_URL}/storage/uploads/pack_files/${data.invoice}`}
                  target={"_blank"}
                >
                  <p
                    className={`text-[14px] text-violet-500 underline cursor-pointer`}
                  >
                    ინვოისი
                  </p>
                </Link>
              ) : (
                <p
                  className={`text-[14px] text-red-500 underline cursor-pointer`}
                >
                  ინვოისი
                </p>
              )}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillHandbagFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                ჩანთა: {data.bag}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsBookshelf className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                თარო: {data.x !== null && `${data.x} ${data.y}`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between flex-col gap-3">
        <div className="flex flex-col gap-y-1">
          <p className="text-[#989ba5] text-[12px]">კომენტარი:</p>
          <p className="text-slate-500 dark:text-gray-400 text-[14px]">
            {data.comment}
          </p>
        </div>
        <div className="grid grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-1">
          <div
            className="bg-white dark:bg-slate-900 p-1 z-40 gap-2 shadow-sm border-gray-300 border rounded-lg
                   w-full grid grid-cols-3"
          >
            <input
              type="text"
              className="h-8 w-full rounded-lg shadow-sm border-gray-300 border dark:bg-slate-700 
                text-gray-400 pl-2 "
              placeholder="x"
              onChange={(e) => {
                handleShelf(e, "x");
              }}
              value={shelf.x}
            />
            <input
              type="text"
              className="h-8 w-full rounded-lg shadow-sm border-gray-300 border dark:bg-slate-700 
                text-gray-400 pl-2"
              placeholder="y"
              onChange={(e) => {
                handleShelf(e, "y");
              }}
              value={shelf.y}
            />
            <div
              className="rounded-lg bg-green-400 flex items-center justify-center p-2 h-8 w-full cursor-pointer"
              onClick={handleChangeShelf}
            >
              <BiSave className="text-white font-bold w-full h-full" />
            </div>
          </div>

          {user.role !== 6 &&
            (user.role !== 4 && user.role !== 5 && data.gansabaj === 0 ? (
              <div
                onClick={() => {
                  setShowContinue3(true);
                }}
                className="rounded-lg bg-red-400 flex items-center justify-center gap-2 px-4 h-10 w-full cursor-pointer"
              >
                <p className="text-white font-bold">
                  გადაიტანე განსაბაჟებლებში
                </p>
              </div>
            ) : data.gansabaj === 2 ? (
              <div className="w-full justify-center items-center flex">
                <p className="text-green-400 font-bold">განბაჟებულია</p>
              </div>
            ) : data.gansabaj !== 0 ? (
              <div className="w-full justify-center items-center flex">
                <p className="text-red-400 font-bold">განსაბაჟებელია</p>
              </div>
            ) : (
              <></>
            ))}

          {user.role !== 6 && (
            <>
              {data.yviteli === 0 && (
                <div
                  onClick={() => {
                    handleSendInYellowCorridor(data.id);
                  }}
                  className="rounded-lg bg-yellow-400 flex items-center justify-center gap-2 px-4 h-10 w-full cursor-pointer"
                >
                  <p className="text-white font-bold">ყვითელი კორიდორი</p>
                </div>
              )}
              {data.yviteli === 1 && (
                <div className="rounded-lg border border-slate-300 flex items-center justify-center gap-2 px-4 h-10 w-full">
                  <p className="font-bold text-green-500">ყვითელ დერეფანშია</p>
                </div>
              )}
              {data.yviteli === 2 && (
                <div className="rounded-lg border border-slate-300 flex items-center justify-center gap-2 px-4 h-10 w-full">
                  <p className="font-bold text-green-500">
                    გამწვანდა არაფერი არაა საჭირო
                  </p>
                </div>
              )}
              {data.yviteli === 3 && (
                <div className="rounded-lg border border-slate-300 flex items-center justify-center gap-2 px-4 h-10 w-full">
                  <p className="font-bold text-green-500">
                    ყვითელიდან გადავიდა განსაბაჟებელში
                  </p>
                </div>
              )}
            </>
          )}

          {(user.role === 1 || user.role === 100 || user.role === 5) && (
            <div
              onClick={() => setShowContinue2(true)}
              className="rounded-lg bg-orange-400 flex items-center justify-center gap-2 px-4 h-10
                    w-full cursor-pointer"
            >
              <BsBoxArrowInRight className="text-white font-bold" />
              <p className="text-white text-sm">გატანა</p>
            </div>
          )}
          {(user.role === 100 || (user.role === 1 && !isPaid)) && (
            <div
              onClick={() => {
                setShowContinue(true);
              }}
              className="w-full h-10 rounded-lg bg-red-400 flex items-center justify-center cursor-pointer"
            >
              <BsTrash className="text-white font-bold" />
              <p className="text-white text-sm">წაიშალა</p>
            </div>
          )}
          {(user.role === 100 || user.role === 1) && (
            <div
              onClick={() => {
                handleMoveBack(data.id);
              }}
              className="h-10 rounded-lg bg-green-400 flex items-center w-full justify-center cursor-pointer gap-2 px-4"
            >
              <BsArrowReturnLeft className="text-white font-bold" />
              <p className="text-white text-sm">უკან</p>
            </div>
          )}
        </div>
      </div>
      <UpdateParcel
        dataUpdater={setDifResponses}
        tracking={data.tracking_number}
      />
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeletePackage}
        id={data.id}
        setShowContinue={setShowContinue}
      />
      <ContinueComponent
        showFlag={showContinue2}
        actionFunction={handleWithdrawal}
        setShowContinue={setShowContinue2}
      />
      <ContinueComponent
        showFlag={showContinue3}
        actionFunction={handleTurnCustomClarence}
        setShowContinue={setShowContinue3}
      />
    </div>
  );
};
export default AppeardPackagesItem;

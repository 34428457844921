import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import axiosClient from "../../api/api";
import CustomTextInut from "../../components/CustomInputs/CustomTextInut";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import CustomCheckbox from "../../components/CustomInputs/CustomCheckbox";
import { useTranslation } from "react-i18next";

//icons
import { BsPerson, BsLock, BsEye } from "react-icons/bs";

const MainAuth = ({ handleStep, setLgErrPopupFlag, handleFpToggle, setAlertMsg }) => {
  //translation
  const { t } = useTranslation();
  //navigation
  const navigate = useNavigate();
  //user
  const { setToken, user, isAuthorized, language, setUser, setIsAuthorized } = useContext(PrimmaryContext);
  //credentials variable
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  //login error
  const [loginErr, setLoginErr] = useState(null);

  //credential value handler during inputting data
  const handleCredentials = (value, inputName) => {
    setCredentials((prev) => ({ ...prev, [inputName]: value }));
  };

  //login api
  const handleLoginApi = async () => {
    if (user.user_type) {
      navigate("/panelroutes/panelpackageswarehouse");
    } else {
      try {
        const res = await axiosClient.post(`/Auth/login_post`, credentials);
        if (res) {
          setToken(res.data.token);
          setUser(res.data.user);
          setIsAuthorized(true);
          navigate("/panelroutes/panelpackageswarehouse");
        }
      } catch (error) {
        setLoginErr(error);
        setLgErrPopupFlag(true);
        setAlertMsg(t("email_or_password_is_incorrect"))
      }
    }
  };

  //logout api
  const handleLogoutApi = async () => {
    try {
      const res = await axiosClient.post(`/Auth/logout`);
    } catch (error) { }
  };

  //logout button functionality
  const handleLogout = () => {
    handleLogoutApi();
    localStorage.removeItem("EXPR_TOKEN");
    setToken({});
    setUser("");
    navigate("/");
  };


  return (
    <>
      <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
        {!isAuthorized &&
          <>
            <div className="flex flex-col items-start justify-start w-[100%]">
              <CustomTextInut
                inputSetter={handleCredentials}
                placeholder={"email"}
                inputName={"email"}
                PrefixIcon={BsPerson}
                type={"email"}
                autoComplete={"email"}
                noDark={true}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-[100%]">
              <CustomTextInut
                type={"password"}
                inputSetter={handleCredentials}
                placeholder={"password"}
                inputName={"password"}
                PrefixIcon={BsLock}
                SuffixIcon={BsEye}
                autoComplete={"current-password"}
                noDark={true}
              />
            </div>
            <div className="flex flex-row gap-[14px] items-center justify-between w-[100%]">
              <CustomCheckbox label={t("remember")} />
              <Text
                onClick={handleFpToggle}
                className="font-normal not-italic text-indigo_400 text-right w-full cursor-pointer"
                variant="body7"
              >
                {t("password_recovery")}
              </Text>
            </div>
          </>
        }
        <div className="flex flex-col gap-[24px] items-start justify-start w-[100%]">
          <Button
            className="common-pointer cursor-pointer font-semibold text-[17px] text-center text-white_A700 w-[100%]"
            onClick={() => {
              handleLoginApi();
            }}
            shape="RoundedBorder11"
            size="5xl"
            variant="OutlineBlack9000a"
          >
            {isAuthorized ?
              (<>
                {user.user_type === 1 && (language === "ge" ? `${user.fname} ${user.lname}` : `${user.fname_eng} ${user.lname_eng}`)}
                {user.user_type === 2 && (language === "ge" ? `${user.ceo_fname} ${user.ceo_lname}` : `${user.ceo_fname_eng} ${user.ceo_lname_eng}`)}
              </>) : t("login")
            }
          </Button>
          {!isAuthorized ?
            (<Button
              className="common-pointer cursor-pointer font-semibold text-[17px] text-center text-teal_A700 w-[100%]"
              onClick={() => {
                handleStep("stepOne");
              }}
              shape="RoundedBorder11"
              size="5xl"
              variant="FillTealA70014"
            >
              {t("register")}
            </Button>) :
            (
              <div className="common-pointer cursor-pointer w-[100%] rounded-radius11 p-[18px] bg-[#c5000026]"
                onClick={() => {
                  handleLogout();
                }}
              >
                <p className="text-[17px] text-center text-[#c50001]">
                  {t("log_out")}
                </p>
              </div>)
          }
        </div>
      </div>
    </>
  );
};

export default MainAuth;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  MultyLangualTextInput,
  SelectInputValueId,
  TextInput,
} from "../../inputComponents/inputcomp";

//images
import logo from "../../../img/phoneLogo.png";
import TextEditor from "../../inputComponents/TextEditor";

const InnerFaqPage = () => {
  //get current user id
  const { faqId } = useParams();

  //new faq data
  const [faqData, setFaqData] = useState({
    mother: 0,
    title: "",
    titleen: "",
    titleru: "",
    text: "",
    texten: "",
    textru: "",
  });
  //data Updater
  const [dataUpdater, setDataUpdater] = useState(null);

  //handle new faq
  const handleNewFaqData = (value, inputName) => {
    setFaqData((prev) => ({ ...prev, [inputName]: value }));
  };

  //add new faq
  const handleEditFaq = async () => {
    try {
      const res = await axiosAdmin.post(`/Faq/edit_post/${faqId}`, faqData);
      if (res) {
        successToast("მონაცემები წარმატებით განახლდა!");
        setDataUpdater(res);
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //faq data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Faq/edit/${faqId}`).then(({ data }) => {
        setFaqData(data);
      });
    } catch (error) {}
  }, [dataUpdater]);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-10 h-10 ">
                <img src={logo} className="w-full h-full rounded-full"></img>
              </div>
              <div>
                <h2
                  className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                >
                  ხდკ-ს რედაქტირება
                </h2>
              </div>
            </div>
            <button
              onClick={handleEditFaq}
              className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
            >
              შენახვა
            </button>
          </div>
          <SelectInputValueId
            name={"კატეგორიები"}
            options={[
              {
                name: "გადახდები",
                value: 1,
              },
              {
                name: "ზოგადი",
                value: 2,
              },
              {
                name: "ინსტუქციები (ვიდეოები)",
                value: 3,
              },
            ]}
            inputName={"mother"}
            inputSetter={handleNewFaqData}
            value={faqData.mother}
          />
          <MultyLangualTextInput
            name={"ფილიალის სახელი"}
            geInputName={"title"}
            enInputName={"titleen"}
            ruInputName={"titleru"}
            inputSetter={handleNewFaqData}
            value={faqData.title}
            valueen={faqData.titleen}
            valueru={faqData.titleru}
          />
          <TextEditor
            name={"პასუხი"}
            geInputName={"text"}
            enInputName={"texten"}
            ruInputName={"textru"}
            getData={handleNewFaqData}
            geValue={faqData.text}
            enValue={faqData.texten}
            ruValue={faqData.textru}
          />
          <TextInput
            name={"youtube url"}
            inputName={"video"}
            inputSetter={handleNewFaqData}
            value={faqData.video}
          />
        </div>
      </div>
    </div>
  );
};

export default InnerFaqPage;

import { useState } from "react";
import CustomTextInut from "../../components/CustomInputs/CustomTextInut";
import axiosClient from "../../api/api";
import CustomAlert from "../../components/Alerts/CustomAlert";
import PanelHead from "../../components/PanelHead/PanelHead";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import { Text } from "../../components/Text";
import { Line } from "../../components/Line";
import { useTranslation } from "react-i18next";

//icons
import { AiOutlineEye, AiOutlineLock } from "react-icons/ai";
import { BsX } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const PanelsettingssecurityPage = () => {
  //navigate 
  const navigate = useNavigate();
  //translations 
  const { t } = useTranslation();
  //change passowrd datta
  const [newPass, setNewPass] = useState({
    old: "",
    password: "",
    passwordConf: "",
  });
  //reset popup error toggler
  const [logErrPopupFlag, setLgErrPopupFlag] = useState(false);
  //reset popup success toggler
  const [sucPopupFlag, setSucPopupFlag] = useState(false);

  //hanle data change
  const handleNewPassoword = (value, inputName) => {
    setNewPass((prev) => ({ ...prev, [inputName]: value }));
  };

  //password change api
  const handleChangePasswordApi = async () => {
    try {
      const res = await axiosClient.post(`/Auth/password_post`, newPass);
      if (res) {
        setSucPopupFlag(true);
        setTimeout(() => {
          navigate(-1)
        }, 2000)
      }
    } catch (error) {
      setLgErrPopupFlag(true);
    }
  };

  //password change api
  const handleChangePasswordButon = () => {
    if (newPass.password !== newPass.passwordConf) {
      setLgErrPopupFlag(true);
    } else {
      handleChangePasswordApi();
    }
  };

  return (
    <>
      {logErrPopupFlag && (
        <CustomAlert
          text={t("passwords_don_match")}
          setLgErrPopupFlag={setLgErrPopupFlag}
          alertType={"error"}
          Icon={BsX}
        />
      )}
      {sucPopupFlag && (
        <CustomAlert
          text={t("password_changed")}
          setLgErrPopupFlag={setSucPopupFlag}
          alertType={"success"}
          Icon={BsX}
        />
      )}
      <div
        className="min-h-screen flex flex-1 flex-col font-firago gap-[36px] items-start 
      justify-start max-w-[1500px] md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%]
      sm:pb-[80px] md:pb-[80px]"
      >
        <PanelHead name={t("security")} goBackButton={true} />
        <div className="hidden sm:flex md:flex items-center gap-3">
          <GoBackButton />
          <Text
            className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto] dark:text-[#c7c7c7]"
            variant="body1"
          >
            {t("security")}
          </Text>
        </div>
        <div className="font-notosansgeorgian relative w-[100%]">
          <div className="flex flex-col gap-[32px] inset-[0] items-start justify-start m-[auto] w-[100%] sm:gap-4">
            <div className="flex flex-col gap-[16px] items-start justify-center w-[100%]">
              <Text
                className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
                variant="body5"
              >
                {t("to_keep_your_password_safe_please_observe_the_following_Recommendations")}:
              </Text>
              <Text
                className="font-fw leading-[20.00px] text-black_900_b7 text-left"
                variant="body7"
              >
                <ul className="list-disc pl-5 dark:text-[#aeaeb2]">
                  <li>
                    {t("must_contain_at_least_characters")}
                  </li>
                  <li>
                    {t("it_is_recommended_to_include_at_least_one_number")}
                  </li>
                  <li>
                    {t("it_is_recommended_to_include_at_least_one_different_characte")}
                  </li>
                  <li>
                    {t("do_not_use_simple_and_common_passwords")}
                  </li>
                </ul>
              </Text>
            </div>
            <div className="flex flex-col gap-[32px] h-[100%] items-start justify-start w-full sm:gap-4">
              <Line className="bg-black_900_14 h-[1px] w-[100%] dark:bg-[#aeaeb2]" />
              <div className="flex flex-col items-start justify-start w-[48%] md:w-full sm:w-full">
                <div className="flex flex-col items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                  <div className="flex flex-col items-start justify-start w-[100%]">
                    <CustomTextInut
                      PrefixIcon={AiOutlineLock}
                      SuffixIcon={AiOutlineEye}
                      placeholder={"password"}
                      inputName={"old"}
                      inputSetter={handleNewPassoword}
                      type="password"
                      autoComplete={"current-password"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex md:flex-col sm:flex-col flex-row gap-[32px] items-start justify-start w-[100%] sm:gap-4">
                <div className="flex flex-1 flex-col items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                  <div className="flex flex-col items-start justify-start w-[100%]">
                    <CustomTextInut
                      PrefixIcon={AiOutlineLock}
                      SuffixIcon={AiOutlineEye}
                      placeholder={"new_password"}
                      inputName={"password"}
                      inputSetter={handleNewPassoword}
                      type="password"
                      autoComplete={"new-password"}
                    />
                  </div>
                </div>
                <div className="flex flex-1 flex-col items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                  <div className="flex flex-col items-start justify-start w-[100%]">
                    <CustomTextInut
                      PrefixIcon={AiOutlineLock}
                      SuffixIcon={AiOutlineEye}
                      placeholder={"conf_password"}
                      inputName={"passwordConf"}
                      inputSetter={handleNewPassoword}
                      type="password"
                      autoComplete={"new-password"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="cursor-pointer font-semibold text-[17px] text-center w-[328px]
              sm:w-full rounded-radius11 p-[14px] bg-indigo_400 outline outline-[1px]
               outline-black_900_0a shadow-bs text-white_A700"
              onClick={handleChangePasswordButon}
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div >
    </>
  );
};

export default PanelsettingssecurityPage;

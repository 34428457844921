import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import FullNavigation from "../navigations";
import LoginLayout from "../pages/account/login";
import UserPageLayout from "../pages/AdminsPage/userPageLayout";
import InnerUsersPage from "../pages/AdminsPage/innerUsersPage";
import UserStatistics from "../pages/AdminsPage/userStatistics";
import Flights from "../pages/flights/Flights";
import AbOfficesLayout from "../pages/AbOffices/AbOfficesLayoutLayout";
import InnerAbOfficesPage from "../pages/AbOffices/innerAbOfficesPage";
import FilialLayout from "../pages/Filials/FilialLayout";
import InnerFilialPage from "../pages/Filials/InnerFilialPage";
import Contact from "../pages/Contact/Contact";
import ContactFilialsLayout from "../pages/ContactFilials/ContactFilialsLayout";
import InnerContactFilialsPage from "../pages/ContactFilials/innerContactFilials";
import Logs from "../pages/logs/Logs";
import CategoryLayout from "../pages/category/CategoryLayout";
import InnerCategoryPage from "../pages/category/innerCategoryPage";
import UsLayout from "../pages/users/usLayout";
import FaqLayout from "../pages/faq/FaqLayout";
import InnerFaqPage from "../pages/faq/innerFaqPage";
import AboutUs from "../pages/ManageSite/AboutUs";
import Privilegies from "../pages/ManageSite/Privilegies";
import AdminHome from "../pages/home/Home";
import Services from "../pages/ManageSite/Services";
import ClearenceLayout from "../pages/Packages/Clearence/ClearenceLayout";
import InnerClearence from "../pages/Packages/Clearence/InnerClearence";
import CuriorLayout from "../pages/Packages/Curior/CuriorLayout";
import InnerCurior from "../pages/Packages/Curior/InnerCurior";
import WarehousePackage from "../pages/Packages/InWarehouse/WarehousePackage";
import SentPackage from "../pages/Packages/SentPackages/SentPackage";
import AppeardPackages from "../pages/Packages/AppeardPackages/AppeardPackages";
import InnerFlightsPage from "../pages/flights/InnerFlightsPage";
import InnerUsPage from "../pages/users/InnerUsPage";
import EditProfile from "../pages/profile/editProfile";
import PassedPackages from "../pages/Packages/PassedPackages/PassedPackages";
import { AdminPrimaryContext } from "../adminContext/AdminContext";
import ParcelsOffice from "../pages/parcelsOffice/ParcelsOffice";

import "react-toastify/dist/ReactToastify.css";
import YellowCorridorFlights from "../pages/Packages/YellowCorridor/YellowCorridorFlights";
import InnerYellowCorridor from "../pages/Packages/YellowCorridor/InnerYellowCorridor";

const AdminRouter = () => {
  //get current page url
  const location = useLocation();
  //correct navigations
  const navigate = useNavigate();

  //check if user is authorized
  const { isAuthorized, user } = useContext(AdminPrimaryContext);

  //correct navigations
  useEffect(() => {
    if (location.pathname === "/admin") {
      navigate("login");
    }
  }, []);

  return (
    <div>
      {isAuthorized && location.pathname !== "/admin/login" && (
        <FullNavigation />
      )}
      <Routes>
        <Route path="login" element={<LoginLayout />} />
        {isAuthorized && (
          <>
            {(user.role === 100 || user.role === 1) && (
              <>
                <Route path="admin-home" element={<AdminHome />} />
                <Route path="admin-page" element={<EditProfile />} />
                <Route path="users" element={<UserPageLayout />} />
                <Route path="users/:usersId" element={<InnerUsersPage />} />
                <Route
                  path="users/st/:userStatId"
                  element={<UserStatistics />}
                />

                <Route path="filial" element={<FilialLayout />} />
                <Route path="filial/:filialId" element={<InnerFilialPage />} />
              </>
            )}

            {(user.role === 100 || user.role === 1 || user.role === 8) && (
              <>
                <Route path="filials-pacels" element={<ParcelsOffice />} />
              </>
            )}

            {(user.role === 100 ||
              user.role === 1 ||
              user.role === 4 ||
              user.role === 5 ||
              user.role === 6 ||
              user.role === 8) && (
              <>
                <Route path="flights" element={<Flights />} />
                <Route
                  path="flights/:flightsId"
                  element={<InnerFlightsPage />}
                />

                <Route path="siteUsers" element={<UsLayout />} />
                <Route
                  path="siteUsers/:siteUsersId"
                  element={<InnerUsPage />}
                />

                <Route path="clearence" element={<ClearenceLayout />} />
                <Route
                  path="clearence/:clearenceId"
                  element={<InnerClearence />}
                />

                <Route path="curier" element={<CuriorLayout />} />
                <Route path="curier/:curierId" element={<InnerCurior />} />

                <Route
                  path="yellow-corridor"
                  element={<YellowCorridorFlights />}
                />
                <Route
                  path="yellow-corridor/:yellowId"
                  element={<InnerYellowCorridor />}
                />

                <Route path="sent" element={<SentPackage />} />
                <Route path="appeard" element={<AppeardPackages />} />
                <Route path="deliverd" element={<PassedPackages />} />
              </>
            )}

            {(user.role === 100 ||
              user.role === 1 ||
              user.role === 4 ||
              user.role === 3 ||
              user.role === 2 ||
              user.role === 5 ||
              user.role === 6 ||
              user.role === 7 ||
              user.role === 8 ||
              user.role === 9 ||
              user.role === 10) && (
              <Route path="in-warehouse" element={<WarehousePackage />} />
            )}

            {user.role === 100 && (
              <>
                <Route path="ab-office" element={<AbOfficesLayout />} />
                <Route
                  path="ab-office/:officeId"
                  element={<InnerAbOfficesPage />}
                />

                <Route path="faq" element={<FaqLayout />} />
                <Route path="faq/:faqId" element={<InnerFaqPage />} />

                <Route
                  path="contact-filial"
                  element={<ContactFilialsLayout />}
                />
                <Route
                  path="contact-filial/:contFilialId"
                  element={<InnerContactFilialsPage />}
                />

                <Route path="contact" element={<Contact />} />
                <Route path="logs" element={<Logs />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="privilegies" element={<Privilegies />} />
                <Route path="services" element={<Services />} />
                <Route path="category" element={<CategoryLayout />} />
                <Route
                  path="category/:categoryId"
                  element={<InnerCategoryPage />}
                />
              </>
            )}
          </>
        )}
      </Routes>
    </div>
  );
};

export default AdminRouter;

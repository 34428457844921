const LogsItem = ({
    itemData
}) => {
    return (
        <div className="flex bg-white rounded-lg p-2 flex-wrap gap-y-2 dark:bg-slate-700">
            <div className="w-[33%] sm:w-full">
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">ადმინი:</p>
                    <p className="text-gray-500 text-[14px]">{itemData.fname} {itemData.lname}</p>
                </div>

            </div>
            <div className="w-[33%] sm:w-full">
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">თარიღი:</p>
                    <p className="text-gray-500 text-[14px]">{itemData.date}</p>
                </div>
            </div>
            <div className="w-[33%] sm:w-full">
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">მოქმედება</p>
                    <p className="text-gray-500 text-[14px]">{itemData.action}</p>
                </div>
            </div>
            <div className="w-[100%]">

                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">აღწერა</p>
                    <p className="text-gray-500 text-[14px]">{itemData.description}</p>
                </div>
            </div>
        </div>
    )
}

export default LogsItem;

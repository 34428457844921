import { useState, useEffect, useContext } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import FlightsItem from "./FlightsItem";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  SelectInputValueId,
  DateInput,
  SelectInput,
} from "../../inputComponents/inputcomp";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import { BsX } from "react-icons/bs";

const Flights = () => {
  //add new user variable
  const [addNewToggler, setAddNewToggler] = useState(false);
  //get flights data from api
  const [flightsData, setFlightsData] = useState([]);
  //errors
  const [errors, setErrors] = useState(null);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //new flights data
  const [newflights, setNewFlights] = useState({
    office: "",
    date: "",
    number: "12",
  });
  //clear fields after submit
  const [makeEmpty, setMakeEmpty] = useState(false);
  //user
  const { user } = useContext(AdminPrimaryContext);
  //abroad offices
  const [abOffices, setAbOffices] = useState([]);
  //selected ab office
  const [abOfficesId, setAbOfficesId] = useState(null);

  const [filter, setFilter] = useState({
    date: null,
    status: null,
  });

  const handleSetFilter = (value, key) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const handleSelectAbOfficeId = (value, _) => {
    setAbOfficesId(value);
  };

  //handle filed clear after submission
  const handleMakeEmpty = (value) => {
    setMakeEmpty(value);
  };

  //handle new flights
  const handleNewFlightsData = (value, inputName) => {
    setNewFlights((prev) => ({ ...prev, [inputName]: value }));
  };

  //add new users handlers
  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  //add new flights
  const handleAddNewFlightsToDb = async () => {
    try {
      const res = await axiosAdmin.post(`/Flights/add_post`, newflights);
      setDifResponses(res);
      if (res) {
        successToast("მონაცემი წარმატებით დაემატა!");
        handleMakeEmpty(true);
      }
    } catch (error) {
      setErrors(error);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //get abroad offices
  const handleAbroadOffices = async () => {
    try {
      const res = await axiosAdmin.get("/Ab_office/index");
      if (res) {
        const tmp = res.data.map((item) => {
          return {
            value: item.id,
            name: item.title,
          };
        });

        setAbOffices(tmp);
      }
    } catch (error) {
      setErrors(error);
    }
  };

  //delete flights
  const handleDeleteFlights = async (id) => {
    try {
      const res = await axiosAdmin.get(`/Flights/delete/${id}`);
      setDifResponses(res);
      if (res) {
        successToast("მონაცემი წარმატებით წაიშალა!");
      }
    } catch (error) {
      setErrors(error);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //flights data from api
  useEffect(() => {
    try {
      axiosAdmin
        .get(
          `/Flights/index?country=${
            abOfficesId ? abOfficesId : ""
          }&orderBydate=${filter?.date ? filter?.date : ""}&flight_status=${
            filter?.status || filter?.status == 0 ? filter?.status : ""
          }`
        )
        .then(({ data }) => {
          setFlightsData(data);
        });
    } catch (error) {
      setErrors(error);
    }
  }, [difResponses, abOfficesId, filter]);

  useEffect(() => {
    handleAbroadOffices();
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="w-full flex flex-col gap-5 pb-10 sm:pb-5">
        {(user.role === 1 || user.role === 100) && (
          <div className="w-full gap-2 grid grid-cols-4 md:grid-cols-2 sm:grid-cols-2">
            <div className="flex items-end gap-2 shrink-0 w-full">
              <SelectInputValueId
                options={abOffices}
                inputName={"abOffice"}
                inputSetter={handleSelectAbOfficeId}
                name={"ქვეყანა"}
              />
              <div
                onClick={() => {
                  handleSelectAbOfficeId(null);
                }}
                className=" bg-red-500 h-10 w-10 shrink-0 rounded-lg"
              >
                <BsX className="cursor-pointer text-white w-full h-full p-2" />
              </div>
            </div>
            <div className="flex items-center gap-4 shrink-0 w-full">
              <SelectInputValueId
                options={[
                  { value: null, name: "სტანდარტულად" },

                  { value: "asc", name: "ზრდადობით" },
                  { value: "desc", name: "კლებადობით" },
                ]}
                inputName={"date"}
                inputSetter={handleSetFilter}
                name={"თარიღი"}
              />
            </div>
            <div className="flex items-center gap-4 shrink-0 w-full">
              <SelectInputValueId
                options={[
                  { value: null, name: "სტანდარტულად" },

                  { value: 0, name: "არაა ჩამოსული" },
                  { value: 1, name: "ჩამოსულია" },
                ]}
                inputName={"status"}
                inputSetter={handleSetFilter}
                name={"სტატუსი"}
              />
            </div>
            <button
              onClick={() => {
                handleAddNewToggle(true);
              }}
              className="w-full rounded-lg p-3 text-white text-sm bg-violet-400 mt-5"
            >
              ახალი რეისის დამატება
            </button>
          </div>
        )}
        {addNewToggler && (
          <div className="bg-slate-100 dark:bg-slate-900 ">
            <div className="sm:px-0">
              <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                <div className="flex items-center justify-between flex-wrap gap-5">
                  <div className="flex items-center gap-5 sm:gap-3 ">
                    <div>
                      <h2
                        className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]"
                      >
                        ახალი რეისის დამატება
                      </h2>
                    </div>
                  </div>
                  <div className="flex gap-2 sm:w-full">
                    <button
                      onClick={handleAddNewFlightsToDb}
                      className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
                    >
                      შენახვა
                    </button>
                    <button
                      onClick={() => {
                        handleAddNewToggle(false);
                      }}
                      className="rounded-lg p-3 text-white text-sm bg-red-400 w-10"
                    >
                      X
                    </button>
                  </div>
                </div>
                <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                  <div className="w-6/12 sm:w-full md:w-full">
                    <SelectInputValueId
                      name={"ქვეყანა"}
                      inputName={"office"}
                      inputSetter={handleNewFlightsData}
                      options={[
                        {
                          name: "თურქეთის მისამართი",
                          value: 1,
                        },
                        {
                          name: "ჩინეთის მისამართი",
                          value: 5,
                        },
                        {
                          name: "ამერიკის მისამართი",
                          value: 16,
                        },
                        {
                          name: "ჩინეთის სახმელეთო მისამართი",
                          value: 19,
                        },
                        {
                          name: "საბერძნეთის მისამართი",
                          value: 20,
                        },
                        {
                          name: "ბრიტანეთი",
                          value: 21,
                        },
                      ]}
                    />
                  </div>
                  <div className="w-6/12 sm:w-full md:w-full">
                    <DateInput
                      name={"თარიღი"}
                      inputSetter={handleNewFlightsData}
                      inputName={"date"}
                      makeEmpty={makeEmpty}
                      handleMakeEmpty={handleMakeEmpty}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-full flex flex-col gap-2">
        {flightsData.map((item) => {
          return (
            <FlightsItem
              key={item.id}
              id={item.id}
              number={item.number}
              date={item.date}
              handleDeleteFlights={handleDeleteFlights}
              sendDate={item.send_date}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Flights;

import chinaImg from "../../img/countryFlags/china.png";
import turkeyImg from "../../img/countryFlags/turkey.png";
import usaImg from "../../img/countryFlags/usa.png";
import greeceImg from "../../img/countryFlags/greece.png";
import ukImg from "../../img/countryFlags/uk.png";

const abOffices = [
  {
    id: 1,
    name: "turkey",
    img: turkeyImg,
  },
  {
    id: 5,
    name: "china",
    img: chinaImg,
  },
  {
    id: 16,
    name: "usa",
    img: usaImg,
  },
  {
    id: 19,
    name: "china_land",
    img: chinaImg,
  },
  {
    id: 20,
    name: "greece",
    img: greeceImg,
  },
  {
    id: 21,
    name: "england",
    img: ukImg,
  },
];

export default abOffices;

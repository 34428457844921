import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsFillCalendarDayFill } from "react-icons/bs";

const CustomDateInput = ({
  name,
  PrefixIcon = null,
  SuffixIcon = null,
  inputName,
  inputSetter = null,
  mFill,
  value,
  validateFuntion,
  validateQuote,
  noDark
}) => {
  //translations 
  const { t } = useTranslation();
  //current value variable
  const [currentValue, setCurrentValue] = useState("");
  //we save value of focus state i this variable
  const [isFocused, setIsFocused] = useState(false);
  //if filed is empty but required we must show
  const [mustFill, setMustFill] = useState("");
  //show validate quote
  const [showValidateQuote, setShowValidateQuote] = useState(false);
  //placeholder
  const [placeholdder, setPlcaholdder] = useState(name);

  //value change handler for input
  const handleValueChange = (e) => {
    setCurrentValue(e.target.value);
  };

  // when current value changes passed function
  // will change specific object based value
  useEffect(() => {
    if (inputSetter) {
      if (validateFuntion !== null) {
        let flag = validateFuntion(currentValue);
        if (flag) {
          setShowValidateQuote(false);
          setPlcaholdder(name);
        } else {
          setPlcaholdder(validateQuote);
          setShowValidateQuote(true);
        }
      }
      inputSetter(currentValue, inputName);
    }
  }, [currentValue]);

  //whenever filed is reuired and user does not
  //filled it
  useEffect(() => {
    if (currentValue !== "") {
      setMustFill(false);
    } else {
      setMustFill(mFill);
    }
  }, [mFill, currentValue]);

  //if default value passed it shoud be changed corectly
  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  return (
    <div
      className={`w-[100%] bg-white_A700 p-4 rounded-xl border border-solid shadow-bs
      ${noDark ? "" : "dark:bg-[#171719]"}
    ${isFocused
          ? "border-[#5856d6] outline outline-[#c9c9f6]"
          : mustFill
            ? "border-[#f61b17] outline outline-[#f7bebd]"
            : "border-bluegray_100"
        }`}
    >
      <div className="flex items-center gap-4">
        {PrefixIcon !== null && (
          <PrefixIcon className={`w-4 h-4 text-[#636366] 
          ${noDark ? "" : "dark:text-[#c7c7c7]"}`} />
        )}
        <div className="w-full relative">
          {currentValue !== "" && (
            <p className={`text-[12px] absolute top-[-12px] left-0  
            ${showValidateQuote ? "text-red-500" : "text-[#5856d6]"}`}>
              {t(`${placeholdder}`)}
            </p>
          )}
          <input
            max="2999-12-31"
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            value={currentValue}
            type="date"
            name={inputName}
            onChange={(e) => {
              handleValueChange(e);
            }}
            className={`w-full text-[#636366] relative z-20 bg-transparent
            ${noDark ? "" : "dark:text-[#c7c7c7]"}`}
          />
        </div>
        {SuffixIcon !== null && (
          <SuffixIcon className={`w-4 h-4 text-[#636366] ${noDark ? "" : "dark:text-[#c7c7c7]"}"`} />
        )}
      </div>
    </div>
  );
};

export default CustomDateInput;

import { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import PanelsettingsaddressesPage from "./pages/Panelsettingsaddresses/PanelsettingsaddressesPage";
import PanelsettingssecurityPage from "./pages/Panelsettingssecurity/PanelsettingssecurityPage";
import PanelpackageswarehousePage from "./pages/Panelpackageswarehouse/Panelpackageswarehouse";
import PanelsettingsprofilePage from "./pages/Panelsettingsprofile/PanelsettingsprofilePage";
import PanelsettingsnotifiPage from "./pages/Panelsettingsnotifi/PanelsettingsnotifiPage";
import PaneladdressesPage from "./pages/Paneladdressesturkey/PaneladdressesPage";
import PaneltransactionsPage from "./pages/Paneltransactions/Paneltransactions";
import PanelsettingsPage from "./pages/Panelsettings/PanelsettingsPage";
import PanelcontactPage from "./pages/Panelcontact/PanelcontactPage";
import UserPanelMenu from "./components/UserPanelMenu/UserPanelMenu";
import PanelflightsPage from "./pages/Panelflights/PanelflightsPage";
import PanelshopsPage from "./pages/Panelshops/PanelshopsPage";
import PanelfaqPage from "./pages/Panelfaq/PanelfaqPage";
import BottomMenu from "./components/Phone/BottomMenu";
import Head from "./components/Phone/Head";
import Messeges from "./pages/Messeges/Messeges";
import PanelSettingsFilials from "./pages/PanelSettingsFilials/PanelSettingsFilials";
import CustomsClearance from "./pages/CustomsClearance/CustomsClearance";
// import Panelsettingscards from "pages/Panelsettingscards";

const UserPanelRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate(null);

  useEffect(() => {
    if (location.pathname === "/panelroutes") {
      navigate("/panelroutes/panelpackageswarehouse");
    }
  }, [location]);

  return (
    <div
      className="bg-gray_100 dark:bg-[#0a0a0c] flex justify-center md:block sm:block flex-row 
    font-notosansgeorgian md:gap-[20px] sm:gap-[20px] mx-[auto] w-[100%] lg1:overflow-x-hidden"
    >
      <UserPanelMenu />
      <Head />
      <BottomMenu />
      <Routes>
        <>
          <Route
            path="/panelpackageswarehouse"
            element={<PanelpackageswarehousePage />}
          />
          <Route path="/panelflights" element={<PanelflightsPage />} />
          <Route path="/paneladdresses" element={<PaneladdressesPage />} />
          <Route path="/panelshops" element={<PanelshopsPage />} />
          <Route path="/panelfaq" element={<PanelfaqPage />} />
          <Route path="/panelcontact" element={<PanelcontactPage />} />
          <Route path="/messeges" element={<Messeges />} />
          <Route path="/courier-clearance" element={<CustomsClearance />} />
          <Route
            path="/paneltransactions"
            element={<PaneltransactionsPage />}
          />
          <Route path="/panelsettings" element={<PanelsettingsPage />} />
          <Route
            path="/panelsettingssecurity"
            element={<PanelsettingssecurityPage />}
          />
          <Route
            path="/panelsettingsaddresses"
            element={<PanelsettingsaddressesPage />}
          />
          <Route
            path="/panelsettingsnotifi"
            element={<PanelsettingsnotifiPage />}
          />
          <Route
            path="/panelsettingsfilials"
            element={<PanelSettingsFilials />}
          />
          <Route
            path="/panelsettingsprofile"
            element={<PanelsettingsprofilePage />}
          />
        </>
        {/* ბარათების გვერდი რომელიც დროებით ან სულ დამალული უნდა იყოს */}
        {/* <Route path="/panelsettingscards" element={<Panelsettingscards />} /> */}
      </Routes>
    </div>
  );
};
export default UserPanelRoutes;

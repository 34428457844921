import { useState, useEffect, useContext, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";
import axiosClient from "../../api/api";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import { Text } from "../../components/Text";
import RegistrationPanelPath from "../../components/RegistrationPanelPath/RegistrationPanelPath";
import CustomTextInut from "../../components/CustomInputs/CustomTextInut";
import CustomSelectInput from "../../components/CustomInputs/CustomSelectInput";
import CustomDateInput from "../../components/CustomInputs/CustomDateInput";
import { useTranslation } from "react-i18next";
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

//icons
import { FaRegUser, FaRegAddressCard } from "react-icons/fa";
import { BiChevronRight, BiChevronLeft, BiX } from "react-icons/bi";
import { CheckBox } from "../../adminPanel/components/inputComponents/inputcomp";

const RegistrationmodalstepTwoPage = ({ step, handleStep, regPersonType }) => {
  //translations 
  const { t } = useTranslation();

  //validate id number
  const handleIdValidation = (text) => {
    if (`${text}`.length === 9 || `${text}`.length === 11) {
      return true;
    } else {
      return false;
    }
  }

  //validate date
  const handleBirthDateValidation = (birthdate) => {
    let today = new Date();
    const birthdateObj = new Date(birthdate);
    let age = today.getFullYear() - birthdateObj.getFullYear();
    const monthDiff = today.getMonth() - birthdateObj.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdateObj.getDate())) {
      age--;
    }
    if (age >= 18) {
      return true
    } else {
      return false
    }
  }

  //input fields for physical person
  const [inputFieldsPhysical, setInputPhysicalFields] = useState([
    {
      placeholder: "name_ge",
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "text",
      inputName: "fname",
      mFill: false,
      pattern: "ge"
    },
    {
      placeholder: "surname_ge",
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "text",
      inputName: "lname",
      mFill: false,
      pattern: "ge"
    },
    {
      placeholder: "name_en",
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "text",
      inputName: "fname_eng",
      mFill: false,
      pattern: "en",
    },
    {
      placeholder: "surname_en",
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "text",
      inputName: "lname_eng",
      mFill: false,
      pattern: "en",
    },
    {
      name: t("birth_date"),
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "date",
      inputName: "birthday",
      mFill: false,
      validateFuntion: handleBirthDateValidation,
      validateQuote: t("underage"),
    },
    {
      placeholder: "id_num",
      prefixIcon: FaRegAddressCard,
      suffixIcon: null,
      input: "text",
      inputName: "id_num",
      mFill: false,
      validateFuntion: handleIdValidation,
      validateQuote: t("incorrect_id"),
      type: "text"
    },
    {
      name: t("gender"),
      options: [
        { id: 0, title: t("woman") },
        { id: 1, title: t("man") },
      ],
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "select",
      inputName: "gender",
      mFill: false,
    },
  ]);

  //input fields for entity person
  const [inputFieldsEntity, setInputEntityFields] = useState([
    {
      placeholder: "dir_name_ge",
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "text",
      inputName: "ceo_fname",
      mFill: false,
      pattern: "ge"
    },
    {
      placeholder: "dir_surname_ge",
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "text",
      inputName: "ceo_lname",
      mFill: false,
      pattern: "ge"
    },
    {
      placeholder: "dir_name_en",
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "text",
      inputName: "ceo_fname_eng",
      mFill: false,
      pattern: "en",
    },
    {
      placeholder: "dir_surname_en",
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "text",
      inputName: "ceo_lname_eng",
      mFill: false,
      pattern: "en",
    },
    {
      name: t("birth_date"),
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "date",
      inputName: "birthday",
      mFill: false,
      validateFuntion: handleBirthDateValidation,
      validateQuote: t("underage"),
    },
    {
      placeholder: "dir_id",
      prefixIcon: FaRegAddressCard,
      suffixIcon: null,
      input: "text",
      inputName: "ceo_id_num",
      mFill: false,
      validateFuntion: handleIdValidation,
      validateQuote: t("incorrect_id"),
      type: "text"
    },
    {
      name: t("gender"),
      options: [
        { id: 0, title: t("woman") },
        { id: 1, title: t("man") },
      ],
      prefixIcon: FaRegUser,
      suffixIcon: null,
      input: "select",
      inputName: "gender",
      mFill: false,
    },
  ]);
  //register credentials setter function
  const { handleRegCredentials, user } = useContext(PrimmaryContext);
  //register credentials
  const { registerCredetials } = useContext(PrimmaryContext);
  //page main fileds based on first step
  const [mainContent, setMainContent] = useState([]);
  //current step tracker
  const [currenStep, setCurrentStep] = useState("");
  const targetElement = useRef(null);

  const disableScroll = () => {
    disableBodyScroll(targetElement.current);
  };

  const enableScroll = () => {
    enableBodyScroll(targetElement.current);
  };

  //handle go to next page
  const handleGoNextStep = () => {
    var goNext = true;

    if (registerCredetials.user_type === 1) {
      const newData = inputFieldsPhysical.map((obj) => {
        if (registerCredetials[obj.inputName] === "") {
          goNext = false;
          return { ...obj, mFill: true };
        }
        return obj;
      });

      setInputPhysicalFields(newData);
    }

    if (registerCredetials.user_type === 2) {
      const newData = inputFieldsEntity.map((obj) => {
        if (registerCredetials[obj.inputName] === "") {
          goNext = false;
          return { ...obj, mFill: true };
        }
        return obj;
      });

      setInputEntityFields(newData);
    }

    if (!handleBirthDateValidation(registerCredetials.birthday)) {
      goNext = false;
    }

    if (registerCredetials.user_type === 1 && !handleIdValidation(registerCredetials.id_num)) {
      goNext = false;
    }

    if (registerCredetials.user_type === 2 && !handleIdValidation(registerCredetials.ceo_id_num)) {
      goNext = false;
    }

    if (registerCredetials.user_type === 1) {
      axiosClient.post(`Auth/chekId`, { id_num: registerCredetials.id_num })
        .then((res) => { })
        .catch((err) => {
          goNext = false;
          if (err.request.response === "exist") {
            errorToast(t("id_registerd"))
          }
        })
    }

    if (registerCredetials.user_type === 2) {
      axiosClient.post(`Auth/chekId`, { ceo_id_num: registerCredetials.ceo_id_num })
        .then((res) => { })
        .catch((err) => {
          goNext = false;
          if (err.request.response === "exist") {
            errorToast(t("id_registerd"))
          }
        })
    }

    setTimeout(function () {
      if (goNext) {
        handleStep("stepThree");
      }
    }, 2000);
  }

  //current step tracker effect
  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  //change content
  useEffect(() => {
    if (regPersonType === "physicalPerson") {
      setMainContent(inputFieldsPhysical);
    }
    if (regPersonType === "legalEntity") {
      setMainContent(inputFieldsEntity);
    }
  }, [regPersonType, inputFieldsEntity, inputFieldsPhysical]);

  useEffect(() => {
    if (currenStep === "stepTwo") {
      disableScroll();
    } else {
      enableScroll();
    }
  },)

  return (
    <>
      <div
        className={`bg-gray_100 flex font-firago outline outline-[1px] outline-black_900_1e popup_container
        w-[100%] fixed top-0 left-0 transition-all duration-300 delay-150 ease-linear z-50 sm:overflow-auto scrollbar
      ${currenStep === "stepTwo" ? "translate-x-[0]" : "translate-x-[100%]"}`}
      >
        <div className="flex flex-col items-start justify-start w-full">
          <header className="bg-white_A700 border-b-[1px] border-black_900_14 border-solid flex flex-row gap-[16px] items-center justify-center sm:px-[20px] px-[32px] py-[16px] w-[100%]">
            <Text
              className="flex-1 font-bold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              variant="body3"
            >
              {t("registration")}
            </Text>
            <div
              onClick={() => {
                handleStep("");
              }}
              className="bg-gray_600_14 flex flex-col h-[36px] items-center justify-center rounded-radius50 w-[36px]
            cursor-pointer"
            >
              <BiX className="text-[#FF3A30] text-[22px]" />
            </div>
          </header>
          <div
            className="flex flex-col font-notosansgeorgian gap-[40px] h-full items-start justify-start p-[32px] 
        sm:px-[20px] w-[100%] sm:gap-5"
          >
            <RegistrationPanelPath stepOnePassed={true} stepTwoActive={true} />
            <div
              className="flex flex-col gap-[24px] items-start justify-start max-w-[1122px] mx-[auto] 
          md:px-[20px] w-[100%] h-full"
            >
              <div className="w-full grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2">
                {mainContent.map((item, index) => {
                  if (item.input === "text") {
                    return (
                      <CustomTextInut
                        key={index}
                        PrefixIcon={item.prefixIcon}
                        SuffixIcon={item.suffixIcon}
                        placeholder={item.placeholder}
                        inputName={item.inputName}
                        inputSetter={handleRegCredentials}
                        mFill={item.mFill}
                        pattern={item.pattern}
                        validateFuntion={item.validateFuntion ? item.validateFuntion : null}
                        validateQuote={item.validateQuote ? item.validateQuote : null}
                        type={item.type ? item.type : "text"}
                        noDark={true}
                      />
                    )
                  }
                  if (item.input === "select") {
                    
                    return (
                      <CustomSelectInput
                        key={index}
                        name={item.name}
                        options={item.options}
                        PrefixIcon={item.prefixIcon}
                        SuffixIcon={item.suffixIcon}
                        inputName={item.inputName}
                        inputSetter={handleRegCredentials}
                        mFill={item.mFill}
                        saveWithNumber={item.saveIds}
                        noDark={true}
                      />
                    )
                  }
                  if (item.input === "date") {
                    return (
                      <CustomDateInput
                        key={index}
                        name={item.name}
                        PrefixIcon={item.prefixIcon}
                        SuffixIcon={item.suffixIcon}
                        inputName={item.inputName}
                        inputSetter={handleRegCredentials}
                        mFill={item.mFill}
                        validateFuntion={item.validateFuntion ? item.validateFuntion : null}
                        validateQuote={item.validateQuote ? item.validateQuote : null}
                        type={item.type ? item.type : "text"}
                        noDark={true}
                      />
                    )
                  }
                })}
              </div>
              <CheckBox
                name={t("foreigner")}
                inputName="is_foreign"
                handleCarData={handleRegCredentials}
              />
            </div>
          </div>
          <footer
            className="bg-white_A700 border-black_900_14 border-solid border-t-[1px] flex flex-row 
        font-notosansgeorgian gap-[16px] items-center justify-end sm:px-[20px] px-[32px] py-[16px] w-[100%]
        sm:justify-between"
          >
            <button
              onClick={() => {
                handleStep("stepOne");
              }}
              className="rounded-lg px-[16px] py-[10px] flex items-center gap-2 bg-gray_600_14"
            >
              <BiChevronLeft className="text-[22px] text-center text-[#636366]" />
              <div
                className="common-pointer bg-transparent cursor-pointer font-fw text-[17px] text-black_900_99 text-left
            sm:hidden"
              >
                {t("back")}
              </div>
            </button>
            <button
              onClick={() => {
                handleGoNextStep();
              }}
              className="rounded-lg px-[16px] py-[10px] flex items-center gap-2 
            bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
            >
              <div className="common-pointer bg-transparent cursor-pointer font-semibold text-[17px] text-left text-white_A700">
                {t("next")}
              </div>
              <BiChevronRight className="text-[22px] text-center" />
            </button>
          </footer>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default RegistrationmodalstepTwoPage;

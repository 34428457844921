import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Envelope, Key } from "react-bootstrap-icons";
import axiosAdmin from "../../../../api/apiAdmin";
import { errorToast } from "../../toastAlerts/ToastAlerts";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import { BsEye } from "react-icons/bs";

const LoginRihtSide = () => {
    const navigate = useNavigate();
    //primary context data and functions
    const { setToken, setUser, isAuthorized, setIsAuthorized, user } = useContext(AdminPrimaryContext);
    //password visible
    const [isVisible, setIsVisible] = useState("password");
    // user credentials
    const [loginCredentials, setloginCredentials] = useState({
        mail: "",
        pass: ""
    })
    const handleLoginCredentials = (e) => {
        setloginCredentials(
            prev => ({ ...prev, [e.target.name]: e.target.value })
        )
    }

    //if user is authorized navigate to correct page
    useEffect(() => {
        if (isAuthorized) {
            if (user.role === 1 || user.role === 100) {
                navigate("../admin-home");
            } else if (user.role === 2 || user.role === 3) {
                navigate("../in-warehouse");
            } else if (user.role === 4 || user.role === 5 || user.role === 6) {
                navigate("../flights");
            }
        }
    }, [isAuthorized])

    const handleLogin = () => {
        axiosAdmin.post('/Login/post', loginCredentials)
            .then(({ data }) => {
                setIsAuthorized(true);
                setUser(data.admin);
                setToken(data.token);
                navigate("../admin-home");
            })
            .catch((err) => {
                errorToast("პაროლი ან ელ-ფოსტა არასწორია!");
            })
    }

    //trigger login on enter click
    function handlePressEnter(event) {
        if (event.key === "Enter") {
            handleLogin();
        }
    }

    return (
        <>
            <div className="w-5/12 max-w-[800px] bg-white  gap-[30px] p-16 max-lg:w-8/12 md:w-10/12
            sm:w-full sm:p-5 md:h-full">
                <div className="mt-4 flex flex-col items-center justify-center">
                    <h1 className="text-2xl font-semibold text-slate-600">კეთილი იყოს შენი დაბრუნება</h1>
                    <p className="text-sm text-slate-400 dark:text-navy-300">გთხოვთ გაიარეთ ავტორიზაცია გასაგრძელებლად</p>
                </div>
                <div className="flex flex-col gap-5 mt-6">
                    <div className="pv-2 h-10 flex items-center bg-slate-100 gap-x-1  px-3.5 py-1 rounded-md  group">
                        <Envelope className="text-gray-500 dark:text-white text-[18px]" />
                        <input
                            name="mail"
                            onChange={handleLoginCredentials}
                            onKeyDown={handlePressEnter}
                            className="w-full pl-1.5 focus:outline-none bg-slate-100 border-0"
                            type="email"
                            placeholder="ელ-ფოსტა" />
                    </div>
                    <div className="pv-2 h-10 flex items-center bg-slate-100 group gap-x-1  px-3.5 py-1 rounded-md ">
                        <Key className="text-gray-500 text-[18px]" />
                        <input
                            name="pass"
                            onChange={handleLoginCredentials}
                            onKeyDown={handlePressEnter}
                            className="w-full pl-1.5 focus:outline-none bg-slate-100 border-0"
                            type={isVisible}
                            placeholder="პაროლი" />
                        <BsEye className="cursor-pointer" onClick={() => {
                            if (isVisible === "password") {
                                setIsVisible("text")
                            } else {
                                setIsVisible("password")
                            }
                        }} />
                    </div>
                    <div className="mt-6">
                        <button
                            onClick={handleLogin}
                            className="w-full py-2  rounded-md bg-[#443cd3] hover:bg-[#4139c9]
                    text-white text-md text-center ">
                            შესვლა
                        </button>

                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    )
}

export default LoginRihtSide;

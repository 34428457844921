import { useState, useEffect } from "react";
import ContactFilialsCard from "./ContactFilialsCard";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput, MultyLangualTextInput } from "../../inputComponents/inputcomp";

const ContactFilialsLayout = () => {
    //add new user variable
    const [addNewToggler, setAddNewToggler] = useState(false);

    //get filial data from api
    const [filialData, setFilialData] = useState([]);
    //get filial data error from api
    const [filialDataErr, setFilialDataErr] = useState(null);
    //add new filial error from api
    const [filialAddErr, setFilialAddErr] = useState(null);
    //add new filial error from api
    const [filialDelErr, setFilialDelErr] = useState(null);
    //affect data change depended on delete and add responses
    const [difResponses, setDifResponses] = useState(null);
    //new filial data
    const [newFilial, setNewFilial] = useState({
        coords: "",
        hours1: "",
        hours2: "",
        mail: "",
        phone: "",
        title: "",
        titleen: "",
        titleru: "",
    });
    //clear fields after submit 
    const [makeEmpty, setMakeEmpty] = useState(false);

    //handle filed clear after submission
    const handleMakeEmpty = (value) => {
        setMakeEmpty(value);
    }

    //handle new filial
    const handleNewFilialData = (value, inputName) => {
        setNewFilial(prev => ({ ...prev, [inputName]: value }))
    }

    //add new filials handler
    const handleAddNewToggle = (value) => {
        setAddNewToggler(value);
    }

    //add new filial 
    const handleAddNewFilialToDb = async () => {
        try {
            const res = await axiosAdmin.post(`Pages/contact2_add_post`, newFilial);
            setDifResponses(res);
            if (res) {
                successToast("ფილიალი წარმატებით დაემატა!");
                handleMakeEmpty(true);
            }
        } catch (error) {
            setFilialAddErr(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //delete filial 
    const handleDeleteFilial = async (id) => {
        try {
            const res = await axiosAdmin.get(`/Pages/contact2_delete/${id}`);
            setDifResponses(res);
            if (res) {
                successToast("ფილიალი წარმატებით წაიშალა!");
            }
        } catch (error) {
            setFilialDelErr(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //filial data from api
    useEffect(() => {
        try {
            axiosAdmin.get(`/Pages/contact2`).then(({ data }) => {
                setFilialData(data);
            });
        } catch (error) {
            setFilialDataErr(error);
        }
    }, [difResponses]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
            <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5" >
                <div className="w-full flex items-center justify-end ">
                    <button
                        onClick={() => { handleAddNewToggle(true) }}
                        className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400">
                        ფილიალის დამატება
                    </button>
                </div>
                {addNewToggler &&
                    <div className="bg-slate-100 dark:bg-slate-900">
                        <div className="sm:px-0">
                            <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                                <div className="flex items-center justify-between flex-wrap gap-5">
                                    <div className="flex items-center gap-5 sm:gap-3 ">
                                        <div>
                                            <h2 className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]">ახალი ფილიალის დამატება</h2>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 sm:w-full">
                                        <button
                                            onClick={handleAddNewFilialToDb}
                                            className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                                            შენახვა
                                        </button>
                                        <button
                                            onClick={() => { handleAddNewToggle(false) }}
                                            className="rounded-lg p-3 text-white text-sm bg-red-400 w-10">
                                            X
                                        </button>
                                    </div>
                                </div>
                                <MultyLangualTextInput
                                    name={"მისამართი"}
                                    geInputName={"title"}
                                    enInputName={"titleen"}
                                    ruInputName={"titleru"}
                                    inputSetter={handleNewFilialData}
                                />
                                <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">

                                    <div className="w-6/12 sm:w-full md:w-full">
                                        <TextInput
                                            name={"სამუშაო საათები ორშაბათი პარასკევი"}
                                            inputName={"hours1"}
                                            inputSetter={handleNewFilialData}
                                        />
                                        <TextInput
                                            name={"ტელეფონი"}
                                            inputName={"phone"}
                                            inputSetter={handleNewFilialData}
                                        />
                                    </div>
                                    <div className="w-6/12 sm:w-full md:w-full">
                                        <TextInput
                                            name={"სამუშაო საათები შაბათი"}
                                            inputName={"hours2"}
                                            inputSetter={handleNewFilialData}
                                        />
                                        <TextInput
                                            name={"ელ. ფოსტა"}
                                            inputName={"mail"}
                                            inputSetter={handleNewFilialData}
                                        />
                                    </div>
                                </div>
                                <TextInput
                                    name={"რუკის კოორდინატები"}
                                    inputName={"coords"}
                                    inputSetter={handleNewFilialData}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="w-full min-h-screen">
                <div className="p-10 pt-0 sm:p-5 sm:pt-0 grid grid-cols-2 gap-5 sm:grid-cols-1">
                    {filialData.map((obj) => {
                        return (
                            < ContactFilialsCard
                                key={obj.id}
                                id={obj.id}
                                name={`${obj.title}`}
                                handleDeleteFilial={handleDeleteFilial}
                            />
                        )
                    })}
                </div>
            </div >
        </div>
    )
}

export default ContactFilialsLayout;

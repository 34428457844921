import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

function PaginatedItems({
    dataLength,
    itemsPerPage = 30,
    children,
    pageNumberSetter,
    forcedPage = 0
}) {
    //current Page
    const [currentPage, setCurrentPage] = useState(forcedPage);
    //calculate how many pages will be there
    const pageCount = Math.ceil(dataLength / itemsPerPage);

    // get current page number
    const handlePageClick = (event) => {
        setCurrentPage(event.selected)
    };

    useEffect(() => {
        if (pageNumberSetter) {
            pageNumberSetter(currentPage);
        }
    }, [currentPage])

    return (
        <>
            <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                breakLinkClassName={"font-bold text-gray-400"}
                breakClassName={"w-8 h-8 flex items-center justify-center"}
                //main container
                containerClassName={`flex items-center gap-1 mt-5`}
                //single page number
                pageLinkClassName={`min-w-[32px] px-2 h-8 text-md font-bold border border-gray-300 shadow-bs
                text-gray-400 flex items-center justify-center rounded-lg`}
                //previous page number
                previousLinkClassName={`hidden`}
                //next page number
                nextLinkClassName={`hidden`}
                //active page
                activeLinkClassName={"bg-green-200 !important text-green-500"}
                forcePage={currentPage}
            />
            {children}
            <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                breakLinkClassName={"font-bold text-gray-400"}
                breakClassName={"w-8 h-8 flex items-center justify-center"}
                //main container
                containerClassName={`flex items-center gap-1`}
                //single page number
                pageLinkClassName={`min-w-[32px] px-2 h-8 text-md font-bold border border-gray-300 shadow-bs
                text-gray-400 flex items-center justify-center rounded-lg`}
                //previous page number
                previousLinkClassName={`hidden`}
                //next page number
                nextLinkClassName={`hidden`}
                //active page
                activeLinkClassName={"bg-green-200 !important text-green-500"}
                forcePage={currentPage}
            />
        </>
    );
}

export default PaginatedItems;
import { Link } from "react-router-dom";
import { useState } from "react";
import ContinueComponent from "../../../../components/Alerts/ContinueComponent";

//icons
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";

const ContactFilialsCard = ({
    id,
    name,
    handleDeleteFilial,
}) => {
    //show contnue
    const [showContinue, setShowContinue] = useState(false);

    return (
        <div className="bg-white rounded-xl p-5 w-full relative flex flex-col items-center justify-center gap-3 dark:bg-slate-700">
            <div className="w-full flex flex-col gap-2">
                <h2 className="text-[14px] font-bold text-[#777a83] dark:text-navy-100 dark:text-gray-200">{name}</h2>
            </div>
            <div className="w-full flex gap-2">
                <Link to={`../contact-filial/${id}`} className="w-9/12 rounded-lg bg-violet-400
                 flex gap-2 items-center justify-center p-1 py-3">
                    <PencilSquare className="text-white font-bold" />
                    <p className="text-white text-sm font-bold">რედაქტირება</p>
                </Link>
                <div
                    onClick={() => { setShowContinue(true) }}
                    className="w-3/12 rounded-lg bg-red-400 cursor-pointer
                 flex gap-2 items-center justify-center p-1 py-3">
                    <BsTrash className="text-white" />
                </div>
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDeleteFilial}
                id={id}
            />
        </div>
    )
}


export default ContactFilialsCard;
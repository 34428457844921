import React, { useEffect } from "react";
import logo from "../img/phoneLogo.png";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen flex items-center justify-center flex-col gap-2">
      <img src={logo} alt="#" className="w-12 h-12" />
      <div
        className="bg-indigo-500 text-white px-4 py-2 rounded-lg cursor-pointer"
        onClick={()=>{navigate("/")}}
      >
        Go Back
      </div>
    </div>
  );
};

export default NotFound;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../../api/apiAdmin";
import GoBackButton from "../../../../../components/GoBackButton/GoBackButton";
import InnerYellowCorridorCard from "./InnerYellowCorridorCard";

const InnerYellowCorridor = () => {
  // id
  const { yellowId } = useParams();
  // data
  const [data, setData] = useState([]);
  // dataErr
  const [dataErr, setDataErr] = useState([]);
  //response data
  const [resetResponse, setResetResponse] = useState(null);

  //change yellow corridor status
  const handleRemoveFromCorridor = (ids) => {
    axiosAdmin
      .post(`/Pack/yviteliar`, {
        ids: ids,
      })
      .then((res) => {
        setResetResponse(res);
      })
      .catch((err) => {
        setDataErr(err);
      });
  };

  //change clarence status
  const handleMoveInCustoms = (ids) => {
    axiosAdmin
      .post(`/Pack/yviteliGansabaj`, {
        ids: ids,
      })
      .then((res) => {
        setResetResponse(res);
      })
      .catch((err) => {
        setDataErr(err);
      });
  };

  //delete yellow status
  const handleDeleteFromYellow = (ids) => {
    axiosAdmin
      .post(`/Pack/yviteliagar`, {
        ids: ids,
      })
      .then((res) => {
        setResetResponse(res);
      })
      .catch((err) => {
        setDataErr(err);
      });
  };

  // data fetching
  useEffect(() => {
    axiosAdmin
      .get(`/Pack/yviteli/${yellowId}`)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        setDataErr(err);
      });
  }, [resetResponse]);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="w-full flex gap-10 items-center">
        <GoBackButton />
        <h2 className="text-slate-400 text-2xl">ყვითელი დერეფანი</h2>
      </div>
      <div className="w-full">
        <div>
          {data.length === 0 && (
            <h2 className="text-slate-400 text-md">მონაცემები არ არის...</h2>
          )}
        </div>
        <div className="w-full flex flex-col gap-3 mt-5">
          {data.map((data) => {
            return (
              <InnerYellowCorridorCard
                key={data.id}
                data={data}
                handleRemoveFromCorridor={handleRemoveFromCorridor}
                handleMoveInCustoms={handleMoveInCustoms}
                handleDeleteFromYellow={handleDeleteFromYellow}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InnerYellowCorridor;

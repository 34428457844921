import { useState, useEffect } from "react";
import axiosAdmin from "../../../../../api/apiAdmin";
import YellowCorridorFlightCard from "./YellowCorridorFlightCard";

import { BsSearch } from "react-icons/bs";

const YellowCorridorFlights = () => {
  //Flight data
  const [flightData, setFlightData] = useState([]);
  //Flight dataErr
  const [flightDataErr, setFlightDataErr] = useState([]);

  //Flight data fetching
  useEffect(() => {
    axiosAdmin
      .get(`/Pack/flight?type=yviteli`)
      .then(({ data }) => {
        setFlightData(data);
      })
      .catch((err) => {
        setFlightDataErr(err);
      });
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
          min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <h2 className="text-slate-400 text-2xl">ყვითელი დერეფანი</h2>
      <div className="w-full">
        <div className="bg-yellow mt-2 flex gap-1">
          <div
            className="bg-white  pv-2 flex items-center hover:outline-slate-400 hover:outline-2
          hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
          border dark:border-slate-600 w-full"
          >
            <input
              type="search"
              placeholder="ძებნა"
              className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                              dark:bg-slate-800 border-0"
            />
          </div>
          <div
            className="w-20 rounded-lg bg-violet-400
               flex items-center justify-center p-1 py-3"
          >
            <BsSearch className="text-white font-bold" />
          </div>
        </div>
        <div className="w-full flex justify-between flex-wrap mt-5 gap-y-3">
          {flightData &&
            flightData.length !== 0 &&
            flightData[0].map((item, index) => {
              return (
                <YellowCorridorFlightCard
                  key={index}
                  code={item.flight_number}
                  amount={item.packcount}
                />
              );
            })}
          {flightData &&
            flightData.length !== 0 &&
            flightData[1].map((item) => {
              return (
                <YellowCorridorFlightCard
                  key={item.id}
                  code={item.number}
                  amount={item.packcount}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default YellowCorridorFlights;

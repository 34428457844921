import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { FaRegCopy } from "react-icons/fa";

const PaneladdressesturkeyPageCard = ({ title, value = "" }) => {
  //copy function
  const copyFunction = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div
      className="bg-white_A700 flex flex-1 flex-row gap-[24px] items-center justify-start dark:bg-[#171719]
       my-[0] outline outline-[1px] outline-black_900_0a px-[24px] py-[20px] rounded-radius13 shadow-bs w-[100%]
       sm:p-3"
    >
      <div className="flex flex-1 flex-col gap-[4px] items-start justify-start w-[100%]">
        <Text
          className="font-normal not-italic text-black_900_b7 text-left w-[auto] dark:text-[#c7c7c7]"
          variant="body7"
        >
          {title}
        </Text>
        <Text
          className="font-semibold text-black_900_e0 text-left w-[auto] dark:text-[#ededed]" 
          variant="body5"
        >
          {value}
        </Text>
      </div>
      <Button
        onClick={() => {
          copyFunction(value);
        }}
        className="flex h-[48px] items-center justify-center w-[48px]"
        variant="icbFillGray60014"
      >
        <FaRegCopy className="text-black_900_b7 dark:text-[#c7c7c7]" />
      </Button>
    </div>
  );
};

export default PaneladdressesturkeyPageCard;

import { Text } from "../Text";
import { Button } from "../Button";
import { useState } from "react";
import CustomTextInut from "../CustomInputs/CustomTextInut";
import axiosClient from "../../api/api";
import { useTranslation } from "react-i18next";

//icons
import { FaMoneyBill } from "react-icons/fa";
import { BsCreditCard } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";

import bogimg from '../../img/bog.png'
import tbcimg from '../../img/tbc.png'

const BalanceModal = ({ showBalance, handleShowBalance }) => {
  //translations 
  const { t } = useTranslation();
  //money amount
  const [amount, setAmount] = useState({})

  //handle amount
  const handleAmount = (value, inputName) => {
    setAmount(prev => ({ ...prev, [inputName]: value }))
  }

  //handle pay tbc
  const handlePay = () => {
    if (amount.amount === null || amount.amount === 0 || amount.amount === "0" || amount.amount === "") {
      errorToast(t("no_amount_was_specified"))
    } else {
      axiosClient.post(`/Payment/start`, amount)
        .then(({ data }) => {
          window.location.href = data;
        })
    }
  }

  //handle pay bog
  const handlePayBog = () => {
    if (amount.amount === null || amount.amount === 0 || amount.amount === "0" || amount.amount === "") {
      errorToast(t("no_amount_was_specified"))
    } else {
      axiosClient.post(`/Payment/bog/start`, amount)
        .then(({ data }) => {
          window.location.href = data;
        })
    }
  }

  return (
    <div
      className={`h-screen fixed top-0 right-0 z-50 rounded-l-[16px] flex flex-col justify-between 
        outline outline-[1px] outline-black_900_0a text-black_900_e0 transition-all delay-150 duration-300
        sm:w-full ease-linear ${showBalance ? "translate-x-0" : "translate-x-[100%]"
        }`}
    >
      <div
        className="py-[16px] px-[24px] bg-white flex justify-between items-center rounded-tl-[16px]
            gap-10 dark:bg-[#171719]"
      >
        <Text
          className="text-[18px] flex-1 font-bold text-black_900_e0  dark:text-[#c7c7c7]
                    text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
          variant="body3"
        >
          {t("top_up")}
        </Text>
        <Button
          onClick={handleShowBalance}
          className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px] dark:bg-[#171719]"
          shape={"circular"}
          variant="icbFillGray6001e"
        >
          <MdClose className="text-black_900_99 font-semibold dark:text-[#c7c7c7]" />
        </Button>
      </div>
      <div className="p-[24px] h-full bg-gray_100 flex flex-col gap-y-5 dark:bg-[#0a0a0c]">
        <div className="flex- flex-col">
          <Button className="flex h-[48px] items-center justify-center w-[48px] dark:bg-[#171719]">
            <BsCreditCard className="font-semibold text-indigo_400 h-[20px] dark:text-[#c7c7c7]" />
          </Button>
          <div className=" pt-3 flex flex-col gap-[16px] items-start justify-center max-w-[300px]">
            <Text
              className="text-[16px] flex-1 font-bold text-black_900_e0 dark:text-[#c7c7c7]
                    text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              variant="body3"
            >
              {t("payment_by_card")}
            </Text>
            {/* <Text
              className="font-sm not-italic text-black_900_99 text-left w-[auto]"
              variant="body5"
            >
              {t("service_fee_for_different_bank_cards")}:
            </Text>
            <Text
              className="font-sm not-italic text-black_900_99 text-left w-[auto]"
              variant="body7"
            >
              <ul className="list-disc pl-5">
                <li>
                  {t("banks1")}
                  <span className="text-indigo_400"> 1.3%</span>
                </li>
                <li>
                  {t("banks2")}
                  <span className="text-indigo_400"> 1.8%</span>
                </li>
              </ul>
            </Text> */}
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-[100%]">
          <CustomTextInut
            PrefixIcon={FaMoneyBill}
            inputSetter={handleAmount}
            placeholder={t("enter_amount")}
            inputName={"amount"}
          />
        </div>
        <div className="flex gap-3 items-center">
          <button
            className="cursor-pointer w-full mt-2 rounded-radius11 p-3 bg-white dark:bg-[#171719]
           flex items-center gap-3 justify-center border-solid shadow-bs border border-bluegray_100"
            onClick={handlePay}
          >
            < img className="w-7 h-7 object-contain" src={tbcimg} alt="#" />
            <p className="text-indigo_400 text-sm font-semibold dark:text-[#c7c7c7]">
              TBC
            </p>
          </button>
          <button
            className="cursor-pointer w-full mt-2 rounded-radius11 p-3 bg-white dark:bg-[#171719]
            flex items-center gap-3 justify-center border-solid shadow-bs border border-bluegray_100"
            onClick={handlePayBog}
          >
            < img className="w-7 h-7 object-contain" src={bogimg} alt="#" />
            <p className="text-indigo_400 text-sm font-semibold dark:text-[#c7c7c7]">
              BOG
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BalanceModal;

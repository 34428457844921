import { useEffect, useState, useContext } from "react";
import { errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { Text } from "../Text";
import { Button } from "../Button";
import CustomTextInut from "../CustomInputs/CustomTextInut";
import CustomSelectInput from "../CustomInputs/CustomSelectInput";
import { useTranslation } from "react-i18next";
import { PrimmaryContext } from "../MainContext/MainContext";

//icons
import { MdClose } from "react-icons/md";
import { AiOutlineTag, AiOutlineInbox } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";

const AddProductModal = ({
  addProductModalOpen,
  handleAddProductModalOpen,
  handleNewPackItems,
  aoId,
}) => {
  //translation
  const { t } = useTranslation();
  // toggler
  const [isOpen, setIsOpen] = useState(false);
  //toggle variable
  const [isOpenCurrency, setIsOpenCurrency] = useState(false);
  //new data
  const [newItem, setNewItem] = useState({
    product_cat: 0,
    product_name: "",
    product_price: 0,
    currency: "",
  });
  //products
  const { products } = useContext(PrimmaryContext);
  //clear fields
  const [clearFields, setClearFields] = useState(false);
  //currency options
  const [currencyOptions, setCurrencyOptions] = useState([]);
  //choosed currency
  const [choosedCurrency, setChooseCurrency] = useState("");

  //handle new item id
  const handleNewItemId = (value, inputName) => {
    setNewItem((prev) => ({ ...prev, [inputName]: value }));
  };

  //hanle pack addition in declaration
  const handleAddToDeclaration = () => {
    if (
      newItem.product_cat === 0 ||
      newItem.product_name === "" ||
      newItem.product_price === 0
    ) {
      errorToast(t("fill_all_fields"));
    } else {
      handleNewPackItems(newItem);
      setClearFields(true);
      setNewItem({
        id: Date.now(),
        product_cat: 0,
        product_name: "",
        product_price: 0,
        currency:
          aoId === 16
            ? "usd"
            : aoId === 20
            ? "eur"
            : aoId === 21
            ? "gbp"
            : "gel",
      });
      handleAddProductModalOpen();
    }
  };

  //toggler handler
  useEffect(() => {
    setIsOpen(addProductModalOpen);
  }, [addProductModalOpen]);

  useEffect(() => {
    if (aoId === 16) {
      setCurrencyOptions([
        {
          title: "usd",
          id: "usd",
        },
      ]);
      setChooseCurrency("usd");
    } else if (aoId === 20) {
      setCurrencyOptions([
        {
          title: "eur",
          id: "eur",
        },
      ]);
      setChooseCurrency("eur");
    } else if (aoId === 21) {
      setCurrencyOptions([
        {
          title: "gbp",
          id: "gbp",
        },
      ]);
      setChooseCurrency("gbp");
    } else {
      setCurrencyOptions([
        {
          title: "gel",
          id: "gel",
        },
      ]);
      setChooseCurrency("gel");
    }
  }, [aoId]);

  useEffect(() => {
    setNewItem((prev) => ({ ...prev, currency: choosedCurrency }));
  }, [choosedCurrency]);

  return (
    <div
      className={`fixed top-0 right-0 z-50 rounded-l-[16px] flex flex-col justify-between popup 
        outline outline-[1px] outline-black_900_0a text-black_900_e0 transition-all delay-150 duration-300
        sm:w-full 
        ease-linear ${isOpen ? "translate-x-0" : "translate-x-[100%]"}`}
    >
      <div
        className="py-[16px] px-[24px] bg-white flex justify-between items-center rounded-tl-[16px] dark:bg-[#171719]
            gap-10"
      >
        <Text
          className="text-[18px] flex-1 font-bold text-black_900_e0 dark:text-[#c7c7c7]
                    text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
          variant="body3"
        >
          {t("parcel")} {t("details")}
        </Text>
        <Button
          onClick={handleAddProductModalOpen}
          className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
          variant="icbFillGray6001e"
          shape="circular"
        >
          <MdClose className="text-black_900_99 font-semibold dark:text-[#c7c7c7]" />
        </Button>
      </div>
      <div className="p-[24px] h-full bg-gray_100 flex flex-col gap-y-5 dark:bg-[#0a0a0c]">
        <div className="flex gap-2">
          <CustomTextInut
            PrefixIcon={AiOutlineTag}
            placeholder={t("price")}
            inputName={"product_price"}
            inputSetter={handleNewItemId}
            clearFields={clearFields}
            pattern={"number"}
            setClearFields={setClearFields}
          />
          <div className="w-16 bg-white_A700 rounded-xl border  border-solid shadow-bs border-bluegray_100 relative">
            <div
              className="w-full h-full flex justify-center items-center rounded-lg focus:outline-slate-300
                  dark:text-gray-300  p-1 dark:bg-[#171719] overflow-hidden
                 dark:border-slate-600 dark:focus:outline-slate-600"
              onClick={() => {
                setIsOpenCurrency((prev) => !prev);
              }}
            >
              <p className="text-sm text-gray-500 text-center uppercase">
                {choosedCurrency}
              </p>
            </div>
            {isOpenCurrency && (
              <div
                className="w-full max-h-80  overflow-y-auto scrollbar bg-white rounded-lg
                        absolute bottom-0 left-0 translate-y-[105%] border border-slate-300 z-40
                        dark:bg-black  dark:border-slate-600"
              >
                {currencyOptions.map((value) => {
                  return (
                    <div
                      onClick={() => {
                        setChooseCurrency(value.id);
                        setIsOpenCurrency(false);
                      }}
                      key={value.id}
                      className="p-1 w-full hover:bg-slate-300 group"
                    >
                      <p
                        className="text-sm text-gray-500 font-bold dark:text-gray-300
                        group-hover:text-slate-800 uppercase text-center"
                      >
                        {t(`${value.title}`)}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <CustomSelectInput
          name={t("product")}
          placeholder={t("category")}
          PrefixIcon={AiOutlineInbox}
          inputSetter={handleNewItemId}
          inputName={"product_cat"}
          options={products}
          clearFields={clearFields}
          setClearFields={setClearFields}
        />
        <CustomTextInut
          PrefixIcon={BiPencil}
          placeholder={t("name")}
          inputName={"product_name"}
          inputSetter={handleNewItemId}
          clearFields={clearFields}
          setClearFields={setClearFields}
        />
        <p className="text-red-500 text-sm font-bold max-w-[300px]">
          {t("dec_text")}
        </p>
      </div>
      <div className="py-[16px] px-[24px] bg-white flex justify-end  rounded-bl-[16px] dark:bg-[#171719]">
        <Button
          onClick={handleAddToDeclaration}
          className="cursor-pointer font-semibold min-w-[118px] 
                    text-[16px] text-center text-white_A700 w-[auto]"
          shape="RoundedBorder11"
          size="3xl"
          variant="OutlineBlack9000a"
        >
          {t("add")}
        </Button>
      </div>
    </div>
  );
};

export default AddProductModal;

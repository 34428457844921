import { useState, useEffect, useContext } from "react";
import axiosClient from "../../api/api";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import PanelHead from "../../components/PanelHead/PanelHead";
import PanelWarehouseMenu from "./PanelWarehouseMenu";
import WarehouseItemCard from "./WarehouseItemCard";
import SentItmesCard from "./SentItmesCard";
import RecivedItemCard from "./RecivedItemCard";
import InstockIemsCard from "./InstockIemsCard";
import { useTranslation } from "react-i18next";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import {
  errorToast,
  successToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import CalculateVolume from "../../components/CalculateVolume/CalculateVolume";

const PanelpackageswarehousePage = () => {
  //translations
  const { t } = useTranslation();
  //current package condition
  const [currentPageTypeId, setCurrentPageTypeId] = useState(2);
  //recived packages variable
  const [packageData, setPackageData] = useState([]);
  //update pack data
  const [updatePackData, setUpdatePackData] = useState(null);
  //handle current package cndition
  const handleCurrentPageTypeId = (name) => {
    setCurrentPageTypeId(name);
  };
  //payAll price
  const [payAllPrice, setPayAllPrice] = useState([]);
  //renew pay all  aount
  const [payAllRes, setPayAllRes] = useState(null);
  //payment
  const { setRenewMoneyData, renewMoneyData, user } =
    useContext(PrimmaryContext);
  //abroad office data
  const [abOffices, setAbOffices] = useState([
    {
      id: 1,
      name: "turkey",
      amount: 0,
    },
    {
      id: 5,
      name: "china",
      amount: 0,
    },
    {
      id: 16,
      name: "usa",
      amount: 0,
    },
    {
      id: 19,
      name: "china_land",
      amount: 0,
    },
    {
      id: 20,
      name: "greece",
      amount: 0,
    },
    {
      id: 21,
      name: "england",
      amount: 0,
    },
  ]);
  //selected abroad office
  const [selectedAbOffice, setSelectedAbOffice] = useState("");

  //main content
  const Content = () => {
    return (
      <>
        {currentPageTypeId === 2 && (
          <>
            {packageData !== null &&
              packageData !== undefined &&
              packageData.map((data) => {
                return (
                  <WarehouseItemCard
                    key={data.id}
                    data={data}
                    setUpdatePackData={setUpdatePackData}
                  />
                );
              })}
          </>
        )}
        {currentPageTypeId === 3 && (
          <>
            {packageData !== null &&
              packageData !== undefined &&
              packageData.map((data) => {
                return (
                  <SentItmesCard
                    key={data.id}
                    data={data}
                    setUpdatePackData={setUpdatePackData}
                  />
                );
              })}
          </>
        )}
        {currentPageTypeId === 4 && (
          <>
            {packageData !== null &&
              packageData !== undefined &&
              packageData.map((data) => {
                return (
                  <InstockIemsCard
                    key={data.id}
                    data={data}
                    setUpdatePackData={setUpdatePackData}
                  />
                );
              })}
          </>
        )}
        {currentPageTypeId === 5 && (
          <>
            {packageData !== null &&
              packageData !== undefined &&
              packageData.map((data) => {
                return (
                  <RecivedItemCard
                    key={data.id}
                    data={data}
                    setUpdatePackData={setUpdatePackData}
                  />
                );
              })}
          </>
        )}
      </>
    );
  };

  //handle pay sent
  const handlePayAllSent = () => {
    if (payAllPrice === 0) {
      successToast(t("all_paid"));
    } else {
      axiosClient
        .get(`/Pack/payy`)
        .then((res) => {
          setPayAllRes(res);
          setRenewMoneyData(res);
          setUpdatePackData(res);
          successToast(t("payment_success"));
        })
        .catch((err) => {
          errorToast(t("not_enough_money"));
        });
    }
  };

  //handle pay
  const handlePayAllArrived = async () => {
    if (payAllPrice === 0) {
      successToast(t("all_paid"));
    } else {
      axiosClient
        .get(`/Pack/payyy`)
        .then((res) => {
          setPayAllRes(res);
          setRenewMoneyData(res);
          setUpdatePackData(res);
          successToast(t("payment_success"));
        })
        .catch((err) => {
          errorToast(t("not_enough_money"));
        });
    }
  };

  const handlePayAll = (id) => {
    if (id === 3) {
      handlePayAllSent();
    } else if (id === 4) {
      handlePayAllArrived();
    }
  };

  //get pay all price
  useEffect(() => {
    if (currentPageTypeId === 3 || currentPageTypeId === 4) {
      axiosClient
        .get(`/Pack/pricee/${currentPageTypeId}`)
        .then((res) => {
          setPayAllPrice(res.data);
        })
        .catch((err) => {});
    }
  }, [payAllRes, renewMoneyData, currentPageTypeId]);

  //package data api
  useEffect(() => {
    try {
      axiosClient
        .get(
          `/Dashboard/pir/${currentPageTypeId}?ab_office=${selectedAbOffice}`
        )
        .then(({ data }) => {
          setPackageData(data.pack);

          setAbOffices((prev) =>
            prev.map((office) => {
              var tmp = 0;
              if (office.id === 1) {
                tmp = data.count_tr;
              } else if (office.id === 5) {
                tmp = data.count_ch;
              } else if (office.id === 16) {
                tmp = data.count_am;
              } else if (office.id === 19) {
                tmp = data.count_ln;
              } else if (office.id === 20) {
                tmp = data.count_gr;
              } else if (office.id === 21) {
                tmp = data.count_uk;
              }
              return {
                ...office,
                amount: tmp,
              };
            })
          );
        });
    } catch (error) {}
  }, [currentPageTypeId, payAllRes, updatePackData, selectedAbOffice]);

  return (
    <div
      className="min-h-screen flex flex-col font-firago items-start justify-start
      max-w-[1500px] px-[46px] lg1:px-2 py-[32px] w-[100%] md:p-0 sm:p-0"
    >
      <PanelHead name={t("parcels")} />
      <div className="font-notosansgeorgian  relative w-[100%]">
        <div
          className="flex flex-col gap-[32px] pt-[36px] inset-[0] items-start justify-start
           m-[auto] w-[100%] md:pt-0 sm:pt-0 md:gap-5 sm:gap-5"
        >
          <PanelWarehouseMenu
            currentPageId={currentPageTypeId}
            handleCurrentPageTypeId={handleCurrentPageTypeId}
            setPackageData={setPackageData}
          />

          <div className="w-full md:px-4 sm:px-4 sm:w-full flex sm:flex-col flex-wrap gap-[32px] md:gap-5 sm:gap-5">
            {(currentPageTypeId === 3 || currentPageTypeId === 4) && (
              <Button
                onClick={() => {
                  handlePayAll(currentPageTypeId);
                }}
                className="cursor-pointer font-semibold text-sm text-center text-white_A700 w-[auto]
                  sm:w-full min-w-[200px]"
                shape="RoundedBorder11"
                size="xl"
                variant="OutlineBlack9000a_5"
              >
                {t("pay_all")} ₾{" "}
                {payAllPrice !== null &&
                  payAllPrice !== undefined &&
                  payAllPrice}
              </Button>
            )}
            <CalculateVolume />
            <div
              className="bg-[#dedfe4] rounded-radius11 outline outline-[1px] outline-black_900_0a shadow-bs
                cursor-pointer p-[2px] flex items-center flex-wrap sm:justify-between  dark:bg-[#171719]"
            >
              {abOffices.map((office) => {
                return (
                  <div
                    className="flex items-center justify-center sm:w-[49%]"
                    onClick={() => {
                      setSelectedAbOffice(office.id);
                    }}
                    key={office.id}
                  >
                    <p
                      className={`text-sm font-semibold text-[#636366] sm:text-xs text-center px-[16px] py-[8px] rounded-radius8 sm:w-full dark:text-[#c7c7c7]
                      ${
                        selectedAbOffice === office.id &&
                        "bg-white outline outline-[1px] outline-black_900_0a shadow-bs dark:bg-[#48484a]"
                      }`}
                    >
                      {t(office.name)} - {office.amount}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="flex-col gap-[24px] grid items-start w-[100%] md:px-[16px] sm:px-[16px]
              md:pb-[80px] sm:pb-[80px]"
          >
            <Text
              className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
          hidden sm:block md:block dark:text-[#c7c7c7]"
              variant="body1"
            >
              {t("parcels")}
            </Text>
            <Content />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelpackageswarehousePage;

import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import { Button } from "../../components/Button";
import { useTranslation } from "react-i18next";
import PanelHead from "../../components/PanelHead/PanelHead";
import PanelsettingsaddressesPageCard from "./PanelsettingsaddressesPageCard";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import AddAdressModal from "../../components/AddAddressModal/AddAddressModa";

//icons
import { HiPlus } from "react-icons/hi";

const PanelsettingsaddressesPage = () => {
  //adress modal
  const [adressModalOpen, setAdressesModalOpen] = useState(false);
  //translation
  const { t } = useTranslation();
  //adresses
  const [adresses, setAdresses] = useState([]);
  //error
  const [error, setError] = useState(null);
  //new address
  const [newAddress, setNewAddress] = useState({
    name: "",
    address: "",
  });
  //required fields controller
  const [mFill, setMFill] = useState(false);
  //update data list  based on different response
  const [fetchUpdater, setFetchUpdater] = useState(null);

  //handle new adress
  const handleNewAdress = (value, inputName) => {
    setNewAddress((prev) => ({ ...prev, [inputName]: value }));
  };

  //handle adress modal open close
  const handleAddreessModalOpen = () => {
    setAdressesModalOpen(prev => !prev)
  }

  //add new Adress data
  const handleAddNewAdresses = async () => {
    if (newAddress.name === "" || newAddress.address === "") {
      setMFill(true);
    } else {
      try {
        const res = await axiosClient.post(`/Adres/create`, newAddress);
        if (res) {
          setFetchUpdater(res);
          handleAddreessModalOpen();
        }
      } catch (err) {
        setError(err);
      }
    }
  };

  //change data
  const handleUpdateRecord = async (id, payload) => {
    try {
      const res = await axiosClient.post(`/Adres/update/${id}`, payload);
      if (res) {
        setFetchUpdater(res);
      }
    } catch (err) {
      setError(err);
    }
  };

  //get address data
  const handleGetAdresses = async () => {
    try {
      const res = await axiosClient.get(`/Adres/index`);
      if (res) {
        setAdresses(res.data);
      }
    } catch (err) {
      setError(err);
    }
  };

  //const delete adress
  const handleDeleteAdresse = async (id) => {
    try {
      const res = await axiosClient.get(`/Adres/delete/${id}`);
      if (res) {
        setFetchUpdater(res);
      }
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    handleGetAdresses();
  }, [fetchUpdater]);

  return (
    <>
      <div
        className="flex flex-1 flex-col font-firago gap-[36px] items-start justify-start max-w-[1500px] 
      md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] min-h-screen sm:gap-8 sm:pb-[80px] md:pb-[80px]"
      >
        <PanelHead name={t("addresses")} goBackButton={true} />
        <div className="hidden sm:flex md:flex items-center gap-3">
          <GoBackButton />
          <p
            className="font-semibold text-black_900_e0 text-left dark:text-[#c7c7c7]
            tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
          sm:text-[20px] md:text-[22px] text-[24px]"
          >
            {t("addresses")}
          </p>
        </div>
        <div className="font-notosansgeorgian relative w-[100%]">
          <div className="flex flex-col gap-[24px] inset-[0] items-center justify-start m-[auto] w-[100%]">
            <div
              className="w-full bg-indigo_400_14 border border-indigo_400 border-dashed flex 
                 items-center justify-center rounded-radius13 h-24"
            >
              <div className="w-full flex flex-row gap-[16px] items-center justify-center sm:p-4">
                <Button
                  onClick={() => {
                    handleAddreessModalOpen();
                  }}
                  className="flex h-[40px] items-center justify-center rounded-full w-[40px]"
                >
                  <HiPlus className="text-xl text-indigo_400  font-semibold" />
                </Button>
                <p className="font-semibold text-indigo_400 text-left w-[auto] text-[17px]">
                  {t("add_new_address")}
                </p>
              </div>
            </div>
            <div className="flex-col gap-[24px] grid items-center w-[100%] sm:gap-4">
              {adresses.map((item) => {
                return (
                  <PanelsettingsaddressesPageCard
                    key={item.id}
                    data={item}
                    handleDeleteAdresse={handleDeleteAdresse}
                    setFetchUpdater={setFetchUpdater}
                    handleUpdateRecord={handleUpdateRecord}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <AddAdressModal
        adressModalOpen={adressModalOpen}
        handleAddreessModalOpen={handleAddreessModalOpen}
        handleNewAdress={handleNewAdress}
        mFill={mFill}
        handleAddNewAdresses={handleAddNewAdresses}
        newAddress={newAddress}
      />
    </>
  );
};

export default PanelsettingsaddressesPage;

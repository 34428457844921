import { Text } from "../../components/Text";
import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import { useTranslation } from "react-i18next";

import {
  TbPlaneArrival,
  TbPlaneDeparture,
  TbBuildingWarehouse,
} from "react-icons/tb";
import { FaDolly } from "react-icons/fa";

const PanelWarehouseMenu = ({
  currentPageId = 2,
  handleCurrentPageTypeId,
  setPackageData
}) => {
  //translations 
  const { t } = useTranslation();
  const [packTypeId, setpackTypeId] = useState(2);
  //amounts
  const [amounts, setAmounts] = useState([]);

  useEffect(() => {
    setpackTypeId(currentPageId);
  }, [currentPageId]);

  //get addres data
  const handleAmounts = async () => {
    try {
      const res = await axiosClient.get(`/Dashboard/numbers`);
      if (res) {
        setAmounts(res.data);
      }
    } catch (err) { }
  };

  useEffect(() => {
    handleAmounts();
  }, []);

  return (
    <div
      className="bg-gray_600_29 flex flex-row md:gap-[0px] sm:gap-[0px] items-center justify-start p-[2px] 
            rounded-radius10 md:w-[100%] sm:w-[100%] w-[auto] md:rounded-none sm:rounded-none cursor-pointer
            dark:bg-[#171719]"
    >
      <div
        className={`common-pointer flex flex-row gap-[12px] items-center justify-center px-[16px] py-[8px] rounded-radius8  
                md:w-full sm:w-full ${packTypeId === 2 &&
          "bg-white outline outline-[1px] outline-black_900_0a shadow-bs dark:bg-[#48484a]"
          }`}
        onClick={() => { handleCurrentPageTypeId(2); setPackageData([]) }}
      >
        <TbBuildingWarehouse
          className="w-5 h-5 bg-transparent 
                    cursor-pointer font-semibold text-[15px] text-[#636366] text-left dark:text-[#c7c7c7]"
        />
        <Text
          className="font-normal not-italic text-black_900_b7 text-left w-[auto]"
          variant="body7"
        >
          <span
            className={`text-[15px] font-notosansgeorgian text-black_900_b7  dark:text-[#c7c7c7] md:hidden sm:hidden
            ${packTypeId === 2 ? "font-[600]" : "font-[400]"}`}
          >
            {t("in_the_warehouse")} -{" "}
          </span>
          <span className="text-black_900_66 text-[15px] font-notosansgeorgian dark:text-[#aeaeb2]">
            {amounts.length !== 0 && amounts[0]}
          </span>
        </Text>
      </div>
      <div
        className={`common-pointer flex flex-row gap-[12px] items-center justify-center px-[16px] py-[8px] rounded-radius8  
                md:w-full sm:w-full ${packTypeId === 3 &&
          "bg-white outline outline-[1px] outline-black_900_0a shadow-bs dark:bg-[#48484a] "
          }`}
        onClick={() => { handleCurrentPageTypeId(3); setPackageData([]) }}
      >
        <TbPlaneDeparture
          className="w-5 h-5 bg-transparent 
                    cursor-pointer font-semibold text-[15px] text-[#636366] text-left dark:text-[#c7c7c7]"
        />
        <Text
          className="font-normal not-italic text-black_900_b7 text-left w-[auto]"
          variant="body7"
        >
          <span
            className={`text-[15px] font-notosansgeorgian text-black_900_b7 dark:text-[#c7c7c7] md:hidden sm:hidden
            ${packTypeId === 3 ? "font-[600]" : "font-[400]"}`}
          >
            {t("sent")} -{" "}
          </span>
          <span className="text-black_900_66 text-[15px] font-notosansgeorgian dark:text-[#aeaeb2]">
            {amounts.length !== 0 && amounts[1]}
          </span>
        </Text>
      </div>
      <div
        className={`common-pointer flex flex-row gap-[12px] items-center justify-center px-[16px] py-[8px] rounded-radius8 
                md:w-full sm:w-full ${packTypeId === 4 &&
          "bg-white outline outline-[1px] outline-black_900_0a shadow-bs dark:bg-[#48484a]"
          }`}
        onClick={() => { handleCurrentPageTypeId(4); setPackageData([]) }}
      >
        <TbPlaneArrival
          className="w-5 h-5 bg-transparent 
                    cursor-pointer font-semibold text-[15px] text-[#636366] text-left dark:text-[#c7c7c7]"
        />
        <Text
          className="font-normal not-italic text-black_900_b7 text-left w-[auto]"
          variant="body7"
        >
          <span
            className={`text-[15px] font-notosansgeorgian text-black_900_b7  dark:text-[#c7c7c7] md:hidden sm:hidden
            ${packTypeId === 4 ? "font-[600]" : "font-[400]"}`}
          >
            {t("arrived")} -{" "}
          </span>
          <span className="text-indigo_400 text-[15px] font-notosansgeorgian">
            {amounts.length !== 0 && amounts[2]}
          </span>
        </Text>
      </div>
      <div
        className={`common-pointer flex flex-row gap-[12px] items-center justify-center px-[16px] py-[8px] rounded-radius8 
                md:w-full sm:w-full ${packTypeId === 5 &&
          "bg-white outline outline-[1px] outline-black_900_0a shadow-bs dark:bg-[#48484a]"
          }`}
        onClick={() => { handleCurrentPageTypeId(5); setPackageData([]) }}
      >
        <FaDolly
          className={`w-5 h-5 bg-transparent cursor-pointer font-semibold text-[15px] text-left dark:text-[#c7c7c7]
                ${packTypeId === "" ? "text-[#48484A]" : "text-[#636366]"}`}
        />
        <Text
          className="font-normal not-italic text-black_900_b7 text-left w-[auto] dark:text-[#c7c7c7]"
          variant="body7"
        >
          <span
            className={`text-[15px] font-notosansgeorgian text-black_900_b7  dark:text-[#c7c7c7] md:hidden sm:hidden
            ${packTypeId === 5 ? "font-[600]" : "font-[400]"}`}
          >
            {t("received")} -{" "}
          </span>
          <span className="text-green_A700 text-[15px] font-notosansgeorgian">
            {amounts.length !== 0 && amounts[3]}
          </span>
        </Text>
      </div>
    </div>
  );
};

export default PanelWarehouseMenu;

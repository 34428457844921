import { useContext, useEffect, useState } from "react";
import AdminRouterLayout from "./adminPanel/components/adminRouter/AdminRouterLayout";
import NotFound from "./pages/NotFound";
import UserPanelRoutes from "./UserPanelRoutes";
import MainPage from "./pages/MainPage/MainPage";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { PrimmaryContext } from "./components/MainContext/MainContext";
import TmpPopup from "./tmpPopup/TmpPopup";
import BarcodePage from "./pages/BarcodePage/BarcodePage";
import { ToastContainer } from "react-toastify";
import Instructions from "./pages/Instructions/Instructions";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import axiosClient from "./api/api";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const ProjectRoutes = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  //scroll flag
  const { scrollFlagOff, isAuthorized } = useContext(PrimmaryContext);

  const [popupTogglers, setPopupTogglers] = useState([
    // {
    //   name: "SHOW_POPUP1",
    //   toggle: false,
    //   showAgain: true,
    //   textGe: `არ გამოტოვო სიახლე !!! 🎉🎉🎉&გაცნობებთ რომ დაგვემატა საბერძნეთის მისამართი.
    //   გადახედეთ ყველაზე პოპულარულ მაღაზიებს და ისიამოვნეთ ონლაინ შოპინგის უპირატესობებით 💙&
    //   კვირაში 1 რეისი სახმელეთო გზით .&არანაირი მოცულობითი წონა, ამანათები ანგარიშდება რეალური წონით
    //   ტრანსპორტირების ვადა: 5 – 7 სამუშაო დღე.&ტარიფი: 1კგ - €3.5`,
    //   textEn: `Don't miss the news!!! 🎉🎉🎉&We inform you that we have added the address of Greece.
    //   Check out the most popular stores and enjoy the benefits of online shopping 💙&
    //   1 flight per week by land.&No volumetric weight, parcels are calculated by actual weight
    //   Delivery time: 5-7 working days.&Tariff: 1 kg - €3.5`,
    //   bg: "greece-bg",
    //   zIndex: "z-50",
    // },
    // {
    //   name: "SHOW_POPUP2",
    //   toggle: false,
    //   showAgain: true,
    //   textGe: `იმისათვის , რომ შეძლოთ ამანათის უმოკლეს დროში მიღება სასურველ მისამართზე შეგიძლიათ გამოიყენოთ Wolt-ის აპლიკაცია.&
    //   ასევე იხილეთ სტანდარტული საკურიერო შეკვეთის ვადები და ტარიფები 5კგ-მდე ამანათებისთვის :&
    //   თბილისი 2 სამუშაო დღე - 6 ლარი ( გარეუბანი 8 ლარი ) &
    //   ქალაქებს შორის 3 სამუშაო დღე - 8-10 ლარი.&
    //   სოფელსა და დაბაში 3 სამუშაო დღე - 13 ლარი`,
    //   textEn: `If you want to receive your packages ASAP, you can use the Wolt application to deliver it.&
    //   See also standard courier order terms and rates for parcels up to 5 kg:&
    //   Tbilisi 2 working days - 6 GEL (suburbs 8 GEL)&
    //   3 working days between cities - 8-10 GEL.&
    //   3 working days in the village and township - 13 GEL`,
    //   bg: "wolt-bg",
    //   zIndex: "z-40",
    // },
  ]);

  const handleTogglePopups = (name) => {
    let showId = null;
    setPopupTogglers((prev) =>
      prev.map((item, id) => {
        if (item.name === name) {
          showId = id + 1;
          return {
            ...item,
            toggle: !item.toggle,
          };
        }

        if (showId === id) {
          return {
            ...item,
            toggle: !item.toggle,
          };
        }

        return item;
      })
    );
  };

  useEffect(() => {
    let firstPopup = null;

    if (localStorage.getItem("SHOW_POPUP1")) {
      setPopupTogglers((prev) =>
        prev.map((item, id) => {
          if (id === 0) {
            return {
              ...item,
              showAgain: false,
            };
          }
          return item;
        })
      );
      firstPopup = true;
    } else {
      setPopupTogglers((prev) =>
        prev.map((item, id) => {
          if (id === 0) {
            return {
              ...item,
              toggle: true,
            };
          }
          return item;
        })
      );
    }

    if (localStorage.getItem("SHOW_POPUP2")) {
      setPopupTogglers((prev) =>
        prev.map((item, id) => {
          if (id === 1) {
            return {
              ...item,
              showAgain: false,
            };
          }
          return item;
        })
      );
    } else {
      // if first popup was hidden
      if (firstPopup) {
        setPopupTogglers((prev) =>
          prev.map((item, id) => {
            if (id === 1) {
              return {
                ...item,
                toggle: true,
              };
            }
            return item;
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (pathname.split("/")[1] === "home") {
      navigate("/");
    }
  }, [pathname]);

  useEffect(() => {
    axiosClient
      .get("/isErr")
      .then(({ data }) => {
        if (data === 1) {
          navigate("/error");
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <div className={`${scrollFlagOff && "overflow-hidden h-screen"}`}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/instructions" element={<Instructions />} />
        <Route path="/admin/*" element={<AdminRouterLayout />} />
        {isAuthorized && (
          <Route path="/panelroutes/*" element={<UserPanelRoutes />} />
        )}
        <Route path="/code/:barcodeId" element={<BarcodePage />} />

        {/* <Route path="/error" element={<ErrorPage />} /> */}
      </Routes>

      {pathname.split("/")[1] !== "admin" &&
        popupTogglers.map((item) => {
          return (
            item.toggle &&
            item.showAgain && (
              <TmpPopup
                key={item.name}
                handleTogglePopups={handleTogglePopups}
                data={item}
              />
            )
          );
        })}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
export default ProjectRoutes;

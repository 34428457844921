import { useState } from "react";
import { BsX } from "react-icons/bs";

const TmpPopup = ({ handleTogglePopups, data }) => {
  const [language, setLanguage] = useState("ge");

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen bg-black
bg-opacity-80 flex items-center justify-center sm:p-10 ${data.zIndex}`}
    >
      <div
        className="bg-white rounded-lg flex flex-col items-center justify-center relative overflow-hidden
      sm:w-full sm:max-h-[90vh]"
      >
        <div
          className={`bg-no-repeat bg-cover bg-center w-full h-[200px] ${data.bg}`}
        >
          <div className="absolute top-5 left-0 flex justify-between items-center w-full px-5 ">
            <div className="flex gap-2 items-center bg-black bg-opacity-40 px-2 rounded-[30px]">
              <p
                className={`text-lg text-center cursor-pointer  ${
                  language === "ge" ? "text-indigo_600" : "text-white"
                }`}
                onClick={() => {
                  setLanguage("ge");
                }}
              >
                GE
              </p>
              <p
                className={`text-lg text-center cursor-pointer ${
                  language === "en" ? "text-indigo_600" : "text-white"
                }`}
                onClick={() => {
                  setLanguage("en");
                }}
              >
                EN
              </p>
            </div>
            <div
              className="cursor-pointer bg-black bg-opacity-50 rounded-full"
              onClick={() => {
                handleTogglePopups(data.name);
              }}
            >
              <BsX className="text-white text-3xl" />
            </div>
          </div>
        </div>
        <div className="p-5 sm:max-h-[50vh] overflow-y-auto">
          <p className="text-sm text-gray-500 max-w-[400px] text-center sm:max-w-[280px]">
            {language === "ge"
              ? data.textGe.split("&").map((text, id) => {
                  return (
                    <span key={id}>
                      {text}
                      {id !== data.textGe.split("&").length - 1 && (
                        <>
                          <br /> <br />
                        </>
                      )}
                    </span>
                  );
                })
              : data.textEn.split("&").map((text, id) => {
                  return (
                    <span key={id}>
                      {text}
                      {id !== data.textGe.split("&").length - 1 && (
                        <>
                          <br /> <br />
                        </>
                      )}
                    </span>
                  );
                })}
          </p>
          <p
            className="text-sm text-indigo_500 max-w-[400px] text-center sm:max-w-[280px] mt-2 cursor-pointer"
            onClick={() => {
              localStorage.setItem(data.name, true);
              handleTogglePopups(data.name);
            }}
          >
            {language === "ge" ? `აღარ შემახსენო` : `Do not show me again`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TmpPopup;

import { Text } from "../../components/Text";
import { Switch } from "../../components/CustomInputs/CustomSwitchComponent";

const SingleOptionsNotification = ({
    name,
    inputName,
    inputSetter,
    value,
    handleUpdateNotifications,
}) => {
    return (
        <div className="border-t-[1px] border-black_900_14 border-solid flex flex-row
         items-center justify-between sm:p-[20px] p-[24px] w-[100%] dark:border-[#aeaeb2]">
            <Text
                className="font-fw text-black_900_b7 text-left dark:text-[#c7c7c7]"
                variant="body5"
            >
                {name}
            </Text>
            <Switch
                inputSetter={inputSetter}
                value={value}
                inputName={inputName}
                handleUpdateNotifications={handleUpdateNotifications}
                className="h-[100%]"
            />
        </div>
    )
}

export default SingleOptionsNotification;
import { useState, useRef, useEffect } from "react";
import { Button } from "../Button";
import { Text } from "../Text";
import { BsReceipt, BsCheck } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaFile } from "react-icons/fa";

const UploadInvoice = ({ name, inputName, invoice }) => {
  //working with single file input
  const [fileState, setFileState] = useState(null);
  //file input element
  const inputElement = useRef(null);
  //file name
  const [fileName, setFileName] = useState("");

  const handleChange = (event) => {
    setFileState(URL.createObjectURL(event.target.files[0]));
    setFileName(event.target.files[0].name);
  };

  const handleOpenInput = () => {
    inputElement.current.click();
  };

  useEffect(() => {
    setFileName(invoice);
  }, [invoice]);

  return (
    <>
      <div
        className="bg-white_A700 flex flex-1 flex-row items-center justify-between outline outline-[1px]
             outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] dark:bg-[#171719]"
      >
        <div
          className="flex items-center gap-[12px] w-full"
          onClick={handleOpenInput}
        >
          <div className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px] bg-indigo_400_14">
            <BsReceipt className="h-[16px] w-[16px] text-indigo_400" />
          </div>
          <Text
            className="flex-1 font-fw text-black_900_b7 text-left w-[auto] dark:text-[#c7c7c7]"
            variant="body7"
          >
            {name}
          </Text>
        </div>
        {fileName && (
          <Link
            to={`${process.env.REACT_APP_API_URL}/storage/uploads/pack_files/${invoice}`}
            target={"_blank"}
          >
            {invoice ? (
              <FaFile className="w-4 h-4 text-green-500 shrink-0" />
            ) : (
              <BsCheck className="w-8 h-8 text-green-500 shrink-0" />
            )}
          </Link>
        )}
        <input
          name={inputName}
          ref={inputElement}
          className="hidden"
          type="file"
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default UploadInvoice;

import { Link } from "react-router-dom";
import defaultImg from "../../../img/profile.jpg";
import ContinueComponent from "../../../../components/Alerts/ContinueComponent";
import { useState } from "react";

//icons
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";

const CategoryCard = ({
    id,
    link,
    img,
    handleDeleteAdmin
}) => {
    //show contnue
    const [showContinue, setShowContinue] = useState(false);

    return (
        <div className="bg-white rounded-xl p-5 w-full relative
        flex items-center justify-center gap-3 dark:bg-slate-700 sm:flex-col">
            <div className="w-full flex gap-5 md:flex-col sm:flex-col">
                <div className="w-[200px]">
                    <img
                        src={img ? `${process.env.REACT_APP_API_URL}/storage/uploads/img/category/${img}` : defaultImg}
                        className="max-w-[200px] h-10" />
                </div>
                <div>
                    <p className="text-[12px] text-[#989ba5]">ლინკი</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200
                    max-w-[400px] overflow-hidden">{link}</p>
                </div>
            </div>
            <div className="flex gap-2 sm:w-full">
                <Link to={`../category/${id}`} className="w-32 rounded-lg bg-violet-400
                 flex gap-2 items-center justify-center p-1 py-3 sm:w-full">
                    <PencilSquare className="text-white font-bold" />
                    <p className="text-white text-sm font-bold">რედაქტირება</p>
                </Link>
                <div
                    onClick={() => { setShowContinue(true) }}
                    className="w-16 rounded-lg bg-red-400 flex items-center justify-center cursor-pointer">
                    <BsTrash className="text-white" />
                </div>
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDeleteAdmin}
                id={id}
            />
        </div>
    )
}


export default CategoryCard;
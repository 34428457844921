import { useState, useEffect } from "react";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";
import CategoryCard from "./CategoryCard";
import {
  TextInput,
  FileInput,
  SelectInputValueId,
} from "../../inputComponents/inputcomp";
import { useTranslation } from "react-i18next";
import categoryOptions from "./selectData";

const CategoryLayout = () => {
  //translations
  const { t } = useTranslation();
  //add new user variable
  const [addNewToggler, setAddNewToggler] = useState(false);
  //get category data from api
  const [categoryData, setCategoryData] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //ab offices with weight and parcel quantity
  const [foreignAddresses, setForeignAddresses] = useState([]);

  //current category tracker
  const [currentCategory, setCurrentCategory] = useState({
    cat: 1,
  });

  //handle current input
  const handleCurrentInput = (value, inputName) => {
    setCurrentCategory((prev) => ({ ...prev, [inputName]: value }));
  };

  //add new users handlers
  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  //delete category
  const handleDeleteAdmin = async (id) => {
    try {
      const res = await axiosAdmin.get(`/Category/delete/${id}`);
      setDifResponses(res);
      if (res) {
        successToast("კატეგორია წარმატებით წაიშალა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //category data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Category/index`).then(({ data }) => {
        setCategoryData(data);
      });
    } catch (error) {}
  }, [difResponses]);

  //change category data
  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.append("cat", currentCategory.cat);
    formData.append("country", currentCategory.country);

    try {
      const res = axiosAdmin.post("/Category/add_post", formData);
      if (res) {
        setDifResponses(res);
        successToast("კატეგორია წარმატებით დაემატა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  }

  //on page load
  useEffect(() => {
    axiosAdmin
      .get(`/Pack/officeInfo/2`)
      .then((res) => {
        setForeignAddresses(
          res.data
            .map((item) => {
              return {
                value: item.id,
                name: item.title,
              };
            })
            ?.filter((item) => item.value !== 19)
        );
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
      <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5">
        <div className="w-full flex items-center justify-end ">
          <button
            onClick={() => {
              handleAddNewToggle(true);
            }}
            className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400"
          >
            ახალი კატეგორიის დამატება
          </button>
        </div>
        {addNewToggler && (
          <div className="bg-slate-100 dark:bg-slate-900">
            <div className="sm:px-0">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                encType="multipart/form-data"
              >
                <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                  <div className="flex items-center justify-between flex-wrap gap-5">
                    <div className="flex items-center gap-5 sm:gap-3 ">
                      <div>
                        <h2
                          className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]"
                        >
                          ახალი კატეგორიის დამატება
                        </h2>
                      </div>
                    </div>
                    <div className="flex gap-2 sm:w-full">
                      <button
                        type="submit"
                        className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
                      >
                        შენახვა
                      </button>
                      <div
                        onClick={() => {
                          handleAddNewToggle(false);
                        }}
                        className="rounded-lg p-3 text-white text-sm bg-red-400 w-10 cursor-pointer
                                                flex items-center justify-center"
                      >
                        X
                      </div>
                    </div>
                  </div>
                  <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                    <div className="w-6/12 sm:w-full md:w-full">
                      <SelectInputValueId
                        name={"კატეგორია"}
                        options={categoryOptions}
                        inputName={"cat"}
                        inputSetter={handleCurrentInput}
                      />
                    </div>
                    <div className="w-6/12 sm:w-full md:w-full">
                      <TextInput name={"ლინკი"} inputName={"link"} />
                    </div>
                    <div className="w-6/12 sm:w-full md:w-full">
                      <SelectInputValueId
                        name={"ქვეყანა"}
                        options={foreignAddresses}
                        inputName={"country"}
                        inputSetter={handleCurrentInput}
                      />
                    </div>
                  </div>
                  <FileInput name={"ფოტოს ატვირთვა"} inputName={"img"} />
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <div className="w-full min-h-screen">
        <div
          className="flex flex-wrap gap-y-5 p-10 pt-0 sm:p-5 justify-center max-md:justify-center gap-2
                sm:pt-0"
        >
          {categoryData.map((item) => {
            return (
              <CategoryCard
                key={item.id}
                id={item.id}
                link={item.link}
                img={item.img}
                handleDeleteAdmin={handleDeleteAdmin}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CategoryLayout;

import { useState } from "react";
import PhoneMenu from "./PhoneMenu";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//icons
import { TbPlane } from "react-icons/tb";
import { BsList } from "react-icons/bs";
import { BiSend, BiX } from "react-icons/bi";
import { FaBoxes } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";


const BottomMenu = () => {
  //translations
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  //current selected page
  const [currentPage, setCurrentPage] = useState("panelpackageswarehouse");

  const handleOpenMenu = () => {
    setOpenMenu((prev) => !prev);
  };

  const menuData = [
    {
      name: t("parcels"),
      pathName: "panelpackageswarehouse",
      Icon: FaBoxes,
    },
    {
      name: t("flights"),
      pathName: "panelflights",
      Icon: TbPlane,
    },
    {
      name: t("contact"),
      pathName: "panelcontact",
      Icon: BiSend,
    },
    {
      name: t("addresses"),
      pathName: "paneladdresses",
      Icon: HiOutlineLocationMarker,
    },
  ]

  return (
    <>
      {openMenu && <PhoneMenu handleOpenMenu={handleOpenMenu} />}
      <div
        className="fixed bottom-0 left-0 hidden md:flex sm:flex bg-white w-full px-[16px] py-[8px] 
        items-center z-40 justify-between dark:bg-[#171719]"
      >
        <div className="flex items-center justify-evenly gap-2 w-full">
          {menuData.map((data, index) => {
            return (
              <Link to={data.pathName} key={index} onClick={() => { setCurrentPage(data.pathName) }}>
                <div className={`rounded-lg h-10 flex items-center justify-center cursor-pointer gap-3 px-3 
                ${currentPage === data.pathName ? "bg-[#5856D6]/25" : "bg-[#F1F2F7] dark:bg-[#48484a]"}`}>
                  <data.Icon className={`"w-5 h-5 bg-transparent text-[15px] font-semibold text-left
                   ${currentPage === data.pathName ? "text-[#5856D6]" : "text-[#636366] dark:text-[#aeaeb2]"} `} />
                  {currentPage === data.pathName &&
                    <p className="text-[15px] text-[#5856D6]">
                      {data.name}
                    </p>
                  }
                </div>
              </Link>
            )
          })}
          <div
            onClick={handleOpenMenu}
            className={`rounded-lg w-10 flex items-center justify-center cursor-pointer
             h-10 ${openMenu ? "bg-[#ff3a3014]" : "bg-[#F1F2F7] dark:bg-[#48484a]"}`}
          >
            {openMenu ? (
              <BiX
                className="w-5 h-5  bg-transparent 
          font-semibold text-[15px] text-[#FF3A30] text-left"
              />
            ) : (
              <BsList
                className="w-5 h-5  bg-transparent 
            font-semibold text-[15px] text-[#636366] text-left dark:text-[#aeaeb2]"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomMenu;

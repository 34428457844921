const HomeCard = ({ name, value, Icon, color }) => {
  return (
    <div className="flex items-center p-3 rounded-lg gap-5 
    shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px]
    w-[31%] md:w-[48%] sm:w-full bg-white dark:bg-slate-700">
      <div
        className={`w-20 h-20 rounded-full flex items-center justify-center p-5`}
        style={{ background: color }}
      >
        <Icon className="text-white h-full w-full" />
      </div>
      <div>
        <p className=" text-2xl text-[#c6cad6] text-center">{value}</p>
        <p className=" text-[12px] text-[#c6cad6] text-center">{name}</p>
      </div>
    </div>
  );
};

export default HomeCard;

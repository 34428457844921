import { useState, useContext } from "react";
import { Text } from "../Text";
import { Button } from "../Button";
import { Img } from "../Img";
import { HiPlus } from "react-icons/hi";
import BalanceModal from "../BalanceModal/BalanceModal";
import UserPanelSmallMenu from "../UserPnelSmallMenu/UserPanelsmallMenu";
import { PrimmaryContext } from "../MainContext/MainContext";
import { useTranslation } from "react-i18next";

//images
import profileimg from "../../img/profile.jpg";
import DarkModeButton from "../DarkModeButton/DarkModeButton";

const Head = () => {
  //translations
  const { t } = useTranslation();
  //show balance
  const [showBalance, setShowBalance] = useState(false);
  //user data
  const { user, moneyData } = useContext(PrimmaryContext);

  const handleShowBalance = () => {
    setShowBalance((prev) => !prev);
  };

  //small user menu
  const [smallMenuShow, setSmallMenuShow] = useState(false);

  const handleSmallMenuShow = () => {
    setSmallMenuShow((prev) => !prev);
  };
  return (
    <>
      <div
        className="hidden md:flex sm:flex bg-white w-full px-[16px] py-[8px] dark:bg-[#171719] 
    items-center justify-between relative"
      >

        <div className="flex gap-3 relative">
          {smallMenuShow && <UserPanelSmallMenu needHeadRoute={true} closeFunction={handleSmallMenuShow} />}
          <div onClick={handleSmallMenuShow}>
            <Img
              src={
                user.img
                  ? `${process.env.REACT_APP_API_URL}/storage/uploads/img/user/${user.img}`
                  : profileimg
              }
              className="w-10 h-10 rounded-radius50 cursor-pointer"
              alt="Image"
            />
          </div>

          <div className="flex flex-col items-start justify-center">
            <p
              className="cursor-pointer font-fw hover:font-fw504 text-[10px]
             text-black_900_99 text-left dark:text-[#aeaeb2]"
            >
              {user.fname}
            </p>
            <p className="cursor-pointer hover:font-fw504 font-semibold
            text-[15px] hover:text-black_900_99 text-indigo_400 text-left">
              EG{user.id}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          {/* <div className="hidden md:block sm:block">
            <DarkModeButton />
          </div> */}
          <div>
            <div className="flex flex-row gap-[8px] items-start justify-start">
              <Text
                className="font-fw text-black_900_b7 text-left w-[auto] dark:text-[#c7c7c7]"
                variant="body10"
              >
                {t("ballance")}:
              </Text>
              <Text
                className="font-semibold text-left text-teal_A700 w-[auto]"
                variant="body10"
              >
                {moneyData.balance && parseFloat(moneyData.balance).toFixed(2)} ₾
              </Text>
            </div>
            <div className="flex flex-row gap-[8px] items-center justify-start w-[auto]">
              <Text
                className="font-fw text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
                variant="body10"
              >
                {t("payable")}:
              </Text>
              <Text
                className="font-semibold text-left text-red_A400 w-[auto]"
                variant="body10"
              >
                {moneyData.debt && parseFloat(moneyData.debt).toFixed(2)} ₾
              </Text>
            </div>
          </div>
          <Button
            onClick={handleShowBalance}
            className="flex h-[36px] items-center justify-center w-[36px]"
            shape="icbCircleBorder20"
            size="mdIcn"
            variant="icbOutlineBlack9000a_2"
          >
            <HiPlus className="text-white" />
          </Button>
        </div>
      </div>
      <BalanceModal
        showBalance={showBalance}
        handleShowBalance={handleShowBalance}
      />
    </>
  );
};

export default Head;

import { Img } from "../../components/Img";
import { useTranslation } from "react-i18next";

//photo
import expressLogo from "../../img/phoneLogo.png";
//icons
import { BsX } from "react-icons/bs";
import { BiBuildings } from "react-icons/bi";
import { CiBoxes } from "react-icons/ci";
import { SlPlane } from "react-icons/sl";
import { TbMessages } from "react-icons/tb";
import { FaRegPaperPlane } from "react-icons/fa";

const MenuOnePage = ({ menuOpen, handleMenuOpen, allRefs }) => {
  //translation
  const { t } = useTranslation();
  //menu list
  const menuList = [
    {
      name: t("about_company"),
      Icon: BiBuildings,
      ref: allRefs.about,
    },
    {
      name: t("priorities"),
      Icon: CiBoxes,
      ref: allRefs.priority,
    },
    {
      name: t("prices"),
      Icon: SlPlane,
      ref: allRefs.price,
    },
    {
      name: t("services"),
      Icon: TbMessages,
      ref: allRefs.services,
    },
    {
      name: t("contact"),
      Icon: FaRegPaperPlane,
      ref: allRefs.contacts,
    },
  ];

  //handle scrolling on sections
  const handleScrolToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`flex flex-col font-abeezee items-center justify-start mx-[auto] w-full
      fixed top-0 left-0 transition-all duration-500 delay-75 ease-linear md:bg-gray_900 sm:bg-gray_900 
      sm:h-screen md:h-screen sm:transition-none md:transition-none
      ${menuOpen ? "z-50 opacity-1" : "z-10 opacity-0"}`}
    >
      <div className="w-[100%] relative ">
        <div
          className={`flex flex-row md:gap-[40px] sm:gap-[40px] items-start justify-between w-[100%]
        absolute top-0 left-0 p-[20px] transition-all duration-1000 delay-1000 ease-linear md:relative sm:relative 
        md:bg-[#FFFFFF]/10 sm:bg-[#FFFFFF]/10 md:py-4 sm:p-4 sm:transition-none md:transition-none
        ${menuOpen ? "opacity-1 block" : "opacity-0 hidden"}`}
        >
          <div className="flex flex-row gap-[15px] items-center justify-start w-[auto]">
            <Img src={expressLogo} className="h-[40px] w-[auto]" alt="ticket" />
          </div>
          <div className="flex items-center gap-5">
            <div
              onClick={handleMenuOpen}
              className="w-[48px] h-[48px] rounded-full flex items-center justify-center bg-[#747480]/25
              cursor-pointer sm:w-10 sm:h-10 md:w-10 md:h-10"
            >
              <BsX className="text-[24px] text-[#FF453A]" />
            </div>
          </div>
        </div>
        <div
          className="w-full flex flex-row font-firago items-center justify-start md:flex-col sm:flex-col
          md:gap-0 sm:gap-0"
        >
          {menuList.map((item, index) => {
            return (
              <div className="w-[20%] sm:w-full md:w-full" key={index}>
                <div
                  className={`h-screen flex items-center justify-center border-r border-gray-600 overflow-hidden 
          transition-all duration-300 delay-75 ease-linear md:h-auto sm:h-auto md:p-[24px] sm:p-[24px] 
          md:justify-start sm:justify-start sm:transition-none md:transition-none
          ${menuOpen ? "bg-gray_900 w-full" : "w-[0px]"}`}
                >
                  <item.Icon
                    className="text-gray_101 sm:text-[17px] md:text-[19px] font-medium mr-[18px]
              sm:block md:block hidden "
                  />
                  <p
                    onClick={() => {
                      handleMenuOpen();
                      handleScrolToSection(item.ref);
                    }}
                    className={`font-medium text-gray_101 text-left w-[auto] sm:text-[17px] md:text-[19px] text-[21px]
            transition-transform duration-300 delay-300 ease-linear md:h-auto sm:h-auto cursor-pointer 
            sm:transition-none md:transition-none
            ${menuOpen
                        ? "translate-x-[0%] opacit-1"
                        : "translate-x-[-100%] opacity-0"
                      }`}
                  >
                    {item.name}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MenuOnePage;

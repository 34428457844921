import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../Button";
import UserPanelMenuItem from "../UserPanelMenu/UserPanelMenuItem";
import LanguageSwitcherPhone from "../Languages/LanguageSwitcherPhone";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { PrimmaryContext } from "../MainContext/MainContext";

//icons
import { BsEnvelope, BsTruck } from "react-icons/bs";
import { HiOutlineLightBulb, HiOutlineLocationMarker } from "react-icons/hi";
import { BiSend } from "react-icons/bi";
import { FaBoxes, FaStore } from "react-icons/fa";
import { TbPlane } from "react-icons/tb";

//images
import phoneLogo from "../../img/phoneLogo.png";

const PhoneMenu = ({ handleOpenMenu }) => {
  //translations
  const { t } = useTranslation();
  //navigation
  const navigate = useNavigate();
  //from context
  const { setToken, setUser, shouldDeclare } = useContext(PrimmaryContext)

  const location = useLocation();

  const [currentPage, setCurrentPage] = useState("panelpackageswarehouse");
  const handleCurrentPageChange = (pageName) => {
    setCurrentPage(pageName);
  };

  useEffect(() => {
    const currentLocation = location.pathname.split("/");
    setCurrentPage(currentLocation[currentLocation.length - 1]);
  }, []);


  const menuData = [
    {
      name: t("parcels"),
      pathName: "panelpackageswarehouse",
      Icon: FaBoxes,
      hasCounter: true,
      count: shouldDeclare
    },
    {
      name: t("flights"),
      pathName: "panelflights",
      Icon: TbPlane,
    },
    {
      name: t("addresses"),
      pathName: "paneladdresses",
      Icon: HiOutlineLocationMarker,
    },
    {
      name: t("shops"),
      pathName: "panelshops",
      Icon: FaStore,
    },
    {
      name: t("instructions"),
      pathName: "panelfaq",
      Icon: HiOutlineLightBulb,
    },
    {
      name: t("contact"),
      pathName: "panelcontact",
      Icon: BiSend,
    },
    {
      name: t("codes"),
      pathName: "messeges",
      Icon: BsEnvelope,
    },
    {
      name: t("courier_clearance"),
      pathName: "courier-clearance",
      Icon: BsTruck,
    },
  ];

  //logout api
  const handleLogoutApi = async () => {
    try {
      const res = await axiosClient.get(`/Auth/logout`);
    } catch (error) { }
  };


  //logout button functionality
  const handleLogout = () => {
    handleLogoutApi();
    localStorage.removeItem("EXPR_TOKEN");
    setToken({});
    setUser("");
    navigate("/");
  };

  return (
    <div className="fixed top-0 left-0 w-full h-screen z-40 bg-[#F1F2F7] dark:bg-[#0a0a0c]">
      <div className="p-4 bg-white w-full flex items-center justify-between dark:bg-[#171719]">
        <img src={phoneLogo} className="h-8 w-8" />
        <div className="flex items-center gap-4">
          <LanguageSwitcherPhone />
        </div>
      </div>
      <div className="flex flex-col p-4">
        {menuData.map((item, index) => {
          return (
            <UserPanelMenuItem
              key={index}
              name={item.name}
              pathName={item.pathName}
              Icon={item.Icon}
              hasCounter={item.hasCounter}
              currentPage={currentPage}
              handleCurrentPageChange={handleCurrentPageChange}
              bgWhite={false}
              handleOpenMenu={handleOpenMenu}
              count={item.count}
              img={item.pathName === "messeges" ? true : false}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PhoneMenu;
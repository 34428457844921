import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import AbOfficeCard from "./AbOfficeCard";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput, MultyLangualTextInput } from "../../inputComponents/inputcomp";

const AbOfficesLayout = () => {
    //add new user variable
    const [addNewToggler, setAddNewToggler] = useState(false);
    //ab office data from api
    const [abOfficeData, setAbOfficeData] = useState([]);
    //ab office data err from api
    const [abOfficeDataErr, setAbOfficeDataErr] = useState(null);
    //ab office data err from api
    const [abAddErr, setAbAddErr] = useState(null);
    //ab office data err from api
    const [abDellErr, setAbDellErr] = useState(null);
    //affect data change depended on delete and add responses
    const [difResponses, setDifResponses] = useState(null);
    //new ab offices  
    const [newAbOffice, setNewAbOffice] = useState({
        address: "",
        address2: "",
        city: "",
        country: "",
        district: "",
        mail: "",
        neighborhood: "",
        phone: "",
        price: 0,
        title: "",
        titleen: "",
        titleru: "",
        zip: "",
    })
    //clear fields after submit 
    const [makeEmpty, setMakeEmpty] = useState(false);

    //handle filed clear after submission
    const handleMakeEmpty = (value) => {
        setMakeEmpty(value);
    }

    //handle new admin
    const handleNewAbOfficeData = (value, inputName) => {
        setNewAbOffice(prev => ({ ...prev, [inputName]: value }))
    }

    //add new users handlers 
    const handleAddNewToggle = (value) => {
        setAddNewToggler(value);
    }

    //add new ab office
    const handleAbOfficeToDb = async () => {
        try {
            const res = await axiosAdmin.post(`/Ab_office/add_post`, newAbOffice);
            setDifResponses(res);
            if (res) {
                successToast("ოფისი წარმატებით დაემატა!");
                handleMakeEmpty(true);
            }
        } catch (error) {
            setAbAddErr(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //delete ab office
    const handleDeleteAbOffice = async (id) => {
        try {
            const res = await axiosAdmin.get(`/Ab_office/delete/${id}`);
            setDifResponses(res);
            if (res) {
                successToast("ოფისი წარმატებით წაიშალა!");
            }
        } catch (error) {
            setAbDellErr(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //ab office data
    useEffect(() => {
        try {
            axiosAdmin.get(`/Ab_office/index`).then(({ data }) => {
                setAbOfficeData(data);
            });
        } catch (error) {
            setAbOfficeDataErr(error);
        }
    }, [difResponses]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
            <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5" >
                <div className="w-full flex items-center justify-end ">
                    <button
                        onClick={() => { handleAddNewToggle(true) }}
                        className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400 ">
                        ახალი საწყობის დამატება
                    </button>
                </div>
                {addNewToggler &&
                    <div className="bg-slate-100 dark:bg-slate-900 ">
                        <div className="sm:px-0">
                            <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                                <div className="flex items-center justify-between flex-wrap gap-5">
                                    <div className="flex items-center gap-5 sm:gap-3 ">
                                        <div>
                                            <h2 className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]">ახალი საწყობის დამატება</h2>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 sm:w-full">
                                        <button
                                            onClick={handleAbOfficeToDb}
                                            className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                                            შენახვა
                                        </button>
                                        <button
                                            onClick={() => { handleAddNewToggle(false) }}
                                            className="rounded-lg p-3 text-white text-sm bg-red-400 w-10">
                                            X
                                        </button>
                                    </div>
                                </div>
                                < MultyLangualTextInput
                                    name={"დასახელება"}
                                    geInputName={"title"}
                                    enInputName={"titleen"}
                                    ruInputName={"titleru"}
                                    inputSetter={handleNewAbOfficeData}
                                />
                                <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                                    <div className="w-6/12 sm:w-full md:w-full">
                                        <TextInput
                                            name={"ქვეყანა"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"country"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"რაიონი"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"district"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"მისამართი1"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"address"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"ტელეფონი"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"phone"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"ZIP"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"zip"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                    </div>
                                    <div className="w-6/12 sm:w-full md:w-full">
                                        <TextInput
                                            name={"პროვინცია"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"city"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"სამეზობლო"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"neighborhood"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"მისამართი2"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"address2"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"ელ.ფოსტა"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"mail"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"1 კგ. ფასი"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"price"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                            type="number"
                                        />
                                        <TextInput
                                            name={"რეალური / მოცულობითი"}
                                            inputSetter={handleNewAbOfficeData}
                                            inputName={"price_text"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                            type="number"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="w-full min-h-screen">
                <div className="p-10 pt-0 sm:p-5 sm:pt-0 grid grid-cols-3 gap-5 md:grid-cols-2 sm:grid-cols-1">
                    {abOfficeData.map((item) => {
                        return (
                            < AbOfficeCard
                                name={item.title}
                                country={item.country}
                                province={item.city}
                                address={item.address}
                                zip={item.zip}
                                phone={item.phone}
                                key={item.id}
                                id={item.id}
                                handleDeleteAbOffice={handleDeleteAbOffice}
                            />
                        )
                    })}
                </div>
            </div >
        </div>
    )
}

export default AbOfficesLayout;

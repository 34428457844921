import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import { FaFile } from "react-icons/fa";

const InnerYellowCorridorCard = ({
  data,
  handleRemoveFromCorridor,
  handleMoveInCustoms,
  handleDeleteFromYellow,
}) => {
  const [sumPrice, setSumPrice] = useState(0);
  const [sumWeight, setSumWeight] = useState(0);
  const [popup, setPopup] = useState(false);
  const { user } = useContext(AdminPrimaryContext);

  const calculateSum = () => {
    const calculatedSum = data.user_packs[0].total_price.reduce(
      (total, num) => total + parseInt(num),
      0
    );
    setSumPrice(calculatedSum);

    const calculatedSumWeight = data.user_packs[0].total_weight.reduce(
      (total, num) => total + parseInt(num),
      0
    );
    setSumWeight(calculatedSumWeight);
  };

  useEffect(() => {
    if (data.user_packs !== null) calculateSum();
  }, [data]);

  return (
    <div className="bg-white p-2 rounded-lg dark:bg-slate-700">
      <div className="w-full grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 md:gap-2  sm:gap-2 gap-5">
        <div className="w-full">
          <p className="text-[#989ba5] text-[10px]">მომხმარებელი</p>
          {data.user_type === 1 ? (
            <p className="text-[#858890] text-[12px]">
              {data.fname + " " + data.lname} ||{" "}
              {data.fname_eng + " " + data.lname_eng}- id: {data.id} - პ.ნ:{" "}
              {data.id_num}
            </p>
          ) : (
            <p className="text-[#858890] text-[12px]">
              {data.ceo_fname + " " + data.ceo_lname} ||{" "}
              {data.ceo_fname_eng + " " + data.ceo_lname_eng}- id: {data.id} -
              ს.კ: {data.ceo_id_num}
            </p>
          )}
        </div>
        <div className="w-full">
          <p className="text-[#989ba5] text-[10px]">კონტაქტი</p>
          <p className="text-[#858890] text-[12px]">{data.phone}</p>
          <p className="text-[#858890] text-[12px]">{data.email}</p>
        </div>
        <div className="w-full">
          <p className="text-[#989ba5] text-[10px]">ჯამური ფასი : {sumPrice}</p>
          <p className="text-[#989ba5] text-[10px]">
            ჯამური წონა : {sumWeight} კგ
          </p>
          {data.user_packs !== null && (
            <p className="text-[#858890] text-[12px]">
              <span
                className={`${
                  data.user_packs[0].gansabaj[0] === "1"
                    ? "text-[#ff6c60]"
                    : "text-green-400"
                }`}
              >
                {data.user_packs[0].gansabaj[0] === "1"
                  ? " განსაბაჟებელია"
                  : "განბაჟდა"}
              </span>
            </p>
          )}
          <div className="relative">
            <p
              className="text-[#858890] text-[12px] cursor-pointer bg-green-100 mt-1 w-52"
              onClick={() => {
                setPopup((prev) => !prev);
              }}
            >
              დეტალები{" "}
            </p>
            {popup && (
              <div
                className="absolute top-[105%] left-0 bg-white rounded-xl gap-2 grid grid-cols-1 z-40 p-2
                    border border-slate-200"
              >
                {data.user_packs !== null &&
                  data.user_packs[0].tracking_number.map((item, index) => {
                    return (
                      <div key={index} className="flex gap-2 items-center">
                        <p className="text-[#858890] text-[12px] p-2 bg-slate-100 w-full">
                          {item} - {data.user_packs[0].total_weight[index]}კგ -{" "}
                          {data.user_packs[0].total_price[index]}ლ
                        </p>
                        {data.user_packs[0].invoice[index] !== "" && (
                          <Link
                            to={`${process.env.REACT_APP_API_URL}/storage/uploads/pack_files/${data.user_packs[0].invoice[index]}`}
                            target="_blank"
                          >
                            <FaFile className="text-sm cursor cursor-pointer text-green-500 shrink-0" />
                          </Link>
                        )}
                        {data.user_packs[0].ganbajFile[index] !== "" && (
                          <Link
                            to={`${process.env.REACT_APP_API_URL}/storage/uploads/pack_files/${data.user_packs[0].ganbajFile[index]}`}
                            target="_blank"
                          >
                            <FaFile className="text-sm cursor cursor-pointer text-yellow-500 shrink-0" />
                          </Link>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-5 sm:flex-col mt-2 items-center">
        {user.role !== 8 && (
          <>
            {data.user_packs[0].yviteli[0] === "1" && (
              <>
                <div
                  onClick={() => {
                    handleRemoveFromCorridor(data.user_packs[0].ids);
                  }}
                  className="rounded-lg bg-yellow-400 cursor-pointer sm:w-full
                         flex items-center justify-center py-3 px-4 text-white"
                >
                  ყვითელი დერეფნიდან მოხსნა
                </div>
                <div
                  onClick={() => {
                    handleMoveInCustoms(data.user_packs[0].ids);
                  }}
                  className="rounded-lg bg-red-400 cursor-pointer sm:w-full
              flex items-center justify-center py-3 px-4 text-white"
                >
                  განსაბაჟებელში გადატანა
                </div>
              </>
            )}
            {["2", "3"].includes(data.user_packs[0].yviteli[0]) && (
              <div
                className="rounded-lg cursor-pointer sm:w-full
flex items-center justify-center py-3 px-4 text-green-500
border border-slate-300"
              >
                {data.user_packs[0].yviteli[0] === "2"
                  ? "გამწვანდა არაფერი არაა საჭირო"
                  : "ყვითელიდან გადავიდა განსაბაჟებელში"}
              </div>
            )}

            <div
              onClick={() => {
                handleDeleteFromYellow(data.user_packs[0].ids);
              }}
              className="rounded-lg bg-red-500 cursor-pointer sm:w-full
                         flex items-center justify-center py-3 px-4 text-white"
            >
              წაშლა
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InnerYellowCorridorCard;

import { useContext } from "react";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { List } from "../../components/List";
import PanelHead from "../../components/PanelHead/PanelHead";
import FilialCard from "./FilialCard";
import { useTranslation } from "react-i18next";

import { BsEnvelope } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";

import contImg from "../../img/contimg.png";

const PanelcontactPage = () => {
  const { t } = useTranslation();
  const { filialsData, socialMediaData } = useContext(PrimmaryContext);

  return (
    <div
      className="min-h-screen flex flex-1 flex-col font-firago gap-[36px] items-start justify-start
       max-w-[1500px] md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] sm:gap-8 sm:pb-[80px] md:pb-[80px]"
    >
      <PanelHead name={t("contact")} />
      <Text
        className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
          hidden sm:block md:block dark:text-[#c7c7c7]"
        variant="body1"
      >
        {t("contact")}
      </Text>
      <div className="flex flex-col gap-[56px] items-start justify-start w-[100%] md:gap-8 sm:gap-8">
        <List
          className="sm:flex-col flex-row gap-[32px] grid md:grid-cols-2 sm:grid-cols-1 grid-cols-2 justify-start w-[100%]
            sm:gap-6"
          orientation="horizontal"
        >
          <div
            className="flex flex-1 flex-col gap-[24px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]
            md:flex-row sm:flex-row"
          >
            <Button className="flex h-[48px] items-center justify-center w-[48px] dark:bg-[#171719]">
              <BsEnvelope className="font-semibold text-indigo_400 h-[20px] dark:text-[#c7c7c7]" />
            </Button>
            <div className="flex flex-col gap-[20px] items-start justify-start w-[100%] md:gap-0 sm:gap-0">
              <Text
                className="font-firago font-semibold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]
                  md:text-[15px] sm:text-[15px] dark:text-[#c7c7c7]"
                variant="body3"
              >
                {t("write_us")}
              </Text>
              <Text
                className="font-normal font-notosansgeorgian not-italic text-black_900_99 text-left w-[auto] first-letter dark:text-[#aeaeb2]
                  md:hidden sm:hidden"
                variant="body7"
              >
                {t("send_us_an_email_via_mail")}
              </Text>
              <a href={`mailto:${socialMediaData.mailGeneral}`}>
                <Text
                  className="font-notosansgeorgian font-semibold text-indigo_400 text-left w-[auto]"
                  variant="body5"
                >
                  {socialMediaData.mailGeneral}
                </Text>
              </a>
            </div>
          </div>
          <div
            className="flex flex-1 flex-col gap-[24px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]
            md:flex-row sm:flex-row"
          >
            <Button className="flex h-[48px] items-center justify-center w-[48px] dark:bg-[#171719]">
              <FiPhone className="font-semibold text-indigo_400 h-[20px] dark:text-[#c7c7c7]" />
            </Button>
            <div className="flex flex-col gap-[20px] items-start justify-start w-[100%] md:gap-0 sm:gap-0">
              <Text
                className="font-firago font-semibold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]
                  md:text-[15px] sm:text-[15px] dark:text-[#c7c7c7]"
                variant="body3"
              >
                {t("call_us")}
              </Text>
              <Text
                className="font-normal font-notosansgeorgian not-italic text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]
                  md:hidden sm:hidden"
                variant="body7"
              >
                {t("contact_us_only_during_working_hours")}
              </Text>
              <a href={`tel:${socialMediaData.phoneGeneral}`}>
                <Text
                  className="font-notosansgeorgian font-semibold text-indigo_400 text-left w-[auto]"
                  variant="body5"
                >
                  {socialMediaData.phoneGeneral}
                </Text>
              </a>
            </div>
          </div>
        </List>
        <div className="flex md:flex-col sm:flex-col flex-row gap-[32px] items-center justify-start w-[100%]">
          <div className="flex flex-1 flex-col gap-[40px] items-start justify-end w-[100%] sm:gap-8">
            <div className="flex flex-col items-start justify-start w-[100%]">
              <Text
                className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto] dark:text-[#c7c7c7]"
                variant="body1"
              >
                {t("filials")}
              </Text>
            </div>
            <div className="font-notosansgeorgian w-[100%] grid grid-cols-2 sm:grid-cols-1 aftersm:grid-cols-1 gap-5">
              {filialsData.map((item) => {
                return (
                  <FilialCard
                    key={item.id}
                    title={item.title}
                    titleen={item.titleen}
                    hours1={item.hours1}
                    hours2={item.hours2}
                    coords={item.coords}
                    phone={item.phone}
                    mail={item.mail}
                  />
                );
              })}
              <div
                className="w-full self-stretch bg-indigo_400_14 border-2 border-indigo_400 border-dashed
                flex flex-col font-firago items-center justify-center p-[24px] sm:px-[20px] rounded-radius13"
              >
                <Text
                  className="font-medium text-indigo_400 text-left tracking-ls04 
                    md:tracking-ls1 sm:tracking-ls1 w-[auto] "
                  variant="body3"
                >
                  Coming soon
                </Text>
              </div>
              <div className="w-[300px] sm:w-full">
                <img src={contImg} alt="#" className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelcontactPage;

const citiesData = [
    {
        "name_ge": "თბილისი",
        "name_en": "Tbilisi",
    },
    {
        "name_ge": "აბაშა",
        "name_en": "Abasha",
    },
    {
        "name_ge": "ამბროლაური",
        "name_en": "Ambrolauri",
    },
    {
        "name_ge": "ახალქალაქი",
        "name_en": "Akhalkalaki",
    },
    {
        "name_ge": "ახალციხე",
        "name_en": "Akhaltsikhe",
    },
    {
        "name_ge": "ახმეტა",
        "name_en": "Akhmeta",
    },
    {
        "name_ge": "ბათუმი",
        "name_en": "Batumi",
    },
    {
        "name_ge": "ბაღდათი",
        "name_en": "Baghdati",
    },
    {
        "name_ge": "ბოლნისი",
        "name_en": "Bolnisi",
    },
    {
        "name_ge": "ბორჯომი",
        "name_en": "Borjomi",
    },
    {
        "name_ge": "გარდაბანი",
        "name_en": "Gardabani",
    },
    {
        "name_ge": "გორი",
        "name_en": "Gori",
    },
    {
        "name_ge": "გურჯაანი",
        "name_en": "Gurjaani",
    },
    {
        "name_ge": "დედოფლისწყარო",
        "name_en": "Dedoplistskaro",
    },
    {
        "name_ge": "დმანისი",
        "name_en": "Dmanisi",
    },
    {
        "name_ge": "დუშეთი",
        "name_en": "Dusheti",
    },
    {
        "name_ge": "ვალე",
        "name_en": "Vale",
    },
    {
        "name_ge": "ვანი",
        "name_en": "Vani",
    },
    {
        "name_ge": "ზესტაფონი",
        "name_en": "Zestaponi",
    },
    {
        "name_ge": "ზუგდიდი",
        "name_en": "Zugdidi",
    },
    {
        "name_ge": "თეთრიწყარო",
        "name_en": "Tetritskaro",
    },
    {
        "name_ge": "თელავი",
        "name_en": "Telavi",
    },
    {
        "name_ge": "თერჯოლა",
        "name_en": "Terjola",
    },
    {
        "name_ge": "კასპი",
        "name_en": "Kaspi",
    },
    {
        "name_ge": "ლაგოდეხი",
        "name_en": "Lagodekhi",
    },
    {
        "name_ge": "ლანჩხუთი",
        "name_en": "Lanchkhuti",
    },
    {
        "name_ge": "მარნეული",
        "name_en": "Marneuli",
    },
    {
        "name_ge": "მარტვილი",
        "name_en": "Martvili",
    },
    {
        "name_ge": "მცხეთა",
        "name_en": "Mtskheta",
    },
    {
        "name_ge": "ნინოწმინდა",
        "name_en": "Ninotsminda",
    },
    {
        "name_ge": "ოზურგეთი",
        "name_en": "Ozurgeti",
    },
    {
        "name_ge": "ონი",
        "name_en": "Oni",
    },
    {
        "name_ge": "რუსთავი",
        "name_en": "Rustavi",
    },
    {
        "name_ge": "საგარეჯო",
        "name_en": "Sagarejo",
    },
    {
        "name_ge": "სამტრედია",
        "name_en": "Samtredia",
    },
    {
        "name_ge": "საჩხერე",
        "name_en": "Sachkhere",
    },
    {
        "name_ge": "სენაკი",
        "name_en": "Senaki",
    },
    {
        "name_ge": "სიღნაღი",
        "name_en": "Sighnaghi",
    },
    {
        "name_ge": "ტყიბული",
        "name_en": "Tkibuli",
    },
    {
        "name_ge": "ფოთი",
        "name_en": "Poti",
    },
    {
        "name_ge": "ქარელი",
        "name_en": "Kareli",
    },
    {
        "name_ge": "ქობულეთი",
        "name_en": "Kobuleti",
    },
    {
        "name_ge": "ქუთაისი",
        "name_en": "Kutaisi",
    },
    {
        "name_ge": "ყვარელი",
        "name_en": "Kvareli",
    },
    {
        "name_ge": "ცაგერი",
        "name_en": "Tsageri",
    },
    {
        "name_ge": "წალენჯიხა",
        "name_en": "Tsalenjikha",
    },
    {
        "name_ge": "წალკა",
        "name_en": "Tsalka",
    },
    {
        "name_ge": "წნორი",
        "name_en": "Tsnori",
    },
    {
        "name_ge": "წყალტუბო",
        "name_en": "Tskaltubo",
    },
    {
        "name_ge": "ჭიათურა",
        "name_en": "Chiatura",
    },
    {
        "name_ge": "ხაშური",
        "name_en": "Khashuri",
    },
    {
        "name_ge": "ხობი",
        "name_en": "Khobi",
    },
    {
        "name_ge": "ხონი",
        "name_en": "Khoni",
    },
    {
        "name_ge": "ჯვარი",
        "name_en": "Jvari",
    }
]

export default citiesData
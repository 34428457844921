import { useState } from "react";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { Img } from "../../components/Img";
import DeclarationaddedPage from "../Declarationadded/DeclarationaddedPage";
import ArrivedpackagedetailsPage from "../Arrivedpackagedetails/ArrivedpackagedetailsPage";
import axiosClient from "../../api/api";
import { useTranslation } from "react-i18next";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import {
  errorToast,
  successToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";

//images
import chinaImg from "../../img/countryFlags/china.png";
import turkeyImg from "../../img/countryFlags/turkey.png";
import usaImg from "../../img/countryFlags/usa.png";
import greeceImg from "../../img/countryFlags/greece.png";
import uk from "../../img/countryFlags/uk.png";

//icons
import { BsTag } from "react-icons/bs";
import { FaBalanceScale } from "react-icons/fa";
import { AiOutlineInbox } from "react-icons/ai";
import { useContext } from "react";

const WarehouseItemCard = ({ data, setUpdatePackData }) => {
  //translations
  const { t } = useTranslation();
  const [declarationOpen, setDeclarrationOpen] = useState(false);
  const handleDeclarationOpen = () => {
    setDeclarrationOpen((prev) => !prev);
  };
  //payment
  const { setRenewMoneyData } = useContext(PrimmaryContext);

  const [arrPackageDetails, setArrPackageDetails] = useState(false);
  const handleArrPackageDetails = () => {
    setArrPackageDetails((prev) => !prev);
  };

  //handle pay
  const handlePay = () => {
    axiosClient
      .get(`/Pack/pay/${data.id}`)
      .then((res) => {
        setUpdatePackData(res);
        setRenewMoneyData(res);
        successToast(t("payment_success"));
      })
      .catch((err) => {
        errorToast(t("not_enough_money"));
      });
  };

  return (
    <div
      className="bg-white_A700 cursor-pointer flex flex-col items-start justify-center
            outline outline-[2px] outline-white_A700 rounded-radius13 shadow-bs5 w-[100%]
            dark:bg-[#171719] dark:outline-none"
    >
      <div
        className="flex sm:flex-wrap items-center justify-between
          p-[16px] w-[100%] md:items-start sm:items-start sm:gap-2 sm:flex-col  md:flex-wrap"
      >
        <div className="flex flex-row gap-[16px] items-center justify-start w-[auto]">
          <Button
            className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
            variant={"icbFillGray6001e"}
            shape={"circular"}
          >
            <AiOutlineInbox
              className={`font-semibold text-black_900_99 dark:text-[#c7c7c7]`}
            />
          </Button>
          <div className="flex flex-col items-start justify-start w-[auto]">
            <p
              className="text-[16px] font-semibold text-indigo_400 text-left max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap 
            transition-all duration-300 ease-in-out hover:max-w-[400px] hover:whitespace-normal hover:text-[10px]"
            >
              {data && data.tracking_number}
            </p>
          </div>
        </div>
        <div
          className="flex gap-2 items-center justify-end sm:justify-between md:self-stretch sm:self-stretch
                    sm:flex-wrap"
        >
          {data.gansabaj !== 0 && data.gansabaj === 1 ? (
            <Text
              className="font-semibold text-red_A400 text-left w-[auto] sm:w-[49%]"
              variant="body6"
            >
              {t("clearance_0")}
            </Text>
          ) : (
            data.gansabaj === 2 && (
              <Text
                className="font-semibold text-green-500 text-left w-[auto] sm:w-[49%]"
                variant="body6"
              >
                {t("clearance_1")}
              </Text>
            )
          )}

          {data?.yviteli === 1 && (
            <Text
              className="font-semibold text-yellow-500 text-left w-[auto] sm:w-[49%]"
              variant="body6"
            >
              {t("yellow")}
            </Text>
          )}

          {data.transport_price === data.transport_paid ? (
            <Text
              className="font-semibold text-green-500 text-left w-[auto] sm:w-[49%]"
              variant="body6"
            >
              {t("payed")}
            </Text>
          ) : (
            <Button
              onClick={handlePay}
              className="cursor-pointer font-semibold min-w-[111px] text-[15px] text-center text-indigo_400 w-[auto] sm:w-full"
              shape="RoundedBorder11"
              size="xl"
              variant="FillIndigo40014"
            >
              {t("pay")}
            </Button>
          )}
          {data.pack_items.length === 0 ? (
            <Button
              className="common-pointer cursor-pointer font-semibold text-[15px] 
                    text-center text-white_A700 w-[auto] sm:w-full"
              onClick={() => handleDeclarationOpen()}
              shape="RoundedBorder11"
              size="xl"
              variant="OutlineBlack9000a_4"
            >
              {t("declaration")}
            </Button>
          ) : (
            <Button
              onClick={handleArrPackageDetails}
              className="cursor-pointer font-semibold min-w-[111px] text-[15px] text-center text-indigo_400 w-[auto] sm:w-full"
              shape="RoundedBorder11"
              size="xl"
              variant="FillTealA70026"
            >
              {t("details")}
            </Button>
          )}
        </div>
      </div>
      <div
        className="border-black_900_14 border-solid border-t-[1px] flex flex-col dark:border-[#aeaeb2]
          items-center justify-start p-[16px] w-[100%]"
      >
        <div className="flex flex-row bg- black items-center justify-between md:self-stretch sm:self-stretch w-[100%]">
          <div className="flex items-center gap-4 flex-wrap sm:gap-1">
            <div
              className="flex items-center justify-center text-center w-[auto] rounded-radius16
                    px-5 py-[6px] bg-gray_600_14 text-black_900_99 gap-2 sm:gap-1 sm:px-2"
            >
              <FaBalanceScale className="text-center text-indigo_400" />
              <div
                className="bg-transparent cursor-pointer font-normal not-italic 
                    text-[15px] text-black_900_99 text-left"
              >
                <span className="sm:hidden dark:text-[#c7c7c7]">
                  {t("weight")}{" "}
                </span>
                <span className="font-semibold text-left text-green-500 w-[auto]">
                  {data.total_weight} {t("kg")}
                </span>
              </div>
            </div>
            <div
              className="flex items-center justify-center text-center w-[auto] rounded-radius16
                    px-5 py-[6px] bg-gray_600_14 text-black_900_99 gap-2 sm:gap-1 sm:px-2"
            >
              <BsTag className="mr-[10px] text-center text-indigo_400 font-semibold" />
              <div
                className="bg-transparent cursor-pointer font-normal not-italic 
                    text-[15px] text-black_900_99 text-left"
              >
                <span className="sm:hidden dark:text-[#c7c7c7]">
                  {t("transportation")}{" "}
                </span>
                <span
                  className={`font-semibold text-left w-[auto]
                                ${
                                  data.transport_price === data.transport_paid
                                    ? "text-green-500"
                                    : "text-red_A400"
                                }`}
                >
                  ₾ {data.transport_price}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Img
              src={
                data.ao_id === 5 || data.ao_id === 19
                  ? chinaImg
                  : data.ao_id === 1
                  ? turkeyImg
                  : data.ao_id === 16
                  ? usaImg
                  : data.ao_id === 21
                  ? uk
                  : greeceImg
              }
              className="h-[32px] w-[32px] object-contain"
              alt="#"
            />
            {data.ao_id === 19 && (
              <Text className="font-semibold text-indigo_400" variant="body10">
                {t("land")}
              </Text>
            )}
          </div>
        </div>
      </div>
      {declarationOpen && (
        <DeclarationaddedPage
          declarationOpen={declarationOpen}
          handleDeclarationOpen={handleDeclarationOpen}
          data={data}
          setUpdatePackData={setUpdatePackData}
        />
      )}
      {arrPackageDetails && (
        <ArrivedpackagedetailsPage
          arrPackageDetails={arrPackageDetails}
          handleArrPackageDetails={handleArrPackageDetails}
          data={data}
          setUpdatePackData={setUpdatePackData}
        />
      )}
    </div>
  );
};

export default WarehouseItemCard;

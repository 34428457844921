import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { CiReceipt } from "react-icons/ci";
import { FaMoneyBillWave } from "react-icons/fa";
import { useTranslation } from "react-i18next";

//images
import tbcImg from "../../img/tbc.svg";
import bogImg from "../../img/bog.png";
import quickShipperImg from "../../img/quickshipper.png";

const TransactionCard = ({ data }) => {
  //translations
  const { t } = useTranslation();

  return (
    <div
      className="bg-white_A700 flex flex-1 flex-row gap-[24px] sm:gap-[8px] dark:bg-[#171719]
         items-center justify-between outline outline-[1px] outline-black_900_0a p-[24px]
        rounded-radius13 shadow-bs w-[100%] sm:p-[8px]"
    >
      <div className="flex items-center gap-[24px] sm:gap-[8px]">
        {data.type === 2 && (
          <div className="w-[48px] h-[48px] border border-dashed border-indigo_600 p-[3px] rounded-full">
            <Button
              className="flex w-full h-full items-center justify-center rounded-radius50 sm:hidden"
              variant="icbFillIndigo40014"
              shape={"circular"}
            >
              <FaMoneyBillWave className="h-[20px] w-[20px] text-indigo_400" />
            </Button>
          </div>
        )}
        {data.type === 1 && (
          <div className="w-[48px] rounded-radius50  sm:hidden ">
            <img src={tbcImg} className="w-full h-full rounded-radius50" />
          </div>
        )}
        {data.type === 3 && (
          <div className="w-[48px] h-[48px] border border-dashed border-orange-500 p-[3px] rounded-full">
            <div className="w-full h-full p-1 rounded-radius50 sm:hidden bg-orange-100 flex items-center justify-center">
              <img
                src={bogImg}
                className="w-[28px] h-[28px] rounded-radius50 object-contain"
              />
            </div>
          </div>
        )}
        {data.type === 10 && (
          <div className="w-[48px] h-[48px] border border-dashed border-violet-500 p-[3px] rounded-full">
            <div className="w-full h-full p-1 rounded-radius50 sm:hidden bg-violet-100 flex items-center justify-center">
              <img
                src={quickShipperImg}
                className="w-[28px] h-[28px] rounded-radius50 object-contain"
              />
            </div>
          </div>
        )}
        <div className="flex flex-1 flex-col items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
          <p className="font-fw text-black_900_b7 text-left w-[auto] text-[12px] dark:text-[#ededed]">
            {data.date}
          </p>
          <div className="flex flex-row gap-[8px] items-start justify-start w-[auto]">
            <Text
              className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
              variant="body7"
            >
              {data.type === 1
                ? `${t("enroll")}:`
                : data.type === 2
                ? `${t("pay")}:`
                : data.type === 10 && `${t("currior")}:`}
            </Text>
            <Text
              className={`font-semibold text-left w-[auto]
                        ${
                          data.type === 1
                            ? "text-teal_A700"
                            : data.type === 2 && "text-indigo_400"
                        }`}
              variant="body5"
            >
              {data.type === 2 && "-"} {data.amount} ₾
            </Text>
          </div>
          {data.type === 1 && (
            <div className="flex flex-1 flex-col items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
              <p className="font-fw text-black_900_b7 text-left w-[auto] text-[14px] sm:text-[10px] dark:text-[#ededed]">
                {data.trs_code}
              </p>
            </div>
          )}
          <div className="flex flex-row gap-[8px] items-start justify-start w-[auto]">
            <p
              className={`font-semibold text-left w-[auto] text-[12px]
                        ${
                          data.status === 1
                            ? "text-teal_A700"
                            : [0, 2].includes(data.status)
                            ? "text-red-400"
                            : data.status === 3 && "text-orange-400"
                        }`}
            >
              {data.status === 1
                ? t("successful")
                : [0, 2].includes(data.status)
                ? t("unsuccessful")
                : data.status === 3 && t("pending")}
            </p>
          </div>
        </div>
      </div>
      <Button
        className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
        variant="icbFillGray60014"
        shape={"circular"}
      >
        <CiReceipt className="text-black_900_99 font-semibold dark:text-[#ededed]" />
      </Button>
    </div>
  );
};

export default TransactionCard;

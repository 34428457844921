import { useEffect, useState, useContext } from "react";
import { Text } from "../../components/Text";
import { List } from "../../components/List";
import { Button } from "../../components/Button";
import RegistrationPanelPath from "../../components/RegistrationPanelPath/RegistrationPanelPath";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import { useTranslation } from "react-i18next";

//icons
import { FaUser, FaUserTie } from "react-icons/fa";
import { BiChevronRight, BiX } from "react-icons/bi";

const RegistrationmodalstepOnePage = ({
  step,
  handleStep,
  handleRegPersonType,
}) => {
  //translations 
  const { t } = useTranslation();
  //current step variable
  const [currenStep, setCurrentStep] = useState("");
  //register credentials setter function
  const { handleRegCredentials } = useContext(PrimmaryContext);

  //step tracker during change
  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  return (
    <>
      <div
        className={`bg-gray_100 flex flex-col font-firago items-start justify-start mx-[auto] outline 
      outline-[1px] outline-black_900_1e fixed top-0 left-0
      transition-all duration-300 delay-150 ease-linear z-50 popup_container
      ${currenStep === "stepOne" ? "translate-x-[0]" : "translate-x-[100%]"}`}
      >
        <header className="bg-white_A700 border-b-[1px] border-black_900_14 border-solid flex flex-row gap-[16px] items-center justify-center sm:px-[20px] px-[32px] py-[16px] w-[100%]">
          <Text
            className="flex-1 font-bold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1
             sm:tracking-ls1 w-[auto]"
            variant="body3"
          >
            {t("registration")}
          </Text>
          <div
            onClick={() => {
              handleStep("");
            }}
            className="bg-gray_600_14 flex flex-col h-[36px] items-center justify-center rounded-radius50 w-[36px]
            cursor-pointer"
          >
            <BiX className="text-[#FF3A30] text-[22px]" />
          </div>
        </header>
        <div
          className="flex flex-col font-notosansgeorgian gap-[40px] h-[100%] items-start justify-start p-[32px] sm:px-[20px]
         w-[100%] sm:gap-5"
        >
          <RegistrationPanelPath stepOneActive={true} />
          <div className="flex flex-col gap-[24px] items-start justify-start max-w-[1122px] mx-[auto] md:px-[20px] w-[100%]">
            <List
              className="flex-col gap-[24px] grid items-center w-[100%]"
              orientation="vertical"
            >
              <div
                className="bg-white_A700 hover:cursor-pointer flex flex-1 flex-col items-start justify-center 
              hover:outline outline hover:outline-[2px] outline-[2px] hover:outline-white_A700 outline-white_A700 
              rounded-radius13 hover:shadow-bs4 shadow-bs4 w-[100%]"
              >
                <div
                  className="common-pointer flex flex-row gap-[16px] items-center justify-start p-[24px] sm:px-[20px] 
                  rounded-radius13 w-[100%] sm:p-[16px]"
                  onClick={() => {
                    handleStep("stepTwo");
                    handleRegPersonType("physicalPerson");
                    handleRegCredentials(1, "user_type");
                  }}
                >
                  <Button
                    className="flex h-[56px] items-center justify-center rounded-radius50 w-[56px]"
                    size="2xlIcn"
                    variant="icbFillIndigo40014"
                    shape="circular"
                  >
                    <FaUser className={"h-[20px] text-[#5856d6]"} />
                  </Button>
                  <div className="flex flex-1 items-center md:self-stretch sm:self-stretch w-[100%] ">
                    <Text
                      className="font-semibold text-black_900_e0 text-left w-[auto]"
                      variant="body5"
                    >
                      {t("physical_person")}
                    </Text>
                  </div>
                  <BiChevronRight className="h-[24px] w-[24px] text-[#8E8E93]" />
                </div>
              </div>
            </List>
            <div
              className="bg-white_A700 flex flex-col items-start justify-center outline outline-[1px]
             outline-black_900_0a rounded-radius13 shadow-bs w-[100%]"
            >
              <div
                onClick={() => {
                  handleStep("stepTwo");
                  handleRegPersonType("legalEntity");
                  handleRegCredentials(2, "user_type");
                }}
                className="flex flex-row gap-[16px] items-center justify-start p-[24px] sm:px-[20px] rounded-radius13 
              w-[100%] sm:p-[16px] cursor-pointer"
              >
                <Button
                  className="flex h-[56px] items-center justify-center rounded-radius50 w-[56px]"
                  size="2xlIcn"
                  variant="icbFillIndigo40014"
                  shape="circular"
                >
                  <FaUserTie className={"h-[20px] text-[#5856d6]"} />
                </Button>
                <div className="flex flex-1 items-center md:self-stretch sm:self-stretch w-[100%] ">
                  <Text
                    className="font-semibold text-black_900_e0 text-left w-[auto]"
                    variant="body5"
                  >
                    {t("legal_entity")}
                  </Text>
                </div>
                <BiChevronRight className="h-[24px] w-[24px] text-[#8E8E93]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationmodalstepOnePage;

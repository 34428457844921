import { useEffect, useState } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  MultyLangualTextInput,
  SelectInputValueId,
} from "../../inputComponents/inputcomp";
import TextEditor from "../../inputComponents/TextEditor";

const AboutUs = () => {
  //current service
  const [currentService, setCurrentSevice] = useState({
    currServId: 2,
  });
  //data
  const [data, setData] = useState({});
  //data Edit error
  const [updateData, setUpdateData] = useState(null);
  //data updater

  //handle current service
  const handleCurrentService = (value, inputName) => {
    setCurrentSevice((prev) => ({ ...prev, [inputName]: value }));
  };

  //handle new data
  const handleNewData = (value, inputName) => {
    setData((prev) => ({ ...prev, [inputName]: value }));
  };

  //edit data
  const handleEditData = async () => {
    try {
      const res = await axiosAdmin.post(
        `/Pages/news_edit_post/${currentService.currServId}`,
        data
      );
      if (res) {
        successToast("მონაცემები წარმატებით განახლდა!");
        setUpdateData(res);
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //fetch current data
  useEffect(() => {
    axiosAdmin
      .get(`/Pages/show/${currentService.currServId}`)
      .then((data) => {
        setData(data.data);
      })
      .catch((err) => {});
  }, [currentService, updateData]);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900 
        min-h-screen"
    >
      <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5">
        <div className="bg-slate-100 dark:bg-slate-900 mx-5 sm:mx-0">
          <div className="sm:px-0">
            <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
              <div className="flex items-center justify-between flex-wrap gap-5">
                <div className="flex items-center gap-5 sm:gap-3 ">
                  <div>
                    <h2
                      className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]"
                    >
                      ჩვენს შესახებ განახლება
                    </h2>
                  </div>
                </div>
                <div className="flex gap-2 sm:w-full">
                  <button
                    onClick={handleEditData}
                    className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
                  >
                    შენახვა
                  </button>
                </div>
              </div>
              <div className="pt-5 flex gap-5 flex-col">
                <SelectInputValueId
                  name={"სერვისი"}
                  inputSetter={handleCurrentService}
                  inputName={"currServId"}
                  options={[
                    {
                      name: "#1",
                      value: 2,
                    },
                    {
                      name: "#2",
                      value: 25,
                    },
                  ]}
                />
                <MultyLangualTextInput
                  name={"სათაური"}
                  geInputName={"title"}
                  enInputName={"titleen"}
                  ruInputName={"titleru"}
                  inputSetter={handleNewData}
                  value={data.title}
                  valueen={data.titleen}
                  valueru={data.titleru}
                />
                <TextEditor
                  name={"ტექსტი"}
                  geInputName={"text"}
                  enInputName={"texten"}
                  ruInputName={"textru"}
                  getData={handleNewData}
                  geValue={data.text}
                  enValue={data.texten}
                  ruValue={data.textru}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Button } from "../Button";
import CustomTextInut from "../CustomInputs/CustomTextInut";
import { MdClose } from "react-icons/md";
import { Text } from "../Text";
import { errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { FaBalanceScaleLeft } from "react-icons/fa";

const CalculateVolume = ({ weight = null }) => {
  //translations
  const { t } = useTranslation();
  // toggler
  const [isOpen, setIsOpen] = useState(false);
  // values
  const [dimensions, setDimensions] = useState({
    x: 0,
    y: 0,
    z: 0,
  });
  //final value
  const [result, setResult] = useState(0);
  //mode
  const [mode, setMode] = useState("sky");
  //divisor by mode
  const [divisor, setDivisor] = useState(6000);

  const handleDimensions = (value, key) => {
    setDimensions((prev) => ({ ...prev, [key]: value }));
  };

  const handelCalculateVolume = () => {
    const values = Object.values(dimensions);
    const hasZeroValue = values.includes(0);

    function roundToDecimal() {
      let res = (dimensions.x * dimensions.y * dimensions.z) / divisor;
      res = res.toFixed(2);
      if (res.substring(0, res.length - 1).endsWith("0")) {
        return res.substring(0, res.length - 1);
      }
      return (parseFloat(res.substring(0, res.length - 1)) + 0.1).toFixed(1);
    }

    if (dimensions.x && dimensions.y && dimensions.z) {
      if (!hasZeroValue) {
        setResult(roundToDecimal());
      } else {
        errorToast(t("value_cant_0"));
      }
    } else {
      errorToast(t("fields_empty"));
    }
  };

  const handleSetInitialData = () => {
    setDimensions({
      x: 0,
      y: 0,
      z: 0,
    });
    setResult(0);
  };

  return (
    <>
      <button
        className="cursor-pointer font-semibold text-[15px] text-center text-teal_A700 sm:w-full w-[auto]
                rounded-radius9 p-[11px] bg-teal_A700_14"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {weight ? (
          <FaBalanceScaleLeft className="w-5 h-5" />
        ) : (
          t("calc_volume_weight")
        )}
      </button>
      <div
        className={`fixed top-0 right-0 z-50 rounded-l-[16px] flex flex-col justify-between popup 
        outline outline-[1px] outline-black_900_0a text-black_900_e0 transition-all 
        sm:w-full ease-linear ${
          isOpen ? "translate-x-0" : "translate-x-[100%]"
        }`}
      >
        <div
          className="py-[16px] px-[24px] bg-white flex justify-between items-center rounded-tl-[16px] 
            gap-10 dark:bg-[#171719]"
        >
          <Text
            className="text-[18px] flex-1 font-bold text-black_900_e0  dark:text-[#c7c7c7]
                    text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
            variant="body3"
          >
            {t("calc_volume_weight")}
          </Text>
          <Button
            onClick={() => {
              setIsOpen((prev) => !prev);
              handleSetInitialData();
            }}
            className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
            variant="icbFillGray6001e"
            shape="circular"
          >
            <MdClose className="text-black_900_99 font-semibold dark:text-[#c7c7c7]" />
          </Button>
        </div>

        <div className="p-[24px] h-full bg-gray_100 flex flex-col gap-y-5 dark:bg-[#0a0a0c]">
          <div className="grid grid-cols-2 gap-5 ">
            <div
              className={`cursor-pointer p-4 rounded-xl border-solid shadow-bs border 
              ${mode === "sky" ? "bg-green-200" : "bg-white"}`}
              onClick={() => {
                setMode("sky");
                setDivisor(6000);
              }}
            >
              <p className="text-center text-sm">{t("air")}</p>
            </div>
            <div
              className={`cursor-pointer p-4 rounded-xl border-solid shadow-bs border 
              ${mode === "land" ? "bg-green-200" : "bg-white"}`}
              onClick={() => {
                setMode("land");
                setDivisor(4000);
              }}
            >
              <p className="text-center text-sm">{t("land")}</p>
            </div>
          </div>
          <CustomTextInut
            placeholder={`${t("length")} ${t("cm")}`}
            inputName={"x"}
            inputSetter={handleDimensions}
            value={dimensions.x}
            type={"number"}
          />
          <CustomTextInut
            placeholder={`${t("width")} ${t("cm")}`}
            inputName={"y"}
            inputSetter={handleDimensions}
            value={dimensions.y}
            type={"number"}
          />
          <CustomTextInut
            placeholder={`${t("height")} ${t("cm")}`}
            inputName={"z"}
            inputSetter={handleDimensions}
            value={dimensions.z}
            type={"number"}
          />
          <Text
            className="text-[18px] flex-1 font-bold text-black_900_e0  w-full
                     tracking-ls04 md:tracking-ls1 sm:tracking-ls1 text-center dark:text-[#c7c7c7]"
            variant="body3"
          >
            {t("volume_weight")} {result} {t("kg")}
          </Text>
        </div>
        <div className="py-[16px] px-[24px] bg-white flex justify-end  rounded-bl-[16px] dark:bg-[#171719]">
          <Button
            onClick={handelCalculateVolume}
            className="cursor-pointer font-semibold min-w-[118px] 
                    text-[16px] text-center text-white_A700 w-[auto]"
            shape="RoundedBorder11"
            size="3xl"
            variant="OutlineBlack9000a"
          >
            {t("calculate")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CalculateVolume;

import React from "react";
import PropTypes from "prop-types";

const shapes = {
  CircleBorder16: "rounded-radius16",
  RoundedBorder11: "rounded-radius11",
  RoundedBorder8: "rounded-radius8",
  icbRoundedBorder9: "rounded-radius9",
  icbCircleBorder28: "rounded-radius28",
  icbCircleBorder24: "rounded-radius24",
  icbCircleBorder20: "rounded-radius20",
  icbCircleBorder16: "rounded-radius16",
  icbRoundedBorder3: "rounded-radius3",
  icbCircleBorder35: "rounded-radius35",
  circular: "rounded-radius50",
};
const variants = {
  FillGray60014: "bg-gray_600_14 text-black_900_99",
  OutlineBlack9000a:
    "bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700",
  FillTealA70014: "bg-teal_A700_14 text-teal_A700",
  FillTealA70028: "bg-teal_A700_28 text-teal_A701",
  FillIndigo40014: "bg-indigo_400_14 text-indigo_400",
  FillGray6001e: "bg-gray_600_1e text-black_900_99",
  OutlineBlack9000a_1:
    "bg-teal_A700 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700",
  OutlineBlack9000a_2:
    "bg-red_500 outline outline-[1px] outline-black_900_0a shadow-bs2 text-white_A700",
  OutlineBlack9000a_3:
    "bg-white_A700 outline outline-[1px] outline-black_900_0a shadow-bs text-black_900_e0",
  OutlineBlack9000a_4:
    "bg-red_A400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700",
  OutlineIndigo400:
    "bg-indigo_400_14 border border-indigo_400 border-solid text-indigo_400",
  OutlineIndigo400_1:
    "bg-indigo_400_14 outline outline-[0.5px] outline-indigo_400 text-black_900_b7",
  OutlineBlack90014:
    "bg-white_A700 outline outline-[1px] outline-black_900_14 shadow-bs text-red_500",
  OutlineBlack9000a_5:
    "bg-orange_500 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700",
  OutlineIndigo400_2:
    "bg-indigo_400_14 border-2 border-indigo_400 border-solid text-black_900_b7",
  FillIndigoA20019: "bg-indigo_A200_19",
  FillIndigoA700: "bg-indigo_A700 text-white_A700",
  OutlineTealA700: "border-2 border-solid border-teal_A700 text-white_A700",
  FillTealA70026: "bg-teal_A700_26 text-teal_A700",
  FillTealA700: "bg-teal_A700 text-white_A700",
  FillRed501: "bg-red_501 text-white_A700",
  FillIndigoA201: "bg-indigo_A201 text-white_A700",
  OutlineRed501: "border-2 border-red_501 border-solid text-red_501",
  FillIndigoA20033: "bg-indigo_A200_33 text-indigo_A201",
  icbOutlineBlack9000a:
    "bg-white_A700 outline outline-[1px] outline-black_900_0a shadow-bs",
  icbOutlineTealA70028:
    "bg-teal_A701 outline outline-[10px] outline-teal_A700_28",
  icbFillIndigoA20028: "bg-indigo_A200_28",
  icbFillIndigo40014: "bg-indigo_400_14",
  icbOutlineBlack9000a_1:
    "bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs",
  icbFillGray60028: "bg-gray_600_28",
  icbOutlineIndigo400:
    "bg-white_A700 border-2 border-indigo_400 border-solid shadow-bs1",
  icbOutlineBlack9001e: "border-2 border-black_900_1e border-solid",
  icbFillIndigo400: "bg-indigo_400",
  icbOutlineBlack9000a_2:
    "bg-teal_A700 outline outline-[1px] outline-black_900_0a shadow-bs2",
  icbFillRedA40014: "bg-red_A400_14",
  icbFillGray60014: "bg-gray_600_14",
  icbFillTealA70014: "bg-teal_A700_14",
  icbFillRed50014: "bg-red_500_14",
  icbFillOrange50014: "bg-orange_500_14",
  icbFillGray6001e: "bg-gray_600_1e",
  icbOutlineBlack9000a_3:
    "bg-white_A700 outline outline-[1px] outline-black_900_0a shadow-bs2",
  icbFillRed501: "bg-red_501",
  icbFillRed50033: "bg-red_500_33",
  icbOutlineTealA70033:
    "bg-teal_A700 outline outline-[10px] outline-teal_A700_33",
  icbFillIndigoA2005e: "bg-indigo_A200_5e",
  icbFillIndigoA20026: "bg-indigo_A200_26",
  icbOutlineGray90034: "border-2 border-gray_900_34 border-solid",
  icbFillIndigoA20033: "bg-indigo_A200_33",
  icbFillGray101: "bg-gray_101",
  icbFillTealA70033: "bg-teal_A700_33",
  icbOutlineIndigoA201: "border-2 border-indigo_A201 border-solid",
  icbOutlineRed501: "border-2 border-red_501 border-solid",
  icbFillWhiteA700: "bg-white_A700",
  icbOutlineIndigoA2004c: "outline-[1px] outline-dashed outline-indigo_A200_4c",
  icbOutlineRed501_1: "border border-red_501 border-solid",
};
const sizes = {
  sm: "p-[4px]",
  md: "pr-[6px] py-[6px]",
  lg: "pr-[10px] py-[10px]",
  xl: "p-[11px]",
  "2xl": "pl-[14px] py-[14px]",
  "3xl": "p-[14px]",
  "4xl": "pr-[14px] py-[14px]",
  "5xl": "p-[18px]",
  "6xl": "p-[21px] sm:px-[20px]",
  "7xl": "sm:pr-[20px] pr-[35px] py-[35px]",
  smIcn: "p-[6px]",
  mdIcn: "p-[9px]",
  lgIcn: "p-[12px]",
  xlIcn: "p-[15px]",
  "2xlIcn": "p-[18px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant,
  size,
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${shapes[shape] || ""} ${
        variants[variant] || ""
      } ${sizes[size] || ""} `}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf([
    "CircleBorder16",
    "RoundedBorder11",
    "RoundedBorder8",
    "icbRoundedBorder9",
    "icbCircleBorder28",
    "icbCircleBorder24",
    "icbCircleBorder20",
    "icbCircleBorder16",
    "icbRoundedBorder3",
    "icbCircleBorder35",
    "circular",
  ]),
  variant: PropTypes.oneOf([
    "FillGray60014",
    "OutlineBlack9000a",
    "FillTealA70014",
    "FillTealA70028",
    "FillIndigo40014",
    "FillGray6001e",
    "OutlineBlack9000a_1",
    "OutlineBlack9000a_2",
    "OutlineBlack9000a_3",
    "OutlineBlack9000a_4",
    "OutlineIndigo400",
    "OutlineIndigo400_1",
    "OutlineBlack90014",
    "OutlineBlack9000a_5",
    "OutlineIndigo400_2",
    "FillIndigoA20019",
    "FillIndigoA700",
    "OutlineTealA700",
    "FillTealA70026",
    "FillTealA700",
    "FillRed501",
    "FillIndigoA201",
    "OutlineRed501",
    "FillIndigoA20033",
    "icbOutlineBlack9000a",
    "icbOutlineTealA70028",
    "icbFillIndigoA20028",
    "icbFillIndigo40014",
    "icbOutlineBlack9000a_1",
    "icbFillGray60028",
    "icbOutlineIndigo400",
    "icbOutlineBlack9001e",
    "icbFillIndigo400",
    "icbOutlineBlack9000a_2",
    "icbFillRedA40014",
    "icbFillGray60014",
    "icbFillTealA70014",
    "icbFillRed50014",
    "icbFillOrange50014",
    "icbFillGray6001e",
    "icbOutlineBlack9000a_3",
    "icbFillRed501",
    "icbFillRed50033",
    "icbOutlineTealA70033",
    "icbFillIndigoA2005e",
    "icbFillIndigoA20026",
    "icbOutlineGray90034",
    "icbFillIndigoA20033",
    "icbFillGray101",
    "icbFillTealA70033",
    "icbOutlineIndigoA201",
    "icbOutlineRed501",
    "icbFillWhiteA700",
    "icbOutlineIndigoA2004c",
    "icbOutlineRed501_1",
  ]),
  size: PropTypes.oneOf([
    "sm",
    "md",
    "lg",
    "xl",
    "2xl",
    "3xl",
    "4xl",
    "5xl",
    "6xl",
    "7xl",
    "smIcn",
    "mdIcn",
    "lgIcn",
    "xlIcn",
    "2xlIcn",
  ]),
};
Button.defaultProps = {
  className: "",
  shape: "icbRoundedBorder9",
  variant: "icbOutlineBlack9000a",
  size: "lgIcn",
};

export { Button };

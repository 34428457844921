import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import PanelHead from "../../components/PanelHead/PanelHead";
import SingleOptionsNotification from "./SingleOptionNotification";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import { useTranslation } from "react-i18next";

//icons
import { BsEnvelope } from "react-icons/bs";
import { AiOutlineMobile } from "react-icons/ai";

const PanelsettingsnotifiPage = () => {
  //translations
  const { t } = useTranslation();
  //curr notifications
  const [currentNotif, setCurrentNotif] = useState(null);
  //new notifs
  const [newNotif, setNewNotif] = useState(null);
  //notifications updater
  const [updateResonse, setUpdateResponse] = useState(null);

  //get notifications
  const handleFetchNotifications = async () => {
    try {
      const res = await axiosClient.get(`/Notif/index`);
      if (res) {
        setCurrentNotif(res.data);
      }
    } catch (err) {}
  };

  //update notifications
  const handleUpdateNotifications = async () => {
    try {
      const res = await axiosClient.post(`/Notif/update`, newNotif);
      if (res) {
        setUpdateResponse(res);
      }
    } catch (err) {}
  };

  //current notification changer
  const handleChangeCurrentNotif = (value, inputName) => {
    setNewNotif((prev) => ({ ...prev, [inputName]: value }));
  };

  //notification setter / updater
  useEffect(() => {
    handleFetchNotifications();
  }, []);

  useEffect(() => {
    if (newNotif) {
      if (Object.keys(newNotif).length !== 0) {
        handleUpdateNotifications();
      }
    }
  }, [newNotif]);

  return (
    <>
      <div
        className="min-h-screen flex flex-1 flex-col font-firago gap-[36px] items-start justify-start max-w-[1500px]
       md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] sm:gap-8 sm:pb-[80px] md:pb-[80px]"
      >
        <PanelHead name={t("notification")} goBackButton={true} />
        <div className="hidden sm:flex md:flex items-center gap-3">
          <GoBackButton />
          <Text
            className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
            dark:text-[#c7c7c7]
          "
            variant="body1"
          >
            {t("notification")}
          </Text>
        </div>
        <div className="relative w-[100%]">
          <div
            className="flex md:flex-col sm:flex-col flex-row gap-[32px] inset-[0] items-start justify-between m-[auto] w-[100%]
          sm:gap-4"
          >
            <div
              className="bg-white_A700 flex flex-1 flex-col items-start justify-start outline outline-[1px]
             outline-black_900_0a rounded-radius17 md:self-stretch sm:self-stretch shadow-bs w-[100%] dark:bg-[#171719]"
            >
              <div className="flex flex-row gap-[20px] items-center justify-start p-[24px] sm:px-[20px] w-[100%]">
                <Button
                  className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                  variant="icbFillIndigo40014"
                  shape="circular"
                >
                  <BsEnvelope className="h-[20px] w-[20px] text-indigo_400" />
                </Button>
                <Text
                  className="flex-1 font-bold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto] dark:text-[#c7c7c7]"
                  variant="body3"
                >
                  Email {t("notification")}
                </Text>
              </div>
              <div className="flex flex-col font-notosansgeorgian items-start justify-start sm:pl-[20px] w-[100%]">
                <SingleOptionsNotification
                  name={t("parsel_sent")}
                  inputName={2}
                  inputSetter={handleChangeCurrentNotif}
                  handleUpdateNotifications={handleUpdateNotifications}
                  value={currentNotif && currentNotif["2"]}
                />
                <SingleOptionsNotification
                  name={t("parsel_arrived")}
                  inputName={3}
                  inputSetter={handleChangeCurrentNotif}
                  handleUpdateNotifications={handleUpdateNotifications}
                  value={currentNotif && currentNotif["3"]}
                />
                <SingleOptionsNotification
                  name={t("parcel_received")}
                  inputName={4}
                  inputSetter={handleChangeCurrentNotif}
                  handleUpdateNotifications={handleUpdateNotifications}
                  value={currentNotif && currentNotif["4"]}
                />
                <SingleOptionsNotification
                  name={t("advertising") + " Email"}
                  inputName={8}
                  inputSetter={handleChangeCurrentNotif}
                  handleUpdateNotifications={handleUpdateNotifications}
                  value={currentNotif && currentNotif["8"]}
                />
              </div>
            </div>
            <div
              className="bg-white_A700 flex flex-1 flex-col items-start justify-start outline outline-[1px] dark:bg-[#171719]
             outline-black_900_0a rounded-radius17 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
            >
              <div
                className="flex flex-row gap-[20px]
              items-center justify-start p-[24px] sm:px-[20px] w-[100%]"
              >
                <Button
                  className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                  variant="icbFillIndigo40014"
                  shape="circular"
                >
                  <AiOutlineMobile className="h-[20px] w-[20px] text-indigo_400" />
                </Button>
                <Text
                  className="flex-1 font-bold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto] dark:text-[#c7c7c7]"
                  variant="body3"
                >
                  SMS {t("notification")}
                </Text>
              </div>
              <div className="flex flex-col font-notosansgeorgian items-start justify-start sm:pl-[20px] w-[100%]">
                <SingleOptionsNotification
                  name={t("parsel_sent")}
                  inputName={6}
                  inputSetter={handleChangeCurrentNotif}
                  handleUpdateNotifications={handleUpdateNotifications}
                  value={currentNotif && currentNotif["6"]}
                />
                <SingleOptionsNotification
                  name={t("parsel_arrived")}
                  inputName={7}
                  inputSetter={handleChangeCurrentNotif}
                  handleUpdateNotifications={handleUpdateNotifications}
                  value={currentNotif && currentNotif["7"]}
                />
                <SingleOptionsNotification
                  name={t("advertising") + " SMS"}
                  inputName={9}
                  inputSetter={handleChangeCurrentNotif}
                  handleUpdateNotifications={handleUpdateNotifications}
                  value={currentNotif && currentNotif["9"]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PanelsettingsnotifiPage;

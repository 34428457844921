import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { RxPaperPlane } from "react-icons/rx";
import { BiMap } from "react-icons/bi";
import { TbMap2 } from "react-icons/tb";
import { FiPhone } from "react-icons/fi";
import { useContext } from "react";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import { useTranslation } from "react-i18next";

const FilialCard = ({
  hours1, hours2, coords, phone, mail, title, titleen
}) => {
  const { language } = useContext(PrimmaryContext);
  const { t } = useTranslation();
  return (
    <div
      className="w-full h-[100%] bg-white_A700 flex flex-col gap-[24px]
                  items-center justify-center outline outline-[1px] outline-black_900_0a
                  p-[16px] sm:px-[20px] rounded-radius13 shadow-bs sm:w-full dark:bg-[#171719]"
    >
      <div className="flex flex-row gap-[16px] items-center justify-start w-[100%]">
        <Button
          className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
          variant="icbFillIndigo40014"
          shape={"circular"}
        >
          <BiMap className="font-semibold text-indigo_400" />
        </Button>
        <div className="flex flex-1 flex-col items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
          <Text
            className="font-semibold text-black_900_e0 text-left w-[auto] dark:text-[#ededed]"
            variant="body5"
          >
            {language === "ge" ? title : titleen}
          </Text>
        </div>
      </div>
      <div className="flex flex-col gap-[20px] items-start justify-start w-[100%]">
        <div className="flex flex-col gap-[8px] items-start justify-start w-[100%]">
          <div className="flex flex-row gap-[16px] items-start justify-start w-[100%]">
            <Text
              className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
              variant="body7"
            >
              {t("mon_fri")}:{" "}
            </Text>
            <Text
              className="flex-1 font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
              variant="body7"
            >
              {hours1}
            </Text>
          </div>
          <div className="flex flex-row gap-[16px] items-start justify-start w-[100%]">
            <Text
              className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
              variant="body7"
            >
              {t("saturdey")}:{" "}
            </Text>
            <Text
              className="flex-1 font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
              variant="body7"
            >
              {hours2}
            </Text>
          </div>
        </div>
        <div className="flex flex-row gap-[16px] items-center justify-start w-[100%]">
          <Button
            className="flex flex-1 items-center justify-center text-center w-[100%]"
            leftIcon={
              <TbMap2 className="mr-[8px] text-center font-semibold text-indigo_400" />
            }
            shape="RoundedBorder11"
            size="lg"
            variant="FillIndigo40014"
          >
            <a href={coords} target="_blank">
              <div className="bg-transparent cursor-pointer font-fw text-[15px] text-indigo_400 text-left">
                {t("viev_on_the_map")}
              </div>
            </a>
          </Button>
          <a href={`tel:${phone}`}>
            <Button
              className="flex h-[40px] items-center justify-center w-[40px]"
              variant="icbOutlineBlack9000a_1"
            >
              <FiPhone className="text-white" />
            </Button>
          </a>
          <a href={`mailto:${mail}`}>
            <div
              className="bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs
                              flex h-[40px] items-center justify-center w-[40px] rounded-[8px]"
            >
              <RxPaperPlane className="text-white" />
            </div>
          </a>
          <div className="flex items-center gap-[16px] sm:gap-2"></div>
        </div>
      </div>
    </div>
  );
};

export default FilialCard;

import { useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import axiosAdmin from "../../../api/apiAdmin";

import { PrimmaryContext } from "../../../components/MainContext/MainContext";
import { GrClose } from "react-icons/gr";

import express_logo from "../../../img/expresLogo.png";
import stamp from "../../../img/stamp/stamp.png";

const TransportationInvoice = ({
  showInvoice,
  setShowInvoice,
  status,
  globalSearchValue,
  flightNum,
  currentPlaceId,
}) => {
  //toggler
  const [isOpen, setIsOpen] = useState(null);
  //translations
  const { t } = useTranslation();
  //reference to invoice
  const innerRef = useRef();

  //date
  const [date, setDate] = useState(0);

  //set invoice open value
  useEffect(() => {
    setIsOpen(showInvoice);
  }, [showInvoice]);

  //date effect
  useEffect(() => {
    const thisDate = new Date().toLocaleDateString();
    setDate(thisDate);
  }, []);

  //parcel data
  const [data, setData] = useState([]);
  //total sum
  const [sum, setSum] = useState(0);
  //user
  const [user, setUser] = useState(0);

  //get transportation data
  const handleGetTransportationData = () => {
    axiosAdmin
      .get(
        `/Packebi?pack_status=${status}&key=${
          globalSearchValue && globalSearchValue.key
        }&flight_number=${flightNum && flightNum.flight}` +
          `&ao_id=${currentPlaceId ? currentPlaceId : ""}`
      )
      .then(({ data }) => {
        setSum(data.sum);
        setData(data.data.data);
        if (data.data.data.length !== 0) setUser(data.data.data[0].user);
      })
      .catch((err) => {});
  };

  //get data based on user
  useEffect(() => {
    handleGetTransportationData();
  }, [status, globalSearchValue, flightNum, currentPlaceId]);

  return (
    <>
      {isOpen && (
        <div
          className={`popup_container2 bg-black bg-opacity-80 flex flex-col font-firago items-start justify-start outline
        outline-[1px] outline-black_900_1e fixed bottom-0 left-0 z-50 overflow-y-auto overflow-x-hidden scrollbar-none p-10`}
        >
          <div className="flex flex-col w-full bg-white p-5 rounded-lg">
            <div className="w-full flex justify-end gap-5 items-center p-5">
              <ReactToPrint
                trigger={() => (
                  <button className="w-8 h-8 cursor-pointer">
                    <HiOutlineDocumentDownload className="text-green-500 w-full h-full font-bold" />
                  </button>
                )}
                content={() => innerRef.current}
              />
              <GrClose
                onClick={() => {
                  setShowInvoice(false);
                }}
                className="h-[36px] w-[24px] text-black_900_b7 cursor-pointer"
              />
            </div>
            <div className="flex h-full items-start justify-start w-[100%]">
              <div className="max-w-[1000px] mx-[auto] w-[100%] overflow-auto scrollbar">
                <div ref={innerRef} className=" p-5 overflow-auto sm:w-[800px]">
                  <div
                    className="w-full flex items-center justify-between
            border-b border-gray-300 pb-5"
                  >
                    <p className="font-bold text-[#5c54ff] text-lg">ინვოისი</p>
                    <img
                      src={express_logo}
                      alt="expressgroup"
                      className="w-[150px]"
                    />
                  </div>
                  <div className="w-full flex gap-10 mt-5">
                    <div>
                      <p className="font-bold text-lg text-[#5c54ff] underline">
                        რეალიზატორი
                      </p>
                      <p className="text-sm text-[#5c54ff]">
                        ს/ნ: <span className="text-gray-400">405427364</span>
                      </p>
                      <p className="text-sm text-[#5c54ff]">
                        მისამართი:{" "}
                        <span className="text-gray-400">
                          ქ.თბილისი, გაზაფხულის ქ.#4
                        </span>
                      </p>
                      <p className="text-sm text-[#625ebc]">
                        ტელ:{" "}
                        <span className="text-gray-400">032 2 197 192</span>
                      </p>
                    </div>
                    <div>
                      <p className="font-bold text-lg text-[#5c54ff] underline">
                        საბანკო რეკვიზიტები:
                      </p>
                      <p className="text-sm text-[#5c54ff]">
                        ბანკი:{" "}
                        <span className="text-gray-400">ს.ს. თიბისი ბანკი</span>
                      </p>
                      <p className="text-sm text-[#5c54ff]">
                        ბანკის კოდი:{" "}
                        <span className="text-gray-400">TBCBGE22</span>
                      </p>
                      <p className="text-sm text-[#5c54ff]">
                        ანგარიშის ნომერი:{" "}
                        <span className="text-gray-400">
                          GE87TB7593136080100010
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-[#5c54ff]">
                        ინვოისი N:{" "}
                        <span className="text-gray-400">{user.id}</span>
                      </p>
                    </div>
                  </div>
                  <div className="w-full mt-5">
                    <div className="grid grid-cols-2  p-2 border-b border-gray-300">
                      <p className="text-sm text-[#5c54ff]">მყიდველი:</p>
                      <div className="w-full flex items-center">
                        <p className="text-sm text-gray-400">
                          {user && user.user_type === 1
                            ? user.fname + " " + user.lname
                            : user.ceo_fname + " " + user.ceo_lname}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2  p-2 border-b border-gray-300">
                      <p className="text-sm text-[#5c54ff]">ს/კ:</p>
                      <div className="w-full flex items-center">
                        <p className="text-sm text-gray-400">
                          {user && user.user_type === 1
                            ? user.id_num
                            : user.company_id}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-3 bg-[#5c54ff] p-2 border-b border-gray-300 mt-5">
                      <div className="w-full flex items-center">
                        <p className="font-bold text-md text-white">
                          ტრანსპორტირების მომსახურება
                        </p>
                      </div>
                      <div className="w-full flex items-center">
                        <p className="font-bold text-md text-white">წონა</p>
                      </div>
                      <div className="w-full flex items-center">
                        <p className="font-bold text-md text-white">ფასი</p>
                      </div>
                    </div>
                    {data.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="grid grid-cols-3 p-2 border-b border-gray-300"
                        >
                          <div className="w-full flex items-center ">
                            <p className="text-sm text-gray-400 ">
                              {item.tracking_number}
                            </p>
                          </div>
                          <div className="w-full flex items-center">
                            <p className="text-sm text-gray-400">
                              {item.total_weight}
                            </p>
                          </div>
                          <div className="w-full flex items-center ">
                            <p className="text-sm text-gray-400">
                              {item.transport_price} GEL
                            </p>
                          </div>
                        </div>
                      );
                    })}
                    <div className="grid grid-cols-3 p-2 border-b border-gray-300">
                      <div className="w-full flex items-center ">
                        <p className="text-sm text-gray-400 "> ჯამი</p>
                      </div>
                      <div className="w-full flex items-center"></div>
                      <div className="w-full flex items-center ">
                        <p className="text-sm text-red-500">{sum} GEL</p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-end mt-5">
                    <p className="font-bold text-lg text-red-500">
                      თანხა შეიცავს დღგ-ს
                    </p>
                  </div>
                  <div className="w-full mt-5 flex items-center gap-5 justify-between">
                    <div className="items-center rounded-lg p-2">
                      <p className="font-bold text-md mb-2 text-[#5c54ff]">
                        პატივისცემით შპს "ექსპრეს გრუპი"
                      </p>
                    </div>
                    <div>
                      <img src={stamp} className="w-20 h-20" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TransportationInvoice;

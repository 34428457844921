import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { MultyLangualTextInput, TextInput } from "../../inputComponents/inputcomp";

//images
import logo from '../../../img/phoneLogo.png';

const InnerContactFilialsPage = () => {
    //get current user id 
    const { contFilialId } = useParams();
    //new filial data
    const [filialData, setFilialData] = useState({
        coords: "",
        hours1: "",
        hours2: "",
        mail: "",
        phone: "",
        title: "",
        titleen: "",
        titleru: "",
    });
    //get filial data error from api
    const [filialDataErr, setFilialDataErr] = useState(null);
    //data Updater
    const [dataUpdater, setDataUpdater] = useState(null);

    //handle new filial
    const handleNewFilialData = (value, inputName) => {
        setFilialData(prev => ({ ...prev, [inputName]: value }))
    }

    //add new filial 
    const handleEditFilial = async () => {
        try {
            const res = await axiosAdmin.post(`/Pages/contact2_edit_post/${contFilialId}`, filialData);
            if (res) {
                successToast("მონაცემები წარმატებით განახლდა!");
                setDataUpdater(res);
            }
        } catch (error) {
            setFilialDataErr(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //filial data from api
    useEffect(() => {
        try {
            axiosAdmin.get(`/Pages/contact2_edit/${contFilialId}`).then(({ data }) => {
                setFilialData(data);
            });
        } catch (error) {
            setFilialDataErr(error);
        }
    }, [dataUpdater]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <div className="sm:px-0">
                <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                    <div className="flex items-center justify-between flex-wrap gap-5">
                        <div className="flex items-center gap-5 sm:gap-3">
                            <div className="w-10 h-10 ">
                                <img src={logo} className="w-full h-full rounded-full"></img>
                            </div>
                            <div>
                                <h2 className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]">საწყობის დამატება</h2>
                            </div>
                        </div>
                        <button
                            onClick={handleEditFilial}
                            className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                            შენახვა
                        </button>
                    </div>
                    <MultyLangualTextInput
                        name={"მისამართი"}
                        geInputName={"title"}
                        enInputName={"titleen"}
                        ruInputName={"titleru"}
                        inputSetter={handleNewFilialData}
                        value={filialData.title}
                        valueen={filialData.titleen}
                        valueru={filialData.titleru}
                    />
                    <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                        <div className="w-6/12 sm:w-full md:w-full">
                            <TextInput
                                name={"სამუშაო საათები ორშაბათი პარასკევი"}
                                inputName={"hours1"}
                                inputSetter={handleNewFilialData}
                                value={filialData.hours1}
                            />
                            <TextInput
                                name={"ტელეფონი"}
                                inputName={"phone"}
                                inputSetter={handleNewFilialData}
                                value={filialData.phone}
                            />
                        </div>
                        <div className="w-6/12 sm:w-full md:w-full">
                            <TextInput
                                name={"სამუშაო საათები შაბათი"}
                                inputName={"hours2"}
                                inputSetter={handleNewFilialData}
                                value={filialData.hours2}
                            />
                            <TextInput
                                name={"ელ. ფოსტა"}
                                inputName={"mail"}
                                inputSetter={handleNewFilialData}
                                value={filialData.mail}
                            />
                        </div>
                    </div>
                    <TextInput
                        name={"რუკის კოორდინატები"}
                        inputName={"coords"}
                        inputSetter={handleNewFilialData}
                        value={filialData.coords}
                    />
                </div>
            </div>
        </div>
    )
}

export default InnerContactFilialsPage;
import { useState } from "react";
import { Button } from "../Button";
import { BsGlobe } from "react-icons/bs";
import { useEffect } from "react";
import { useContext } from "react";
import { PrimmaryContext } from "../MainContext/MainContext";

const LanguageSwitcherPhone = () => {
    const { setLanguage, language } = useContext(PrimmaryContext);
    const [show, setShow] = useState(false);
    const [value, setValue] = useState(language);
    const languages = ["ge", "en", "ru"];

    const handleShow = () => {
        setShow(prev => !prev);
    }

    useEffect(() => {
        setLanguage(value);
    }, [value])

    return (
        <div className="relative">
            <Button
                onClick={handleShow}
                shape={"circular"}
                className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px] bg-[#F1F2F7] dark:bg-[#48484a]
                cursor-pointer"
            >
                <BsGlobe className="cursor-pointer text-[24px] text-[#636366] dark:text-[#c7c7c7]" />
            </Button>
            {show &&
                <div className="rounded-lg absolute top-[105%] left-0 bg-white shadow-md border border-gray-300 w-full p-2 dark:bg-[#48484a]">
                    {languages.map((value, id) => {
                        return (
                            <p key={id}
                                onClick={() => { handleShow(); setValue(value) }}
                                className="text-[#5856D6] hover:text-[#3735b0] text-[16px] font-bold text-center
                                cursor-pointer uppercase">
                                {value}
                            </p>
                        )
                    })}
                </div>
            }
        </div >
    )
}

export default LanguageSwitcherPhone;
import { useContext, useState } from "react";
import { Text } from "../Text";
import { Img } from "../Img";
import UserPanelSmallMenu from "../UserPnelSmallMenu/UserPanelsmallMenu";
import { PrimmaryContext } from "../MainContext/MainContext";

//icons
import { HiChevronDown } from "react-icons/hi";

//images
import profileimg from "../../img/profile.jpg";
import GoBackButton from "../GoBackButton/GoBackButton";
import DarkModeButton from "../DarkModeButton/DarkModeButton";

const PanelHead = ({ name, goBackButton = false }) => {
  //user
  const { user } = useContext(PrimmaryContext);
  //menu show hide
  const [smallMenuShow, setSmallMenuShow] = useState(false);

  //show menu toggler function
  const handleSmallMenuShow = () => {
    setSmallMenuShow((prev) => !prev);
  };

  return (
    <header
      className="border-b-[1px] border-black_900_14 border-solid flex sm:flex-col dark:border-[#aeaeb2]
         flex-row gap-[16px] items-center justify-between pb-[16px] pt-[20px] w-[100%] max_sm:pt-[0px!important]
         md:px-[16px] sm:px-[16px] md:hidden sm:hidden"
    >
      <div className="flex gap-3 items-center">
        {goBackButton && <GoBackButton />}
        <Text
          className="flex-1 text-black_900_b7 text-left tracking-ls064 md:tracking-ls1 sm:tracking-ls1 w-[auto]
                max_sm:order-2 max_sm:w-[100%!important] dark:text-[#c7c7c7]"
          as="h4"
          variant="h4"
        >
          {name}
        </Text>
      </div>
      <div
        className="flex font-notosansgeorgian gap-[16px] items-center
            max_sm:w-full max_sm:gap-[6px!important] max_sm:justify-between max_sm:order-1"
      >
        {/* <DarkModeButton /> */}
        <div className="relative">
          <div
            onClick={handleSmallMenuShow}
            className="bg-white_A700 flex flex-row gap-[12px] items-center  dark:bg-[#171719]
                outline outline-[1px] outline-black_900_0a pl-[4px] pr-[12px] max_sm:px-[4px !important]
                py-[4px] rounded-[40px]  shadow-bs common-row-list max_sm:justify-center"
          >
            <div className="w-[28%] rounded-radius50">
              <Img
                src={
                  user.img
                    ? `${process.env.REACT_APP_API_URL}/storage/uploads/img/user/${user.img}`
                    : profileimg
                }
                className="w-10 h-10 rounded-radius50"
                alt="Image"
              />
            </div>
            <div className="my-[2px] max_sm:w-[auto!important]">
              <div className="flex flex-col items-start justify-start">
                <p className="cursor-pointer font-fw hover:font-fw504 text-[10px] text-black_900_99 text-left dark:text-[#aeaeb2]">
                  {user.fname}
                </p>
                <p className="cursor-pointer hover:font-fw504 font-semibold text-[15px] hover:text-black_900_99 text-indigo_400 text-left">
                  EG{user.id}
                </p>
              </div>
            </div>
            <div className="w-[14%] my-[10px]">
              <HiChevronDown className="cursor-pointer text-[24px] text-black_900_99 dark:text-[#aeaeb2]" />
            </div>
          </div>
          {smallMenuShow && <UserPanelSmallMenu />}
        </div>
      </div>
    </header>
  );
};

export default PanelHead;

import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import {
  CheckBox,
  SelectInput,
  SelectInputValueId,
} from "../../../inputComponents/inputcomp";
import axiosAdmin from "../../../../../api/apiAdmin";
import AppeardPackagesItem from "./AppeardPackagesItem";
import PaginatedItems from "../../../Pagination/Pagination";
import { BsX } from "react-icons/bs";

//icons
import { FaRegHandPointRight } from "react-icons//fa";
import ContinueComponent from "../../../../../components/Alerts/ContinueComponent";
import SeeParcelPlaces from "../../../seeParcelPlaces/SeeParcelPlaces/SeeParcelPlaces";

const AppeardPackages = () => {
  //current filial
  const [currentPlaceId, setCurrentPlaceId] = useState(1);
  //branches data
  const {
    user,
    globalSearchValue,
    handleSearchValue,
    handleSearchTransport,
    flights,
    setGlobalAoId,
  } = useContext(AdminPrimaryContext);
  //package data
  const [packageData, setPackageData] = useState([]);
  //package dataErr
  const [packageDataErr, setPackageDataErr] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //package check all
  const [checkAll, setCheckAll] = useState(false);
  //selected Packages
  const [selectedPackages, setSelectedPackages] = useState({
    pack_id: [],
  });
  //current page
  const [currentPage, setCurrentPage] = useState(0);
  //show continue
  const [showContinue, setShowContinue] = useState(false);
  //send with flights
  const [showContinue2, setShowContinue2] = useState(false);
  //total records
  const [total, setTotal] = useState(0);
  //flight num
  const [flightNum, setFlightNum] = useState(null);
  //ab offices with weight and parcel quantity
  const [foreignAddresses, setForeignAddresses] = useState([]);
  //selected branch
  const [selectedBranch, setSelectedBranch] = useState("");
  //get filial data from api
  const [filialData, setFilialData] = useState([]);
  const [visible, setVisible] = useState("false");

  //handle branch selecting
  const handleSelectBranch = (value, _) => {
    setSelectedBranch(value);
  };

  //handle flight number change
  const handleFlightNum = (value, key) => {
    setFlightNum(value);
  };

  //handle selected Packages
  const handleSelectedPackages = (value) => {
    if (!selectedPackages.pack_id.includes(value)) {
      setSelectedPackages((prev) => ({ pack_id: [...prev.pack_id, value] }));
    }
  };
  //handle delete selected Packages
  const handleDeleteSelectedPackages = (value) => {
    setSelectedPackages((prev) => ({
      pack_id: prev.pack_id.filter((obj) => {
        return obj !== value;
      }),
    }));
  };

  //handle checking
  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);
  };

  //current filial handler
  const handleCurrentPlaceId = (value) => {
    setGlobalAoId(value);
    setCurrentPlaceId(value);
    setCheckAll(false);
    setSelectedPackages({
      pack_id: [],
    });
  };

  //const handle withdrawal package
  const handleSendAllWithdrawalPackages = async () => {
    setShowContinue(false);
    try {
      const res = await axiosAdmin.post(`/Pack/set_out`, selectedPackages);
      if (res) {
        successToast("მოქმედება წარმატებით განხორციელდა!");
        setCheckAll(false);
        setSelectedPackages({
          pack_id: [],
        });
        setDifResponses(res);
      }
    } catch (err) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //handle delete package
  const handleDeletePackage = async (id) => {
    try {
      const res = await axiosAdmin.get(`/Pack/delete/${id}`);
      if (res) {
        setDifResponses(res);
        successToast("ამანათი წარმატებით წაიშალა!");
      }
    } catch (err) {
      setPackageDataErr(err);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //handle delete package
  const handleShowAllPackages = async () => {
    try {
      const res = await axiosAdmin.post(`/Pack/show_to_user`, selectedPackages);
      if (res) {
        successToast("წარმათებით გამოჩნდა მომხმარებლისთვს!");
        setCheckAll(false);
        setSelectedPackages({
          pack_id: [],
        });
        setDifResponses(res);
      }
    } catch (err) {
      setPackageDataErr(err);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //parcel function
  const handleParcelThings = () => {
    axiosAdmin
      .get(
        `/Pack/get/4/${currentPlaceId}/30/${currentPage + 1}?key=${
          globalSearchValue.key
        }` +
          `&transport=${
            globalSearchValue.transport
          }&show_user=${visible}&flight_number=${
            flightNum === null ? "" : flightNum
          }` +
          `&branchId=${selectedBranch !== "" ? selectedBranch : ""}`
      )
      .then((res) => {
        setPackageData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //show parcels to users with specific flight number to the next section / stage
  const handleShowWithFlightNum = () => {
    axiosAdmin
      .post(`/gamoachine?branch_id=${selectedBranch}`, {
        flight_num: flightNum,
      })
      .then((res) => {
        setDifResponses(res);
        successToast("ამანათები გამოუჩნდა მომხარებლებს!");
      })
      .catch(() => {
        errorToast(
          "რეისის ნომერი არასწორია ან დაფიქსირდა გაუთვალისწინებელი შეცდომა ცადეთ ხელახლა!"
        );
      });
  };

  //move parcel to previous status
  const handleMoveBack = (parcelId) => {
    axiosAdmin
      .get(`/Pack/back/${parcelId}`)
      .then((res) => {
        setDifResponses(res);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //when scanner is used
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleParcelThings();
    }
  };

  //show parcels from every ab_office
  const handleShowAllPackage = () => {
    setCurrentPlaceId(0);
  };

  //package data fetching
  useEffect(() => {
    handleParcelThings();
  }, [
    currentPlaceId,
    difResponses,
    globalSearchValue,
    currentPage,
    visible,
    flightNum,
    selectedBranch,
  ]);

  //when page changes change initial data
  useEffect(() => {
    if (selectedPackages.pack_id.length > 0) {
      setCheckAll(false);
      setSelectedPackages({
        pack_id: [],
      });
    }
  }, [currentPage]);

  //on page load
  useEffect(() => {
    axiosAdmin
      .get(`/Pack/officeInfo/4`)
      .then((res) => {
        setForeignAddresses(res.data);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  }, []);

  //filial data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Branches/index`).then(({ data }) => {
        setFilialData(
          data.map((item) => {
            return {
              value: item.id,
              name: item.title,
            };
          })
        );
      });
    } catch (error) {
      setPackageDataErr(error);
    }
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className=" mb-5 p-2 overflow-x-auto scrollbar relative">
        <div className="min-w-[1000px] w-full flex gap-4">
          {foreignAddresses.map((item) => {
            return (
              <div
                key={item.id}
                className={`w-full flex items-center p-3 rounded-lg gap-5 cursor-pointer
                    shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px] 
                    ${
                      currentPlaceId === item.id
                        ? "border border-green-500 bg-green-100"
                        : "bg-white dark:bg-slate-700 "
                    }`}
              >
                <div className="w-full flex flex-col gap-2 justify-between h-full">
                  <p className="text-[14px] text-[#8e95a8]">{item.title}</p>
                  <p className="text-[14px] text-[#8e95a8]">
                    {`${item.country} ${item.city}`}
                  </p>
                  <p className="text-[14px] text-[#8e95a8]">{item.address}</p>
                  <p className="text-[14px] text-[#8e95a8]">{item.phone}</p>
                  <p className="text-[14px] text-[#8e95a8]">
                    {`${item.pack_count}`} parcel /{" "}
                    {`${item.total_weight.toFixed(2)}`} kg
                  </p>
                  <div className="flex gap-1">
                    <div
                      onClick={() => {
                        handleCurrentPlaceId(item.id);
                      }}
                      className="w-full rounded-lg bg-green-400
                 flex items-center justify-center gap-1 p-1 py-3 text-white"
                    >
                      <FaRegHandPointRight />
                      <p>აირჩიე</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="absolute top-0 right-0 bg-red-500 w-8 h-8 rounded-full p-1 cursor-pointer"
          onClick={handleShowAllPackage}
        >
          <BsX className="w-full h-full text-white" />
        </div>
      </div>
      <h2 className="text-slate-400 text-2xl">ჩამოსული ამანათები</h2>
      <div className="w-full">
        <div className="bg-yellow mt-2 flex gap-1 sm:flex-col">
          <div
            className="bg-white  pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-9/12 sm:w-full"
          >
            <input
              type="search"
              placeholder="ძებნა"
              value={globalSearchValue.key}
              onKeyDown={handleKeyPress}
              onChange={(e) => {
                handleSearchValue(e, "key");
              }}
              className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
            />
          </div>
          {(user.role === 100 || user.role === 1) && (
            <Link
              to={`${
                process.env.REACT_APP_API_URL
              }/api/admin/Pack/export/4/${currentPlaceId}/30/0?key=${
                globalSearchValue.key ? parseInt(globalSearchValue.key) : ""
              }`}
              className="w-3/12 rounded-lg bg-green-400 sm:w-full
                 flex items-center justify-center p-1 py-3 text-white"
            >
              EXPORT CSV
            </Link>
          )}
          <SeeParcelPlaces parcels={packageData} />
        </div>
        <div className="flex gap-2 items-center sm:order-2 mt-3">
          <CheckBox
            name={"ტრანსპორტირება"}
            inputName={"transport"}
            handleCarData={handleSearchTransport}
          />
          <CheckBox
            name={"დამალული"}
            inputName={"visible"}
            handleCarData={setVisible}
          />
          {(user.role === 100 || user.role === 1 || user.role === 5) && (
            <div className="flex items-center gap-3">
              <input
                checked={checkAll}
                onChange={(e) => {
                  handleCheckAll(e);
                }}
                type="checkbox"
              />
              <p className="text-sm text-gray-500">ყველას მონიშვნა</p>
            </div>
          )}
        </div>
        <div className="my-3 grid grid-cols-4 gap-3 md:grid-cols-2 sm:grid-cols-1">
          {(user.role === 100 || user.role === 1 || user.role === 5) && (
            <>
              <div
                onClick={handleShowAllPackages}
                className="rounded-lg bg-yellow-400 w-full
                 flex items-center justify-center gap-1 p-1 py-2 text-white cursor-pointer"
              >
                <p>ჩამოსულებში გამოჩენა</p>
              </div>
              <div
                onClick={() => {
                  setShowContinue(true);
                }}
                className="rounded-lg bg-green-400 w-full cursor-pointer
                 flex items-center justify-center gap-1 p-1 py-2 text-white"
              >
                <p>მონიშნულის გაცემა</p>
              </div>
            </>
          )}
          {(user.role === 100 || user.role === 1) && (
            <>
              <SelectInput
                handleCarData={handleFlightNum}
                options={flights}
                defaultText={"რეისები"}
              />
              <div className="flex items-center">
                <SelectInputValueId
                  inputSetter={handleSelectBranch}
                  options={filialData}
                  defaultText={"ფილიალი"}
                />
                <BsX
                  onClick={() => {
                    setSelectedBranch("");
                  }}
                  className="text-red-500 shrink-0 text-4xl cursor-pointer"
                />
              </div>
              <div
                onClick={() => {
                  setShowContinue2(true);
                }}
                className="rounded-lg bg-purple-400 w-full cursor-pointer
                 flex items-center justify-center gap-1 p-1 py-2 text-white"
              >
                <p>რეისით გამოჩენა</p>
              </div>
            </>
          )}
        </div>
        <div className="w-full flex gap-3 flex-wrap mt-5">
          <PaginatedItems pageNumberSetter={setCurrentPage} dataLength={total}>
            <div className="w-full overflow-auto scrollbar flex flex-col gap-2">
              {packageData.map((item) => {
                return (
                  <AppeardPackagesItem
                    key={item.id}
                    data={item}
                    handleDeletePackage={handleDeletePackage}
                    setCheckedAll={checkAll}
                    handleSelectedPackages={handleSelectedPackages}
                    handleDeleteSelectedPackages={handleDeleteSelectedPackages}
                    setDifResponses={setDifResponses}
                    setSelectedPackages={setSelectedPackages}
                    handleMoveBack={handleMoveBack}
                  />
                );
              })}
            </div>
          </PaginatedItems>
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleSendAllWithdrawalPackages}
        setShowContinue={setShowContinue}
      />
      <ContinueComponent
        showFlag={showContinue2}
        actionFunction={handleShowWithFlightNum}
        setShowContinue={setShowContinue2}
      />
    </div>
  );
};

export default AppeardPackages;

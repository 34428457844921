import { useParams } from 'react-router-dom'
import Barcode from 'react-barcode';
import phoneLogo from "../../img/phoneLogo.png";

const BarcodePage = () => {
    const { barcodeId } = useParams();
    return (
        <div className='w-screen h-screen flex items-center justify-center sm:p-10'>
            <img src={phoneLogo} className='fixed top-5 left-5 w-10 h-10' />
            {barcodeId !== null &&
                <div className="border border-gray-300 shadow-md rounded-lg bg-white
 flex items-center justify-center sm:w-full p-4 ">
                    <Barcode
                        value={barcodeId}
                        style={{ height: "200px", width: "200px" }}
                        background={"transparent"}
                    />
                </div>
            }
        </div>
    )
}

export default BarcodePage
import { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import {
  errorToast,
  successToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import axiosClient from "../../api/api";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import { Text } from "../../components/Text";
import RegistrationPanelPath from "../../components/RegistrationPanelPath/RegistrationPanelPath";
import CustomTextInut from "../../components/CustomInputs/CustomTextInut";
import CustomSelectInput from "../../components/CustomInputs/CustomSelectInput";
import { useTranslation } from "react-i18next";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";

//icons
import {
  BiMobileAlt,
  BiCurrentLocation,
  BiChevronRight,
  BiChevronLeft,
  BiMap,
  BiBuildings,
  BiX,
} from "react-icons/bi";
import { BsEnvelope, BsFillBuildingFill, BsFlag } from "react-icons/bs";
import { FaBarcode } from "react-icons/fa";
import { useRef } from "react";

const RegistrationmodalstepThreePage = ({
  step,
  handleStep,
  regPersonType,
}) => {
  //translations
  const { t } = useTranslation();
  //register credentials setter function
  const {
    handleRegCredentials,
    registerCredetials,
    user,
    unauthorizedBranches,
  } = useContext(PrimmaryContext);
  //page main fileds based on first step
  const [mainContent, setMainContent] = useState([]);
  //current step tracker
  const [currenStep, setCurrentStep] = useState("");
  //cities from api
  const [cityOptions, setCityOptions] = useState([]);
  //cities from api ids
  const [cityOptionsIds, setCityOptionsIds] = useState([]);
  //cities api err
  const [cityOptionsErr, setCityOptionsErr] = useState(null);
  // email verification code
  const [verifyEmail, setVerifyEmail] = useState({
    code_mail: "",
  });
  //phone verification code
  const [verifyPhone, setVerifyPhone] = useState({
    code_phone: "",
  });
  //email code and phone code check responses
  const [codeCheckResponses, setCodeCkeckResponses] = useState({
    phone: null,
    email: null,
  });
  //make email, password or branch fields red whether they are empty
  const [otherIsEmptys, setOtherIsEmptys] = useState({
    emailMfill: false,
    emailCodeMfill: false,
    phoneMfill: false,
    phoneCodeMfill: false,
    branchMfill: false,
  });
  const targetElement = useRef(null);
  const [branchData, setBranchData] = useState([]);
  const [verificationNecessity, setVerificationNecessity] = useState({});

  useEffect(() => {
    axiosClient
      .get("chackkkk")
      .then(({ data }) => {
        setVerificationNecessity(data);
      })
      .catch((err) => {});
  }, []);

  const disableScroll = () => {
    disableBodyScroll(targetElement.current);
  };

  const enableScroll = () => {
    enableBodyScroll(targetElement.current);
  };

  //validate phone number
  const handlePhoneValidation = (text) => {
    if (`${text}`.length === 9) {
      return true;
    } else {
      return false;
    }
  };

  //validate email
  const handleEmailValidation = (text) => {
    if (text.includes("@")) {
      return true;
    } else {
      return false;
    }
  };

  //validate id number
  const handleIdValidation = (text) => {
    if (`${text}`.length === 9) {
      return true;
    } else {
      return false;
    }
  };

  //input fields for physical person
  const [inputFieldsPhysical, setInputPhysicalFields] = useState([
    {
      name: t("city"),
      options: cityOptions,
      prefixIcon: BsFlag,
      suffixIcon: null,
      input: "select",
      inputName: "city",
      mFill: false,
    },
    {
      placeholder: "address",
      prefixIcon: BiMap,
      suffixIcon: BiCurrentLocation,
      input: "text",
      inputName: "address",
      mFill: false,
    },
  ]);

  //input fields for entity person
  const [inputFieldsEntity, setInputEntityFields] = useState([
    {
      placeholder: "company_name",
      prefixIcon: BiBuildings,
      suffixIcon: null,
      input: "text",
      inputName: "company_name",
      mFill: false,
      pattern: "en",
    },
    {
      placeholder: "company_ic",
      prefixIcon: FaBarcode,
      suffixIcon: null,
      input: "text",
      inputName: "company_id",
      mFill: false,
      validateFuntion: handleIdValidation,
      validateQuote: t("incorrect_id"),
      type: "number",
    },
    {
      name: t("city"),
      options: cityOptions,
      prefixIcon: BsFlag,
      suffixIcon: null,
      input: "select",
      inputName: "city",
      mFill: false,
    },
    {
      placeholder: "address",
      prefixIcon: BiMap,
      suffixIcon: BiCurrentLocation,
      input: "text",
      inputName: "address",
      mFill: false,
    },
  ]);

  //verify email code handler
  const handleVerifyEmail = (value, inputName) => {
    setVerifyEmail({ [inputName]: value });
  };

  //verify phone code handler
  const handleVerifyPhone = (value, inputName) => {
    setVerifyPhone({ [inputName]: value });
  };

  //send code to email
  const handleSendCodeEmail = async () => {
    axiosClient
      .post(`/Auth/sendEmail`, { email: registerCredetials.email })
      .then((res) => {
        successToast(t("success"));
      })
      .catch((err) => {
        if (err.request.response === "exist") {
          errorToast(t("email_registerd"));
        }
      });
  };

  //send code to email
  const handleCheckCodeEmail = async () => {
    try {
      const res = await axiosClient.post(`/Auth/cheeMail`, verifyEmail);
      if (res) {
        if (res.data === 0) {
          errorToast(t("does_not_match"));
          setCodeCkeckResponses((prev) => ({ ...prev, email: 0 }));
        } else if (res.data === 1) {
          setCodeCkeckResponses((prev) => ({ ...prev, email: res.data }));
          successToast(t("success"));
        }
      }
    } catch (err) {}
  };

  //send code to phone
  const handleSendCodePhone = async () => {
    axiosClient
      .post(`/Auth/sendPhone`, { phone: registerCredetials.phone })
      .then((res) => {
        successToast(t("success"));
      })
      .catch((err) => {
        if (err.request.response === "exist") {
          errorToast(t("phone_registerd"));
        }
      });
  };

  //send code to email
  const handleCheckCodePhone = async () => {
    try {
      const res = await axiosClient.post(`/Auth/cheePhone`, verifyPhone);
      if (res) {
        setCodeCkeckResponses((prev) => ({ ...prev, phone: res.data }));
        if (res.data === 1) {
          successToast(t("success"));
        } else {
          errorToast(t("does_not_match"));
        }
      }
    } catch (err) {
      errorToast(t("does_not_match"));
    }
  };

  //handle go to next page
  const handleGoNextStep = () => {
    var goNext = true;

    if (registerCredetials.user_type === 1) {
      const newData = inputFieldsPhysical.map((obj) => {
        if (registerCredetials[obj.inputName] === "") {
          goNext = false;
          return { ...obj, mFill: true };
        }
        return obj;
      });

      setInputPhysicalFields(newData);
    }

    if (registerCredetials.user_type === 2) {
      const newData = inputFieldsEntity.map((obj) => {
        if (registerCredetials[obj.inputName] === "") {
          goNext = false;
          return { ...obj, mFill: true };
        }
        return obj;
      });

      setInputEntityFields(newData);
    }

    if (user.user_type === 2) {
      axiosClient
        .post(`Auth/chekId`, { company_id: registerCredetials.company_id })
        .then((res) => {})
        .catch((err) => {
          if (err.request.response === "exist") {
            errorToast(t("sc_registerd"));
          }
          goNext = false;
        });
    }

    if (verificationNecessity.mail) {
      if (codeCheckResponses.email !== 1) {
        errorToast(t("el_code_err"));
        goNext = false;
      }
    }

    if (verificationNecessity.phone) {
      if (codeCheckResponses.phone !== 1) {
        errorToast(t("phone_code_err"));
        goNext = false;
      }
    }

    if (registerCredetials.email === "") {
      setOtherIsEmptys((prev) => ({ ...prev, emailMfill: true }));
      goNext = false;
    }

    if (registerCredetials.branch_id === "") {
      setOtherIsEmptys((prev) => ({ ...prev, branchMfill: true }));
      goNext = false;
    }

    if (registerCredetials.phone === "") {
      setOtherIsEmptys((prev) => ({ ...prev, phoneMfill: true }));
      goNext = false;
    }

    if (verificationNecessity.phone) {
      if (verifyPhone.code_phone === "") {
        setOtherIsEmptys((prev) => ({ ...prev, phoneCodeMfill: true }));
        goNext = false;
      }
    }

    if (verificationNecessity.mail) {
      if (verifyEmail.code_mail === "") {
        setOtherIsEmptys((prev) => ({ ...prev, emailCodeMfill: true }));
        goNext = false;
      }
    }

    if (goNext) {
      handleStep("stepFour");
    }
  };

  //current step tracker effect
  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  //person type changer effect
  useEffect(() => {
    if (regPersonType === "physicalPerson") {
      setMainContent(inputFieldsPhysical);
    }
    if (regPersonType === "legalEntity") {
      setMainContent(inputFieldsEntity);
    }
  }, [regPersonType, inputFieldsEntity, inputFieldsPhysical]);

  //fetch cities from api
  useEffect(() => {
    try {
      axiosClient.get(`/Auth/register`).then((res) => {
        setCityOptions(res.data.city);
      });
    } catch (error) {
      setCityOptionsErr(error);
    }
  }, []);

  //fetch cities from api
  useEffect(() => {
    if (cityOptions.length !== 0) {
      const tmp1 = inputFieldsEntity.map((item) => {
        if (item.inputName === "city") {
          return { ...item, options: cityOptions, ids: cityOptionsIds };
        }
        return item;
      });

      setInputEntityFields(tmp1);

      const tmp2 = inputFieldsPhysical.map((item) => {
        if (item.inputName === "city") {
          return { ...item, options: cityOptions, ids: cityOptionsIds };
        }
        return item;
      });

      setInputPhysicalFields(tmp2);
    }
  }, [cityOptions]);

  useEffect(() => {
    if (currenStep === "stepThree") {
      disableScroll();
    } else {
      enableScroll();
    }
  }, []);

  useEffect(() => {
    if (unauthorizedBranches) {
      setBranchData(
        unauthorizedBranches.map((branch) => {
          return {
            id: branch.id,
            title: branch.addressen + ", Tbilisi",
          };
        })
      );
    }
  }, [unauthorizedBranches]);

  return (
    <>
      <div
        className={`bg-gray_100 flex font-firago outline outline-[1px] outline-black_900_1e fixed top-0 left-0
      transition-all duration-300 delay-150 ease-linear z-50 sm:overflow-auto scrollbar popup_container
      ${currenStep === "stepThree" ? "translate-x-[0]" : "translate-x-[100%]"}`}
      >
        <div className="flex flex-col items-start justify-start w-full">
          <header
            className="bg-white_A700 border-b-[1px] border-black_900_14 border-solid flex flex-row gap-[16px] 
          items-center justify-center sm:px-[20px] px-[32px] py-[16px] w-[100%]"
          >
            <Text
              className="flex-1 font-bold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
              variant="body3"
            >
              {t("registration")}
            </Text>
            <div
              onClick={() => {
                handleStep("");
              }}
              className="bg-gray_600_14 flex flex-col h-[36px] items-center justify-center rounded-radius50 w-[36px]
            cursor-pointer"
            >
              <BiX className="text-[#FF3A30] text-[22px]" />
            </div>
          </header>
          <div
            className="flex flex-col font-notosansgeorgian gap-[40px] h-[100%] items-start justify-start p-[32px] sm:px-[20px]
         w-[100%] sm:gap-5"
          >
            <RegistrationPanelPath
              stepOnePassed={true}
              stepTwoPassed={true}
              stepThreeActive={true}
            />
            <div
              className="flex flex-col gap-[24px] items-start justify-start max-w-[1122px] mx-[auto] 
          md:px-[20px] w-[100%] h-full sm:overflow-auto scrollbar sm:px-2"
            >
              <div className="w-full grid grid-cols-2 sm:grid-cols-1 gap-2">
                {verificationNecessity.mail === 0 ?(
                  <CustomTextInut
                    PrefixIcon={BsEnvelope}
                    placeholder={"email"}
                    inputName={"email"}
                    inputSetter={handleRegCredentials}
                    mFill={otherIsEmptys.emailMfill}
                    validateFuntion={handleEmailValidation}
                    validateQuote={t("invalid_email")}
                    type={"text"}
                    noDark={true}
                  />
                ) : (
                  <></>
                )}

                {verificationNecessity.phone === 0 ? (
                  <CustomTextInut
                    PrefixIcon={BiMobileAlt}
                    placeholder={"phone"}
                    inputName={"phone"}
                    inputSetter={handleRegCredentials}
                    mFill={otherIsEmptys.phoneMfill}
                    validateFuntion={handlePhoneValidation}
                    validateQuote={t("symbol")}
                    noDark={true}
                    pattern={"number"}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="w-full flex flex-col gap-2">
                {verificationNecessity.mail !== 0 ? (
                  <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2">
                    <CustomTextInut
                      PrefixIcon={BsEnvelope}
                      placeholder={"email"}
                      inputName={"email"}
                      inputSetter={handleRegCredentials}
                      mFill={otherIsEmptys.emailMfill}
                      validateFuntion={handleEmailValidation}
                      validateQuote={t("invalid_email")}
                      type={"text"}
                      noDark={true}
                    />
                    <div
                      onClick={handleSendCodeEmail}
                      className="bg-green-400 rounded-lg px-4 py-2 text-white font-bold
                flex items-center justify-center cursor-pointer"
                    >
                      {t("send_code")}
                    </div>
                    <CustomTextInut
                      PrefixIcon={BsEnvelope}
                      placeholder={"email_code"}
                      inputName={"code_mail"}
                      inputSetter={handleVerifyEmail}
                      mFill={otherIsEmptys.emailCodeMfill}
                      type={"text"}
                      noDark={true}
                    />
                    <div
                      onClick={handleCheckCodeEmail}
                      className="bg-green-400 rounded-lg px-4 py-2 text-white font-bold
                flex items-center justify-center cursor-pointer"
                    >
                      {t("confirm")}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {verificationNecessity.phone ? (
                  <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2">
                    <CustomTextInut
                      PrefixIcon={BiMobileAlt}
                      placeholder={"phone"}
                      inputName={"phone"}
                      inputSetter={handleRegCredentials}
                      mFill={otherIsEmptys.phoneMfill}
                      validateFuntion={handlePhoneValidation}
                      validateQuote={t("symbol")}
                      noDark={true}
                      pattern={"number"}
                    />
                    <div
                      onClick={handleSendCodePhone}
                      className="bg-green-400 rounded-lg px-4 py-2 text-white font-bold
                flex items-center justify-center cursor-pointer"
                    >
                      {t("send_code")}
                    </div>
                    <CustomTextInut
                      PrefixIcon={BiMobileAlt}
                      placeholder={"phone_code"}
                      inputName={"code_phone"}
                      inputSetter={handleVerifyPhone}
                      mFill={otherIsEmptys.phoneCodeMfill}
                      type={"text"}
                      noDark={true}
                    />
                    <div
                      onClick={handleCheckCodePhone}
                      className="bg-green-400 rounded-lg px-4 py-2 text-white font-bold
                flex items-center justify-center cursor-pointer"
                    >
                      {t("confirm")}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="w-full grid grid-cols-2 sm:grid-cols-1 gap-2">
                {/* grid-cols-4 md:grid-cols-2 */}
                {mainContent.map((item, index) => {
                  return (
                    <div className="w-full" key={index}>
                      {item.input === "text" && (
                        <CustomTextInut
                          PrefixIcon={item.prefixIcon}
                          SuffixIcon={item.suffixIcon}
                          placeholder={item.placeholder}
                          inputName={item.inputName}
                          inputSetter={handleRegCredentials}
                          mFill={item.mFill}
                          validateFuntion={
                            item.validateFuntion ? item.validateFuntion : null
                          }
                          validateQuote={
                            item.validateQuote ? item.validateQuote : null
                          }
                          type={item.type ? item.type : "text"}
                          noDark={true}
                          pattern={item?.pattern}
                        />
                      )}
                      {item.input === "select" && (
                        <CustomSelectInput
                          name={item.name}
                          options={item.options}
                          PrefixIcon={item.prefixIcon}
                          SuffixIcon={item.suffixIcon}
                          inputName={item.inputName}
                          inputSetter={handleRegCredentials}
                          mFill={item.mFill}
                          ids={item.ids}
                          saveWithNumber={item.saveIds}
                          noDark={true}
                        />
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="w-full flex flex-col gap-2">
                <p className="text-[#636366] text-sm">
                  {t("office_notification_text")}
                </p>
                <div className="w-6/12 sm:w-full">
                  <CustomSelectInput
                    name={t("office")}
                    options={branchData}
                    PrefixIcon={BsFillBuildingFill}
                    inputName={"branch_id"}
                    inputSetter={handleRegCredentials}
                    mFill={otherIsEmptys.branchMfill}
                    noDark={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <footer
            className="bg-white_A700 border-black_900_14 border-solid border-t-[1px] flex flex-row 
        font-notosansgeorgian gap-[16px] items-center justify-end sm:px-[20px] px-[32px] py-[16px] w-[100%]
        sm:justify-between"
          >
            <button
              onClick={() => {
                handleStep("stepTwo");
              }}
              className="rounded-lg px-[16px] py-[10px] flex items-center gap-2 bg-gray_600_14"
            >
              <BiChevronLeft className="text-[22px] text-center text-[#636366]" />
              <div
                className="common-pointer bg-transparent cursor-pointer font-fw text-[17px] text-black_900_99 text-left
            sm:hidden"
              >
                {t("back")}
              </div>
            </button>
            <button
              onClick={() => {
                handleGoNextStep();
              }}
              className="rounded-lg px-[16px] py-[10px] flex items-center gap-2 
            bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
            >
              <div className="common-pointer bg-transparent cursor-pointer font-semibold text-[17px] text-left text-white_A700">
                {t("next")}
              </div>
              <BiChevronRight className="text-[22px] text-center" />
            </button>
          </footer>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default RegistrationmodalstepThreePage;

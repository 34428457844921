import { useEffect, useState } from "react";

const Switch = ({ inputName, inputSetter, value }) => {
  const [checkedValue, setCheckedValue] = useState(false);
  const handleCheckedValue = (e) => {
    setCheckedValue(e.target.checked);
    if (inputSetter) {
      inputSetter(e.target.checked ? "1" : "0", inputName);
    }
  };

  //set initial value based on value
  useEffect(() => {
    if (value === "1" || value === 1) {
      setCheckedValue(true);
    } else {
      setCheckedValue(false);
    }
  }, [value]);

  return (
    <label className="relative inline-block w-[48px] h-[24px]">
      <input
        name={inputName}
        checked={checkedValue}
        onChange={(e) => {
          handleCheckedValue(e);
        }}
        type="checkbox"
        className="opacity-0 w-0 h-0"
      ></input>
      <span
        className={`
                  rounded-full
                  slider absolute cursor-pointer top-0 left-0 right-0 before:rounded-full
                  bottom-0 before:content[''] before:w-[18px] before:h-[18px]
                  before:absolute before:bg-white before:left-[4px] before:top-[3px] 
                  ${
                    checkedValue
                      ? "before:translate-x-[22px] bg-[#5856D6]"
                      : "bg-gray-300"
                  } before:transition-all
                  before:ease-in`}
      ></span>
    </label>
  );
};

export { Switch };

import { useState, useContext, useEffect } from "react";
import ParcelsOfficeCard from "./ParcelsOfficeCard";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import axiosAdmin from "../../../../api/apiAdmin";
import PaginatedItems from "../../Pagination/Pagination";
import TransportationInvoice from "../../TransportationInvoice/TransportationInvoice";

//icons
import { FaRegHandPointRight } from "react-icons//fa";
import { SelectInput } from "../../inputComponents/inputcomp";
import { BsX } from "react-icons/bs";

const ParcelsOffice = () => {
  //current filial
  const [currentPlaceId, setCurrentPlaceId] = useState(1);
  //filials data
  const {
    foreignAdresses,
    user,
    flights,
    globalSearchValue,
    handleSearchValue,
    setGlobalAoId,
  } = useContext(AdminPrimaryContext);
  //package data
  const [packageData, setPackageData] = useState([]);
  //package dataErr
  const [packageDataErr, setPackageDataErr] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //current page
  const [currentPage, setCurrentPage] = useState(0);
  //total records
  const [total, setTotal] = useState(0);
  //get filial data from api
  const [filialData, setFilialData] = useState([]);
  //current filial handler
  const handleCurrentPlaceId = (value) => {
    setGlobalAoId(value);
    setCurrentPlaceId(value);
  };
  //selected branch
  const [selectedBranch, setSelectedBranch] = useState(1);
  //pack status
  const [status, setStatus] = useState(2);
  //old parcels
  const [old, setOld] = useState("");
  //in office status
  const [inOffice, setInOffice] = useState("");
  //transportation price sum
  const [transportationSum, setTransportationSum] = useState(0);
  //flight number
  const [flightNum, setFlightNum] = useState({
    flight: "",
  });
  //make fields empty
  const [makeEmpty, setMakeEmpty] = useState(false);
  //show invoice
  const [showInvoice, setShowInvoice] = useState(false);

  //handle flight number
  const handleFlightNumber = (value, key) => {
    setFlightNum({ [key]: value });
  };

  //clear branch id
  const handleClearBranchId = () => {
    setSelectedBranch("");
  };

  //handle delete package
  const handleDeletePackage = async (id) => {
    try {
      const res = await axiosAdmin.get(`/Pack/delete/${id}`);
      if (res) {
        setDifResponses(res);
        successToast("ამანათი წარმატებით წაიშალა!");
      }
    } catch (err) {
      setPackageDataErr(err);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  const handleParcelThings = () => {
    axiosAdmin
      .get(
        `/Packebi?pack_status=${status}&key=${globalSearchValue.key}` +
          `&ao_id=${currentPlaceId}&per_page=${30}&page=${currentPage + 1}&${
            user.role !== 8 ? `branch_id=${selectedBranch}&` : ""
          }` +
          `vadagasuli=${old}&in_office=${inOffice}&flight_number=${flightNum.flight}`
      )
      .then(({ data }) => {
        setPackageData(data.data.data);
        setTotal(data.data.total);
        setTransportationSum(data.sum);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //move parcel to previous status
  const handleMoveBack = (parcelId) => {
    axiosAdmin
      .get(`/Pack/back/${parcelId}`)
      .then((res) => {
        setDifResponses(res);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //package data fetching
  useEffect(() => {
    handleParcelThings();
  }, [
    currentPlaceId,
    difResponses,
    globalSearchValue,
    currentPage,
    selectedBranch,
    flightNum,
    status,
    old,
    inOffice,
  ]);

  //on barcode scanner using
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleParcelThings();
    }
  };

  //filial data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Branches/index`).then(({ data }) => {
        setFilialData(data);
      });
    } catch (error) {
      setPackageDataErr(error);
    }
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="flex gap-1 mb-5 sm:flex-wrap">
        {foreignAdresses.map((item) => {
          return (
            <div
              key={item.id}
              className={`w-full flex items-center p-3 rounded-lg gap-5 cursor-pointer
                    shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px] 
                    ${
                      currentPlaceId === item.id
                        ? "border border-green-500 bg-green-100"
                        : "bg-white dark:bg-slate-700 "
                    }`}
            >
              <div className="w-full flex flex-col gap-2 justify-between h-full">
                <p className="text-[14px] text-[#8e95a8]">{item.title}</p>
                <p className="text-[14px] text-[#8e95a8]">
                  {`${item.country} ${item.city}`}
                </p>
                <p className="text-[14px] text-[#8e95a8]">{item.address}</p>
                <p className="text-[14px] text-[#8e95a8]">{item.phone}</p>
                <div className="flex gap-1">
                  <div
                    onClick={() => {
                      handleCurrentPlaceId(item.id);
                    }}
                    className="w-full rounded-lg bg-green-400
                 flex items-center justify-center gap-1 p-1 py-3 text-white"
                  >
                    <FaRegHandPointRight />
                    <p>აირჩიე</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-full">
        <div className="w-full mt-2 gap-1 grid grid-cols-3 sm:grid-cols-1 aftersm:grid-cols-1">
          <div
            className="bg-white  pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-full"
          >
            <input
              type="search"
              placeholder="ძებნა"
              value={globalSearchValue.key}
              onKeyDown={handleKeyPress}
              onChange={(e) => {
                handleSearchValue(e, "key");
              }}
              className="w-full h-10 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
            />
          </div>
          {user.role !== 8 &&
            filialData.map((item) => {
              return (
                <div
                  className={`p-4 w-full border border-slate-200 rounded-lg  flex items-center justify-between
                        ${
                          item.id === selectedBranch
                            ? "bg-slate-200"
                            : "bg-white"
                        }`}
                  key={item.id}
                >
                  <p
                    className="text-slate-500 cursor-pointer"
                    onClick={() => {
                      setSelectedBranch(item.id);
                    }}
                  >
                    {item.title}
                  </p>
                  {item.id === selectedBranch && (
                    <BsX
                      className="text-red-500 cursor-pointer text-xl"
                      onClick={() => {
                        handleClearBranchId();
                      }}
                    />
                  )}
                </div>
              );
            })}

          <div
            className={`p-4 w-full border border-slate-200 rounded-lg cursor-pointer
                                ${status === 2 ? "bg-slate-200" : "bg-white"}`}
            onClick={() => {
              setStatus(2);
            }}
          >
            <p className="text-slate-500">საწყობში მისული</p>
          </div>
          <div
            className={`p-4 w-full border border-slate-200 rounded-lg cursor-pointer
                                ${status === 3 ? "bg-slate-200" : "bg-white"}`}
            onClick={() => {
              setStatus(3);
            }}
          >
            <p className="text-slate-500">გამოგზავნილი</p>
          </div>
          <div
            className={`p-4 w-full border border-slate-200 rounded-lg cursor-pointer
                                ${status === 4 ? "bg-slate-200" : "bg-white"}`}
            onClick={() => {
              setStatus(4);
            }}
          >
            <p className="text-slate-500">ჩამოსული</p>
          </div>
          <div
            className={`p-4 w-full border border-slate-200 rounded-lg cursor-pointer
                                ${status === 5 ? "bg-slate-200" : "bg-white"}`}
            onClick={() => {
              setStatus(5);
            }}
          >
            <p className="text-slate-500">ჩაბარებული</p>
          </div>
          <div
            className={`p-4 w-full border border-slate-200 rounded-lg cursor-pointer
                                ${old === 1 ? "bg-slate-200" : "bg-white"}`}
            onClick={() => {
              setOld((prev) => (prev === 1 ? "" : 1));
            }}
          >
            <p className="text-slate-500">ვადა გადაცილებული</p>
          </div>
          <div
            className={`p-4 w-full border border-slate-200 rounded-lg cursor-pointer
                                ${
                                  inOffice === 1 ? "bg-slate-200" : "bg-white"
                                }`}
            onClick={() => {
              setInOffice((prev) => (prev === 1 ? "" : 1));
            }}
          >
            <p className="text-slate-500">არასწორად გადანაწილებულები</p>
          </div>
          <div className="flex gap-2 items-center">
            <SelectInput
              handleCarData={handleFlightNumber}
              options={flights}
              inputName={"flight"}
              makeEmpty={makeEmpty}
              handleMakeEmpty={setMakeEmpty}
            />
            <div
              className="w-10 h-10 rounded-lg bg-white border border-slate-200"
              onClick={() => {
                setFlightNum({ flight: "" });
                setMakeEmpty(true);
              }}
            >
              <BsX className="text-red-500 w-full h-full cursor-pointer" />
            </div>
          </div>
          <div
            className={`p-4 w-full border bg-green-500 rounded-lg cursor-pointer `}
            onClick={() => {
              setShowInvoice(true);
            }}
          >
            <p className="text-white text-center">ტრანსპორტირების ინვოისი</p>
          </div>
        </div>
        <div className="w-full flex flex-col gap-3 flex-wrap mt-5">
          <div className="flex gap-2 items-center flex-wrap">
            <p className="text-slate-500">
              ტრანსპორტირების ფასის ჯამი : {transportationSum}
            </p>
            <p className="text-slate-500">პაკეტების როდენობა : {total}</p>
          </div>
          <PaginatedItems pageNumberSetter={setCurrentPage} dataLength={total}>
            <div className="w-full flex flex-col gap-y-2">
              {packageData.map((item) => {
                return (
                  <ParcelsOfficeCard
                    key={item.id}
                    data={item}
                    handleDeletePackage={handleDeletePackage}
                    handleMoveBack={handleMoveBack}
                  />
                );
              })}
            </div>
          </PaginatedItems>
        </div>
      </div>
      <TransportationInvoice
        showInvoice={showInvoice}
        setShowInvoice={setShowInvoice}
        status={status}
        globalSearchValue={globalSearchValue}
        flightNum={flightNum}
        currentPlaceId={currentPlaceId}
      />
    </div>
  );
};

export default ParcelsOffice;

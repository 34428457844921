import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import { PrimmaryContext } from "../../../../components/MainContext/MainContext";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput } from "../../inputComponents/inputcomp";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";

//images
import logo from "../../../img/phoneLogo.png";

const InnerUsPage = () => {
  //get current user id
  const { siteUsersId } = useParams();
  //new Users data
  const [usersData, setUsersData] = useState({});
  //error
  const [error, setError] = useState(null);
  //context functions
  const { setUser, setToken } = useContext(PrimmaryContext);
  //user
  const { user } = useContext(AdminPrimaryContext);
  //user data updater
  const [updateUserData, setUpdateUserData] = useState(null);
  //change ballance
  const [ballancePayload, setBallancePayload] = useState({});

  //handle new admin
  const handleBallance = (value, inputName) => {
    setBallancePayload((prev) => ({ ...prev, [inputName]: value }));
  };

  //handle new Users
  const handleNewUsersData = (value, inputName) => {
    setUsersData((prev) => ({ ...prev, [inputName]: value }));
  };

  // access user
  const handleAccessUser = async () => {
    try {
      const res = await axiosAdmin.get(`/Users/access/${siteUsersId}`);
      if (res) {
        setUser(res.data.user);
        setToken(res.data.token);
        window.open("https://expressgroup.ge", "_blank");
      }
    } catch (error) {
      setError(error);
    }
  };

  //update Users data
  const handleEditUsers = () => {
    axiosAdmin
      .post(`/Users/edit_post/${siteUsersId}`, {
        fname: usersData.fname,
        lname: usersData.lname,
        email: usersData.email,
        fname_eng: usersData.fname_eng,
        lname_eng: usersData.lname_eng,
        phone: usersData.phone,
        pass: usersData.pass ? usersData.pass : null,
      })
      .then(({ data }) => {
        setUpdateUserData(data);
        successToast("მონაცემები წარმატებით განახლდა!");
      })
      .catch((err) => {
        setError(err);
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  //update Users ballance
  const handleEditBallance = () => {
    axiosAdmin
      .post("/user/updateBalance", {
        ...ballancePayload,
        pervious: usersData.balance,
        user_id: usersData.id,
      })
      .then(({ data }) => {
        setUpdateUserData(data);
        successToast("მონაცემები წარმატებით განახლდა!");
      })
      .catch((err) => {
        setError(err);
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  //Users data from api
  useEffect(() => {
    axiosAdmin
      .get(`/Users/edit/${siteUsersId}`)
      .then(({ data }) => {
        setUsersData(data);
      })
      .catch((err) => {
        setError(err);
      });
  }, [updateUserData]);

  return (
    <div
      className="xl:ml-[280px] mt-[55px]  bg-slate-100 dark:bg-slate-900
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-10 h-10 ">
                <img src={logo} className="w-full h-full rounded-full"></img>
              </div>
              <div>
                <h2
                  className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                >
                  მომხმარებლის ინფორმაციის რედაქტირება
                </h2>
              </div>
            </div>
            <div className="flex gap-1 items-center">
              <button
                onClick={handleEditUsers}
                className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
              >
                შენახვა
              </button>
              <div
                onClick={handleAccessUser}
                className="w-32 rounded-lg bg-green-400 cursor-pointer
                 flex gap-2 items-center justify-center p-1 py-3 md:w-[49%] sm:w-[49%]"
              >
                <p className="text-white text-sm ">შესვლა</p>
              </div>
            </div>
          </div>
          <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
            {usersData.user_type === 1 && (
              <>
                <div className="w-6/12 sm:w-full md:w-full">
                  <TextInput
                    name={"სახელი"}
                    inputName={"fname"}
                    inputSetter={handleNewUsersData}
                    value={usersData.fname}
                  />
                  <TextInput
                    name={"გვარი"}
                    inputName={"lname"}
                    inputSetter={handleNewUsersData}
                    value={usersData.lname}
                  />
                  <TextInput
                    name={"ელ.ფოსტა"}
                    inputName={"email"}
                    inputSetter={handleNewUsersData}
                    value={usersData.email}
                  />
                  <TextInput
                    name={"პირდი ნომერი"}
                    inputName={"id_num"}
                    inputSetter={handleNewUsersData}
                    value={usersData.id_num}
                  />
                </div>
                <div className="w-6/12 sm:w-full md:w-full">
                  <TextInput
                    name={"სახელი ლათინურად"}
                    inputName={"fname_eng"}
                    inputSetter={handleNewUsersData}
                    value={usersData.fname_eng}
                  />
                  <TextInput
                    name={"გვარი ლათინურად"}
                    inputName={"lname_eng"}
                    inputSetter={handleNewUsersData}
                    value={usersData.lname_eng}
                  />
                  <TextInput
                    name={"ტელეფონი"}
                    inputName={"phone"}
                    inputSetter={handleNewUsersData}
                    value={usersData.phone}
                  />
                  <TextInput
                    name={"პაროლი"}
                    inputName={"pass"}
                    inputSetter={handleNewUsersData}
                  />
                </div>
              </>
            )}
            {usersData.user_type === 2 && (
              <>
                <div className="w-6/12 sm:w-full md:w-full">
                  <TextInput
                    name={"დირექტორის სახელი"}
                    inputName={"ceo_fname"}
                    inputSetter={handleNewUsersData}
                    value={usersData.ceo_fname}
                  />
                  <TextInput
                    name={"დირექტორის გვარი"}
                    inputName={"ceo_lname"}
                    inputSetter={handleNewUsersData}
                    value={usersData.ceo_lname}
                  />
                  <TextInput
                    name={"ელ.ფოსტა"}
                    inputName={"email"}
                    inputSetter={handleNewUsersData}
                    value={usersData.email}
                  />
                  <TextInput
                    name={"დირექტორის პირდი ნომერი"}
                    inputName={"ceo_id_num"}
                    inputSetter={handleNewUsersData}
                    value={usersData.ceo_id_num}
                  />
                  <TextInput
                    name={"კომპანიის დასახელება"}
                    inputName={"company_name"}
                    inputSetter={handleNewUsersData}
                    value={usersData.company_name}
                  />
                </div>
                <div className="w-6/12 sm:w-full md:w-full">
                  <TextInput
                    name={"დირექტორის სახელი ლათინურად"}
                    inputName={"ceo_fname_eng"}
                    inputSetter={handleNewUsersData}
                    value={usersData.ceo_fname_eng}
                  />
                  <TextInput
                    name={"დირექტორის გვარი ლათინურად"}
                    inputName={"ceo_lname_eng"}
                    inputSetter={handleNewUsersData}
                    value={usersData.ceo_lname_eng}
                  />
                  <TextInput
                    name={"ტელეფონი"}
                    inputName={"phone"}
                    inputSetter={handleNewUsersData}
                    value={usersData.phone}
                  />
                  <TextInput
                    name={"პაროლი"}
                    inputName={"pass"}
                    inputSetter={handleNewUsersData}
                  />
                  <TextInput
                    name={"კომპანიის საიდენთიფიკაციო კოდი"}
                    inputName={"company_id"}
                    inputSetter={handleNewUsersData}
                    value={usersData.company_id}
                  />
                </div>
              </>
            )}
          </div>
          <div className="grid grid-cols-3 gap-3 mt-3  sm:grid-cols-1">
            {user.role !== 2 && user.role !== 3 && user.role !== 4 && (
              <>
                <TextInput
                  name={"ბალანსი"}
                  inputName={"updated"}
                  inputSetter={handleBallance}
                  value={usersData.balance}
                />
                <TextInput
                  name={"კომენტარი"}
                  inputName={"comment"}
                  inputSetter={handleBallance}
                />
                <div className="flex flex-col items-end justify-end w-full">
                  <button
                    onClick={handleEditBallance}
                    className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full self-start w-full"
                  >
                    ბალანსის შენახვა
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-1 gap-3 mt-10">
        {usersData.balance_change &&
          usersData.balance_change.map((item) => {
            return (
              <div
                key={item.id}
                className="rounded-xl bg-white dark:bg-slate-700 p-3 grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1"
              >
                <div className="w-full flex flex-col gap-2">
                  <p className="text-sm text-gray-500 dark:text-gray-300 font-bold">
                    ძველი ბალანსი
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-300">
                    {item.pervious}
                  </p>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <p className="text-sm text-gray-500 dark:text-gray-300 font-bold">
                    ახალი ბალანსი
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-300">
                    {item.updated}
                  </p>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <p className="text-sm text-gray-500 dark:text-gray-300 font-bold">
                    კომენტარი
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-300">
                    {item.comment}
                  </p>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <p className="text-sm text-gray-500 dark:text-gray-300 font-bold">
                    თარიღი
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-300">
                    {item.created_at && item.created_at.split("T")[0]}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default InnerUsPage;

const translationEn = {
  flights: "Flights",
  receive_packages: "Receive packages",
  from_Turkey_and_from_China: "from all over the world",
  email_or_password_is_incorrect: "Email or password is incorrect!",
  email: "Email",
  password: "Password",
  remember: "Remember",
  password_recovery: "Password recovery",
  login: "Login",
  register: "Register",
  why_expressgroup: "Why ExpressGroup?",
  our_advantages: "Our advantages",
  prices: "Prices",
  turkey: "Turkey",
  china: "China",
  china_land: "China Land",
  usa: "USA",
  bulk_shipments_are_subject_to_a_different_rate_based_on_actual_weights:
    "A special (low) tariff applies to large quantities of cargo, please write to us for details.",
  what_ExpressGroup_offers: "What wer offer ExpressGroup?",
  our_services: "Our services",
  contact: "Contact",
  write_us: "Write us",
  send_us_an_email_via_mail: "Send us an email. Via mail",
  call_us: "Call us",
  contact_us_only_during_working_hours: "Contact us only during working hours",
  filials: "Branches",
  mon_fri: "Mon-Fri",
  saturdey: "Saturday",
  viev_on_the_map: "View on the map",
  all_rights_reserved: "All rights reserved",
  terms_of_use_of_the_site: "Terms of use of the site",
  about_company: "About company",
  priorities: "Priorities",
  services: "Services",
  language: "language",
  profile: "Profile",
  parametrs: "Parametrs",
  dark_mode: "Dark mode",
  ballance: "Ballance",
  payable: "Payable",
  faq: "FAQ",
  top_up: "Top up",
  payment_by_card: "Payment by card",
  service_fee_for_different_bank_cards: "Service fee for different bank cards",
  pay: "Pay",
  enter_amount: "Enter amount",
  banks1: " TBC, Libery, Halik, Basis Bank, Tera Bank",
  banks2: "BOG, Qartu Bank, Prokredit Bank",
  room: "Room",
  all: "All",
  payment: "Payment",
  general: "General",
  shops: "Shops",
  addresses: "Addresses",
  for_woman: "For woman",
  for_man: "For man",
  for_childrens: "For childrens",
  self_care_products: "Self care products",
  residence: "Residence",
  brend_shops: "Brend shops",
  add_new_address: "Add New Address",
  name: "Name",
  surname: "Surname",
  address: "Address",
  save: "Save",
  flight: "Flight",
  arrived: "Arrived",
  departure: "Departure",
  pay_all: "Pay all",
  from_china: "From Turkey",
  from_china_land: "From Turkey (Land)",
  from_turkey: "From China",
  from_america: "From USA",
  parcels: "Parcels",
  in_the_warehouse: "In the warehouse",
  sent: "sent",
  received: "received",
  arrival_time: "Estimated time of arrival",
  payed: "Payed",
  weight: "Weight",
  transportation: "Transportation",
  declaration: "Declare",
  details: "Details",
  tracking: "Tracking",
  courier_service: "Courier service",
  products: "Products",
  sum: "Sum",
  personal_parcel: "Personal parcel",
  web_site: "web-site",
  insurence: "Insurance",
  comment: "Comment",
  declarationn: "Declaration",
  add_new_product: "Add new product",
  delete: "Delete",
  add_invoice: "Add invoice",
  price: "Price",
  category: "Category",
  add: "Add",
  "სხვადასხვა მზა ნაწარმი": "Various finished products",
  "ავტო ნაწილები": "Auto parts",
  "აუდიო აპარატურა": "Audio devices",
  "აუდიო აპარატურის ნაწილი": "Audio device parts",
  ბიჟუტერია: "jewelry",
  გასაღები: "Key",
  დანა: "Knife",
  "ვიდეო აპარატურა": "Video devices",
  "ვიდეო აპარატურის ნაწილები": "Video device parts",
  თეთრეული: "bed linen",
  "თვითწებვადი ლენტი (სკოჩი)": "Tape",
  კომპიუტერი: "Computer",
  "კომპიუტერის ნაწილი": "Computer Parts",
  "კოსმეტიკური საშუალებები": "Cosmetics",
  მედიკამენტები: "Medicaments",
  "მინის ნაწარმი": "Glass products",
  "მოტოციკლის ნაწილები": "Motorcycle parts",
  "მუსიკალური ინსტრუმენტები": "Musical instruments",
  "მუსიკალური ინსტრუმენტის ნაწილი": "Musical instrument parts",
  მცენარეები: "Plants",
  "ნაბეჭდი პროდუქცია": "Printed products",
  პარფიუმერია: "Parfume",
  "პლასტმასის საკანცელარიო ნივთები": "Plastic stationery",
  "პლასტმასის სასადილო, სამზარეულო ჭურჭელი": "Plastic dining, kitchen utensils",
  "რეზინის ნაწარმი": "Rubber products",
  საათი: "Wach",
  "საზომ-საკონტროლო ხელსაწყოები": "Measuring and control tools",
  სათამაშო: "Toy",
  სათვალე: "Glasses",
  საკეტი: "Lock",
  "საკვები დანამატები": "Food additives",
  "სანათი მოწყობილობები": "Lighting devices",
  "საოფისე ინვენტარი": "Office equipment",
  "სარეცხი და საწმენდი საშუალებები": "Washing and cleaning products",
  სასწორი: "Scales",
  "საყოფაცხოვრებო ავეჯი": "Household furniture",
  "საყოფაცხოვრებო ტექნიკა": "Household appliances",
  "სპორტული ინვენტარი": "Sports equipment",
  ტანსაცმელი: "Clothes",
  ტელეფონი: "Telephone",
  ტენტი: "Tent",
  "ფაიფურის ჭურჭელი": "Porcelain dishes",
  ფეხსაცმელი: "Shoes",
  "ფოტო აპარატურა": "Photo equipment",
  "ფოტო აპარატურის ნაწილი": "A piece of photographic equipment",
  "ქსელური მოწყობილობა": "Network device",
  ჩანთა: "Bag",
  "ხელის ინსტრუმენტები": "Hand tools",
  "სხვადასხვა ელექტრონული მოწყობილობები": "Various electronic devices",
  invoice: "Invoice",
  inv_name: "Name: Express Group LTD",
  inv_identification_code: "I/C: 405427364",
  inv_phone: "Phone: 0 322 197 192",
  inv_email: "Email: info@expressgroup.ge",
  inv_address: "Address: 4 Spring Street",
  recipient: "Recipient",
  fullname: "Name / Surname",
  id: "ID",
  room_id: "Room ID",
  phone: "Phone",
  tracking_number: "Tracking number",
  exp_ltd: "Express Group LTD",
  bank_details: "Bank details",
  bank_code: "Bank code",
  inv_pr: "Purpose: specify the room number when making the payment",
  change_photo: "Change photo",
  name_ge: "Name In Georgian",
  surname_ge: "Surname In Georgian",
  name_en: "Name In English",
  surname_en: "Surname In English",
  birth_date: "Date Of Birth ",
  id_num: "ID",
  gender: "Gender",
  city: "City",
  man: "Man",
  woman: "Woman",
  transactions: "Transactions",
  enroll: "Enroll",
  successful: "Successful",
  unsuccessful: "Unsuccessful",
  manage_your_personal_information: "Manage your personal information",
  security: "Security",
  view_or_change_your_password: "View or change your password",
  my_addresses: "My addresses",
  manage_your_address_for_better_courier_service:
    "Manage your address for better courier service",
  notification: "Notification",
  manage_the_information_you_want_to_receive_via_SMS_and_Email:
    "Manage the information you want to receive via SMS and Email",
  to_keep_your_password_safe_please_observe_the_following_Recommendations:
    "To keep your password safe, please follow the recommendations below",
  must_contain_at_least_characters: "Must contain at least 6 characters",
  it_is_recommended_to_include_at_least_one_number:
    "It is recommended to include at least one number",
  it_is_recommended_to_include_at_least_one_different_characte:
    "It is recommended to include at least one different character",
  do_not_use_simple_and_common_passwords:
    "Do not use simple and common passwords",
  new_password: "New Password",
  conf_password: "Confirm Password",
  old_password: "Old Password",
  parcel_warehouse: "Parcel is in warehouse",
  parsel_sent: "Parcel sent",
  parsel_arrived: "Parcel Arrived",
  parcel_received: "Parcel received",
  registration: "Registration",
  step: "Step",
  contact_info: "Contact information",
  personal_info: "Personal Information",
  user_type: "User type",
  physical_person: "Physical person",
  legal_entity: "Legal entity",
  next: "Next",
  back: "Back",
  underage: "Underage!",
  incorrect_id: "Invalid ID!",
  dir_name_ge: "Director Name (Ge)",
  dir_surname_ge: "Director Surname (Ge)",
  dir_name_en: "Director Name (En)",
  dir_surname_en: "Director Surname (En)",
  dir_id: "Director Id",
  symbol: "9 symbol!",
  invalid_email: "Invalid email!",
  identification_number: "Identification number",
  compay_name: "Company Name",
  error: "Error",
  password_dont_match: "Passwords Don`t match!",
  i_agree_the_terms_and_conditions_of_the_site:
    "I agree to the terms and conditions of use of the site",
  in_warehouse: "In warehouse",
  change_pass_email: "Reset password with email",
  change_pass: "Reset password",
  success: "Success !",
  parcels_arrived_at_the_warehouse: "Parcels arrived at the warehouse",
  search: "Search",
  choose: "Choose",
  bag: "Bag",
  receive: "Receive",
  user_information: "User infomation",
  parcel_information: "Parcel Information",
  parcel_id: "Parcel ID",
  price_sum: "Declare price sum",
  date: "Date",
  secured: "Secured",
  unsecured: "Unsecured",
  send: "send",
  company_name: "Company Name (En)",
  company_ic: "Company IC",
  parcel: "Parcel",
  trancking_number_is_alreay_added: "Barcode is already added",
  el_code_err: "Email code is invalid or filds are empty!",
  phone_code_err: "Phone code is invalid or filds are empty!",
  email_registerd: "Email already registered or filds are empty!",
  phone_registerd: "Phone already registered or filds are empty!",
  id_registerd: "ID already registered or filds are empty!",
  sc_registerd: "IC already registered or filds are empty!",
  does_not_match: "Code does not match or other error!",
  recover_password: "Password recovery",
  password_sent: "Password sent!",
  user_do_not_exists: "User is not registered!",
  log_out: "Log Out",
  declaration_successfull: "Declaration Succesfull !",
  update_address: "Update Address",
  update: "Update",
  email_code: "Email code",
  phone_code: "Phone code",
  send_code: "Send code",
  confirm: "Confirm",
  product: "Product",
  fill_all_fields: "All fields are required!",
  pay_successfull: "Payment completed successfully!",
  clearance_0: "It is customs clearance",
  clearance_1: "Customs cleared",
  messeges: "Messages",
  codes: "Codes",
  else: "Other sites",
  dec_text:
    "P.S. If the total value of the item or the parcels on one flight exceeds 300 GEL or the weight exceeds 30 kg, the parcel is subject to customs clearance, please upload the invoice!",
  "მოცულობითი წონით": "By Volumetric Weight",
  "რეალური წონით": "By Real Weight",
  invalid_phone: "Phone number is invlid!",
  fill_phone_required: "Phone number is required!",
  office: "office",
  please_fill_website_field: "website field is required !",
  check_currior_box:
    "Check the box courier service, otherwise the changes will not be recorded!",
  where_you_take_package: "Where do you want to pick up the parcel?",
  no_amount_was_specified: "No amount was specified!",
  pay_transportation: "Please pay the shipping fee before signing the courier",
  dec_pack_err: "Please add a new product",
  address_required:
    "Specifying the address is mandatory, the desired address can be updated/added: Settings -> My addresses",
  setting_filil_text:
    "Indicate from which branch you want to pick up the parcel",
  chose_filial: "Choose branch",
  choose_city: "Choose a city",
  payment_success: "The payment was made successfully",
  all_paid: "Unpaid parcel not found",
  not_enough_money: "There is not enough money in the account",
  length: "Length",
  width: "Width",
  height: "Height",
  calculate: "calculation",
  calc_volume_weight: "calculate volumetric weight",
  value_cant_0: "Value cannot be zero",
  fields_empty: "Fill all fields",
  volume_weight: "Volume Weight",
  already_paid: "paid",
  courier_clearance: "Courier",
  courier_description:
    "Tick the desired parcels for which you want the courier service to be signed",
  office_notification_text:
    "Specify the office from which you want to pick up the parcel",
  chose_filial_caution:
    "The change of the branch will affect only the parcels that arrived at the foreign warehouse after this change",
  select_all: "Select All",
  land: "Land",
  air: "Air",
  instructions: "Instructions",
  video_instructions: "Video Instructions",
  prices_text: "Weight is calculated to the nearest 100 grams",
  cm: "cm",
  kg: "kg",
  greece: "greece",
  curriorText: `The cost of delivering a parcel up to 3 kg to Tbilisi is 5.50 GEL.
    The cost of delivery of parcels from 3 to 10 kg is 10 GEL. The cost of delivering parcels up to 5 kg in the region
    It is 7-12 GEL. From 5 kg and above, different tariffs apply, depending on the specific address`,
  curriorText2: `First, please pay the shipping fee for the parcels to be cleared by the courier`,
  foreigner: "citizen of a foreign country",
  cheque: "20 lari receipt",
  yellow: "In Yellow",
  yellowCautionText:
    "Your parcel(s) has arrived in the yellow corridor. Please upload the invoices for each parcel immediately",
  advertising: "Advertising",
  deleteAccount: "delete account",
  autoParts: "Auto parts",
  other: "Other",
  parcel_clarence: "Parcel clearance",
  reallyWantClarenceText: "Are you sure you want to clear the item?!",
  clarence_caution_text:
    "Take note! Do not mark customs clearance if the total value of your parcels per flight does not exceed 300 GEL; does not exceed 30 kilos; There are not a few homogeneous things; Or you don't want to clear your parcels yourself.",
  yes: "Yes",
  no: "No",
  england: "England",
  რეალური: "Real",
  მოცულობითი: "Volumetric",
  pending: "pending",
  currior: "currior",
  trusteeError: "Personal number, house and surname are mandatory fields!",
  anotherPerson: "another person",
  incorrect_id: "Incorrect id",
  already_updated: "you have already updated in the last 5 seconds",
};

export default translationEn;

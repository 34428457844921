import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  SelectInputValueId,
  DateInput,
  CheckBox,
} from "../../inputComponents/inputcomp";

//images
import logo from "../../../img/phoneLogo.png";

const InnerFlightsPage = () => {
  //get current user id
  const { flightsId } = useParams();
  //new flights data
  const [flightsData, setFlightsData] = useState({
    office: "",
    date: "",
  });

  //handle new flights
  const handleNewFlightsData = (value, inputName) => {
    if (inputName === "flight_status") {
      setFlightsData((prev) => ({
        ...prev,
        [inputName]: value === "true" ? 1 : 0,
      }));
    } else setFlightsData((prev) => ({ ...prev, [inputName]: value }));
  };

  //add new flights
  const handleEditFlights = async () => {
    try {
      const res = await axiosAdmin.post(
        `/Flights/edit_post/${flightsId}`,
        flightsData
      );
      if (res) {
        successToast("მონაცემები წარმატებით განახლდა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //flights data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Flights/edit/${flightsId}`).then(({ data }) => {
        setFlightsData({
          date: data.date,
          office: data.office,
          flight_status: data.flight_status,
        });
      });
    } catch (error) {}
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-10 h-10 ">
                <img src={logo} className="w-full h-full rounded-full"></img>
              </div>
              <div>
                <h2
                  className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                >
                  რეისის რედაქტირება
                </h2>
              </div>
            </div>
            <button
              onClick={handleEditFlights}
              className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
            >
              შენახვა
            </button>
          </div>
          <div className="pt-5 grid grid-cols-2 gap-5 md:grid-cols-1 sm:grid-cols-1">
            <div className="w-full">
              <SelectInputValueId
                name={"ქვეყანა"}
                inputName={"office"}
                inputSetter={handleNewFlightsData}
                options={[
                  {
                    name: "თურქეთის მისამართი",
                    value: 1,
                  },
                  {
                    name: "ჩინეთის მისამართი",
                    value: 5,
                  },
                  {
                    name: "ამერიკის მისამართი",
                    value: 16,
                  },
                  {
                    name: "ჩინეთის სახმელეთო მისამართი",
                    value: 19,
                  },
                  {
                    name: "საბერძნეთის მისამართი",
                    value: 20,
                  },
                  {
                    name: "ბრიტანეთი",
                    value: 21,
                  },
                ]}
                value={flightsData.office}
              />
            </div>
            <div className="w-full">
              <DateInput
                name={"თარიღი"}
                inputSetter={handleNewFlightsData}
                inputName={"date"}
                value={flightsData.date}
              />
            </div>
            <div className="w-full">
              <CheckBox
                name="ჩამოსულია ? (თუ მონიშნულია ესიგი კი )"
                value={flightsData.flight_status === 1 ? "true" : "false"}
                toString={true}
                inputName={"flight_status"}
                handleCarData={handleNewFlightsData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerFlightsPage;

import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import axiosClient from "../../api/api";

import { Text } from "../../components/Text";
import PanelHead from "../../components/PanelHead/PanelHead";
import SelecWithValue from "../../components/CustomInputs/SelectWithValue";
import {
  successToast,
  errorToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { PrimmaryContext } from "../../components/MainContext/MainContext";

import { TbPlaneArrival, TbPlaneDeparture } from "react-icons/tb";
import CustomsClearanceCard from "./CustomsClearanceCard";
import Map from "../../components/googleMap/Map";
import CustomTextArea from "../../components/CustomInputs/CustomTextArea";

const CustomsClearance = () => {
  //translations
  const { t } = useTranslation();
  //pack type
  const [packTypeId, setPackTypeId] = useState(3);
  //packages
  const [parcels, setParcels] = useState([]);
  //user
  const { addresses, user } = useContext(PrimmaryContext);
  //data updater
  const [update, setUpdate] = useState(null);
  //select All
  const [selectAll, setSelectAll] = useState(false);
  //delivery company
  const [companyList, setCompanyList] = useState([]);
  //selected options
  const [currierPayload, setCurrierPayload] = useState({
    providerId: null,
    providerFeeId: null,
    phone: null,
    transport_address: null,
    selectedParcels: [],
  });

  //process
  const [process, setProcess] = useState("start");

  //handle select all
  const handleCurrierPayload = (value, key) => {
    if (key === "lat") {
      setPackTypeId(4);
      setCurrierPayload((prev) => ({ ...prev, selectedParcels: [] }));
    } else {
    }
    setCurrierPayload((prev) => ({ ...prev, [key]: value }));
  };

  const handleProviderInformation = (
    providerId,
    providerFeeId,
    parcelDimensionsId
  ) => {
    setCurrierPayload((prev) => ({
      ...prev,
      providerId,
      providerFeeId,
      parcelDimensionsId,
    }));
  };

  const submitFunction = () => {
    axiosClient
      .post("Pack/makeTransportations", {
        transport_address: currierPayload.transport_address,
        addon_transportation: "on",
        ids: currierPayload.selectedParcels,
        phone: currierPayload.phone,
      })
      .then((res) => {
        setUpdate(res);
        successToast(t("success"));
      })
      .catch((err) => {
        errorToast(t("error"));
      });
  };

  //change current user data in db
  function handleSubmit() {
    const tmp = parcels.filter(
      (item) => item.transport_price === item.transport_paid
    );

    if (tmp) {
      if (currierPayload.phone.length === 9) {
        if (currierPayload.transport_address) {
          submitFunction();
        } else {
          errorToast(t("address_required"));
        }
      } else if (currierPayload.phone.length === 0) {
        errorToast(t("fill_phone_required"));
      } else {
        errorToast(t("invalid_phone"));
      }
    } else {
      errorToast(t("pay_transportation"));
    }
  }

  //handle selected packages
  const handleSelectedPackages = (e, id) => {
    if (e.target.checked) {
      setCurrierPayload((prev) => ({
        ...prev,
        selectedParcels: [...prev.selectedParcels, id],
      }));
    } else {
      setCurrierPayload((prev) => ({
        ...prev,
        selectedParcels: prev.selectedParcels.filter((item) => item !== id),
      }));
    }
  };

  //handle select all
  const handleSelectAll = (status) => {
    if (status) {
      setCurrierPayload((prev) => ({
        ...prev,
        selectedParcels: parcels.map((parcel) => {
          return parcel.id;
        }),
      }));
    } else {
      setCurrierPayload((prev) => ({
        ...prev,
        selectedParcels: [],
      }));
    }
    setSelectAll(status);
  };

  //package data api
  useEffect(() => {
    axiosClient
      .get(`/Dashboard/pir/${packTypeId}?nocur=${1}`)
      .then(({ data }) => {
        setParcels(data.pack);
      })
      .catch((err) => {});
  }, [packTypeId, update]);

  const handleGetFees = () => {
    if (!currierPayload.lng) {
      errorToast("მონიშნეთ ადგილმდებარეობა");
    } else if (currierPayload.selectedParcels.length === 0) {
      errorToast("მონიშნეთ ამანათები");
    } else {
      axiosClient
        .post(`/quickshipper2/start`, {
          ToLatitude: currierPayload.lat,
          ToLongitude: currierPayload.lng,
          parcels: currierPayload.selectedParcels,
        })
        .then(({ data }) => {
          setCompanyList(data);
          setProcess("finish");
        });
    }
  };

  const handleFinishOrdering = () => {
    if (!currierPayload.lng) {
      errorToast("მონიშნეთ ადგილმდებარეობა");
    } else if (currierPayload.selectedParcels.length === 0) {
      errorToast("მონიშნეთ ამანათები");
    } else if (!currierPayload.transport_address) {
      errorToast("აირჩიეთ მისამართი მისამართების ველიდან");
    } else if (!currierPayload.phone) {
      errorToast("მიუთითეთ ტელეფონის ნომერი");
    } else if (!currierPayload.providerId) {
      errorToast("აირჩიეთ საკურიერო სერვისი");
    } else {
      axiosClient
        .post(`/quickshipper2/makeOrder`, {
          comment: currierPayload.comment,
          dropOffInfo: {
            longitude: currierPayload.lng,
            latitude: currierPayload.lat,
            addressComment: currierPayload.addressComment,
            address: currierPayload.transport_address,
            name: user.fname_eng + " " + user.lname_eng,
            phonePrefix: "+995",
            phone: currierPayload.phone,
            city: currierPayload.city,
            country: currierPayload.country,
          },
          provider: {
            providerId: currierPayload.providerId,
            providerFeeId: currierPayload.providerFeeId,
          },
          parcels: currierPayload.selectedParcels,
          parcelDimensionsId: currierPayload.parcelDimensionsId,
        })
        .then((res) => {
          successToast("შეკვეთა წარმატებით დასრულდა");
        });
    }
  };

  return (
    <div
      className="min-h-screen flex flex-1 flex-col font-firago gap-[36px] items-start
     justify-start max-w-[1500px] md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] sm:gap-8
     sm:pb-[80px] md:pb-[80px]"
    >
      <PanelHead name={t("courier_clearance")} />
      <div className="hidden sm:flex md:flex items-center gap-3 mb-3">
        <Text
          className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
                    dark:text-[#c7c7c7]"
          variant="body1"
        >
          {t("courier_clearance")}
        </Text>
      </div>
      <div className="font-notosansgeorgian relative w-[100%]">
        <div className="w-full grid grid-cols-1 gap-5">
          <div className="w-full font-notosansgeorgian grid grid-cols-2 sm:grid-cols-1 gap-4">
            <SelecWithValue
              name={t("address")}
              options={addresses}
              inputName={"transport_address"}
              inputSetter={handleCurrierPayload}
            />
            <div
              className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center 
                            justify-start outline outline-[1px] outline-black_900_0a dark:bg-[#171719]
                            p-[18px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] h-full dark"
            >
              <div className="flex flex-1 flex-col gap-[4px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                <Text
                  className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
                  variant="body9"
                >
                  {t("phone")}
                </Text>
                <input
                  name="transport_phone"
                  placeholder={"XXX XX XX XX"}
                  className=" border-0 outline-none text-indigo_400 font-semibold dark:text-[#c7c7c7] bg-transparent"
                  value={currierPayload.phone}
                  onChange={(e) => {
                    handleCurrierPayload(e.target.value, "phone");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items center justify-between gap-3 sm:flex-col">
            <div
              className="bg-gray_600_29 flex flex-row md:gap-[0px] sm:gap-[0px] items-center justify-start p-[2px] 
            rounded-radius10 md:w-[100%] sm:w-[100%] w-[auto] cursor-pointer dark:bg-[#171719]"
            >
              {!currierPayload.lat && (
                <div
                  className={`common-pointer flex flex-row gap-[12px] items-center justify-center px-[16px] py-[8px] rounded-radius8  
 md:w-full sm:w-full h-full ${
   packTypeId === 3 &&
   "bg-white outline outline-[1px] outline-black_900_0a shadow-bs dark:bg-[#48484a]"
 }`}
                  onClick={() => {
                    setPackTypeId(3);
                  }}
                >
                  <TbPlaneDeparture
                    className="w-5 h-5 bg-transparent 
     cursor-pointer font-semibold text-[15px] text-[#636366] text-left dark:text-[#c7c7c7]"
                  />
                  <Text
                    className="font-normal not-italic text-black_900_b7 text-left w-[auto]  md:hidden sm:hidden"
                    variant="body7"
                  >
                    <span
                      className={`text-[15px] font-notosansgeorgian text-black_900_b7  dark:text-[#c7c7c7]
       ${packTypeId === 3 ? "font-[600]" : "font-[400]"}`}
                    >
                      {t("sent")}
                    </span>
                  </Text>
                </div>
              )}

              <div
                className={`common-pointer flex flex-row gap-[12px] items-center justify-center px-[16px] py-[8px] rounded-radius8 
                md:w-full sm:w-full h-full ${
                  packTypeId === 4 &&
                  "bg-white outline outline-[1px] outline-black_900_0a shadow-bs dark:bg-[#48484a]"
                }`}
                onClick={() => {
                  setPackTypeId(4);
                }}
              >
                <TbPlaneArrival
                  className="w-5 h-5 bg-transparent 
                    cursor-pointer font-semibold text-[15px] text-[#636366] text-left dark:text-[#c7c7c7]"
                />
                <Text
                  className="font-normal not-italic text-black_900_b7 text-left w-[auto] md:hidden sm:hidden"
                  variant="body7"
                >
                  <span
                    className={`text-[15px] font-notosansgeorgian text-black_900_b7 dark:text-[#c7c7c7] 
                                        ${
                                          packTypeId === 4
                                            ? "font-[600]"
                                            : "font-[400]"
                                        }`}
                  >
                    {t("arrived")}
                  </span>
                </Text>
              </div>
            </div>
            <button
              onClick={handleSubmit}
              className="cursor-pointer font-semibold min-w-[181px] text-[17px] text-center w-[auto] rounded-radius11
              p-[14px] bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
            >
              {t("save")}
            </button>
          </div>

          <Text
            className="font-fw text-black_900_b7 text-left dark:text-[#c7c7c7]"
            variant="body7"
          >
            {t("curriorText2")}
          </Text>
          <Text
            className="font-fw text-black_900_b7 text-left dark:text-[#c7c7c7]"
            variant="body7"
          >
            {t("courier_description")}
          </Text>
          <Text
            className="font-fw text-red-400 text-left dark:text-[#c7c7c7]"
            variant="body7"
          >
            {t("curriorText")}
          </Text>
          <div className="flex flex-row gap-[16px] items-center justify-start w-[auto]">
            <input
              type="checkbox"
              className="mt-1"
              onChange={(e) => {
                handleSelectAll(e.target.checked);
              }}
            />
            <div className="flex flex-col items-start justify-start w-[auto]">
              <Text
                className="font-semibold text-black_900_b7 text-left w-[auto] dark:text-[#c7c7c7]"
                variant="body7"
              >
                {t("select_all")}
              </Text>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-3">
            {parcels.map((data) => {
              return (
                <CustomsClearanceCard
                  key={data.id}
                  data={data}
                  handleSelectedPackages={handleSelectedPackages}
                  isChecked={selectAll}
                  strangeFlag={currierPayload.selectedParcels}
                />
              );
            })}
          </div>

          {/* <p className="font-fw text-black_900_b7 dark:text-[#c7c7c7] text-xl mt-5">
            ან მიუთითეთ საასურველი საკურიერო მომსახურება
          </p>
          <div>
            <div className="mb-5">
              <Text
                className="font-fw text-black_900_b7 text-left dark:text-[#c7c7c7] "
                variant="body7"
              >
                მუთითეთ ზუსტი ადგილმდებარეობა
              </Text>
            </div>
            <Map getInformation={handleCurrierPayload} />
          </div>
          <div className="grid grid-cols-3 gap-5">
            {companyList.map((data) => {
              return (
                <div>
                  <div
                    key={data.providerId}
                    className={
                      "p-4 rounded-lg flex items-center justify-between cursor-pointer gap-2 mb-2 bg-white_A700"
                    }
                  >
                    <div className="flex items-center gap-2">
                      <img
                        src={data.providerLogoUrl}
                        alt={data.providerName}
                        className="w-8 h-8"
                      />
                      <p className="text-sm">{data.providerName}</p>
                    </div>
                  </div>
                  {data?.hasWeight && data?.weightData ? (
                    <>
                      <div
                        key={data.weightData?.parcelDimensionsId}
                        onClick={() => {
                          handleCurrierPayload("parcelDimensionsId");
                          handleProviderInformation(
                            data.providerId,
                            data?.prices?.length ? data?.prices[0]?.id : "",
                            data.weightData?.parcelDimensionsId
                          );
                        }}
                        className={`p-4 rounded-lg flex items-center justify-between cursor-pointer gap-2 mb-2
                          ${
                            currierPayload.parcelDimensionsId ===
                              data.weightData?.parcelDimensionsId &&
                            currierPayload.providerId === data.providerId
                              ? "bg-green-100"
                              : "bg-white_A700"
                          }`}
                      >
                        <p className="text-sm">
                          {data?.prices?.length > 0
                            ? data?.prices[0].deliverySpeedName
                            : ""}
                          {" / "}
                          {data.weightData?.displayName}
                        </p>
                        <p className="text-sm text-green-500">
                          {data.weightData?.userPrice}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      {data?.prices?.map((price) => {
                        return (
                          <div
                            key={price.id}
                            onClick={() => {
                              handleProviderInformation(
                                data.providerId,
                                price.id,
                                ""
                              );
                            }}
                            className={`p-4 rounded-lg flex items-center justify-between cursor-pointer gap-2 mb-2
                          ${
                            currierPayload.providerFeeId === price.id &&
                            currierPayload.providerId === data.providerId
                              ? "bg-green-100"
                              : "bg-white_A700"
                          }`}
                          >
                            <p className="text-sm">{price.deliverySpeedName}</p>
                            <p className="text-sm text-green-500">
                              {price.amount}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              );
            })}
          </div>

          {process === "finish" && (
            <div className="flex gap-4 flex-col">
              <CustomTextArea
                placeholder={"კომენტარი"}
                inputName={"comment"}
                inputSetter={handleCurrierPayload}
              />
              <CustomTextArea
                placeholder={"მისამართის კომენტარი"}
                inputName={"addressComment"}
                inputSetter={handleCurrierPayload}
              />
            </div>
          )}
          <div className="flex gap-[16px] items-center justify-start">
            <button
              onClick={handleGetFees}
              className="cursor-pointer font-semibold min-w-[181px] text-[17px] text-center w-[auto] rounded-radius11
    p-[14px] bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
            >
              დაწყება
            </button>
            {process === "finish" && (
              <button
                onClick={handleFinishOrdering}
                className="cursor-pointer font-semibold min-w-[181px] text-[17px] text-center w-[auto] rounded-radius11
 p-[14px] bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
              >
                შეკვეთის დასრულება
              </button>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CustomsClearance;

import { useContext, useEffect, useState } from "react";
import { TextInput } from "../../../inputComponents/inputcomp";
import axiosAdmin from "../../../../../api/apiAdmin";
import { errorToast, successToast } from "../../../toastAlerts/ToastAlerts";
import { useTranslation } from "react-i18next";

//icon
import { FaBarcode, FaRegHandPointRight } from "react-icons/fa";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";

const FilialsCardWar = ({
  item,
  currentPlaceId,
  handleCurrentPlaceId,
  setDifResponses,
}) => {
  //translations
  const { t } = useTranslation();
  //api errors
  const [error, setError] = useState(null);
  //dropdown toggler
  const [toggler, setToggler] = useState(false);
  //user
  const { user } = useContext(AdminPrimaryContext);
  //searched user
  const [searchedUser, setSearchedUser] = useState(null);
  //selected user
  const [selectedUser, setSelectedUser] = useState("-Search user-");
  //search toggler
  const [searchToggler, setSearchToggler] = useState(false);
  //make fields empty after submission
  const [makeEmpty, handleMakeEmpty] = useState(false);
  //weight and bag
  const [payloadBarcode, setPayloadBarcode] = useState({
    weight: "",
    bag: "",
    user_id: "",
    ao_id: item.id,
    barcode: "",
  });
  //america volume weight
  const [americaDimensions, setAmericaDimensions] = useState({});
  let timer;

  //handle Payload
  const handlePayloadBarcode = (value, inputName) => {
    setPayloadBarcode((prev) => ({ ...prev, [inputName]: value }));
    if (inputName !== "weight") {
      setAmericaDimensions((prev) => ({ ...prev, [inputName]: value }));
    }
  };

  //receive package functionality
  const handleReceive = async () => {
    if (payloadBarcode.weight !== "" && payloadBarcode.bag !== "") {
      try {
        const res = await axiosAdmin.post(
          `Pack/barcode_post/${payloadBarcode.barcode}`,
          payloadBarcode
        );
        if (res) {
          successToast(t("success"));
          setDifResponses(res);
          handleMakeEmpty(true);
        }
      } catch (err) {
        setError(err);
        errorToast(t("trancking_number_is_alreay_added"));
      }
    } else {
      errorToast("Fill all fields");
    }
  };

  //receive package functionality
  const handleFindUser = (e) => {
    const searchTerm = e.target.value;
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (searchTerm) {
        axiosAdmin
          .get(`/Users/sh/${searchTerm}`)
          .then(({ data }) => {
            setSearchedUser(data);
          })
          .catch((err) => {});
      }
    }, 1000);
  };

  //const choosing user
  const hanleChooseUser = (value, userId) => {
    setSelectedUser(value);
    handlePayloadBarcode(userId, "user_id");
  };

  //clear all fields
  useEffect(() => {
    if (makeEmpty) {
      setSelectedUser("-Search user-");
      setSearchedUser([]);
      setPayloadBarcode({
        weight: "",
        bag: "",
        user_id: "",
        ao_id: item.id,
        barcode: "",
      });
      setAmericaDimensions({});
    }
  }, [makeEmpty]);

  //calculate volume weight based on dimension values and write it in state
  useEffect(() => {
    let x = americaDimensions.dementionX ? americaDimensions.dementionX : 0;
    let y = americaDimensions.dementionY ? americaDimensions.dementionY : 0;
    let z = americaDimensions.dementionZ ? americaDimensions.dementionZ : 0;
    let divideBy = item?.id === 19 ? 4000 : 6000;
    if (x * y * z !== 0) {
      var newWeight = (x * y * z) / divideBy;
      setAmericaDimensions((prev) => ({
        ...prev,
        volume_weight: newWeight.toFixed(2),
      }));
    }
  }, [americaDimensions]);

  return (
    <div
      className={`w-full flex items-center p-3 rounded-lg gap-5 cursor-pointer
shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px] relative
 ${
   currentPlaceId === item.id
     ? "border border-green-500 bg-green-100"
     : "bg-white dark:bg-slate-700"
 }`}
    >
      <div className="w-full flex flex-col gap-2 justify-between h-full">
        <p className="text-[14px] text-[#8e95a8]">
          {user.role === 3 || user.role === 2 ? item.titleen : item.title}
        </p>
        <p className="text-[14px] text-[#8e95a8]">
          {`${item.country} ${item.city}`}
        </p>
        <p className="text-[14px] text-[#8e95a8]">{item.address}</p>
        <p className="text-[14px] text-[#8e95a8]">{item.phone}</p>
        <p className="text-[14px] text-[#8e95a8]">
          {`${item.pack_count}`} parcel / {`${item.total_weight.toFixed(2)}`} kg
        </p>
        <div className="flex gap-1 relative">
          <div
            onClick={() => {
              handleCurrentPlaceId(item.id);
            }}
            className="w-full rounded-lg bg-green-400
flex items-center justify-center gap-1 p-1 py-3 text-white"
          >
            <FaRegHandPointRight />
            <p>{t("choose")}</p>
          </div>
          {!(user.role === 4) &&
            !(user.role === 5) &&
            !(user.role === 6) &&
            !(user.role === 8) && (
              <div className="w-full relative ">
                <div
                  onClick={() => {
                    setToggler(true);
                  }}
                  className="w-full rounded-lg bg-yellow-400
flex items-center justify-center gap-1 p-1 py-3 text-white"
                >
                  <FaBarcode />
                  <p>Barcode Scan</p>
                </div>
                {toggler && (
                  <div className="w-screen h-screen fixed top-0 left-0 p-10 bg-white z-[100] dark:bg-slate-700">
                    <div className="w-full flex  items-center justify-end">
                      <div
                        onClick={() => {
                          handleMakeEmpty(true);
                          setToggler(false);
                        }}
                        className="w-6 h-6 rounded-full bg-red-400 flex items-center justify-center cursor-pointer"
                      >
                        <p className="text-white font-bold">X</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2">
                      <TextInput
                        name={"Barcode"}
                        inputName={"barcode"}
                        inputSetter={handlePayloadBarcode}
                        makeEmpty={makeEmpty}
                        handleMakeEmpty={handleMakeEmpty}
                      />
                      <div className="w-full flex gap-2 items-center">
                        <TextInput
                          name={t("weight")}
                          inputName={"weight"}
                          inputSetter={handlePayloadBarcode}
                          type="number"
                          makeEmpty={makeEmpty}
                          handleMakeEmpty={handleMakeEmpty}
                          value={payloadBarcode.weight}
                        />
                        {(item.id === 16 ||
                          item.id === 19 ||
                          item.id === 5) && (
                          <TextInput
                            name={"volume weight"}
                            inputName={"volume_weight"}
                            type="number"
                            makeEmpty={makeEmpty}
                            handleMakeEmpty={handleMakeEmpty}
                            value={americaDimensions.volume_weight}
                          />
                        )}
                      </div>
                      <TextInput
                        name={t("bag")}
                        inputName={"bag"}
                        inputSetter={handlePayloadBarcode}
                        makeEmpty={makeEmpty}
                        handleMakeEmpty={handleMakeEmpty}
                        value={payloadBarcode.bag}
                      />
                      <div className="flex flex-col gap-2 relative">
                        <div
                          className="flex flex-col mt-2"
                          onClick={() => {
                            setSearchToggler((prev) => !prev);
                          }}
                        >
                          <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">
                            {t("room_id")}
                          </p>
                          <div
                            className="h-10 w-full pl-1.5 dark:bg-slate-800 border border-gray-300 shadow rounded-lg
                                                flex items-center "
                          >
                            <p className="text-gray-500">{selectedUser}</p>
                          </div>
                        </div>
                        {searchToggler && (
                          <div
                            className="border border-gray-300 shadow-sm rounded-lg p-2
                                            flex flex-col gap-2 absolute top-[105%] left-0 w-full bg-white z-30"
                          >
                            <input
                              type="text"
                              onChange={(e) => {
                                handleFindUser(e);
                              }}
                              className="w-full h-8 pl-1.5 focus:outline-none text-gray-500
                                                dark:bg-slate-800 border border-gray-300 shadow rounded-lg"
                            />
                            {searchedUser &&
                              searchedUser.map((item) => {
                                return (
                                  <div key={item.id}>
                                    {item.user_type === 1 && (
                                      <p
                                        onClick={() => {
                                          hanleChooseUser(
                                            `EG${item.id} - ${item.fname_eng} ${
                                              item.lname_eng
                                            } (${
                                              [9, 7, 2, 3].includes(user.role)
                                                ? ""
                                                : item.id_num
                                            }) (${item.office.addressen})`,
                                            item.id
                                          );
                                          setSearchToggler(false);
                                        }}
                                        className="text-gray-500 text-sm"
                                      >
                                        {`EG${item.id} - ${item.fname_eng} ${
                                          item.lname_eng
                                        } (${
                                          [9, 7, 2, 3].includes(user.role)
                                            ? ""
                                            : item.id_num
                                        }) (${item.office.addressen})`}
                                      </p>
                                    )}
                                    {item.user_type === 2 && (
                                      <p
                                        onClick={() => {
                                          hanleChooseUser(
                                            `EG${item.id} - ${
                                              item.ceo_fname_eng
                                            } ${item.ceo_lname_eng} (${
                                              [9, 7, 2, 3].includes(user.role)
                                                ? ""
                                                : item.ceo_id_num
                                            }) (${item.office.addressen})`,
                                            item.id
                                          );
                                          setSearchToggler(false);
                                        }}
                                        className="text-gray-500 text-sm"
                                      >
                                        {`EG${item.id} - ${
                                          item.ceo_fname_eng
                                        } ${item.ceo_lname_eng} (${
                                          [9, 7, 2, 3].includes(user.role)
                                            ? ""
                                            : item.ceo_id_num
                                        }) (${item.office.addressen})`}
                                      </p>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                      {(item.id === 16 || item.id === 19 || item.id === 5 || item.id === 21) && (
                        <>
                          <TextInput
                            name={"width"}
                            inputName={"dementionX"}
                            inputSetter={handlePayloadBarcode}
                            makeEmpty={makeEmpty}
                            handleMakeEmpty={handleMakeEmpty}
                          />
                          <TextInput
                            name={"height"}
                            inputName={"dementionY"}
                            inputSetter={handlePayloadBarcode}
                            makeEmpty={makeEmpty}
                            handleMakeEmpty={handleMakeEmpty}
                          />
                          <TextInput
                            name={"length"}
                            inputName={"dementionZ"}
                            inputSetter={handlePayloadBarcode}
                            makeEmpty={makeEmpty}
                            handleMakeEmpty={handleMakeEmpty}
                          />
                        </>
                      )}
                      <div
                        onClick={() => {
                          handleReceive();
                        }}
                        className="w-full  h-10 rounded-lg bg-green-400 flex items-center justify-center cursor-pointe mt-8"
                      >
                        <p className="text-white font-bold">{t("receive")}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default FilialsCardWar;

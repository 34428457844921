import { useEffect, useState } from "react";
import { Text } from "../../components/Text";
import PanelHead from "../../components/PanelHead/PanelHead";
import PanelfaqPageCard from "./PanelfaqPageCard";
import axiosClient from "../../api/api";
import { useTranslation } from "react-i18next";

const PanelfaqPage = () => {
  //translations
  const { t } = useTranslation();
  //faq categorys
  const categoryList = [
    {
      name: t("all"),
      value: 0,
    },
    {
      name: t("video_instructions"),
      value: 3,
    },
    {
      name: t("payment"),
      value: 1,
    },
    {
      name: t("general"),
      value: 2,
    },
  ];
  //faqs based on cateory
  const [faqCategory, setFaqCategory] = useState(0);
  //faqs data variable
  const [faqs, setFaqs] = useState([]);
  //error
  const [error, setError] = useState(null);

  //get current logged in user
  useEffect(() => {
    axiosClient
      .get(`/Faq/sub/${faqCategory}`)
      .then(({ data }) => {
        setFaqs(data);
      })
      .catch((err) => {
        setError(err);
      });
  }, [faqCategory]);

  return (
    <div
      className="min-h-screen flex flex-1 gap-[36px] flex-col font-firago items-start justify-start
      max-w-[1500px] md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] sm:pb-[80px] md:pb-[80px]"
    >
      <PanelHead name={t("faq")} />
      <Text
        className="font-semibold text-black_900_e0 dark:text-[#c7c7c7]
        text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
          hidden sm:block md:block"
        variant="body1"
      >
        {t("faq")}
      </Text>
      <div
        className="flex flex-col font-notosansgeorgian gap-[40px] items-start justify-start w-[100%]
        sm:gap-4"
      >
        <div
          className="bg-gray_600_29 flex items-center justify-start p-[2px] dark:bg-[#171719]
             rounded-radius10 sm:w-[100%] w-[auto] sm:gap-[0px] sm:flex-wrap sm:justify-between"
        >
          {categoryList.map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  setFaqCategory(item.value);
                }}
                className={`cursor-pointer rounded-radius8 p-[11px] sm:w-[49%] sm:h-[70px]
                ${faqCategory === item.value &&
                  "bg-white_A700 outline outline-[1px] outline-black_900_0a shadow-bs text-black_900_e0 dark:bg-[#48484a]"
                  }`}
              >
                <p
                  className={`text-center sm:w-full sm:text-sm text-[15px] ${faqCategory === item.value
                    ? "text-black_900_e0 dark:text-[#c7c7c7]  font-semibold"
                    : "font-normal not-italic text-black_900_b7 dark:text-[#ededed]"
                    }`}
                >
                  {item.name}
                </p>
              </button>
            );
          })}
        </div>
        <div className="flex flex-col font-firago items-start justify-start w-[100%]">
          <div
            className="flex flex-col gap-[24px] items-start justify-start w-[100%]
            sm:gap-4"
          >
            {faqs.map((item) => {
              return (
                <PanelfaqPageCard
                  key={item.id}
                  text={item.text}
                  texten={item.texten}
                  textru={item.textru}
                  title={item.title}
                  titleen={item.titleen}
                  titleru={item.titleru}
                  video={item.video}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelfaqPage;

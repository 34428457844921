import { useState, useEffect, useContext } from "react";
import UserCard from "./userCard";
import axiosAdmin from "../../../../api/apiAdmin";
import { TextInput, SelectInputValueId } from "../../inputComponents/inputcomp";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";

//icons
import { BiUser } from "react-icons/bi";
import { BsEnvelope, BsLock, BsTelephone } from "react-icons/bs";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";

const UserPageLayout = () => {
    //add new user variable
    const [addNewToggler, setAddNewToggler] = useState(false);
    //get admin data from api
    const [adminData, setAdminData] = useState(null);
    //get admin data error from api
    const [adminDataErr, setAdminDataErr] = useState(null);
    //add new admin error from api
    const [adminAddErr, setAdminAddErr] = useState(null);
    //add new admin error from api
    const [adminDelErr, setAdminDelErr] = useState(null);
    //affect data change depended on delete and add responses
    const [difResponses, setDifResponses] = useState(null);
    //new admin data
    const [newAdmin, setNewAdmin] = useState({
        fname: "",
        lname: "",
        user: "",
        mail: "",
        phone: "",
        pass: "",
        role: ""
    });
    //clear fields after submit 
    const [makeEmpty, setMakeEmpty] = useState(false);
    //user
    const { user } = useContext(AdminPrimaryContext);

    //handle filed clear after submission
    const handleMakeEmpty = (value) => {
        setMakeEmpty(value);
    }

    //handle new admin
    const handleNewAdminData = (value, inputName) => {
        setNewAdmin(prev => ({ ...prev, [inputName]: value }))
    }

    //add new users handlers 
    const handleAddNewToggle = (value) => {
        setAddNewToggler(value);
    }

    //add new admin 
    const handleAddNewAdminToDb = async () => {
        try {
            const res = await axiosAdmin.post(`/Admins/add_post`, newAdmin);
            setDifResponses(res);
            if (res) {
                successToast("ადმინი წარმატებით დაემატა!");
                handleMakeEmpty(true);
            }
        } catch (error) {
            setAdminAddErr(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //delete admin 
    const handleDeleteAdmin = async (id) => {
        try {
            const res = await axiosAdmin.get(`/Admins/delete/${id}`);
            setDifResponses(res);
            if (res) {
                successToast("ადმინი წარმატებით წაიშალა!");
            }
        } catch (error) {
            setAdminDelErr(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //admin data from api
    useEffect(() => {
        try {
            axiosAdmin.get(`/Admins/index`).then(({ data }) => {
                setAdminData(data);
            });
        } catch (error) {
            setAdminDataErr(error);
        }
    }, [difResponses]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
            <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5" >
                {user.role === 100 &&
                    <div className="w-full flex items-center justify-end ">
                        <button
                            onClick={() => { handleAddNewToggle(true) }}
                            className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400 ">
                            ახალი ადმინის დამატება
                        </button>
                    </div>
                }
                {addNewToggler &&
                    <div className="bg-slate-100 dark:bg-slate-900">
                        <div className="sm:px-0">
                            <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                                <div className="flex items-center justify-between flex-wrap gap-5">
                                    <div className="flex items-center gap-5 sm:gap-3 ">
                                        <div>
                                            <h2 className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]">ახალი ადმინის დამატება</h2>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 sm:w-full">
                                        <button
                                            onClick={handleAddNewAdminToDb}
                                            className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                                            შენახვა
                                        </button>
                                        <button
                                            onClick={() => { handleAddNewToggle(false) }}
                                            className="rounded-lg p-3 text-white text-sm bg-red-400 w-10">
                                            X
                                        </button>
                                    </div>
                                </div>
                                <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                                    <div className="w-6/12 sm:w-full md:w-full">
                                        <TextInput
                                            name={"სახელი"}
                                            Icon={BiUser}
                                            inputSetter={handleNewAdminData}
                                            inputName={"fname"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"გვარი"}
                                            Icon={BiUser}
                                            inputSetter={handleNewAdminData}
                                            inputName={"lname"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"მომხმარებლის სახელი"}
                                            Icon={BiUser}
                                            inputSetter={handleNewAdminData}
                                            inputName={"user"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"ელ-ფოსტა"}
                                            Icon={BsEnvelope}
                                            inputSetter={handleNewAdminData}
                                            inputName={"mail"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                    </div>
                                    <div className="w-6/12 sm:w-full md:w-full">
                                        <TextInput
                                            name={"ტელეფონი"}
                                            Icon={BsTelephone}
                                            inputSetter={handleNewAdminData}
                                            inputName={"phone"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"პაროლი"}
                                            Icon={BsLock}
                                            inputSetter={handleNewAdminData}
                                            inputName={"pass"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <SelectInputValueId
                                            name={"ადმინის როლი"}
                                            inputName={"role"}
                                            inputSetter={handleNewAdminData}
                                            options={[
                                                {
                                                    name: "სუპერ ადმინი",
                                                    value: 100
                                                },
                                                {
                                                    name: "მთავარი ადმინი",
                                                    value: 1
                                                },
                                                {
                                                    name: "თურქეთის ოპერატორი",
                                                    value: 2
                                                },
                                                {
                                                    name: "ჩინეთის ოპერატორი",
                                                    value: 3
                                                },
                                                {
                                                    name: "ამერიკის ოპერატორი",
                                                    value: 7
                                                },
                                                {
                                                    name: "საქართველოს ოპერატიორი Back",
                                                    value: 4
                                                },
                                                {
                                                    name: "საქართველოს ოპერატიორი Front",
                                                    value: 5
                                                },
                                                {
                                                    name: "საქართველოს ქოლ ოპერატორი",
                                                    value: 6
                                                },
                                                {
                                                    name: "დეკლარანტი",
                                                    value: 8
                                                },
                                                {
                                                    name: "საბერძნეთს ოპერატორი",
                                                    value: 9
                                                },
                                                {
                                                    name: "ბრიტანეთის ოპერატორი",
                                                    value: 10
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="w-full min-h-screen">
                <div className="p-10 pt-0 sm:p-5 sm:pt-0 grid grid-cols-3 gap-5 md:grid-cols-2 sm:grid-cols-1">
                    {adminData && adminData.map((obj) => {
                        return (
                            < UserCard
                                name={`${obj.fname} ${obj.lname}`}
                                nickname={obj.user}
                                mail={obj.mail}
                                phone={obj.phone}
                                key={obj.id}
                                id={obj.id}
                                handleDeleteAdmin={handleDeleteAdmin}
                            />
                        )

                    })}
                </div>
            </div >
        </div>
    )
}

export default UserPageLayout;

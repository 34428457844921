import { useContext } from "react";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import FlightsModalCard from "./FlightsModalCard";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const FlightsModal = ({ flightsOpen, handleflightsOpen }) => {
  //translations
  const { t } = useTranslation();
  const { flightsData } = useContext(PrimmaryContext);

  return (
    <div
      className={`h-screen w-[400px] fixed top-0 right-0 bg-[#1c1c1e]
        z-50 transition-all dduration-300 delay-150 ease-linear sm:w-screen border-l border-gray-500
        ${flightsOpen ? "translate-x-[0px]" : "translate-x-[100%]"}`}
    >
      <div
        className="h-[10vh] w-full bg-[#2C2C2E]/75 flex items-center justify-between px-4
            border-b border-gray-700"
      >
        <h2 className="font-firago text-[20px] text-white font-[700]">
          {t("flights")}
        </h2>
        <div
          onClick={handleflightsOpen}
          className="w-[36px] h-[36px] rounded-full flex items-center justify-center bg-[#747480]/25
                    cursor-pointer"
        >
          <BsX className="text-[24px] text-[#FF453A]" />
        </div>
      </div>
      <div className="h-[90vh] overflow-y-auto scollbar-none scrollbar-none">
        {flightsData.map((obj) => {
          return (
            <div key={obj.id}>
              <FlightsModalCard data={obj} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FlightsModal;

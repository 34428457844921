import { useContext, useEffect } from "react";
import { PrimmaryContext } from "../MainContext/MainContext";

const CustomAlert = ({ text, setLgErrPopupFlag, alertType, Icon, value }) => {
  //scrolling prevented
  const { handleScollOff } = useContext(PrimmaryContext);

  //disable and enable scrollbar based on poup state
  useEffect(() => {
    if (value) {
      handleScollOff(true);
    }
  }, [value])

  return (
    <div
      className="fixed top-0 left-0 z-[60] w-screen h-screen bg-black
    bg-opacity-80 flex items-center justify-center"
    >
      <div className="bg-white rounded-lg p-10 flex flex-col items-center justify-center gap-5">
        <div
          className={`rounded-full w-20 h-20 border-4  p-1
        ${alertType === "error" && "border-red-500"}  
        ${alertType === "success" && "border-green-500"}`}
        >
          <Icon
            className={`w-full h-full 
          ${alertType === "error" && "text-red-500"}
          ${alertType === "success" && "text-green-500"}`}
          />
        </div>
        <div>
          <p className="text-xl font-firago text-gray-500">{text}</p>
        </div>
        <button
          className="px-4 py-2 rounded-lg bg-blue-700 text-white text-xl"
          onClick={() => {
            handleScollOff(false);
            setLgErrPopupFlag(false);
          }}
        >
          ok
        </button>
      </div>
    </div>
  );
};

export default CustomAlert;

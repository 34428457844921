import { useEffect, useRef, useState } from "react";
import {
  successToast,
  errorToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import axiosClient from "../../api/api";
import CustomTextInut from "../../components/CustomInputs/CustomTextInut";
import CustomDateInput from "../../components/CustomInputs/CustomDateInput";
import CustomSelectInput from "../../components/CustomInputs/CustomSelectInput";
import { Text } from "../../components/Text";
import { Line } from "../../components/Line";
import { Button } from "../../components/Button";
import { Img } from "../../components/Img";
import PanelHead from "../../components/PanelHead/PanelHead";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import Barcode from "react-barcode";

//icons
import { AiOutlineCamera, AiOutlineMobile } from "react-icons/ai";
import {
  BsTrash,
  BsPerson,
  BsCalendar,
  BsGenderAmbiguous,
  BsEnvelope,
  BsFlag,
  BsCheckLg,
} from "react-icons/bs";
import { BiMap } from "react-icons/bi";
import { FaRegAddressCard } from "react-icons/fa";

//images
import profileimg from "../../img/profile.jpg";
import { redirect, useNavigate } from "react-router-dom";

const PanelsettingsprofilePage = () => {
  //translations
  const { t } = useTranslation();
  //cities from api
  const [cityOptions, setCityOptions] = useState([]);
  //curren user previous data
  const [currUserData, setCurrUserData] = useState({});
  //new data for data change
  const [newData, setNewData] = useState({});
  //file input element
  const inputElement = useRef(null);
  //user
  const { user, setUpdateUser, setToken, setUser } =
    useContext(PrimmaryContext);
  //data updater
  const [upadeDataRes, setUpadeDataRes] = useState(null);
  //check icon
  const [checkIcons, setCheckIcons] = useState(false);
  //const barcode
  const [barcode, setBarcode] = useState(null);
  const navigate = useNavigate();

  //fetch current user data
  useEffect(() => {
    axiosClient.get("/Auth/profile").then(({ data }) => {
      setCityOptions(data.city);
      setCurrUserData(data.user);
    });

    axiosClient.get("/barcode").then(({ data }) => {
      setBarcode(data);
    });
  }, [upadeDataRes]);

  //handle check icon
  const handleCheckIcons = () => {
    setCheckIcons(true);
  };

  //change current user data in db
  async function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.append("city", newData.city);
    formData.append("gender", newData.gender);
    // formData.append("deliver_office", newData.deliver_office);

    try {
      const res = await axiosClient.post("/Auth/profile_post", formData);
      if (res) {
        setUpadeDataRes(res);
        setUpdateUser(res);
        successToast(t("success"));
      }
    } catch (err) {
      errorToast(t("error"));
    }
  }

  //handle delete photo
  const handleDeletePhoto = async () => {
    try {
      const res = await axiosClient.get(`/Auth/delImg/${currUserData.id}`);
      if (res) {
        setUpadeDataRes(res);
        setUpdateUser(res);
      }
    } catch (err) {}
  };

  //handle newdata change
  const handleNewDataChange = (value, inputName) => {
    setNewData((prev) => ({ ...prev, [inputName]: value }));
  };

  //open file input
  const handleOpenInput = () => {
    inputElement.current.click();
  };

  const handleDeleteAccount = () => {
    axiosClient
      .delete("/delme")
      .then((res) => {
        if (res) {
          setUser({});
          setToken("");
          navigate("/");
        }
      })
      .catch((err) => {});
  };

  return (
    <div
      className="min-h-screen flex flex-1 flex-col font-firago gap-[36px] items-start
       justify-start max-w-[1500px] md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] sm:gap-8
       sm:pb-[80px] md:pb-[80px]"
    >
      <PanelHead name={t("profile")} goBackButton={true} />
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="hidden sm:flex md:flex items-center gap-3 mb-3">
          <GoBackButton />
          <Text
            className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto] dark:text-[#c7c7c7]
          "
            variant="body1"
          >
            {t("profile")}
          </Text>
        </div>
        <div className="font-notosansgeorgian relative w-[100%]">
          <div className="flex flex-col gap-[32px] inset-[0] items-start justify-start m-[auto] w-[100%]">
            <div
              className="flex bg-white_A700 flex-row gap-[24px] items-center justify-between sm:flex-col
             outline outline-[1px] outline-black_900_0a p-[24px] rounded-radius13 shadow-bs w-[100%] dark:bg-[#171719]
             sm:p-[12px]"
            >
              <div
                className="bg-white_A700 flex items-center justify-center
                 outline outline-[2px] outline-white_A700 rounded-radius50 shadow-bs2 w-[100px] h-[100px]"
              >
                <Img
                  src={
                    currUserData.img
                      ? `${process.env.REACT_APP_API_URL}/storage/uploads/img/user/${currUserData.img}`
                      : profileimg
                  }
                  className="w-full h-full rounded-radius50 "
                  alt="Image One"
                />
              </div>
              <div className="flex items-center justify-between gap-5 w-[80%] sm:w-full sm:gap-1">
                <div>
                  <div
                    onClick={handleOpenInput}
                    className="flex items-center justify-center pl-[11px] text-center w-[auto] sm:w-full rounded-radius11
                      bg-indigo_400_14 text-indigo_400 pr-[14px] py-[14px] cursor-pointer"
                  >
                    <AiOutlineCamera
                      className="mr-[11px] h-[24px] w-[24px] text-indigo_400
                    sm:h-[18px] sm:w-[18px] "
                    />
                    <div
                      className="bg-transparent cursor-pointer font-semibold text-[17px] text-indigo_400 text-left
                  sm:text-[14px] flex gap-2 items-center"
                    >
                      <p>{t("change_photo")}</p>
                      {checkIcons && (
                        <BsCheckLg className="text-green-500 h-6 w-6" />
                      )}
                    </div>
                  </div>
                  <input
                    name="img"
                    className="hidden"
                    type="file"
                    ref={inputElement}
                    onChange={(e) => {
                      handleCheckIcons();
                    }}
                  />
                </div>
                <Button
                  onClick={handleDeletePhoto}
                  className="flex h-[48px] items-center justify-center w-[48px]"
                  variant="icbFillRed50014"
                >
                  <BsTrash className="text-xl text-red_A400 font-semibold" />
                </Button>
              </div>
            </div>
            <div className=" flex flex-col gap-[32px] h-[100%] items-start justify-center w-[100%] md:gap-5 sm:gap-5">
              <div className=" flex flex-wrap  justify-start items-center min-h-[auto] gap-[1.6%] gap-y-2">
                {user.user_type === 1 && (
                  <>
                    <div className="w-[32%] md:w-[48%] sm:w-full">
                      <CustomTextInut
                        PrefixIcon={BsPerson}
                        placeholder={"name_ge"}
                        inputName={"fname"}
                        value={currUserData.fname && currUserData.fname}
                      />
                    </div>
                    <div className="w-[32%] md:w-[48%] sm:w-full">
                      <CustomTextInut
                        PrefixIcon={BsPerson}
                        placeholder={"surname_ge"}
                        inputName={"lname"}
                        value={currUserData.lname && currUserData.lname}
                      />
                    </div>
                    <div className="w-[32%] md:w-[48%] sm:w-full">
                      <CustomTextInut
                        PrefixIcon={BsPerson}
                        placeholder={"surname_en"}
                        inputName={"fname_eng"}
                        pattern={true}
                        value={currUserData.fname_eng && currUserData.fname_eng}
                      />
                    </div>
                    <div className="w-[32%] md:w-[48%] sm:w-full">
                      <CustomTextInut
                        PrefixIcon={BsPerson}
                        placeholder={"surname_en"}
                        inputName={"lname_eng"}
                        pattern={true}
                        value={currUserData.lname_eng && currUserData.lname_eng}
                      />
                    </div>
                    <div className="w-[32%] md:w-[48%] sm:w-full">
                      <CustomTextInut
                        PrefixIcon={FaRegAddressCard}
                        placeholder={"id_num"}
                        inputName={"id_num"}
                        value={currUserData.id_num && currUserData.id_num}
                      />
                    </div>
                  </>
                )}
                {user.user_type === 2 && (
                  <>
                    <div className="w-[32%] md:w-[48%] sm:w-full">
                      <CustomTextInut
                        PrefixIcon={BsPerson}
                        placeholder={"dir_name_ge"}
                        inputName={"ceo_fname"}
                        value={currUserData.ceo_fname && currUserData.ceo_fname}
                      />
                    </div>
                    <div className="w-[32%] md:w-[48%] sm:w-full">
                      <CustomTextInut
                        PrefixIcon={BsPerson}
                        placeholder={"dir_surname_ge"}
                        inputName={"ceo_lname"}
                        value={currUserData.ceo_lname && currUserData.ceo_lname}
                      />
                    </div>
                    <div className="w-[32%] md:w-[48%] sm:w-full">
                      <CustomTextInut
                        PrefixIcon={BsPerson}
                        placeholder={"dir_name_en"}
                        inputName={"ceo_fname_eng"}
                        pattern={true}
                        value={
                          currUserData.ceo_fname_eng &&
                          currUserData.ceo_fname_eng
                        }
                      />
                    </div>
                    <div className="w-[32%] md:w-[48%] sm:w-full">
                      <CustomTextInut
                        PrefixIcon={BsPerson}
                        placeholder={"dir_surname_en"}
                        inputName={"ceo_lname_eng"}
                        pattern={true}
                        value={
                          currUserData.ceo_lname_eng &&
                          currUserData.ceo_lname_eng
                        }
                      />
                    </div>
                    <div className="w-[32%] md:w-[48%] sm:w-full">
                      <CustomTextInut
                        PrefixIcon={FaRegAddressCard}
                        placeholder={"dir_id"}
                        inputName={"ceo_id_num"}
                        value={
                          currUserData.ceo_id_num && currUserData.ceo_id_num
                        }
                      />
                    </div>
                  </>
                )}

                <div className="w-[32%] md:w-[48%] sm:w-full">
                  <CustomDateInput
                    name={"birth_date"}
                    PrefixIcon={BsCalendar}
                    inputName={"birthday"}
                    value={currUserData.birthday && currUserData.birthday}
                  />
                </div>
                <div className="w-[32%] md:w-[48%] sm:w-full">
                  <CustomSelectInput
                    name={t("gender")}
                    PrefixIcon={BsGenderAmbiguous}
                    inputName={"gender"}
                    inputSetter={handleNewDataChange}
                    value={currUserData.gender && currUserData.gender}
                    options={[
                      { id: 0, title: t("woman") },
                      { id: 1, title: t("man") },
                    ]}
                  />
                </div>
              </div>
              <Line className="bg-black_900_14 h-[1px] w-[100%] dark:bg-[#aeaeb2]" />
              <div className="w-full flex flex-wrap  justify-start items-center min-h-[auto] gap-1">
                <div className="w-[32%] md:w-[48%] sm:w-full">
                  <CustomTextInut
                    PrefixIcon={AiOutlineMobile}
                    placeholder={"phone"}
                    inputName={"phone"}
                    value={currUserData.phone && currUserData.phone}
                    pattern={"number"}
                  />
                </div>
                <div className="w-[32%] md:w-[48%] sm:w-full">
                  <CustomTextInut
                    PrefixIcon={BsEnvelope}
                    placeholder={"email"}
                    inputName={"email"}
                    value={currUserData.email && currUserData.email}
                  />
                </div>
                <div className="w-[32%] md:w-[48%] sm:w-full">
                  <CustomSelectInput
                    name={t("city")}
                    PrefixIcon={BsFlag}
                    inputName={"city"}
                    inputSetter={handleNewDataChange}
                    value={currUserData.city && currUserData.city}
                    options={cityOptions}
                  />
                </div>
                <div className="w-[32%] md:w-[48%] sm:w-full">
                  <CustomTextInut
                    PrefixIcon={BiMap}
                    placeholder={"address"}
                    inputName={"address"}
                    value={currUserData.address && currUserData.address}
                  />
                </div>
                {/* <div className="w-[32%] md:w-[48%] sm:w-full">
                    <CustomSelectInput
                      name={t("filials")}
                      PrefixIcon={BiMap}
                      inputName={"deliver_office"}
                      inputSetter={handleNewDataChange}
                      value={currUserData.deliver_office}
                      options={branchesData}
                      placeholder={t("where_you_take_package")}
                    />
                  </div> */}
              </div>
            </div>
            {barcode !== null && (
              <div
                className="border border-gray-300 shadow-md rounded-lg bg-white
 flex items-center justify-center sm:w-full p-4 "
              >
                <Barcode
                  value={barcode.toString()}
                  style={{ height: "200px", width: "200px" }}
                  background={"transparent"}
                />
              </div>
            )}
            <div className="flex items-center gap-5 sm:w-full sm:flex-col">
              <button
                type="submit"
                className="cursor-pointer font-semibold text-[17px] text-center w-[328px]
              sm:w-full rounded-radius11 p-[14px] bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
              >
                {t("save")}
              </button>
              <div
                onClick={handleDeleteAccount}
                className="cursor-pointer font-semibold text-[17px] text-center w-[328px]
              sm:w-full rounded-radius11 p-[14px] bg-red-400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
              >
                {t("deleteAccount")}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PanelsettingsprofilePage;

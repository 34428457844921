import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import { TextInput, SelectInputValueId } from "../../inputComponents/inputcomp";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";

//icons
import { BiUser } from "react-icons/bi";
import { BsEnvelope, BsLock, BsTelephone } from "react-icons/bs";

//images
import logo from "../../../img/phoneLogo.png";

const InnerUsersPage = () => {
  //get current user id
  const { usersId } = useParams();
  //new admin data
  const [adminData, setAdminData] = useState({
    fname: "",
    lname: "",
    user: "",
    mail: "",
    phone: "",
    pass: "",
    role: "",
  });

  //get admin data error from api
  const [adminDataErr, setAdminDataErr] = useState(null);
  //get admin data error from api
  const [adminEditErr, setAdminEditErr] = useState(null);

  //handle new admin
  const handleNewAdminData = (value, inputName) => {
    setAdminData((prev) => ({ ...prev, [inputName]: value }));
  };

  //add new admin
  const handleEditAdmin = async () => {
    try {
      const res = await axiosAdmin.post(
        `/Admins/edit_post/${usersId}`,
        adminData
      );
      if (res) {
        successToast("მონაცემები წარმატებით განახლდა!");
      }
    } catch (error) {
      setAdminEditErr(error);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //admin data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Admins/edit/${usersId}`).then(({ data }) => {
        setAdminData({ ...data, pass: "" });
      });
    } catch (error) {
      setAdminDataErr(error);
    }
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-10 h-10 ">
                <img src={logo} className="w-full h-full rounded-full"></img>
              </div>
              <div>
                <h2
                  className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                >
                  ადმინის პროფილის რედაქტირება
                </h2>
              </div>
            </div>
            <button
              onClick={handleEditAdmin}
              className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
            >
              შენახვა
            </button>
          </div>
          <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
            <div className="w-6/12 sm:w-full md:w-full">
              <TextInput
                name={"სახელი"}
                Icon={BiUser}
                inputName={"fname"}
                inputSetter={handleNewAdminData}
                value={adminData.fname}
              />
              <TextInput
                name={"გვარი"}
                Icon={BiUser}
                inputName={"lname"}
                inputSetter={handleNewAdminData}
                value={adminData.lname}
              />
              <TextInput
                name={"მომხმარებლის სახელი"}
                Icon={BiUser}
                inputName={"user"}
                inputSetter={handleNewAdminData}
                value={adminData.user}
              />
              <TextInput
                name={"ელ-ფოსტა"}
                inputName={"mail"}
                inputSetter={handleNewAdminData}
                Icon={BsEnvelope}
                value={adminData.mail}
              />
            </div>
            <div className="w-6/12 sm:w-full md:w-full">
              <TextInput
                name={"ტელეფონი"}
                inputName={"phone"}
                inputSetter={handleNewAdminData}
                Icon={BsTelephone}
                value={adminData.phone}
              />
              <TextInput
                name={"პაროლი"}
                inputName={"pass"}
                inputSetter={handleNewAdminData}
                Icon={BsLock}
              />
              <SelectInputValueId
                name={"ადმინის როლი"}
                inputName={"role"}
                inputSetter={handleNewAdminData}
                options={[
                  {
                    name: "სუპერ ადმინი",
                    value: 100,
                  },
                  {
                    name: "მთავარი ადმინი",
                    value: 1,
                  },
                  {
                    name: "თურქეთის ოპერატორი",
                    value: 2,
                  },
                  {
                    name: "ჩინეთის ოპერატორი",
                    value: 3,
                  },
                  {
                    name: "ამერიკის ოპერატორი",
                    value: 7,
                  },
                  {
                    name: "საქართველოს ოპერატიორი Back",
                    value: 4,
                  },
                  {
                    name: "საქართველოს ოპერატიორი Front",
                    value: 5,
                  },
                  {
                    name: "საქართველოს ქოლ ოპერატორი",
                    value: 6,
                  },
                  {
                    name: "დეკლარანტი",
                    value: 8,
                  },
                  {
                    name: "საბერძნეთს ოპერატორი",
                    value: 9,
                  },
                  {
                    name: "ბრიტანეთის ოპერატორი",
                    value: 10,
                  },
                ]}
                value={adminData.role}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerUsersPage;

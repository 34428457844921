import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import LogsItem from "./LogsItem";
import PaginatedItems from "../../Pagination/Pagination";

const Logs = () => {
    //logs data 
    const [logs, setLogs] = useState([]);
    //logs err
    const [logsErr, setLogsErr] = useState(null);
    //search value
    const [searchValue, setSearchValue] = useState("");
    //total amount
    const [total, setTotal] = useState(0)
    //current page
    const [currentPage, setCurrentPage] = useState(0)

    //handle search value change
    const handleSearchValue = (e) => {
        setSearchValue(e.target.value)
        if (currentPage !== 0) {
            setCurrentPage(0)
        }
    }

    //logs data from api
    useEffect(() => {
        axiosAdmin.get(`/logebi?per_page=${20}&page=${currentPage + 1}&key=${searchValue}`)
            .then(({ data }) => {
                setTotal(data.total)
                setLogs(data.data)
            })
            .catch((error) => {
                setLogsErr(error);

            })
    }, [searchValue, currentPage]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <div className="sm:px-0">
                <div className="w-full">
                    <div className="w-full flex justify-between gap-1 sm:w-full">
                        <div className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-full bg-white">
                            <input
                                type="search"
                                placeholder="სახელი, გვარი, აღწერა"
                                className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
                                onChange={(e) => { handleSearchValue(e) }}
                                value={searchValue}
                            />
                        </div>
                    </div>
                    <PaginatedItems
                        pageNumberSetter={setCurrentPage}
                        dataLength={total}
                        forcedPage={currentPage}
                        itemsPerPage={20}
                    >
                        <div className="w-full mt-5 flex flex-col gap-2 mb-5">
                            {logs.map((item, index) => {
                                return (
                                    <LogsItem
                                        key={index}
                                        itemData={item}
                                    />
                                )
                            })}
                        </div>
                    </PaginatedItems>
                </div>
            </div>
        </div>
    )
}

export default Logs;
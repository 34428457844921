import { useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import express_logo from "../../img/expresLogo.png";
import stamp from "../../img/stamp/stamp.png";
import { PrimmaryContext } from "../MainContext/MainContext";
import { Text } from "../../components/Text";
import { BsX } from "react-icons/bs";

const Invoice = ({
    data,
    showInvoice,
    setShowInvoice
}) => {
    //toggler
    const [isOpen, setIsOpen] = useState(null);
    //translations 
    const { t } = useTranslation();
    //reference to invoice
    const innerRef = useRef();
    //user
    const { user } = useContext(PrimmaryContext);
    //date
    const [date, setDate] = useState(0);

    //set invoice open value
    useEffect(() => {
        setIsOpen(showInvoice);
    }, [showInvoice]);

    //date effect
    useEffect(() => {
        const thisDate = new Date(data.last_update).toLocaleDateString();
        setDate(thisDate);
    }, [])

    return (
        <div
            className={`popup_container2 bg-gray_100 flex flex-col font-firago items-start justify-start outline
        outline-[1px] outline-black_900_1e fixed bottom-0 left-0 z-50 transition-all delay-50 duration-300 ease-linear 
        overflow-y-auto overflow-x-hidden scrollbar-none dark:bg-[#0a0a0c] ${isOpen ? "translate-y-0" : "translate-y-full"}`}
        >
            <header className="bg-white_A700 border-b-[1px] border-black_900_14 border-solid flex flex-row gap-[8px] items-center 
        justify-center px-10 sm:px-[20px] md:px-[40px] py-[16px] w-[100%] dark:bg-[#171719]">
                <div className="w-full max-w-[1500px] flex items-center justify-between">
                    <Text
                        className="flex-1 font-bold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]
                        dark:text-[#c7c7c7]"
                        variant="body3"
                    >
                        {t("invoice")}
                    </Text>
                    <div className="flex gap-4 items-center font-notosansgeorgian">
                        <ReactToPrint
                            trigger={() => <button className="w-6 h-6 cursor-pointer">
                                <HiOutlineDocumentDownload className="text-green-500 w-full h-full font-bold" />
                            </button>}
                            content={() => innerRef.current}
                        />
                        <BsX
                            onClick={() => { setShowInvoice(false) }}
                            className="h-[36px] w-[24px] text-black_900_b7 cursor-pointer dark:text-[#c7c7c7]"
                        />
                    </div>
                </div>
            </header>
            <div className="flex flex-col font-notosansgeorgian gap-[24px] h-full 
            items-start justify-start px-10 sm:px-[20px] md:px-[40px] py-[24px] w-[100%]">
               <div
                    className="gap-[16px] max-w-[1000px] mx-[auto] w-[100%] overflow-auto scrollbar"
                >
                    <div
                        ref={innerRef}
                        className="bg-white rounded-lg p-5 overflow-auto sm:w-[800px] dark:bg-[#171719]">
                        <div className="w-full flex items-center justify-between
            border-b border-gray-300 pb-5">
                            <h1 className="font-bold text-2xl dark:text-[#c7c7c7]">{t("invoice")} - {data.tracking_number}</h1>
                            <img src={express_logo} alt="expressgroup" className="w-[150px]" />
                        </div>
                        <div className="w-full flex gap-10 mt-5">
                            <div>
                                <p className="font-bold text-lg dark:text-[#c7c7c7]">{t("details")}</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("inv_name")}</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("inv_identification_code")}</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("inv_phone")}</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("inv_email")}</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("inv_address")}</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("date")}: {data.last_update !== null && data.last_update.split(" ")[0]}</p>
                            </div>
                            <div>
                                <p className="font-bold text-lg dark:text-[#c7c7c7]">{t("recipient")}</p>
                                {user.user_type === 2 &&
                                    <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("company_name")}: {user.company_name}</p>
                                }
                                {user.user_type === 2 &&
                                    <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("company_ic")}: {user.company_id}</p>
                                }
                                {user.user_type === 1 &&
                                    <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("fullname")}: {user.lname} {user.fname}</p>
                                }
                                {user.user_type === 1 &&
                                    <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("id")}: {user.id_num}</p>
                                }
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("room_id")}: EG{user.id}</p>
                            </div>
                            <div>
                                <p className="font-bold text-lg dark:text-[#c7c7c7]">{t("recipient")} {t("contact")}</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("phone")}: {user.phone}</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("email")}: {user.email}</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("address")}: {user.address}</p>
                            </div>
                        </div>
                        <div className="w-full mt-5">
                            <div className="grid grid-cols-4 bg-slate-200 p-2 
                border-b border-gray-300">
                                <div className="w-full flex items-center">
                                    <p className="font-bold text-md">{t("tracking_number")}</p>
                                </div>
                                <div className="w-full flex items-center">
                                    <p className="font-bold text-md">{t("flight")}</p>
                                </div>
                                <div className="w-full flex items-center">
                                    <p className="font-bold text-md">{t("weight")}</p>
                                </div>
                                <div className="w-full flex items-center">
                                    <p className="font-bold text-md">{t("price")}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-4 p-2 border-b border-gray-300">
                                <div className="w-full flex items-center ">
                                    <p className="text-sm text-gray-400 dark:text-[#aeaeb2] ">{data.tracking_number}</p>
                                </div>
                                <div className="w-full flex items-center">
                                    <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{data.flight_number}</p>
                                </div>
                                <div className="w-full flex items-center ">
                                    <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{data.total_weight}</p>
                                </div>
                                <div className="w-full flex items-center">
                                    <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{data.transport_price}</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-end mt-5">
                            <p className="font-bold text-lg dark:text-[#c7c7c7]">{t("sum")}: {data.transport_price}</p>
                        </div>
                        <div className="w-full mt-5 flex items-center gap-5">
                            <div className="items-center rounded-lg p-2">
                                <p className="font-bold text-md mb-2 dark:text-[#c7c7c7]">{t("exp_ltd")} {t("inv_identification_code")}</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("bank_details")}: GE27TB7593136070100002</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("bank_code")}: TBCBGE22</p>
                                <p className="text-sm text-gray-400 dark:text-[#aeaeb2]">{t("inv_pr")}: EG{data.user_id}</p>
                            </div>
                            <div>
                                <img src={stamp} className="w-20 h-20" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default Invoice;

import { useState, useEffect } from "react";

const CustomCheckbox = ({
  inputName,
  inputSetter = null,
  mFill,
  label,
  classNames,
}) => {
  //current value variable
  const [currentValue, setCurrentValue] = useState("");
  //if filed is empty but required we must show
  const [mustFill, setMustFill] = useState("");

  //value change handler for input
  const handleValueChange = (e) => {
    setCurrentValue(e.target.checked);
  };

  //when current value changes passed function
  //will change specific object based value
  useEffect(() => {
    if (inputSetter) {
      inputSetter(currentValue);
    }
  }, [currentValue]);

  //whenever filed is reuired and user does not
  //filled it
  useEffect(() => {
    if (currentValue) {
      setMustFill(false);
    } else {
      setMustFill(mFill);
    }
  }, [mFill, currentValue]);

  return (
    <div className="flex w-[100%]">
      <input
        type={"checkbox"}
        className={`mr-[5px] cursor-pointer border ${classNames}
        `}
        id={inputName}
        onChange={(e) => {
          handleValueChange(e);
        }}
      />
      <label
        htmlFor={inputName}
        className={`font-normal not-italic text-[15px]  text-left
        cursor-pinter 
        ${mustFill ? "text-[#f61b17]" : "text-black_900_99"}`}
      >
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;

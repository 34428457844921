import React, { useEffect, useState } from "react";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { TbPlaneDeparture, TbPlaneArrival } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { BsTruck } from "react-icons/bs";

const PanelFlightsCard = ({ data }) => {
  //translations
  const { t } = useTranslation();
  //is arrived
  const [isArrived, setIsArrived] = useState(false);

  useEffect(() => {
    if (data.flight_status === 1) {
      setIsArrived(true);
    } else {
      setIsArrived(false);
    }
  }, [data]);

  //place icon
  const Icon = () => {
    return isArrived ? (
      ["gr", "tb", "ln"].includes(
        data?.number?.split("-")[0]?.toLowerCase()
      ) ? (
        <BsTruck className="font-semibold text-teal_A700 " />
      ) : (
        <TbPlaneArrival className="font-semibold text-teal_A700 " />
      )
    ) : ["gr", "tb", "ln"].includes(
        data?.number?.split("-")[0]?.toLowerCase()
      ) ? (
      <BsTruck className="font-semibold text-indigo_400 " />
    ) : (
      <TbPlaneDeparture className="font-semibold text-indigo_400" />
    );
  };
  return (
    <div
      className="bg-white_A700 flex flex-1 flex-row gap-[24px] dark:bg-[#171719]
      items-center my-[0] outline outline-[1px] outline-black_900_0a p-[24px]
      sm:p-3 sm:gap-3 rounded-radius13 shadow-bs w-[100%]"
    >
      <div
        className="border border-black_900_14 flex flex-col items-center
        justify-center p-[8px] rounded-radius50 border-dashed"
      >
        <Button
          className="flex h-10 w-10 items-center justify-center rounded-radius50"
          variant={`${isArrived ? "icbFillTealA70014" : "icbFillIndigo40014"}`}
          shape={"circular"}
        >
          <Icon />
        </Button>
      </div>
      <div className="flex flex-1 flex-col items-start justify-start">
        <div className="flex flex-row gap-[8px] justify-start w-[auto] ">
          <Text
            className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
            variant="body10"
          >
            {t("flight")}:
          </Text>
          <Text
            className={`font-semibold text-left ${
              isArrived ? "text-teal_A700" : "text-indigo_400"
            }`}
            variant="body10"
          >
            {data.number}
          </Text>
        </div>
        <div className="flex flex-row gap-[8px] items-start justify-start w-[auto]">
          <Text
            className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
            variant="body10"
          >
            {isArrived ? t("arrived") : t("arrival_time")}:{" "}
            <span className="text-black_900_e0 font-semibold dark:text-[#ededed]">
              {data.date}
            </span>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default PanelFlightsCard;

import { useEffect, useState } from "react";
import { Text } from "../Text";
import { Button } from "../Button";
import CustomTextInut from "../CustomInputs/CustomTextInut";
import { useTranslation } from "react-i18next";
import Cities from "../../adminPanel/components/cities/Cities";

//icons
import { MdClose } from "react-icons/md";
import { errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";

const AddAdressModal = ({
    adressModalOpen,
    handleAddreessModalOpen,
    handleNewAdress,
    mFill,
    handleAddNewAdresses,
    status,
    data,
    updatedAdress,
    newAddress = null
}) => {
    //translation
    const { t } = useTranslation();
    // toggler
    const [isOpen, setIsOpen] = useState(false);

    //toggler handler
    useEffect(() => {
        setIsOpen(adressModalOpen);
    }, [adressModalOpen]);


    const handleAction = () => {

        if (status === "update") {
            if (updatedAdress.phone.length === 9) {
                handleAddNewAdresses(data.id, updatedAdress);
            } else {
                errorToast(t("invalid_phone"));
            }
        } else {
            if (newAddress.phone.length === 9) {
                handleAddNewAdresses();
            } else {
                errorToast(t("invalid_phone"));
            }
        }

    }

    return (
        <div
            className={`h-screen fixed top-0 right-0 z-50 rounded-l-[16px] flex flex-col justify-between 
        outline outline-[1px] outline-black_900_0a text-black_900_e0 transition-all delay-150 duration-300
        sm:w-full
        ease-linear ${isOpen ? "translate-x-0" : "translate-x-[100%]"}`}
        >
            <div
                className="py-[16px] px-[24px] bg-white flex justify-between items-center rounded-tl-[16px] dark:bg-[#171719]
            gap-10"
            >
                <Text
                    className="text-[18px] flex-1 font-bold text-black_900_e0 
                    text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto] dark:text-[#c7c7c7]"
                    variant="body3"
                >
                    {status === "update" ? t("update_address") : t("add_new_address")}
                </Text>
                <Button
                    onClick={handleAddreessModalOpen}
                    className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                    variant="icbFillGray6001e"
                    shape="circular"
                >
                    <MdClose className="text-black_900_99 font-semibold dark:text-[#c7c7c7]" />
                </Button>
            </div>
            <div className="p-[24px] h-full bg-gray_100 flex flex-col gap-y-5 dark:bg-[#0a0a0c]">

                <CustomTextInut
                    placeholder={t("name")}
                    inputName={"name"}
                    inputSetter={handleNewAdress}
                    mFill={mFill && mFill}
                    value={status === "update" && data.name}
                />
                <CustomTextInut
                    placeholder={t("address")}
                    inputName={"address"}
                    inputSetter={handleNewAdress}
                    mFill={mFill && mFill}
                    value={status === "update" && data.address}
                />
                <Cities
                    inputSetter={handleNewAdress}
                    inputName={"city"}
                    value={status === "update" && data.city}
                />
                <CustomTextInut
                    placeholder={t("phone")}
                    inputName={"phone"}
                    inputSetter={handleNewAdress}
                    mFill={mFill && mFill}
                    value={status === "update" && data.phone}
                />
            </div>
            <div className="py-[16px] px-[24px] bg-white flex justify-end  rounded-bl-[16px] dark:bg-[#171719]">
                <Button
                    onClick={handleAction}
                    className="cursor-pointer font-semibold min-w-[118px]
                text-[16px] text-center text-white_A700 w-[auto]"
                    shape="RoundedBorder11"
                    size="3xl"
                    variant="OutlineBlack9000a"
                >
                    {status === "update" ? t("update") : t("add")}
                </Button>
            </div>
        </div >
    );
};

export default AddAdressModal;

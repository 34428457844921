import { Link } from "react-router-dom";
import { useContext } from "react";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import { useState, useEffect } from "react";

//icons
import {
  BsTrash,
  BsFillPersonFill,
  BsFillEnvelopeFill,
  BsTelephoneFill,
  BsFillCalendarFill,
  BsFillHandbagFill,
  BsFillBuildingFill,
  BsArrowReturnLeft,
  BsBookshelf,
} from "react-icons/bs";
import { BiPackage } from "react-icons/bi";
import {
  FaBarcode,
  FaMoneyBillAlt,
  FaPlaneDeparture,
  FaScroll,
  FaTruckMoving,
} from "react-icons/fa";
import { GiWeight } from "react-icons/gi";
import ContinueComponent from "../../../../../components/Alerts/ContinueComponent";

const PassedPackagesItem = ({ data, handleDeletePackage, handleMoveBack }) => {
  //user
  const { user } = useContext(AdminPrimaryContext);
  //show contnue
  const [showContinue, setShowContinue] = useState(false);
  //is paid
  const [isPaid, setIsPaid] = useState(false);

  //if package is paid
  useEffect(() => {
    if (data.transport_paid !== null) {
      setIsPaid(true);
    }
  }, [data]);

  return (
    <div className="bg-white p-4 rounded-lg dark:bg-slate-700">
      <div
        className="w-full flex flex-wrap justify-between pb-2
            gap-y-2 border-b border-gray-200 mb-2"
      >
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">
              მომხმარებელის ინფორმაცია
            </p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillPersonFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data?.user?.user_type === 1 && (
                <p className="text-slate-500 dark:text-gray-400 text-[12px] font-bold">
                  {`${data?.user?.fname} ${data?.user?.lname}`} <br />
                  {`${data?.user?.fname_eng} ${data?.user?.lname_eng}`}
                </p>
              )}
              {data?.user?.user_type === 2 && (
                <p className="text-slate-500 dark:text-gray-400 text-[12px] font-bold">
                  {`${data?.user?.ceo_fname} ${data?.user?.ceo_lname}`} <br />
                  {`${data?.user?.ceo_fname_eng} ${data?.user?.ceo_lname_eng}`}
                </p>
              )}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaBarcode className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">{`id: ${data?.user?.id}`}</p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillEnvelopeFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {data?.user?.email}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsTelephoneFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {data?.user?.phone}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillBuildingFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.abroad_office !== null && (
                <p
                  className={`text-[14px] cursor-pointer font-bold
                                ${
                                  data.abroad_office.titleen ===
                                  "Vazha Pshavela branch N2"
                                    ? "text-yellow-500"
                                    : "text-purple-500"
                                }
                                `}
                >
                  ოფისი: {data.abroad_office.titleen}
                </p>
              )}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaMoneyBillAlt className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={` text-[14px] font-bold
                            ${
                              data.transport_paid === data.transport_price
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
              >
                {data.transport_paid === data.transport_price
                  ? "გადახდილია"
                  : "გადასახდელია"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillCalendarFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                თარიღი: {data.date}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">გზავნილის ინფორმაცია</p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BiPackage className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                გზავნილის ID: {data.tracking_number}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaMoneyBillAlt className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                დეკლ. თანხის ჯამი{" "}
                {data.total_price && data.total_price.toFixed(2)}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillCalendarFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                შექმნის-თარიღი: {data.create_date}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                წონა real: {data.real_weight}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                წონა volume: {data.volume_weight} {"("} w:
                {JSON.parse(data.dementions)?.x} h:
                {JSON.parse(data.dementions)?.y} l:
                {JSON.parse(data.dementions)?.z}
                {")"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                წონა : {data.total_weight}{" "}
                {data.real_weight === 0 && data.volume_weight === 0
                  ? "გაურკვეველია"
                  : data.real_weight < data.volume_weight
                  ? "volume"
                  : "real"}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">გზავნილის ინფორმაცია</p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaPlaneDeparture className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                რეისი: {data.flight_number}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.addon_transportation ? "text-green-500" : "text-red-500"
                } `}
              >
                {data.addon_transportation ? "დაზღვეულია" : "არაა დაზღვეული"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaTruckMoving className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.transport_address ? "text-green-500" : "text-red-500"
                } `}
              >
                ტრანსპორტირება:{" "}
                {data.transport_address ? data.transport_address : "X"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.invoice !== "" ? (
                <Link
                  to={`${process.env.REACT_APP_API_URL}/storage/uploads/pack_files/${data.invoice}`}
                  target={"_blank"}
                >
                  <p
                    className={`text-[14px] text-violet-500 underline cursor-pointer`}
                  >
                    ინვოისი
                  </p>
                </Link>
              ) : (
                <p
                  className={`text-[14px] text-red-500 underline cursor-pointer`}
                >
                  ინვოისი
                </p>
              )}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillHandbagFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                ჩანთა: {data.bag}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsBookshelf className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                თარო: {data.x !== null && `${data.x} ${data.y}`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-1 w-full justify-between sm:flex-col sm:gap-y-2">
        <div className="flex flex-col gap-y-1">
          <p className="text-[#989ba5] text-[12px]">კომენტარი:</p>
          <p className="text-slate-500 dark:text-gray-400 text-[14px]">
            {data.comment}
          </p>
        </div>
        <div className="flex items-center gap-2">
          {(user.role === 100 || (user.role === 1 && !isPaid)) && (
            <div className="flex justify-end gap-1 ">
              <div
                onClick={() => {
                  setShowContinue(true);
                }}
                className="w-10 h-10 rounded-lg bg-red-400 flex items-center justify-center cursor-pointer"
              >
                <BsTrash className="text-white font-bold" />
              </div>
            </div>
          )}
          {(user.role === 100 || user.role === 1 || user.role === 5) && (
            <div className="flex justify-end gap-1 ">
              <div
                onClick={() => {
                  handleMoveBack(data.id);
                }}
                className="h-10 rounded-lg bg-green-400 flex items-center justify-center cursor-pointer gap-2 px-4"
              >
                <BsArrowReturnLeft className="text-white font-bold" />
                <p className="text-white text-md font-bold">უკან</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeletePackage}
        id={data.id}
        setShowContinue={setShowContinue}
      />
    </div>
  );
};
export default PassedPackagesItem;

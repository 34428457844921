import { useEffect, useState, useContext } from "react";
import axiosClient from "../../api/api";
import { PrimmaryContext } from "../../components/MainContext/MainContext";
import { Button } from "../../components/Button";
import { useTranslation } from "react-i18next";
import PanelHead from "../../components/PanelHead/PanelHead";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import { Text } from "../../components/Text";
import { successToast, errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";

//icons
import { BsBuildingDown, BsFillCheckCircleFill } from "react-icons/bs"

const PanelSettingsFilials = () => {
    //translation
    const { t } = useTranslation();
    //data from context
    const { branchesData, language, user, setUpdateUser } = useContext(PrimmaryContext);
    //new data for data change
    const [newData, setNewData] = useState({
        branch_id: user.branch_id
    });

    //change current user data in db
    async function handleChange() {
        try {
            const res = await axiosClient.post("/Auth/profile_post", newData);
            if (res) {
                setUpdateUser(res);
                successToast(t("success"))
            }
        } catch (err) {
            errorToast(t("error"))
        }
    }

    useEffect(() => {
        if (user) {
            setNewData({ branch_id: user.branch_id })
        }
    }, [user])

    useEffect(() => {
        if (newData.branch_id !== user.branch_id) {
            handleChange({ branch_id: user.branch_id })
        }
    }, [newData])

    return (
        <div
            className="flex flex-1 flex-col font-firago gap-[36px] items-start justify-start max-w-[1500px] 
      md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-full min-h-screen sm:gap-8 sm:pb-[80px] md:pb-[80px]"
        >
            <PanelHead name={t("filials")} goBackButton={true} />
            <div className="hidden sm:flex md:flex items-center gap-3">
                <GoBackButton />
                <p
                    className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
          sm:text-[20px] md:text-[22px] text-[24px] dark:text-[#c7c7c7]"
                >
                    {t("filials")}
                </p>
            </div>
            <div className="w-full">
                <div className="bg-white_A700 flex flex-1 flex-col items-start justify-start overflow-hidden
                    outline outline-[1px] outline-black_900_0a rounded-radius17 shadow-bs w-full dark:bg-[#171719]">

                    <div className="border-b-[1px] border-black_900_14 border-solid flex flex-col  gap-5 p-[24px] sm:px-[20px] w-full dark:border-[#aeaeb2]">
                        <div className="flex items-center gap-5">
                            <Button
                                className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                                variant="icbFillIndigo40014"
                                shape="circular"
                            >
                                <BsBuildingDown className="h-[20px] w-[20px] text-indigo_400" />
                            </Button>
                            <Text
                                className="flex-1 font-bold text-black_900_e0 text-left  dark:text-[#c7c7c7]
                                tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                                variant="body3"
                            >
                                {t("setting_filil_text")}
                            </Text>
                        </div>
                        <Text
                            className="flex-1 text-red-500 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto] "
                            variant="bodymd"
                        >
                            {t("chose_filial_caution")}
                        </Text>
                    </div>
                    <div className="flex flex-col font-notosansgeorgian justify-start w-full">

                        {branchesData.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    onClick={() => { setNewData({ branch_id: item.id }) }}
                                    className="border-b border-slate-200 py-4 hover:bg-slate-200 px-6 sm:px-5 w-full 
                                    cursor-pointer flex items-center justify-between dark:border-[#aeaeb2]">
                                    <Text
                                        className="flex-1 text-black_900_e0 text-left dark:text-[#c7c7c7]
                                        tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                                        variant="bodymd"
                                    >
                                        {(language === "ge") && item.title}
                                        {(language === "en") && item.titleen}
                                        {(language === "ru") && item.titleru}
                                    </Text>
                                    <div className={`transition-all duration-300 ${newData.branch_id === item.id ? "opacity-100" : "opacity-0"}`}>
                                        <BsFillCheckCircleFill className="text-indigo_400 text-xl" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default PanelSettingsFilials;

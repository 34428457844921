import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import TransactionCard from "./TransacttionCard";
import PanelHead from "../../components/PanelHead/PanelHead";
import { useTranslation } from "react-i18next";

const PaneltransactionsPage = () => {
  //translations 
  const { t } = useTranslation();
  //transactions data
  const [transactions, setTansactions] = useState([]);
  //error
  const [error, setError] = useState(null);

  //transactions data api
  useEffect(() => {
    try {
      axiosClient.get(`/Billing/transactions`).then(({ data }) => {
        setTansactions(data.data);
      });
    } catch (error) {
      setError(error);
    }
  }, []);

  return (
    <div
      className="min-h-screen flex flex-1 flex-col font-firago gap-[36px] items-start justify-start
     max-w-[1500px] md:px-[20px] sm:px-[20px] px-[46px] py-[32px] w-[100%] sm:gap-8 sm:pb-[80px] md:pb-[80px]"
    >
      <PanelHead name={t("transactions")} />
      <p
        className="font-semibold text-black_900_e0 text-left tracking-ls048 md:tracking-ls1 sm:tracking-ls1 w-[auto]
          hidden sm:block md:block sm:text-[20px] md:text-[22px] text-[24px] dark:text-[#c7c7c7]"
      >
       {t("transactions")}
      </p>
      <div className="font-notosansgeorgian relative w-[100%]">
        <div className="flex flex-col inset-[0] items-center justify-start m-[auto] w-[100%]">
          <div className="flex flex-col h-[100%] items-start justify-start w-[100%]">
            <div className="flex-col gap-[24px] grid items-center w-[100%] sm:gap-4">
              {transactions.map((item) => {
                return <TransactionCard key={item.id} data={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaneltransactionsPage;

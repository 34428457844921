import { useState, useEffect, useContext } from "react";
import {
  errorToast,
  successToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { Switch } from "../../components/CustomInputs/CustomSwitchComponent";
import DeclarationItem from "./DeclarationItem";
import AddProductModal from "../../components/AddProductModal/AddProductModal";
import axiosClient from "../../api/api";
import UploadInvoice from "../../components/CustomInputs/UploadInvoice";
import { useTranslation } from "react-i18next";
import { PrimmaryContext } from "../../components/MainContext/MainContext";

//icons
import { CiBoxes } from "react-icons/ci";
import { BiBarcodeReader, BiMap, BiLoaderAlt } from "react-icons/bi";
import { BsPerson, BsShield, BsX } from "react-icons/bs";
import { HiPlus } from "react-icons/hi";
import { FaCheck, FaRegFile } from "react-icons/fa";

const DeclarationaddedPage = ({
  declarationOpen,
  handleDeclarationOpen,
  data,
  setUpdatePackData,
}) => {
  //translations
  const { t } = useTranslation();
  //toggler
  const [isOpen, setIsOpen] = useState(null);
  //second toggler
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);
  //const pack items data
  const [newPackItems, setNewPackItems] = useState([]);
  //update declaration packs abmounts
  const { setUpdateDeclare, branchesData, user, language } =
    useContext(PrimmaryContext);
  const [websiteInput, setWebsiteInput] = useState("");
  //selected branch
  const [selectedBranch, setSelectedBranch] = useState([]);
  // response loading
  const [isLoading, setIsLoading] = useState(false);
  // show clarence popup
  const [toggleClarenceText, setToggleClarenceText] = useState(false);
  //if user wanted to clarence
  const [clickedYesOnClarence, setClickedYesOnClarence] = useState(false);

  const [trustee, setTrustee] = useState("0");

  //handle second toggler
  const handleAddProductModalOpen = () => {
    setAddProductModalOpen((prev) => !prev);
  };

  //handle add new pack items
  const handleNewPackItems = (pack) => {
    setNewPackItems((prev) => [...prev, pack]);
  };

  //handle delete new item
  const handleDeleteNewItem = (id) => {
    setNewPackItems(
      newPackItems.filter((item) => {
        return item.id !== id;
      })
    );
  };

  const submitPart = async (formData) => {
    setIsLoading(true);
    try {
      formData.append("pack_items", JSON.stringify(newPackItems));
      const res = await axiosClient.post(
        `Pack/declaration/${data.id}`,
        formData
      );

      if (res) {
        setUpdatePackData(res);
        setUpdateDeclare(res);
        successToast(t("declaration_successfull"));
        setIsLoading(false);
      }
    } catch (err) {
      errorToast(t("error"));
      setIsLoading(false);
    }
  };

  function isEmpty(value) {
    return value ? false : true;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    if (newPackItems.length === 0) {
      errorToast(t("dec_pack_err"));
    } else {
      if (websiteInput !== "") {
        if (trustee === "1") {
          if (
            !isEmpty(formData.get("fname")) &&
            !isEmpty(formData.get("lname")) &&
            !isEmpty(formData.get("opersonNumber"))
          ) {
            if (formData.get("ophone")?.length === 9) {
              if (
                formData.get("opersonNumber")?.length === 11 ||
                formData.get("opersonNumber")?.length === 9
              ) {
                submitPart(formData);
              } else {
                errorToast(t("incorrect_id"));
              }
            } else {
              errorToast(t("invalid_phone"));
            }
          } else {
            errorToast(t("trusteeError"));
          }
        } else {
          submitPart(formData);
        }
      } else {
        errorToast(t("please_fill_website_field"));
      }
    }
  }

  //cancel declaration
  const cancelDeclaration = () => {
    setNewPackItems([]);
    setWebsiteInput("");
  };

  const handleConfirmClarence = () => {
    axiosClient
      .get(`/Pack/notDeclared/${data.id}`)
      .then((res) => {
        setClickedYesOnClarence(true);
        setToggleClarenceText((prev) => !prev);
        setUpdatePackData(res);
        setUpdateDeclare(res);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (user && branchesData) {
      var tmp = branchesData.map((item) => {
        if (user.branch_id === item.id) {
          return item;
        }
        return null;
      });
      setSelectedBranch(tmp.filter((item) => item !== null));
    }
  }, [user]);

  //set declaration open value
  useEffect(() => {
    setIsOpen(declarationOpen);
  }, [declarationOpen]);

  return (
    <>
      <div
        className={`bg-gray_100 flex flex-col font-firago items-start justify-start outline
       outline-[1px] outline-black_900_1e fixed bottom-0 left-0 popup_container2  dark:bg-[#0a0a0c]
        transition-all delay-50 duration-300 ease-linear overflow-y-auto overflow-x-hidden scrollbar-none
        ${isOpen ? "translate-y-0 z-50" : "translate-y-full z-0"}`}
      >
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="w-full"
        >
          <header
            className="bg-white_A700 border-b-[1px] border-black_900_14 border-solid flex flex-row gap-[8px] items-center 
        justify-center px-10 sm:px-[20px] md:px-[40px] py-[16px] w-[100%] dark:bg-[#171719]"
          >
            <div className="w-full max-w-[1500px] flex items-center justify-between">
              <Text
                className="flex-1 font-bold text-black_900_e0 text-left sm:hidden
                tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto] dark:text-[#c7c7c7]"
                variant="body3"
              >
                {t("parcels")} {t("declarationn")}
              </Text>
              <div className="flex gap-4 items-center font-notosansgeorgian sm:w-full sm:justify-between">
                <div className="flex gap-4 items-center">
                  <div
                    onClick={cancelDeclaration}
                    className="cursor-pointer min-w-[108px] w-[auto] rounded-radius11 p-[10px]
              bg-white_A700 outline outline-[1px] outline-black_900_14 shadow-bs dark:bg-[#48484a]"
                  >
                    <p className="font-semibold text-[14px] text-center text-red_500">
                      {" "}
                      {t("delete")}
                    </p>
                  </div>
                  {isLoading ? (
                    <div
                      className="cursor-pointer font-semibold min-w-[181px] text-[14px] w-[auto] rounded-radius11
               p-[10px] bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700
               flex items-center justify-center"
                    >
                      <BiLoaderAlt className="w-6 h-6 animate-spin text-teal_600" />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="cursor-pointer font-semibold min-w-[181px] text-[14px] text-center w-[auto] rounded-radius11
              p-[10px] bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
                    >
                      {t("declaration")}
                    </button>
                  )}
                </div>
                <BsX
                  onClick={handleDeclarationOpen}
                  className="h-[36px] w-[24px] text-black_900_b7 cursor-pointer dark:text-[#c7c7c7]"
                />
              </div>
            </div>
          </header>

          <div
            className="flex flex-col font-notosansgeorgian gap-[24px] h-[100%] items-start 
          justify-start px-10 sm:px-[20px] md:px-[40px] py-[24px] w-[100%]"
          >
            <div
              className="gap-[16px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-start max-w-[1500px] 
          mx-[auto] w-[100%] x-10"
            >
              <div
                className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline dark:bg-[#171719]
             outline-[1px] outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
              >
                <Button
                  className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                  size="mdIcn"
                  variant="icbFillIndigo40014"
                  shape={"circular"}
                >
                  <BiBarcodeReader className="h-[17px] w-[17px] text-indigo_400" />
                </Button>
                <div className="flex flex-col gap-[4px] items-start justify-start w-[auto]">
                  <Text
                    className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
                    variant="body9"
                  >
                    {t("tracking")}
                  </Text>
                  <Text
                    className="font-semibold text-indigo_400 text-left w-[auto]"
                    variant="body7"
                  >
                    {data && data.tracking_number}
                  </Text>
                </div>
              </div>
              <div
                className="flex flex-row gap-[20px] items-center justify-between pr-[20px]
              bg-white_A700 shadow-bs p-5 rounded-radius17 outline
              outline-[1px] outline-black_900_0a dark:bg-[#171719]"
              >
                <div className="flex items-center gap-2">
                  <Button
                    className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                    variant="icbFillIndigo40014"
                    shape="circular"
                  >
                    <BiMap className="h-[17px] w-[17px] text-indigo_400" />
                  </Button>
                  {selectedBranch.length !== 0 ? (
                    <Text
                      className="font-fw text-black_900_b7 text-left dark:text-[#c7c7c7]"
                      variant="body7"
                    >
                      {language === "ge" && selectedBranch[0].title}
                      {language === "en" && selectedBranch[0].titleen}
                      {language === "ru" && selectedBranch[0].titleru}
                    </Text>
                  ) : (
                    <Text
                      className="font-fw text-black_900_b7 text-left dark:text-[#c7c7c7]"
                      variant="body7"
                    >
                      {t("chose_filial")}
                    </Text>
                  )}
                </div>
              </div>
              <div
                className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline 
              outline-[1px] outline-black_900_0a dark:bg-[#171719]
             p-[18px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
              >
                <div className="flex flex-1 flex-col gap-[4px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                  <Text
                    className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
                    variant="body9"
                  >
                    {t("web_site")}
                  </Text>
                  <input
                    name="sender_name"
                    placeholder="..."
                    className=" border-0 outline-none text-indigo_400 font-semibold bg-transparent"
                    onChange={(e) => {
                      setWebsiteInput(e.target.value);
                    }}
                    value={websiteInput}
                  />
                </div>
              </div>
              <div
                className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline outline-[1px]
             outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] dark:bg-[#171719]"
              >
                <Button
                  className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                  variant="icbFillIndigo40014"
                  shape={"circular"}
                >
                  <BsShield className="h-[24px] w-[24px] text-indigo_400" />
                </Button>
                <Text
                  className="flex-1 font-fw text-black_900_b7 text-left w-[auto] dark:text-[#c7c7c7]"
                  variant="body7"
                >
                  {t("insurence")}
                </Text>
                <Switch
                  value={data.addon_security && data.addon_security}
                  inputName={"addon_security"}
                />
              </div>
              <UploadInvoice inputName={"invoice"} name={t("add_invoice")} />
              <div
                className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline outline-[1px] outline-black_900_0a
             p-[18px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] dark:bg-[#171719]"
              >
                <div className="flex flex-1 flex-col gap-[4px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                  <Text
                    className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
                    variant="body9"
                  >
                    {t("comment")}
                  </Text>
                  <input
                    name="comment"
                    placeholder="..."
                    className=" border-0 outline-none text-indigo_400 font-semibold bg-transparent"
                  />
                </div>
              </div>
              <div
                onClick={() => {
                  !(data.gansabaj === 1 || clickedYesOnClarence) &&
                    setToggleClarenceText((prev) => !prev);
                }}
                className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline outline-[1px]
             outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] dark:bg-[#171719]"
              >
                <Button
                  className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                  variant="icbFillIndigo40014"
                  shape={"circular"}
                >
                  <FaRegFile className="h-[18px] w-[18px] text-indigo_400" />
                </Button>
                <Text
                  className="flex-1 font-fw text-black_900_b7 text-left w-[auto] dark:text-[#c7c7c7]"
                  variant="body7"
                >
                  {t("parcel_clarence")}
                </Text>
                {(data.gansabaj === 1 || clickedYesOnClarence) && (
                  <FaCheck className="text-green-500" />
                )}
              </div>
            </div>
            <div className="w-full border-t border-slate-200 pt-5 gap-[16px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 max-w-[1500px] mx-[auto]">
              <div
                className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline outline-[1px]
             outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] dark:bg-[#171719]"
              >
                <Button
                  className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                  variant="icbFillIndigo40014"
                  shape={"circular"}
                >
                  <BsPerson className="h-[24px] w-[24px] text-indigo_400" />
                </Button>
                <Text
                  className="flex-1 font-fw text-black_900_b7 text-left w-[auto] dark:text-[#c7c7c7]"
                  variant="body7"
                >
                  {t("anotherPerson")}
                </Text>
                <Switch
                  value={data.addon_security && data.addon_security}
                  inputSetter={(value, _) => {
                    setTrustee(value);
                  }}
                />
              </div>
              {trustee === "1" && (
                <>
                  <div
                    className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline 
              outline-[1px] outline-black_900_0a dark:bg-[#171719]
             p-[18px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
                  >
                    <div className="flex flex-1 flex-col gap-[4px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                      <Text
                        className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
                        variant="body9"
                      >
                        {t("name")}
                      </Text>
                      <input
                        name="fname"
                        placeholder="..."
                        className=" border-0 outline-none text-indigo_400 font-semibold bg-transparent"
                      />
                    </div>
                  </div>
                  <div
                    className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline 
              outline-[1px] outline-black_900_0a dark:bg-[#171719]
             p-[18px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
                  >
                    <div className="flex flex-1 flex-col gap-[4px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                      <Text
                        className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
                        variant="body9"
                      >
                        {t("surname")}
                      </Text>
                      <input
                        name="lname"
                        placeholder="..."
                        className=" border-0 outline-none text-indigo_400 font-semibold bg-transparent"
                      />
                    </div>
                  </div>
                  <div
                    className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline 
              outline-[1px] outline-black_900_0a dark:bg-[#171719]
             p-[18px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
                  >
                    <div className="flex flex-1 flex-col gap-[4px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                      <Text
                        className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
                        variant="body9"
                      >
                        {t("id_num")}
                      </Text>
                      <input
                        name="opersonNumber"
                        placeholder="..."
                        type="number"
                        className=" border-0 outline-none text-indigo_400 font-semibold bg-transparent w-full"
                      />
                    </div>
                  </div>
                  <div
                    className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline 
              outline-[1px] outline-black_900_0a dark:bg-[#171719]
             p-[18px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
                  >
                    <div className="flex flex-1 flex-col gap-[4px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                      <Text
                        className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#c7c7c7]"
                        variant="body9"
                      >
                        {t("phone")}
                      </Text>
                      <input
                        name="ophone"
                        placeholder="..."
                        type="number"
                        className=" border-0 outline-none text-indigo_400 font-semibold bg-transparent w-full"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="bg-white_A700 flex flex-col font-firago h-[100%] items-start justify-start max-w-[1500px] 
          mx-[auto] outline outline-[1px] outline-black_900_0a md:px-0 sm:px-0 rounded-radius17 shadow-bs 
          w-[100%] dark:bg-[#171719]"
            >
              <div
                className="border-b-[1px] border-black_900_14 border-solid flex flex-row gap-[20px] items-center 
            justify-between p-[20px] w-[100%] sm:flex-col sm:items-start dark:border-[#aeaeb2]"
              >
                <div className="flex items-center gap-5">
                  <Button
                    className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                    variant="icbFillIndigo40014"
                    shape="circular"
                  >
                    <CiBoxes className="h-[24px] w-[24px] text-indigo_400" />
                  </Button>
                  <Text
                    className="flex-1 font-bold text-black_900_e0 text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]
                    dark:text-[#c7c7c7]"
                    variant="body3"
                  >
                    {t("products")}
                  </Text>
                </div>
              </div>
              <div className="flex flex-col font-notosansgeorgian h-[100%] items-start justify-start p-[20px] w-[100%]">
                <div className="w-full overflow-auto flex flex-wrap justify-between gap-y-5 max-h-72 p-2 scrollbar">
                  <div
                    onClick={handleAddProductModalOpen}
                    className="w-[49%] md:w-full sm:w-full flex items-center justify-center p-6 bg-[#5856d6] bg-opacity-10
                    border border-dashed border-[#5856D6] rounded-xl cursor-pointer"
                  >
                    <div className="flex items-center gap-4">
                      <div
                        className="h-[40px] w-[40px] p-3 outline outline-[1px] outline-black_900_0a
                       bg-white_A700 shadow-bs2 rounded-radius50 flex justify-center items-center"
                      >
                        <HiPlus className="w-full h-full text-indigo_400" />
                      </div>
                      <div>
                        <p className="font-fw text-base text-black_900_b7 sm:text-xs dark:text-[#c7c7c7]">
                          {t("add_new_product")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {newPackItems.length !== 0 &&
                    newPackItems.map((item) => {
                      return (
                        <DeclarationItem
                          key={item.id}
                          id={item.id}
                          price={item.product_price}
                          name={item.product_name}
                          currency={item.currency}
                          handleAddProductModalOpen={handleAddProductModalOpen}
                          handleDeleteNewItem={handleDeleteNewItem}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <AddProductModal
        addProductModalOpen={addProductModalOpen}
        handleAddProductModalOpen={handleAddProductModalOpen}
        handleNewPackItems={handleNewPackItems}
        aoId={data.ao_id}
      />

      {toggleClarenceText && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black z-50 bg-opacity-80 flex items-center justify-center p-10">
          <div className="bg-white rounded-[30px] sm:rounded-[20px] p-10 md:p-5 sm:p-5 w-6/12 md:w-8/12 sm:w-full">
            <div className="flex items-center justify-between">
              <p className="font-bold text-lg sm:text-sm">
                {t("reallyWantClarenceText")}
              </p>
              <BsX
                onClick={() => setToggleClarenceText((prev) => !prev)}
                className="text-4xl text-red-500"
              />
            </div>
            <p className="text-sm mt-5 sm:text-xs">
              {t("clarence_caution_text")}
            </p>
            <div className="flex items-center gap-3 justify-end mt-5">
              <div
                className="rounded-lg px-4 py-2 bg-slate-200"
                onClick={() => setToggleClarenceText((prev) => !prev)}
              >
                <p className="sm:text-sm">{t("no")}</p>
              </div>
              <div
                className="rounded-lg px-4 py-2 bg-green-200"
                onClick={handleConfirmClarence}
              >
                <p className="sm:text-sm">{t("yes")}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeclarationaddedPage;

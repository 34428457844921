import axios from "axios";

const axiosAdmin = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/admin`,
});

axiosAdmin.interceptors.request.use((config) => {
  const token = localStorage.getItem("EXPR_TOKEN_ADM");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosAdmin.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response.status === 401) {
      localStorage.removeItem("EXPR_TOKEN_ADM");
    } else if (response.status === 404) {
    }

    throw error;
  }
);

export default axiosAdmin;

import Routes from "./Routes";
import favicon from "./favicon.ico";
import { useEffect } from "react";

function App() {

  // Function to clear complete cache data
  const clearCacheData = () => {
    const oldToken = localStorage.getItem("EXPR_TOKEN")
    const oldTokenAdm = localStorage.getItem("EXPR_TOKEN_ADM")
    const pageNum = localStorage.getItem("EXPRPPGNUM")
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.setItem("EXPR_TOKEN", oldToken)
    localStorage.setItem("EXPR_TOKEN_ADM", oldTokenAdm)
    localStorage.setItem("EXPRPPGNUM", pageNum)
  };

  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/png';
    link.rel = 'icon';
    link.href = favicon;
    document.head.appendChild(link);

    clearCacheData()
  }, []);

  useEffect(() => {
    // Define the gtag() function
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    // Initialize gtag with your tracking ID
    gtag('js', new Date());
    gtag('config', 'G-ZEJXT31TCF');
  }, []);

  return <Routes />;
}

export default App;

import { Text } from "../../components/Text";
import { AiOutlineInbox } from "react-icons/ai";
import { useTranslation } from "react-i18next";


const DecDetailsCard = ({
    data,
}) => {
    //translations 
    const { t } = useTranslation();
    return (
        <div className="w-[48%] bg-gray_600_14 border border-black_900_0a border-solid flex
    gap-[16px] items-center justify-start p-[20px] rounded-radius13 md:w-full sm:w-full">
            <div className="rounded-full flex h-[48px] items-center justify-center w-[48px]">
                <AiOutlineInbox className="h-[24px] w-[24px] text-indigo_400" />
            </div>
            <div className="flex flex-1 flex-col items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                <Text
                    className="font-semibold text-black_900_b7 text-left w-[auto] dark:text-[#aeaeb2]"
                    variant="body5"
                >
                    {t(`${data.name}`)}
                </Text>
                <Text
                    className="font-fw text-black_900_99 text-left w-[auto] uppercase dark:text-[#c7c7c7]"
                    variant="body7"
                >
                    {data.price} {data.currency}
                </Text>
            </div>
        </div >
    );
};

export default DecDetailsCard;

import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function TextEditor({
  name,
  getData,
  geInputName,
  enInputName,
  ruInputName,
  geValue,
  enValue,
  ruValue,
}) {
  const languages = ["ge", "en", "ru"];
  const [selectedLanguage, setSelectedLanguage] = useState("ge");
  const [editorValue, setEditorValue] = useState({
    ge: "<p><br></p>",
    en: "<p><br></p>",
    ru: "<p><br></p>",
  });

  const handleEditorChange = (value) => {
    setEditorValue((prev) => ({ ...prev, [selectedLanguage]: value }));
    if (getData) {
      let key =
        selectedLanguage === "ge"
          ? geInputName
          : selectedLanguage === "en"
          ? enInputName
          : ruInputName;
      getData(value, key);
    }
  };

  useEffect(() => {
    if (geValue || enValue || ruValue) {
      setEditorValue({
        ge: geValue ? geValue : "<p><br></p>",
        en: enValue ? enValue : "<p><br></p>",
        ru: ruValue ? ruValue : "<p><br></p>",
      });
    }
  }, [geValue, enValue, ruValue]);

  const modules = {
    toolbar: [
      [
        { size: ["small", false, "large", "huge"] },
        { header: [1, 2, 3, 4, 5, 6, false] },
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        { list: "ordered" },
        { list: "bullet" },
        { script: "sub" },
        { script: "super" },
        { color: [] },
        { background: [] },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
        "clean",
      ],
    ],
  };
  return (
    <div className="relative mt-5">
      <div className="flex items-center justify-between mb-1">
        {name && <p className="font-customSecondary text-xs mb-2">{name}</p>}

        <div className="flex items-center gap-3 ">
          {languages.map((language) => {
            return (
              <div
                key={language}
                onClick={() => {
                  setSelectedLanguage(language);
                }}
                className={`w-10 h-10 flex items-center justify-center border dark:border-slate-600 
                  border-gray-300 rounded-lg cursor-pointer ${
                    selectedLanguage === language && "bg-violet-400"
                  }`}
              >
                <p className="text-sm text-gray-500 dark:text-gray-300">
                  {language}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <ReactQuill
        value={editorValue[selectedLanguage]}
        onChange={handleEditorChange}
        modules={modules}
      />
      <textarea
        style={{ display: "none" }}
        value={editorValue[selectedLanguage]}
        readOnly
      />
    </div>
  );
}

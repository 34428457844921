import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { PrimmaryContext } from "../MainContext/MainContext";

const LanguageSwitcherAdmin = ({ userRole }) => {
    const { setLanguage, language } = useContext(PrimmaryContext);
    const [value, setValue] = useState(language);

    useEffect(() => {
        if (value !== language) {
            setLanguage(value);
        }
    }, [value])

    useEffect(() => {
        if (userRole === 2 || userRole === 3) {
            setValue("en")
        }
    }, [])

    return (
        <div className="flex items-center gap-2 px-2">
            <p
                onClick={() => { setValue("ge") }}
                className="text-[#5856D6] text-[16px] font-bold hover:bg-slate-200 p-1 rounded-lg cursor-pointer">
                GE
            </p>
            <p
                onClick={() => { setValue("en") }}
                className="text-[#5856D6] text-[16px] font-bold hover:bg-slate-200 p-1 rounded-lg cursor-pointer">
                EN
            </p>
        </div >
    )
}

export default LanguageSwitcherAdmin;
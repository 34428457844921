import { Text } from "../Text";
import { Button } from "../Button";
import { List } from "../List";
import { useTranslation } from "react-i18next";

//icons
import {
    FaUserSecret, FaUserNinja,
    FaUser, FaUserTie
} from "react-icons/fa";

const RegistrationPanelPath = ({
    stepOneActive = false,
    stepOnePassed = false,
    stepTwoActive = false,
    stepTwoPassed = false,
    stepThreeActive = false,
    stepThreePassed = false,
    stepFourActive = false,
    stepFourPassed = false,
}) => {
    //translations 
    const { t } = useTranslation();

    return (
        <List
            className="sm:flex-col flex-row md:gap-[20px] grid md:grid-cols-2 grid-cols-4 justify-start 
            max-w-[1122px] mx-[auto] md:px-[20px] w-[100%]"
            orientation="horizontal"
        >
            <div className="flex flex-1 flex-col gap-[20px] items-start justify-start sm:ml-[0] w-[100%]">
                <div className="flex flex-row gap-[16px] items-center justify-start w-[100%] sm:justify-center" >
                    <Button
                        className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                        variant={stepOneActive ? "icbOutlineIndigo400" : stepOnePassed ? "icbFillIndigo400" : "icbOutlineBlack9001e"}
                        shape="circular"
                    >
                        <FaUserSecret className={`h-[17px]  ${stepOnePassed ? "text-white" : "text-[#5856d6]"}`} />
                    </Button>
                    <div className="flex flex-1 flex-col gap-[5px] items-start justify-start md:self-stretch sm:self-stretch 
                    w-[100%] sm:hidden">
                        <Text
                            className="font-semibold text-indigo_400 text-left tracking-ls11 uppercase w-[auto]"
                            variant="body9"
                        >
                            {t("step")} 1
                        </Text>
                        <Text
                            className="font-fw text-black_900_e0 text-left w-[auto]"
                            variant="body5"
                        >
                            {t("user_type")}
                        </Text>
                    </div>
                </div>
                <div className={`rounded-l-full h-[4px] w-[100%] 
                ${(stepOneActive || stepOnePassed) ? "bg-[#5856D6]" : "bg-[#dedfe4]"}`}></div>
            </div>
            <div className="flex flex-1 flex-col gap-[20px] items-start justify-start sm:ml-[0] w-[100%]">
                <div className="flex flex-row gap-[16px] items-center justify-start w-[100%] sm:justify-center">
                    <Button
                        className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                        variant={stepTwoActive ? "icbOutlineIndigo400" : stepTwoPassed ? "icbFillIndigo400" : "icbOutlineBlack9001e"}
                        shape="circular"
                    >
                        <FaUserNinja className={`h-[17px]  ${stepTwoPassed ? "text-white" : "text-[#5856d6]"}`} />
                    </Button>
                    <div className="flex flex-1 flex-col gap-[5px] items-start justify-start md:self-stretch sm:self-stretch 
                    w-[100%] sm:hidden">
                        <Text
                            className="font-semibold text-black_900_99 text-left tracking-ls11 uppercase w-[auto]"
                            variant="body9"
                        >
                            {t("step")} 2
                        </Text>
                        <Text
                            className="font-fw text-black_900_e0 text-left w-[auto]"
                            variant="body5"
                        >
                            {t("personal_info")}
                        </Text>
                    </div>
                </div>
                <div className={`h-[4px] w-[100%] 
                ${(stepTwoActive || stepTwoPassed) ? "bg-[#5856D6]" : "bg-[#dedfe4]"}`}></div>
            </div>
            <div className="flex flex-1 flex-col gap-[20px] items-start justify-start sm:ml-[0] w-[100%]">
                <div className="flex flex-row gap-[16px] items-center justify-start w-[100%] sm:justify-center">
                    <Button
                        className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px] "
                        variant={stepThreeActive ? "icbOutlineIndigo400" : stepThreePassed ? "icbFillIndigo400" : "icbOutlineBlack9001e"}
                        shape="circular"
                    >
                        <FaUser className={`h-[17px]  ${stepThreePassed ? "text-white" : "text-[#5856d6]"}`} />
                    </Button>
                    <div className="flex flex-1 flex-col gap-[5px] items-start justify-start md:self-stretch sm:self-stretch 
                    w-[100%] sm:hidden">
                        <Text
                            className="font-semibold text-black_900_99 text-left tracking-ls11 uppercase w-[auto]"
                            variant="body9"
                        >
                            {t("step")} 3
                        </Text>
                        <Text
                            className="font-fw text-black_900_e0 text-left w-[auto]"
                            variant="body5"
                        >
                            {t("contact_info")}
                        </Text>
                    </div>
                </div>
                <div className={`h-[4px] w-[100%] 
                ${(stepThreeActive || stepThreePassed) ? "bg-[#5856D6]" : "bg-[#dedfe4]"}`}></div>
            </div>
            <div className="flex flex-1 flex-col gap-[20px] items-start justify-start sm:ml-[0] w-[100%]">
                <div className="flex flex-row gap-[16px] items-center justify-start w-[100%] sm:justify-center">
                    <Button
                        className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                        variant={stepFourActive ? "icbOutlineIndigo400" : stepFourPassed ? "icbFillIndigo400" : "icbOutlineBlack9001e"}
                        shape="circular"
                    >
                        <FaUserTie className={`h-[17px]  ${stepFourPassed ? "text-white" : "text-[#5856d6]"}`} />
                    </Button>
                    <div className="flex flex-1 flex-col gap-[5px] items-start justify-start md:self-stretch sm:self-stretch 
                    w-[100%] sm:hidden">
                        <Text
                            className="font-semibold text-black_900_99 text-left tracking-ls11 uppercase w-[auto]"
                            variant="body9"
                        >
                            {t("step")} 4
                        </Text>
                        <Text
                            className="font-fw text-black_900_e0 text-left w-[auto]"
                            variant="body5"
                        >
                            {t("security")}
                        </Text>
                    </div>
                </div>
                <div className={`rounded-r-full h-[4px] w-[100%] 
                ${(stepFourActive || stepFourPassed) ? "bg-[#5856D6]" : "bg-[#dedfe4]"}`}></div>
            </div>
        </List>
    )
}

export default RegistrationPanelPath;
import { useState, useEffect, useContext } from "react";
import { successToast, errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { Switch } from "../../components/CustomInputs/CustomSwitchComponent";
import AddCurriorOnPackageItem from "./AddCurriorOnPackageItem";
import axiosClient from "../../api/api";
import { PrimmaryContext } from "../../components/MainContext/MainContext";

//icons
import { CiBoxes } from "react-icons/ci";
import { BiBarcodeReader, BiLoaderAlt } from "react-icons/bi";
import { BsTruck, BsX } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import SelecWithValue from "../../components/CustomInputs/SelectWithValue";
import { useTranslation } from "react-i18next";

const AddCurriorOnPackage = ({
    arrPackageDetails,
    handleArrPackageDetails,
    data,
    setUpdatePackData,
    shouldUpdate = true
}) => {
    //translations 
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(null);
    //selected address 
    const [selectedAddresses, setSelectedAdresses] = useState({});
    //user
    const { addresses } = useContext(PrimmaryContext);
    //phone number
    const [phone, setPhone] = useState("");
    //switch value
    const [switchValue, setSwitchValue] = useState(0);
    // response loading
    const [isLoading, setIsLoading] = useState(false);

    const handleSwitchChange = (value) => {
        setSwitchValue(value)
    }

    //open and close this tab
    useEffect(() => {
        setIsOpen(arrPackageDetails);
    }, [arrPackageDetails]);

    //handle selected value
    const handleSelectedValue = (value, inputName) => {
        setSelectedAdresses({ [inputName]: value })
    }

    const submitFunction = async (event) => {
        setIsLoading(true)
        try {
            const form = event.target;
            const formData = new FormData(form);

            formData.append("transport_address", selectedAddresses.transport_address);

            const res = await axiosClient.post(
                `Pack/declaration/${data.id}`,
                formData
            );

            if (res) {
                setUpdatePackData(res);
                successToast(t("success"))
                handleArrPackageDetails();
                setIsLoading(false)
            }
        } catch (err) {
            errorToast(t("error"))
            setIsLoading(false)
        }
    }

    //change current user data in db
    function handleSubmit(event) {
        event.preventDefault();
        if (data.transport_price === data.transport_paid) {
            if (phone.length === 9) {
                if (data.addon_transportation === 1) {
                    submitFunction(event)
                } else {
                    if (switchValue === "1") {
                        if (selectedAddresses.transport_address !== "" &&
                            selectedAddresses.transport_address !== null &&
                            selectedAddresses.transport_address !== undefined
                        ) {
                            submitFunction(event)
                        } else {
                            errorToast(t("address_required"))
                        }
                    } else {
                        errorToast(t("check_currior_box"))
                    }
                }
            } else if (phone.length === 0) {
                errorToast(t("fill_phone_required"));
            } else {
                errorToast(t("invalid_phone"));
            }
        } else {
            errorToast(t("pay_transportation"));
        }

    }

    useEffect(() => {
        if (data.transport_phone !== null && data.transport_phone !== undefined) {
            setPhone(data.transport_phone)
        }
        if (data.addon_transportation !== null && data.addon_transportation !== undefined) {
            setSwitchValue(data.addon_transportation.toString())
        }
    }, [])

    return (
        <div
            className={`popup_container2 bg-gray_100 flex flex-col font-firago items-start justify-start outline
       outline-[1px] outline-black_900_1e fixed bottom-0 left-0 transition-all delay-50 duration-300 ease-linear 
       overflow-y-auto overflow-x-hidden scrollbar dark:bg-[#0a0a0c] ${isOpen ? "translate-y-0 z-50" : "translate-y-full z-0"}`}
        >
            <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                className="w-full"
            >
                <header className="bg-white_A700 border-b-[1px] border-black_900_14 
                dark:bg-[#171719] border-solid flex flex-row gap-[8px] items-center justify-center
                 py-[16px] w-[100%] px-10 sm:px-[20px] md:px-[40px]">
                    <div className="w-full max-w-[1500px] flex items-center justify-between">
                        <Text
                            className="flex-1 font-bold text-black_900_e0 text-left
                            dark:text-[#c7c7c7] tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto] sm:hidden"
                            variant="body3"
                        >
                            {t("courier_service")}
                        </Text>
                        <div className="flex gap-4 items-center font-notosansgeorgian sm:w-full sm:justify-between">

                            {isLoading ?
                                <div
                                    className="cursor-pointer font-semibold min-w-[181px] text-[14px] w-[auto] rounded-radius11
               p-[10px] bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700
               flex items-center justify-center">
                                    <BiLoaderAlt className="w-6 h-6 animate-spin text-teal_600" />
                                </div>
                                :
                                shouldUpdate &&
                                <button
                                    type="submit"
                                    className="cursor-pointer font-semibold min-w-[181px] text-[14px] text-center w-[auto] rounded-radius11
              p-[10px] bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
                                >
                                    {t("save")}
                                </button>
                            }
                            <BsX
                                onClick={handleArrPackageDetails}
                                className="h-[36px] w-[24px] text-black_900_b7 dark:text-[#c7c7c7]"
                            />
                        </div>
                    </div>
                </header>

                <div className="flex flex-col font-notosansgeorgian gap-[24px] h-[100%] items-start justify-start py-[24px] w-[100%]
                    px-10 sm:px-[20px] md:px-[40px]">
                    <div
                        className="gap-[16px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-start max-w-[1500px] 
          mx-[auto] w-[100%] x-10"
                    >
                        <div
                            className="bg-white_A700 dark:bg-[#171719] flex flex-1 flex-row gap-[12px] items-center justify-start outline
             outline-[1px] outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
                        >
                            <Button
                                className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                                size="mdIcn"
                                variant="icbFillIndigo40014"
                                shape={"circular"}
                            >
                                <BiBarcodeReader className="h-[17px] w-[17px] text-indigo_400" />
                            </Button>
                            <div className="flex flex-col gap-[4px] items-start justify-start w-[auto]">
                                <Text
                                    className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
                                    variant="body9"
                                >
                                    {t("Tracking")}
                                </Text>
                                <Text
                                    className="font-semibold text-indigo_400 text-left w-[auto]"
                                    variant="body7"
                                >
                                    {data && data.tracking_number}
                                </Text>
                            </div>
                        </div>
                        {shouldUpdate ?
                            <>
                                <div
                                    className="flex items-center justify-between pr-[20px] dark:bg-[#171719]
              bg-white_A700 shadow-bs p-5 rounded-radius17 outline outline-[1px] outline-black_900_0a"
                                >
                                    <div className="flex items-center gap-2">
                                        <Button
                                            className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                                            variant="icbFillIndigo40014"
                                            shape="circular"
                                        >
                                            <BsTruck className="h-[17px] w-[17px] text-indigo_400" />
                                        </Button>
                                        <Text
                                            className="font-fw text-black_900_b7 text-left dark:text-[#c7c7c7]"
                                            variant="body7"
                                        >
                                            {t("courier_service")}
                                        </Text>
                                    </div>
                                    <Switch
                                        value={data && data.addon_transportation}
                                        inputName={"addon_transportation"}
                                        inputSetter={handleSwitchChange}
                                    />
                                </div>
                                <SelecWithValue
                                    name={data.transport_address !== null ? data.transport_address : t("address")}
                                    options={addresses}
                                    inputName={"transport_address"}
                                    inputSetter={handleSelectedValue}
                                />
                                <div
                                    className="bg-white_A700 flex dark:bg-[#171719] flex-1 flex-row gap-[12px] items-center justify-start outline outline-[1px] outline-black_900_0a
             p-[18px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
                                >
                                    <div className="flex flex-1 flex-col gap-[4px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                                        <Text
                                            className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
                                            variant="body9"
                                        >
                                            {t("phone")}
                                        </Text>
                                        <input
                                            name="transport_phone"
                                            placeholder={data && data.transport_phone}
                                            className=" border-0 outline-none text-indigo_400 font-semibold bg-transparent"
                                            value={phone}
                                            onChange={(e) => { setPhone(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            </> :
                            <>
                                <div
                                    className="bg-white_A700 dark:bg-[#171719] flex flex-1 flex-row gap-[12px] items-center justify-start outline outline-[1px] outline-black_900_0a
             p-[18px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] min-h-[80px]"
                                >
                                    <div className="flex flex-1 flex-col gap-[4px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                                        <Text
                                            className="font-normal not-italic text-black_900_99 text-left w-[auto]"
                                            variant="body9"
                                        >
                                            {t("address")}
                                        </Text>
                                        <Text
                                            className="font-semibold text-indigo_400 text-left w-[auto]"
                                            variant="body7"
                                        >
                                            {data && data.transport_address}
                                        </Text>
                                    </div>
                                </div>
                                <div
                                    className="bg-white_A700 dark:bg-[#171719] flex flex-1 flex-row gap-[12px] items-center justify-start outline outline-[1px] outline-black_900_0a
             p-[18px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] min-h-[80px]"
                                >
                                    <div className="flex flex-1 flex-col gap-[4px] items-start justify-start md:self-stretch sm:self-stretch w-[100%]">
                                        <Text
                                            className="font-normal not-italic text-black_900_99 text-left w-[auto]"
                                            variant="body9"
                                        >
                                            {t("phone")}
                                        </Text>
                                        <Text
                                            className="font-semibold text-indigo_400 text-left w-[auto]"
                                            variant="body7"
                                        >
                                            {data && data.transport_phone}
                                        </Text>
                                    </div>
                                </div>
                            </>
                        }

                    </div>

                    <div className="font-firago h-full w-full max-w-[1500px] mx-[auto] ">
                        {switchValue === "1" &&
                            <p className="text-sm text-red-400 mb-5"> {t("curriorText")}</p>
                        }
                        <div
                            className="bg-white_A700 outline-[1px] outline-black_900_0a rounded-radius17 shadow-bs w-full 
            h-full flex flex-col items-start justify-start outline dark:bg-[#171719]"
                        >
                            <div
                                className="border-b-[1px] border-black_900_14 border-solid flex flex-row gap-[20px] items-center 
            justify-between p-[20px] w-[100%] sm:flex-col sm:items-start dark:border-[#aeaeb2]"
                            >
                                <div className="flex items-center gap-5">
                                    <Button
                                        className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                                        variant="icbFillIndigo40014"
                                        shape="circular"
                                    >
                                        <CiBoxes className="h-[24px] w-[24px] text-indigo_400" />
                                    </Button>
                                    <Text
                                        className="flex-1 font-bold text-black_900_e0 dark:text-[#c7c7c7]
                                        text-left tracking-ls04 md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                                        variant="body3"
                                    >
                                        {t("products")}
                                    </Text>
                                </div>
                                <div
                                    className="bg-gray_600_14 flex flex-row font-notosansgeorgian gap-[8px] items-start justify-start 
              px-[16px] py-[12px] rounded-radius11 w-[auto] sm:w-full sm:justify-center"
                                >
                                    <Text
                                        className="font-fw text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
                                        variant="body9"
                                    >
                                        {t("sum")}:
                                    </Text>
                                    <Text
                                        className="font-semibold text-black_900_b7 text-left w-[auto] dark:text-[#c7c7c7]"
                                        variant="body7"
                                    >
                                        {data.total_price?.toFixed(1)} ₾
                                    </Text>
                                </div>
                            </div>
                            <div className="flex flex-col font-notosansgeorgian h-[100%] items-start justify-start p-[20px] w-[100%]">
                                <div className="w-full overflow-auto flex flex-wrap justify-between gap-y-5 max-h-72 scrollbar p-2">
                                    {data.pack_items.map((item) => {
                                        return <AddCurriorOnPackageItem key={item.id} data={item} />;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddCurriorOnPackage;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput, MultyLangualTextInput } from "../../inputComponents/inputcomp";
// import { useAxios, updateRecord } from "../../../../api/Api";

//images
import logo from '../../../img/phoneLogo.png';

const InnerAbOfficesPage = () => {
    //get current office id from params
    const { officeId } = useParams();

    //new ad office data
    const [adOfficeData, setAdOfficeData] = useState({
        address: "",
        address2: "",
        city: "",
        country: "",
        district: "",
        mail: "",
        neighborhood: "",
        phone: "",
        price: 0,
        title: "",
        titleen: "",
        titleru: "",
        zip: "",
    });
    //get ad office data error from api
    const [adOfficeDataErr, setAdOfficeDataErr] = useState(null);
    //get ad office edit error from api
    const [adOfficeEditErr, setAdOfficeEditErr] = useState(null);

    //handle new admin
    const handleNewAdOfficeData = (value, inputName) => {
        setAdOfficeData(prev => ({ ...prev, [inputName]: value }))
    }

    //edit ad office
    const handleEditAdOffice = async () => {
        try {
            const res = await axiosAdmin.post(`/Ab_office/edit_post/${officeId}`, adOfficeData);
            if (res) {
                successToast("მონაცემები წარმატებით განახლდა!");
            }
        } catch (error) {
            setAdOfficeEditErr(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //ad office data from api
    useEffect(() => {
        try {
            axiosAdmin.get(`/Ab_office/edit/${officeId}`).then(({ data }) => {
                setAdOfficeData(data);
            });
        } catch (error) {
            setAdOfficeDataErr(error);
        }
    }, []);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <div className="sm:px-0">
                <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                    <div className="flex items-center justify-between flex-wrap gap-5">
                        <div className="flex items-center gap-5 sm:gap-3">
                            <div className="w-10 h-10 ">
                                <img src={logo} className="w-full h-full rounded-full"></img>
                            </div>
                            <div>
                                <h2 className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]">საწყობის რედაქტირება</h2>
                            </div>
                        </div>
                        <button
                            onClick={handleEditAdOffice}
                            className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                            შენახვა
                        </button>
                    </div>
                    < MultyLangualTextInput
                        name={"დასახელება"}
                        geInputName={"title"}
                        enInputName={"titleen"}
                        ruInputName={"titleru"}
                        inputSetter={handleNewAdOfficeData}
                        value={adOfficeData.title}
                    />
                    <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                        <div className="w-6/12 sm:w-full md:w-full">
                            <TextInput
                                name={"ქვეყანა"}
                                inputName={"country"}
                                inputSetter={handleNewAdOfficeData}
                                value={adOfficeData.country}
                            />
                            <TextInput
                                name={"რაიონი"}
                                inputName={"district"}
                                inputSetter={handleNewAdOfficeData}
                                value={adOfficeData.district}
                            />
                            <TextInput
                                name={"მისამართი1"}
                                inputName={"address"}
                                inputSetter={handleNewAdOfficeData}
                                value={adOfficeData.address}
                            />
                            <TextInput
                                name={"ტელეფონი"}
                                inputName={"phone"}
                                inputSetter={handleNewAdOfficeData}
                                value={adOfficeData.phone}
                            />
                            <TextInput
                                name={"ZIP"}
                                inputName={"zip"}
                                inputSetter={handleNewAdOfficeData}
                                value={adOfficeData.zip}
                            />
                        </div>
                        <div className="w-6/12 sm:w-full md:w-full">
                            <TextInput
                                name={"პროვინცია"}
                                inputName={"city"}
                                inputSetter={handleNewAdOfficeData}
                                value={adOfficeData.city}
                            />
                            <TextInput
                                name={"სამეზობლო"}
                                inputName={"neighborhood"}
                                inputSetter={handleNewAdOfficeData}
                                value={adOfficeData.neighborhood}
                            />
                            <TextInput
                                name={"მისამართი2"}
                                inputName={"address2"}
                                inputSetter={handleNewAdOfficeData}
                                value={adOfficeData.address2}
                            />
                            <TextInput
                                name={"ელ.ფოსტა"}
                                inputName={"mail"}
                                inputSetter={handleNewAdOfficeData}
                                value={adOfficeData.mail}
                            />
                            <TextInput
                                name={"1 კგ. ფასი"}
                                inputName={"price"}
                                inputSetter={handleNewAdOfficeData}
                                value={adOfficeData.price}
                                type="number"
                            />
                            <TextInput
                                name={"რეალური / მოცულობითი"}
                                inputSetter={handleNewAdOfficeData}
                                inputName={"price_text"}
                                value={adOfficeData.price_text}
                                type="text"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InnerAbOfficesPage;
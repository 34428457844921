import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "../../components/Img";
import { Text } from "../../components/Text";
import { FaBalanceScale } from "react-icons/fa";

import chinaImg from "../../img/countryFlags/china.png";
import turkeyImg from "../../img/countryFlags/turkey.png";
import usaImg from "../../img/countryFlags/usa.png";
import greeceImg from "../../img/countryFlags/greece.png";
import ukImg from "../../img/countryFlags/uk.png";

const CustomsClearanceCard = ({
  data,
  handleSelectedPackages,
  isChecked,
  strangeFlag,
}) => {
  const { t } = useTranslation();
  const [selectedStatus, setSelectStatus] = useState(false);

  useEffect(() => {
    if (isChecked !== undefined && isChecked !== null) {
      setSelectStatus(isChecked);
    }
  }, [isChecked]);

  useEffect(() => {

    if (strangeFlag && selectedStatus) {
      if(!strangeFlag.includes(data.id)){
        setSelectStatus(false);
      }
    }
  }, [strangeFlag]);

  return (
    <div
      className="bg-white_A700 cursor-pointer flex flex-col items-start justify-center
outline outline-[2px] outline-white_A700 rounded-radius13 shadow-bs5 w-[100%] dark:bg-[#171719] dark:outline-none"
    >
      <div
        className="flex items-center justify-between
p-[16px] w-[100%] md:items-start sm:items-start sm:gap-2 sm:flex-wrap md:flex-wrap"
      >
        <div className="flex flex-row gap-[16px] items-center justify-start w-[auto]">
          <input
            type="checkbox"
            onChange={(e) => {
              handleSelectedPackages(e, data.id);
              setSelectStatus(e.target.checked);
            }}
            checked={selectedStatus}
          />
          <div className="flex flex-col items-start justify-start w-[auto]">
            <Text
              className="font-semibold text-indigo_400 text-left w-[auto]"
              variant="body5"
            >
              {data && data.tracking_number}
            </Text>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <div
            className="flex items-center justify-center text-center w-[auto] rounded-radius16
px-5 py-[6px] bg-gray_600_14 text-black_900_99 gap-2 sm:gap-1 sm:px-2 dark:bg-[#48484a]"
          >
            <FaBalanceScale className="text-center text-indigo_400" />
            <div
              className="bg-transparent cursor-pointer font-normal not-italic 
text-[15px] text-black_900_99 text-left"
            >
              <span className="sm:hidden dark:text-[#aeaeb2]">
                {t("weight")}{" "}
              </span>
              <span className="font-semibold text-left text-green-500 w-[auto]">
                {data && data.total_weight} KG
              </span>
            </div>
          </div>
          <Img
            src={
              data.ao_id === 5
                ? chinaImg
                : data.ao_id === 1
                ? turkeyImg
                : data.ao_id === 16
                ? usaImg
                : data.ao_id === 20
                ? greeceImg
                : ukImg
            }
            className="h-[32px] w-[32px] object-contain"
            alt="#"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomsClearanceCard;

import { TextInput } from "../../inputComponents/inputcomp";
import { useContext, useEffect, useState } from "react";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";
import { useTranslation } from "react-i18next";

//icons
import { BiLockAlt } from "react-icons/bi";
import { BsFillEnvelopeFill } from "react-icons/bs";

const EditProfile = () => {
    //translations
    const { t } = useTranslation();
    // user Data
    const { user } = useContext(AdminPrimaryContext);
    //new password 
    const [newPass, setNewPass] = useState({
        old: "",
        pass: "",
        repass: "",
    })
    //new password with email
    const [newPassWithEmail, setNewPassWithEmail] = useState({
        email: ""
    })

    //handle new password with email
    const handleNewPassWithEmail = (value, inputName) => {
        setNewPassWithEmail(prev => ({ ...prev, [inputName]: value }));
    }

    //handle new password 
    const handleNewPass = (value, inputName) => {
        setNewPass(prev => ({ ...prev, [inputName]: value }));
    }

    //change password with (api)
    const handeChangePassword = async () => {
        try {
            const res = await axiosAdmin.post(`/Login/change`, newPass);
            if (res) {
                successToast(t("success"));
            }
        } catch (err) {
            errorToast(t("error"));
        }
    }

    //change password with (api)
    const handeChangePasswordwithEmail = async () => {
        try {
            const res = await axiosAdmin.get(`/Login/resetPass/${newPassWithEmail.email}`);
            if (res) {
                successToast("success");
            }
        } catch (err) {
            errorToast(t("error"));
        }
    }

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900 min-h-screen">
            <div className="p-10 max-sm:p-5 flex justify-center">
                <div className="w-full rounded-xl bg-white first-letter p-5 dark:bg-slate-700 max-lg:order-2">
                    <div className="flex justify-between items-center border-b border-gray-300
                        dark:border-slate-600 pb-3 max-sm:flex-col max-md:gap-2.5 max-sm:items-start">
                        <div className=" flex items-center space-x-4 rounded-t-lg">
                            <div>
                                <p className="text-base font-medium text-slate-700 hover:text-primary focus:text-primary dark:text-navy-100 
                                dark:hover:text-accent-light dark:focus:text-accent-light dark:text-gray-300">
                                    {`${user.fname} ${user.lname}`}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="border-b border-gray-300 pb-8">
                        <p className="mt-5 text-base font-medium text-slate-700 hover:text-primary focus:text-primary dark:text-navy-100 
                                dark:hover:text-accent-light dark:focus:text-accent-light dark:text-gray-300">
                            {t("change_pass")}
                        </p>
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
                            <TextInput
                                name={t("new_password")}
                                inputName={"pass"}
                                inputSetter={handleNewPass}
                                Icon={BiLockAlt}
                                type={"password"}
                            />
                            <TextInput
                                name={t("conf_password")}
                                inputName={"repass"}
                                inputSetter={handleNewPass}
                                Icon={BiLockAlt}
                                type={"password"}
                            />
                            <TextInput
                                name={t("old_password")}
                                inputName={"old"}
                                inputSetter={handleNewPass}
                                Icon={BiLockAlt}
                                type={"password"}
                            />
                        </div>
                        <div className="mt-5">
                            <button
                                onClick={handeChangePassword}
                                className="max-sm:w-full rounded-lg p-3 text-white text-sm bg-violet-600 dark:bg-slate-900">
                                {t("save")}
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className="mt-5 text-base font-medium text-slate-700 hover:text-primary focus:text-primary dark:text-navy-100 
                                dark:hover:text-accent-light dark:focus:text-accent-light dark:text-gray-300">
                            {t("change_pass_email")}
                        </p>
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
                            <TextInput
                                name={t("email")}
                                inputName={"email"}
                                inputSetter={handleNewPassWithEmail}
                                Icon={BsFillEnvelopeFill}
                            />
                        </div>
                        <div className="mt-5">
                            <button
                                onClick={handeChangePasswordwithEmail}
                                className="max-sm:w-full rounded-lg p-3 text-white text-sm bg-violet-600 dark:bg-slate-900">
                                {t("save")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EditProfile;
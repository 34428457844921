import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";
import {
  TextInput,
  FileInput,
  SelectInputValueId,
} from "../../inputComponents/inputcomp";
// import { useAxios, updateRecord } from "../../../../api/Api";

import categoryOptions from "./selectData";

//images
import logo from "../../../img/phoneLogo.png";
import defaultImg from "../../../img/profile.jpg";

const InnerCategoryPage = () => {
  //get category id
  const { categoryId } = useParams();
  //new category data
  const [categoryData, setCategoryData] = useState({});
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //current category tracker
  const [currentCategory, setCurrentCategory] = useState({
    cat: 1,
  });
  //ab offices with weight and parcel quantity
  const [foreignAddresses, setForeignAddresses] = useState([]);

  //handle current input
  const handleCurrentInput = (value, inputName) => {
    setCurrentCategory((prev) => ({ ...prev, [inputName]: value }));
  };

  //category data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Category/edit/${categoryId}`).then(({ data }) => {
        setCategoryData(data[0]);
        setCurrentCategory((prev) => ({
          ...prev,
          cat: data[0].cat,
          country: data[0].country,
        }));
      });
    } catch (error) {}
  }, [difResponses]);

  //change category data
  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.append("cat", currentCategory.cat);
    formData.append("country", currentCategory.country);

    axiosAdmin
      .post(`/Category/edit_post/${categoryId}`, formData)
      .then((res) => {
        setDifResponses(res);
        successToast("კატეგორია წარმატებით შეიცვალა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  }

  //on page load
  useEffect(() => {
    axiosAdmin
      .get(`/Pack/officeInfo/2`)
      .then((res) => {
        setForeignAddresses(
          res.data
            .map((item) => {
              return {
                value: item.id,
                name: item.title,
              };
            })
            ?.filter((item) => item.value !== 19)
        );
      })
      .catch((err) => {});
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          encType="multipart/form-data"
        >
          <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
            <div className="flex items-center justify-between flex-wrap gap-5">
              <div className="flex items-center gap-5 sm:gap-3">
                <div className="w-10 h-10 ">
                  <img src={logo} className="w-full h-full rounded-full"></img>
                </div>
                <div>
                  <h2
                    className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                  >
                    კატეგორიების რედაქტირება
                  </h2>
                </div>
              </div>
              <button
                type="submit"
                className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
              >
                შენახვა
              </button>
            </div>
            <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
              <div
                className="w-[300px] h-[300px] md:w-full sm:w-full bg-slate-100 rounded-lg
                            p-5 dark:bg-slate-800"
              >
                <img
                  src={
                    categoryData.img !== "" && categoryData
                      ? `${process.env.REACT_APP_API_URL}/storage/uploads/img/category/${categoryData.img}`
                      : defaultImg
                  }
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="w-10/12 sm:w-full md:w-full">
                <SelectInputValueId
                  name={"კატეგორია"}
                  options={categoryOptions}
                  inputName={"cat"}
                  inputSetter={handleCurrentInput}
                  value={currentCategory?.cat}
                />
                <TextInput
                  name={"ლინკ"}
                  inputName={"link"}
                  value={categoryData.link}
                />
                <SelectInputValueId
                  name={"ქვეყანა"}
                  options={foreignAddresses}
                  inputName={"country"}
                  inputSetter={handleCurrentInput}
                  value={categoryData?.country}
                />
                <FileInput name={"ფოტოს ატვირთვა"} inputName={"img"} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InnerCategoryPage;

import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../../api/apiAdmin";
import InnerCuriorCard from "./InnerCuriorCard";

//icons
import GoBackButton from "../../../../../components/GoBackButton/GoBackButton";
import { BsX } from "react-icons/bs";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";

const InnerCurior = () => {
  //context data
  const { user } = useContext(AdminPrimaryContext);
  // id
  const { curierId } = useParams();
  // data
  const [data, setData] = useState([]);
  // dataErr
  const [dataErr, setDataErr] = useState([]);
  //updater
  const [updater, setUpdater] = useState([]);
  //selected branch
  const [selectedBranch, setSelectedBranch] = useState(1);
  //get filial data from api
  const [filialData, setFilialData] = useState([]);

  //clear branch id
  const handleClearBranchId = () => {
    setSelectedBranch("");
  };

  // data fetching
  useEffect(() => {
    axiosAdmin
      .get(`/Pack/courier/${curierId}?branchId=${selectedBranch}`)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        setDataErr(err);
      });
  }, [updater,selectedBranch]);

  //filial data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/Branches/index`).then(({ data }) => {
        setFilialData(data);
      });
    } catch (error) {}
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="w-full flex gap-10 items-center justify-between md:flex-col sm:flex-col md:items-start sm:items-start">
        <div>
          <GoBackButton />
          <h2 className="text-slate-400 text-2xl">საკურიერო</h2>
        </div>
        <div className="flex items-center gap-3 md:w-full sm:w-full sm:flex-col">
          {user.role !== 8 &&
            filialData.map((item) => {
              return (
                <div
                  className={`p-4 border border-slate-200 rounded-lg  flex items-center justify-between  sm:w-full
                        ${
                          item.id === selectedBranch
                            ? "bg-slate-200"
                            : "bg-white"
                        }`}
                  key={item.id}
                >
                  <p
                    className="text-slate-500 cursor-pointer"
                    onClick={() => {
                      setSelectedBranch(item.id);
                    }}
                  >
                    {item.title}
                  </p>
                  {item.id === selectedBranch && (
                    <BsX
                      className="text-red-500 cursor-pointer text-xl"
                      onClick={() => {
                        handleClearBranchId();
                      }}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className="w-full">
        <div>
          {data.length === 0 && (
            <h2 className="text-slate-400 text-md">მონაცემები არ არის...</h2>
          )}
        </div>
        <div className="w-full flex flex-col gap-3 mt-5">
          {data.map((data) => {
            return (
              <InnerCuriorCard
                key={data.id}
                data={data}
                setUpdater={setUpdater}
                curierId={curierId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InnerCurior;

import { forwardRef, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TextInput } from "../../inputComponents/inputcomp";
import axiosAdmin from "../../../../api/apiAdmin";
import ContinueComponent from "../../../../components/Alerts/ContinueComponent";

//icons
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";
import { BiSave } from "react-icons/bi";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";

const UsCard = ({
    data,
    handleAccessUser,
    handleDeleteUser
}) => {
    //error
    const [error, setError] = useState(0);
    //user type
    const [userType, setUserType] = useState(0);
    //special prices
    const [specialPrices, setSpecialPrices] = useState({
        "1": "",
        "5": ""
    });

    //user
    const { user } = useContext(AdminPrimaryContext);
    //show contnue
    const [showContinue, setShowContinue] = useState(false);

    //handle special prices
    const handleSpecialPrices = (value, inputName) => {
        setSpecialPrices(prev => ({ ...prev, [inputName]: value }))
    }
    //const set special prices
    const handleSetSpecialPrices = async () => {
        try {
            const res = await axiosAdmin.post(
                `/Users/update_special_price/${data.id}`,
                { special_price: { ...specialPrices } });
        } catch (err) {
            setError(err);
        }
    }

    //set user type on load
    useEffect(() => {
        setUserType(data.user_type);
        if (data.special_price) {
            setSpecialPrices(JSON.parse(data.special_price))
        }
    }, [data]);

    return (
        <div className="bg-white rounded-xl p-5 w-full relative
    flex items-center justify-center gap-3 dark:bg-slate-700 flex-col">
            <div className="w-full flex flex-wrap ">
                <div className="flex flex-col gap-2 w-3/12 md:w-6/12 sm:w-full sm:gap-0 md:gap-0">
                    <div className="flex gap-1">
                        <p className="text-[12px] text-[#989ba5]">ტიპი:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {userType === 1 ? "ფიზიკური პირი" : (userType === 2 && "იურიდიულიი პირი")}
                        </p>
                    </div>
                    <div className="flex gap-1">
                        <p className="text-[12px] text-[#989ba5]">ტელეფონი:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {data.phone}
                        </p>
                    </div>
                    <div className="flex gap-1">
                        <p className="text-[12px] text-[#989ba5]">უცხო ქვეყნის მოქალაქე:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {data.is_foreign !== 0 ? "არის" : "არაა"}
                        </p>
                    </div>
                </div>
                <div className="flex flex-col gap-2 w-3/12 md:w-6/12 sm:w-full sm:gap-0 md:gap-0">
                    <div className="flex gap-1">
                        <p className="text-[12px] text-[#989ba5]">ოთახის ნომერი:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            EG{data.id}
                        </p>
                    </div>
                    <div className="flex gap-1">
                        <p className="text-[12px] text-[#989ba5]">სახელი & გვარი:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {userType === 1 ? `${data.fname} ${data.lname}` :
                                (userType === 2 && `${data.ceo_fname} ${data.ceo_lname}`)}
                        </p>
                    </div>
                    {userType === 2 &&
                        <div className="flex gap-1">
                            <p className="text-[12px] text-[#989ba5]">საიდენთიფიკაციო კოდი:</p>
                            <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                                {data.company_id}
                            </p>
                        </div>
                    }
                </div>
                <div className="flex flex-col gap-2 w-3/12 md:w-6/12 sm:w-full sm:gap-0 md:gap-0">
                    <div className="flex gap-1">
                        <p className="text-[12px] text-[#989ba5]">პირადი ნომერი:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {userType === 1 ? data.id_num : (userType === 2 && data.ceo_id_num)}
                        </p>
                    </div>
                    <div className="flex gap-1">
                        <p className="text-[12px] text-[#989ba5]">ელ.ფოსტა:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{data.email}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-2 w-3/12 md:w-6/12 sm:w-full sm:gap-0 md:gap-0">
                    <div className="flex gap-1">
                        <p className="text-[12px] text-[#989ba5]">კომპანიის დასახელება: </p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{data.company_name}</p>
                    </div>
                    <div className="flex gap-1">
                        <p className="text-[12px] text-[#989ba5]">მისამართი:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{data.address}</p>
                    </div>
                </div>
            </div>
            <div className="flex gap-2 w-full justify-between">
                <div className="flex flex-wrap gap-1 items-center">
                    {(!(user.role === 4) && !(user.role === 8)) &&
                        <Link to={`../siteUsers/${data.id}`} className="w-32 rounded-lg bg-violet-400
                flex gap-2 items-center justify-center p-1 py-3 md:w-[49%] sm:w-full mt-3">
                            <PencilSquare className="text-white font-bold" />
                            <p className="text-white text-sm font-bold">რედაქტირება</p>
                        </Link>
                    }
                    <div
                        onClick={() => { handleAccessUser(data.id) }}
                        className="w-32 rounded-lg bg-green-400 cursor-pointer
             flex gap-2 items-center justify-center p-1 py-3 md:w-[49%] sm:w-full mt-3">
                        <PencilSquare className="text-white font-bold" />
                        <p
                            className="text-white text-sm font-bold">შესვლა</p>
                    </div>
                    {(!(user.role === 4) && !(user.role === 5) && !(user.role === 6) && !(user.role === 8)) &&
                        <div className="flex items-center justify-between gap-2">
                            <TextInput
                                placeholder={"სპეც. ფასი Turkey %"}
                                inputName={"1"}
                                inputSetter={handleSpecialPrices}
                                value={data.special_price && JSON.parse(data.special_price)["1"]}
                            />
                            <TextInput
                                placeholder={"სპეც. ფასი China %"}
                                inputName={"5"}
                                inputSetter={handleSpecialPrices}
                                value={data.special_price && JSON.parse(data.special_price)["5"]}
                            />
                            <TextInput
                                placeholder={"სპეც. ფასი USA %"}
                                inputName={"16"}
                                inputSetter={handleSpecialPrices}
                                value={data.special_price && JSON.parse(data.special_price)["16"]}
                            />
                            <TextInput
                                placeholder={"სპეც. ფასი China Land %"}
                                inputName={"19"}
                                inputSetter={handleSpecialPrices}
                                value={data.special_price && JSON.parse(data.special_price)["19"]}
                            />
                            <div
                                onClick={handleSetSpecialPrices}
                                className="w-10 h-10 rounded-lg bg-violet-400
             flex gap-2 items-center justify-center p-1 py-3 mt-3 cursor-pointer">
                                <BiSave className="text-white font-bold" />
                            </div>
                        </div>
                    }
                </div>
                {user.role === 100 &&
                    <div
                        onClick={() => { setShowContinue(true) }}
                        className="w-16 rounded-lg bg-red-400 flex items-center justify-center 
                        sm:absolute sm:top-3 sm:right-3 sm:w-8 sm:h-8 cursor-pointer mt-3">
                        <BsTrash className="text-white" />
                    </div>
                }
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDeleteUser}
                id={data.id}
            />
        </div>
    )
}

export default UsCard;

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const CustomSelectInput = ({
  name,
  PrefixIcon = null,
  SuffixIcon = null,
  options,
  inputName,
  inputSetter = null,
  mFill,
  value,
  clearFields = "",
  setClearFields,
  placeholder = null,
  noDark
}) => {
  //translations
  const { t } = useTranslation();
  //toggle variable
  const [isOpen, setIsOpen] = useState(false);
  //current selected value
  const [selectValue, setSelectValue] = useState("");
  //selected value title
  const [selectValueTitle, setSelectValueTitle] = useState("");
  //if filed is empty but required we must show
  const [mustFill, setMustFill] = useState("");

  //select handler
  const handleSelect = (index, title) => {
    setSelectValue(index);
    setSelectValueTitle(title);
    inputSetter(index, inputName);
  };

  //toggle handler
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  //whenever filed is required and user does not
  //filled it
  useEffect(() => {
    if (selectValueTitle !== "") {
      setMustFill(false);
    } else {
      setMustFill(mFill);
    }
  }, [mFill, selectValueTitle]);

  useEffect(() => {
    if (value !== undefined) {
      inputSetter(value, inputName);
      options.forEach((element) => {
        if (element.id === value) {
          setSelectValueTitle(element.title);
        }
      });
    }
  }, [value]);


  //clear field if nesecity
  useEffect(() => {
    if (clearFields !== "" && clearFields) {
      setSelectValue("");
      setSelectValueTitle("");
      setClearFields(false);
    }
  }, [clearFields]);

  return (
    <div
      className={`w-[100%] bg-white_A700 px-4 py-2 rounded-xl border  border-solid shadow-bs
       ${noDark ? "" : "dark:bg-[#171719]"}
    ${isOpen
          ? "border-[#5856d6] outline outline-[#c9c9f6]"
          : mustFill
            ? "border-[#f61b17] outline outline-[#f7bebd]"
            : "border-bluegray_100"
        }`}
    >
      <div className="flex items-center gap-4">
        {PrefixIcon !== null && (
          <PrefixIcon className={`w-4 h-4 text-[#636366] 
           ${noDark ? "" : "dark:text-[#c7c7c7]"}`} />
        )}

        <div
          onClick={() => {
            handleOpen();
          }}
          className={`w-full p-2 rounded-lg focus:outline-slate-300 relative 
                 ${noDark ? "" : "dark:text-gray-300 dark:border-slate-600 dark:focus:outline-slate-600 dark:bg-[#171719]"}`}
        >
          <div className="h-[22px] flex justify-between items-center">
            <div className="flex flex-col gap-1">
              {placeholder !== null && (
                <p className={"text-[12px] text-[#5856d6]"}>
                  {t(`${placeholder}`)}
                </p>
              )}
              <p className={`text-sm text-gray-500  ${noDark ? "" : "dark:bg-[#171719]"}`}>
                {selectValueTitle === "" ? name : t(`${selectValueTitle}`)}
              </p>
            </div>
            {isOpen ? (
              <BiChevronUp className={`text-sm text-gray-500 
               ${noDark ? "" : "dark:text-[#c7c7c7]"}`} />
            ) : (
              <BiChevronDown className={`text-sm text-gray-500 
              ${noDark ? "" : "dark:text-[#c7c7c7]"}`} />
            )}
          </div>
          {isOpen && (
            <div
              className={`w-full max-h-80  overflow-y-auto scrollbar bg-white rounded-lg
                        absolute bottom-0 left-0 translate-y-[105%] border border-slate-300 z-40
                        ${noDark ? "" : "dark:bg-[#171719]  dark:border-slate-600"}`}
            >
              {options.map((value) => {
                return (
                  <div
                    onClick={() => {
                      handleSelect(value.id, value.title);
                    }}
                    key={value.id}
                    className="p-3 w-full hover:bg-slate-300 group"
                  >
                    <p
                      className={`text-sm text-gray-500 font-bold group-hover:text-slate-800 
                      ${noDark ? "" : "dark:text-[#c7c7c7]"}`}
                    >
                      {t(`${value.title}`)}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {SuffixIcon !== null && (
          <SuffixIcon className={`w-4 h-4 text-[#636366] 
          ${noDark ? "" : "dark:text-[#c7c7c7]"}`} />
        )}
      </div>
    </div >
  );
};

export default CustomSelectInput;

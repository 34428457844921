import { Link } from "react-router-dom";
import { useState } from "react";
import ContinueComponent from "../../../../components/Alerts/ContinueComponent";

//icons
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";

const AbOfficeCard = ({
    id,
    name,
    country,
    province,
    address,
    zip,
    phone,
    handleDeleteAbOffice
}) => {
    //show contnue
    const [showContinue, setShowContinue] = useState(false);

    return (
        <div className="bg-white rounded-xl p-5 relative
        w-full flex flex-col items-center justify-between gap-3 dark:bg-slate-700">
            <div
                onClick={() => { setShowContinue(true) }}
                className="w-8 h-8 rounded-lg bg-red-400 flex items-center justify-center
            absolute top-3 right-3 cursor-pointer">
                <BsTrash className="text-white" />
            </div>
            <div className="w-full flex flex-col gap-2">
                <h2 className="text-[14px] font-bold text-[#777a83] dark:text-navy-100 dark:text-gray-200">{name}</h2>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">ქვეყანა:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{country}</p>
                </div>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">პროვინცია:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{province}</p>
                </div>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">მისამართი:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{address}</p>
                </div>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">ZIP:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{zip}</p>
                </div>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">ტელეფონი:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{phone}</p>
                </div>
            </div>
            <div className="w-full flex gap-2 md:flex-col sm:flex-col">
                <Link to={`../ab-office/${id}`} className="w-full rounded-lg bg-violet-400
                 flex gap-2 items-center justify-center p-1 py-3">
                    <PencilSquare className="text-white font-bold" />
                    <p className="text-white text-sm font-bold">რედაქტირება</p>
                </Link>
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDeleteAbOffice}
                id={id}
            />
        </div>
    )
}


export default AbOfficeCard;
import { useState, useEffect } from "react";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { useTranslation } from "react-i18next";
import DecDetailsCard from "./DecDetailsCard";
import { Link } from "react-router-dom";
import axiosClient from "../../api/api";
import {
  successToast,
  errorToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";

//icons
import { CiBoxes } from "react-icons/ci";
import { BiBarcodeReader, BiMap, BiSave } from "react-icons/bi";
import { BsReceipt, BsShield, BsX } from "react-icons/bs";
import UploadInvoice from "../../components/CustomInputs/UploadInvoice";

const DecDetails = ({
  arrPackageDetails,
  handleArrPackageDetails,
  setUpdatePackData = null,
  data,
}) => {
  //translations
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(null);

  useEffect(() => {
    setIsOpen(arrPackageDetails);
  }, [arrPackageDetails]);

  //change current user data in db
  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const form = event.target;
      const formData = new FormData(form);
      const res = await axiosClient.post(
        `Pack/declaration/${data.id}`,
        formData
      );
      if (res) {
        successToast(t("declaration_successfull"));
        if (setUpdatePackData) {
          setUpdatePackData(res);
        }
      }
    } catch (err) {
      errorToast(t("error"));
    }
  }

  return (
    <>
      <div
        className={`w-[100vw] h-[80vh] bg-gray_100 flex flex-col font-firago items-start justify-start outline
       outline-[1px] outline-black_900_1e md:w-[100%] sm:w-[100%] fixed bottom-0 left-0  dark:bg-[#0a0a0c]
       z-50 tranition-all delay-50 duration-300 ease-linear overflow-auto scrollbar
       ${isOpen ? "translate-y-0" : "translate-y-full"}`}
      >
        <header
          className="bg-white_A700 border-b-[1px] border-black_900_14 border-solid flex flex-row gap-[8px] items-center
         justify-center sm:px-[20px] px-[32px] py-[16px] w-[100%] dark:bg-[#171719]"
        >
          <div className="w-full max-w-[1500px] flex items-center justify-between">
            <Text
              className="flex-1 font-bold text-black_900_e0 text-left tracking-ls04
                             md:tracking-ls1 sm:tracking-ls1 w-[auto] dark:text-[#c7c7c7]"
              variant="body3"
            >
              {t("parcels")} {t("details")}
            </Text>
            <BsX
              onClick={() => {
                handleArrPackageDetails(false);
              }}
              className="h-[36px] w-[24px] text-black_900_b7 cursor-pointer dark:text-[#c7c7c7]"
            />
          </div>
        </header>

        <div className="flex flex-col font-notosansgeorgian gap-[24px] h-[100%] items-start justify-start px-10 sm:px-[20px] md:px-[40px] py-[24px] w-[100%]">
          <div
            className="gap-[16px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-start max-w-[1500px] 
          mx-[auto] w-[100%] x-10"
          >
            <div
              className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline dark:bg-[#171719]
             outline-[1px] outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
            >
              <Button
                className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                size="mdIcn"
                variant="icbFillIndigo40014"
                shape={"circular"}
              >
                <BiBarcodeReader className="h-[17px] w-[17px] text-indigo_400" />
              </Button>
              <div className="flex flex-col gap-[4px] items-start justify-start w-[auto]">
                <Text
                  className="font-normal not-italic text-black_900_99 text-left w-[auto] dark:text-[#aeaeb2]"
                  variant="body9"
                >
                  {t("tracking")}
                </Text>
                <Text
                  className="font-semibold text-indigo_400 text-left w-[auto]"
                  variant="body7"
                >
                  {data && data.tracking_number}
                </Text>
              </div>
            </div>
            <div
              className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline dark:bg-[#171719]
             outline-[1px] outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
            >
              <div className="flex flex-col gap-[4px] items-start justify-start w-[auto]">
                <Text
                  className="font-normal not-italic text-black_900_99 text-left w-[auto]  dark:text-[#aeaeb2]"
                  variant="body9"
                >
                  {t("filials")}
                </Text>
                <Text
                  className="font-semibold text-indigo_400 text-left w-[auto]"
                  variant="body7"
                >
                  {data.abroad_office !== null && data.abroad_office.title}
                </Text>
              </div>
            </div>
            <div
              className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline h-full dark:bg-[#171719]
             outline-[1px] outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
            >
              <div className="flex flex-col gap-[4px] items-start justify-start w-[auto]">
                <Text
                  className="font-normal not-italic text-black_900_99 text-left w-[auto]  dark:text-[#aeaeb2]"
                  variant="body9"
                >
                  {t("web_site")}
                </Text>
                <Text
                  className="font-semibold text-indigo_400 text-left w-[auto]"
                  variant="body7"
                >
                  {data && data.sender_name}
                </Text>
              </div>
            </div>
            <div
              className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline outline-[1px]
             outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] dark:bg-[#171719]"
            >
              <Button
                className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                variant="icbFillIndigo40014"
                shape={"circular"}
              >
                <BsShield className="h-[24px] w-[24px] text-indigo_400" />
              </Button>
              <Text
                className={`flex-1 font-fw text-left w-[auto] ${
                  data.addon_security === 1 ? "text-green-500" : "text-red-500"
                }`}
                variant="body7"
              >
                {t("insurence")}
              </Text>
            </div>
            {data.gansabaj === 1 ? (
              <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                className="w-full flex items-center gap-2"
              >
                <UploadInvoice
                  inputName={"ganbajFile"}
                  name={t("cheque")}
                  invoice={data.ganbajFile}
                />
                <button
                  type="submit"
                  className="cursor-pointer font-semibold text-[17px] text-center w-[auto] rounded-radius11 h-full
                                    p-[20px] bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
                >
                  <BiSave className="w-8 h-8" />
                </button>
              </form>
            ) : (
              <div
                className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline h-full
             outline-[1px] outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] dark:bg-[#171719]"
              >
                <div className="flex flex-col gap-[4px] items-start justify-start w-[auto]">
                  <Text
                    className="font-normal not-italic text-black_900_99 text-left w-[auto]  dark:text-[#aeaeb2]"
                    variant="body9"
                  >
                    {t("comment")}
                  </Text>
                  <Text
                    className="font-semibold text-indigo_400 text-left w-[auto]"
                    variant="body7"
                  >
                    {data && data.comment}
                  </Text>
                </div>
              </div>
            )}
            <div
              className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline dark:bg-[#171719]
             outline-[1px] outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
            >
              <Button
                className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                size="mdIcn"
                variant="icbFillIndigo40014"
                shape={"circular"}
              >
                <BiMap className="h-[17px] w-[17px] text-indigo_400" />
              </Button>
              <div className="flex flex-col gap-[4px] items-start justify-start w-[auto]">
                <Text
                  className="font-normal not-italic text-black_900_99 text-left w-[auto]  dark:text-[#aeaeb2]"
                  variant="body9"
                >
                  {t("address")}
                </Text>
                <Text
                  className="font-semibold text-indigo_400 text-left w-[auto]"
                  variant="body7"
                >
                  {data && data.transport_address}
                </Text>
              </div>
            </div>
            <div
              className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline h-full dark:bg-[#171719]
             outline-[1px] outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%]"
            >
              <div className="flex flex-col gap-[4px] items-start justify-start w-[auto]">
                <Text
                  className="font-normal not-italic text-black_900_99 text-left w-[auto]  dark:text-[#aeaeb2]"
                  variant="body9"
                >
                  {t("phone")}
                </Text>
                <Text
                  className="font-semibold text-indigo_400 text-left w-[auto]"
                  variant="body7"
                >
                  {data && data.transport_phone}
                </Text>
              </div>
            </div>
            {!data.invoice || data.invoice === "" ? (
              <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                className="w-full flex items-center gap-2"
              >
                <UploadInvoice inputName={"invoice"} name={t("add_invoice")} />
                <button
                  type="submit"
                  className="cursor-pointer font-semibold text-[17px] text-center w-[auto] rounded-radius11 h-full
                                    p-[20px] bg-indigo_400 outline outline-[1px] outline-black_900_0a shadow-bs text-white_A700"
                >
                  <BiSave className="w-8 h-8" />
                </button>
              </form>
            ) : (
              <div
                className="bg-white_A700 flex flex-1 flex-row gap-[12px] items-center justify-start outline outline-[1px]
         outline-black_900_0a p-[20px] rounded-radius13 md:self-stretch sm:self-stretch shadow-bs w-[100%] dark:bg-[#171719]"
              >
                <Button
                  className="flex h-[40px] items-center justify-center rounded-radius50 w-[40px]"
                  variant="icbFillIndigo40014"
                  shape={"circular"}
                >
                  <BsReceipt className="h-[24px] w-[24px] text-indigo_400" />
                </Button>
                <Text
                  className={`flex-1 font-fw text-left w-[auto] ${
                    data.addon_security === 1
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                  variant="body7"
                >
                  <Link
                    to={`${process.env.REACT_APP_API_URL}/storage/uploads/pack_files/${data.invoice}`}
                    target={"_blank"}
                  >
                    <p
                      className={`text-[14px] text-indigo_400 underline cursor-pointer`}
                    >
                      {t("invoice")}
                    </p>
                  </Link>
                </Text>
              </div>
            )}
          </div>
          <div className="font-firago h-full w-full max-w-[1500px] mx-[auto] ">
            <div
              className="bg-white_A700 outline-[1px] outline-black_900_0a rounded-radius17 shadow-bs w-full 
            h-full flex flex-col items-start justify-start outline dark:bg-[#171719]"
            >
              <div
                className="border-b-[1px] border-black_900_14 border-solid flex flex-row gap-[20px] items-center 
            justify-between p-[20px] w-[100%] sm:flex-col sm:items-start"
              >
                <div className="flex items-center gap-5">
                  <Button
                    className="flex h-[48px] items-center justify-center rounded-radius50 w-[48px]"
                    variant="icbFillIndigo40014"
                    shape="circular"
                  >
                    <CiBoxes className="h-[24px] w-[24px] text-indigo_400" />
                  </Button>
                  <Text
                    className="flex-1 font-bold text-black_900_e0 text-left tracking-ls04 
                                        dark:text-[#aeaeb2] md:tracking-ls1 sm:tracking-ls1 w-[auto]"
                    variant="body3"
                  >
                    {t("products")}
                  </Text>
                </div>
                <div
                  className="bg-gray_600_14 flex flex-row font-notosansgeorgian gap-[8px] items-start justify-start 
              px-[16px] py-[12px] rounded-radius11 w-[auto] sm:w-full sm:justify-center"
                >
                  <Text
                    className="font-fw text-black_900_99 text-left w-[auto]  dark:text-[#aeaeb2]"
                    variant="body9"
                  >
                    {t("sum")}:
                  </Text>
                  <Text
                    className="font-semibold text-black_900_b7 text-left w-[auto]  dark:text-[#c7c7c7]"
                    variant="body7"
                  >
                    {data.total_price?.toFixed(1)} ₾
                  </Text>
                </div>
              </div>
              <div className="flex flex-col font-notosansgeorgian h-[100%] items-start justify-start p-[20px] w-[100%]">
                <div className="w-full overflow-auto flex flex-wrap justify-between gap-y-5 max-h-72 scrollbar p-2">
                  {data.pack_items.map((item) => {
                    return <DecDetailsCard key={item.id} data={item} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DecDetails;

import { createContext, useState, useEffect } from "react";
import axiosClient from "../../api/api";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationGe from "../Languages/Ge";
import translationEn from "../Languages/En";
import translationRu from "../Languages/Ru";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEn },
      ge: { translation: translationGe },
      ru: { translation: translationRu },
    },
    lng: "ge",
    fallbackLng: 'ge',
    interpolation: { escapeValue: false },
  });

const PrimmaryContext = createContext(null);

const MainContext = ({ children }) => {
  // disable scroll on certain moments
  const [scrollFlagOff, setScrollFlagOff] = useState(false);
  //userInfo
  const [user, setUser] = useState({});
  //update user
  const [updateUser, setUpdateUser] = useState(null);
  //token
  const [token, _setToken] = useState(localStorage.getItem("EXPR_TOKEN"));
  //filghts data
  const [flightsData, setFlightsData] = useState([]);
  //social media links
  const [socialMediaData, setsocialMediaData] = useState({
    fb: "",
    ins: "",
    yt: "",
    mailGeneral: "",
    phoneGeneral: "",
  });
  //filials contact data
  const [filialsData, setFilialsData] = useState([]);
  //ge branches contact data
  const [branchesData, setBranchesData] = useState([]);
  //unauthorized branch data
  const [unauthorizedBranches, setUnauthorizedBranches] = useState([]);
  //register user variable
  const [registerCredetials, setRegisterCredentials] = useState({
    // fname: "",
    // fname_eng: "",
    // lname: "",
    // lname_eng: "",
    // email: "",
    // phone: "",
    // id_num: "",
    // address: "",
    // password: "",
    // passwordConf: "",
    // ceo_fname: "",
    // ceo_fname_eng: "",
    // ceo_lname: "",
    // ceo_lname_eng: "",
    // company_name: "",
    // ceo_id_num: "",
    // company_id: "",
    city: "",
    // birthday: "",
    gender: "",
    branch_id: "",
    // user_type: "",
  });

  //dark theme control
  const [theme, setTheme] = useState(localStorage.getItem("exprDark"));
  //page data api err
  const [pageDataErr, setPageDataErr] = useState(null);
  //check if user is authorized
  const [isAuthorized, setIsAuthorized] = useState(false);
  //pages data
  const [pagesData, setPagesData] = useState(false);
  //money stuff
  const [moneyData, setMoneyData] = useState({});
  //renew money response
  const [renewMoneyData, setRenewMoneyData] = useState({});
  //language
  const [language, setLanguage] = useState("ge");
  //product names
  const [products, setProducts] = useState([]);
  //declaration pack count
  const [shouldDeclare, setShouldDeclare] = useState(0);
  //renew declared data 
  const [updateDeclare, setUpdateDeclare] = useState(null);
  //country prices
  const [prices, setPrices] = useState([])
  //express addresses
  const [addresses, setAdresses] = useState([]);

  //scrollbar hide show
  const handleScollOff = (value) => {
    setScrollFlagOff(value);
  };

  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
    localStorage.setItem("exprDark", theme === "dark" ? "light" : "dark")
  };

  //setting token
  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("EXPR_TOKEN", token);
    } else {
      localStorage.removeItem("EXPR_TOKEN");
    }
  };

  //register credentials handler
  const handleRegCredentials = (value, inputName) => {
    setRegisterCredentials((prev) => ({ ...prev, [inputName]: value }));
  };

  //language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  //get current logged in user
  useEffect(() => {
    axiosClient.get("/Auth/isLogedin").then(({ data }) => {
      setUser(data.user);
    });
  }, [token, updateUser]);

  //get current logged in user
  useEffect(() => {
    axiosClient.get("/Auth/money").then(({ data }) => {
      setMoneyData(data);
    });
  }, [renewMoneyData, user]);

  //check if user is authoruized and save result in state
  useEffect(() => {
    if (user.user_type) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [user]);

  //main page content
  useEffect(() => {
    try {
      axiosClient.get(`/front/index`)
        .then(({ data }) => {
          setPrices(data.prices)
          setFlightsData(data.flights);
          setsocialMediaData({
            fb: data.contact.fb,
            ins: data.contact.ins,
            yt: data.contact.yp,
            mailGeneral: data.contact.mail,
            phoneGeneral: data.contact.phone,
          });
          setFilialsData(data.contact2);
          setPagesData(data.pages);
          setUnauthorizedBranches(data.branch);
        });
    } catch (error) {
      setPageDataErr(error);
    }
  }, []);

  //pack items
  useEffect(() => {
    //get user addresses
    axiosClient
      .get(`/Adres/index`)
      .then(({ data }) => {
        setAdresses(data);
      })
      .catch((err) => { });
    axiosClient
      .get(`/Dashboard/products`)
      .then(({ data }) => {
        setProducts(data);
      })
      .catch((err) => { });
    axiosClient
      .get(`/filials`)
      .then(({ data }) => {
        setBranchesData(data)
      })
      .catch((err) => { });
  }, [user]);
  //update declared items amount number
  useEffect(() => {
    axiosClient
      .get(`/Pack/declarationCount`)
      .then(({ data }) => {
        setShouldDeclare(data);
      })
      .catch((err) => { });
  }, [updateDeclare]);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return (
    <PrimmaryContext.Provider
      value={{
        scrollFlagOff,
        handleScollOff,
        registerCredetials,
        handleRegCredentials,
        user,
        setUser,
        token,
        setToken,
        flightsData,
        socialMediaData,
        filialsData,
        isAuthorized,
        setIsAuthorized,
        pagesData,
        moneyData,
        setRenewMoneyData,
        renewMoneyData,
        language,
        setLanguage,
        setUpdateUser,
        products,
        shouldDeclare,
        setUpdateDeclare,
        branchesData,
        prices,
        setPrices,
        addresses,
        unauthorizedBranches,
        handleThemeSwitch
      }}
    >
      {children}
    </PrimmaryContext.Provider>
  );
};

export { PrimmaryContext, MainContext };

import { BsTrash } from "react-icons/bs";
import { AiOutlineInbox } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const DeclarationItem = ({
  name,
  price,
  handleDeleteNewItem,
  currency,
  id
}) => {
  //translations 
  const { t } = useTranslation();

  return (
    <div className="w-[49%] md:w-full sm:w-full bg-gray_600_14 border border-black_900_0a border-solid
    flex sm:flex-col gap-4 sm:gap-0 items-center justify-between p-5 sm:p-0 rounded-radius13">
      <div className="flex gap-3 items-center sm:w-full sm:justify-start sm:p-4 sm:border-b sm:border-gray-300">
        <div className="h-[40px] w-[40px] p-3 outline outline-[1px] outline-black_900_0a dark:bg-[#48484a]
            bg-white_A700 shadow-bs2 rounded-radius50 flex justify-center items-center">
          <AiOutlineInbox className="w-full h-full text-[#5856D6] dark:text-[#c7c7c7]" />
        </div>

        <div className="flex flex-col">
          <p className="font-semibold text-black_900_b7 text-base dark:text-[#c7c7c7]">
            {t(`${name}`)}
          </p>
          <p className="font-fw text-black_900_99 text-sm dark:text-[#aeaeb2]">
            {price} {currency}
          </p>
        </div>
      </div>
      <div className="flex gap-2 sm:w-full sm:justify-end sm:p-4">
        <BsTrash
          onClick={() => { handleDeleteNewItem(id) }}
          className="text-xl text-red_A400 font-semibold cursor-pointer"
        />
      </div>
    </div>
  );
};

export default DeclarationItem;

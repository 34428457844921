import { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import axiosAdmin from "../../../../api/apiAdmin";
import { DateInput } from "../../inputComponents/inputcomp";
import UserStatisticCard from "./userStatisticCard";

//images
import logo from '../../../img/phoneLogo.png';

const UserStatistics = () => {
    //st id
    const { userStatId } = useParams();
    //data from api
    const [data, setData] = useState(null);
    //data packs
    const [packs, setPacks] = useState([]);
    //data err from api
    const [dataErr, setDataErr] = useState([]);
    //current filial 
    const [currentPlaceId, setCurrentPlaceId] = useState(1);
    //filials data
    const { foreignAdresses } = useContext(AdminPrimaryContext);
    //filter dates
    const [dates, setDates] = useState({
        start: "1990-03-01",
        end: "3001-03-01"
    })

    //handle dates 
    const handleDates = (value, inputName) => {
        setDates(prev => ({ ...prev, [inputName]: value }))
    }

    //current filial handler
    const handleCurrentPlaceId = (value) => {
        setCurrentPlaceId(value);
    }

    //search data
    const handleSearch = async () => {
        try {
            const res = await axiosAdmin.post(
                `Admins/statistics/${userStatId}/${currentPlaceId}`,
                dates
            );
            if ({ res }) {
                setData(res.data)
                setPacks(res.data.packs);
            }
        } catch (err) {
            setDataErr(err);
        }

    }

    //data from api
    useEffect(() => {
        axiosAdmin.post(`Admins/statistics/${userStatId}/${currentPlaceId}`)
            .then(({ data }) => {
                setPacks(data.packs);
                setData(data);
            })
            .catch((err) => {
                setDataErr(err);
            })
    }, [currentPlaceId]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <div className="sm:px-0">
                <div className="w-full">
                    <div className="flex items-center justify-between flex-wrap gap-5">
                        <div className="flex items-center gap-5 sm:gap-3">
                            <div className="w-10 h-10 ">
                                <img src={logo} className="w-full h-full rounded-full"></img>
                            </div>
                            <div>
                                <h2 className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]">ადმინის სტატისტიკა</h2>
                            </div>
                        </div>
                        <div className="flex gap-1 flex-wrap md:w-full sm:w-full">

                            {foreignAdresses.map((item) => {
                                return (
                                    <button
                                        key={item.id}
                                        onClick={() => { handleCurrentPlaceId(item.id) }}
                                        className={`rounded-lg p-3 text-sm sm:w-full border border-violet-400 md:w-[49%]
                                ${currentPlaceId === item.id ? "bg-violet-400 text-white" :
                                                "text-slate-600 dark:bg-slate-800 dark:text-slate-400 bg-white"}`}>
                                        {item.title}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div className="pt-5 flex gap-5 flex-col">
                        <div className="w-full grid grid-cols-4 gap-3">
                            <div className="flex w-full p-3 rounded-lg items-center justify-center flex-col gap-2
                        shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px]
                        dark:bg-slate-800 bg-white md:w-full sm:w-full">
                                <p className="text-[16px] text-[#989ba5]">მომხმარებელი</p>
                                <p className="text-[24px] text-[#989ba5]">{data ? `${data.adminData.fname} ${data.adminData.lname}` : "სახელი"}</p>
                                <p className="text-[14px] text-[#989ba5]">ID: {data && data.adminData.id} || თარიღი: {data && data.adminData.reg_date}</p>
                            </div>
                            <div className="flex w-full p-3 rounded-lg items-center justify-center flex-col gap-2
                        shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px]
                        dark:bg-slate-800 bg-white md:w-full sm:w-full">
                                <p className="text-[16px] text-[#989ba5]">სულ ამანათი</p>
                                <p className="text-[24px] text-[#989ba5]">{data ? data.packs_count : 0}</p>
                                <p className="text-[14px] text-[#989ba5]">ცალი</p>
                            </div>
                            <div className="flex w-full p-3 rounded-lg items-center justify-center flex-col gap-2
                        shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px]
                        dark:bg-slate-800 bg-white md:w-full sm:w-full">
                                <p className="text-[16px] text-[#989ba5]">ტრანსპორტირების ჯამური თანხა</p>
                                <p className="text-[24px] text-[#989ba5]">{data ? data.transaction_count : "0"}</p>
                                <p className="text-[14px] text-[#989ba5]">ლარი</p>
                            </div>
                            <div className="flex w-full p-3 rounded-lg items-center justify-center flex-col gap-2
                        shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px]
                        dark:bg-slate-800 bg-white md:w-full sm:w-full">
                                <p className="text-[16px] text-[#989ba5]">მიღებული მომხმარებელი</p>
                                <p className="text-[24px] text-[#989ba5]">{data ? data.user_count : "0"}</p>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-between p-3 rounded-lg flex-col gap-5">
                            <div className="w-full flex items-end justify-between flex-wrap gap-3">
                                <div className="flex items-end gap-1 md:w-full sm:w-full sm:flex-col">
                                    <DateInput
                                        name="დაწყება"
                                        inputName={"start"}
                                        inputSetter={handleDates}
                                    />
                                    <DateInput
                                        name="დასრულება"
                                        inputName={"end"}
                                        inputSetter={handleDates}
                                    />
                                    <button
                                        onClick={handleSearch}
                                        className="rounded-lg p-[10px] text-sm sm:w-full bg-violet-400 text-white ">
                                        ძებნა
                                    </button>
                                </div>
                                <Link to={`${process.env.REACT_APP_API_URL}/api/admin/Admins/export/${userStatId}/${currentPlaceId}/${dates.start}/${dates.end}`}
                                    className="rounded-lg bg-green-400 sm:w-full
                 flex items-center justify-center p-1 py-3 text-white">
                                    EXPORT CSV
                                </Link>
                            </div>
                            <div className="w-full flex flex-col gap-2">
                                {
                                    packs.map((data) => {
                                        return (
                                            <UserStatisticCard
                                                key={data.id}
                                                data={data}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default UserStatistics;